import { Point, Surface } from "@celonis/surface-core";

interface Particle {
	x: number;
	y: number;
	d: number;
	r: number;
}

export class Snow {
	private _particles: Particle[] = [];
	private _angle: number = 0;
	private _surface: Surface;

	constructor(surface: Surface) {
		this._surface = surface;
	
		setTimeout(() => {
			for (let i = 1; i <= 100; i++) {
				this._particles.push({
					x: Math.random() * this._surface.canvas.width,
					y: Math.random() * this._surface.canvas.height,
					r: (Math.random() * 8) + 1,
					d: Math.random() * 100
				});
			}
		
			const originalRenderFrame = this._surface.renderFrame;
			this._surface.renderFrame = (ctx) => {
				ctx.save();
				originalRenderFrame.call(this._surface, ctx);
				ctx.restore();
				this.render(ctx);
			}
			setInterval(this.update.bind(this), 16);
		
			let lastCameraPosition: Point = new Point(this._surface.camera.x, this._surface.camera.y);
			this._surface.camera.on('move', () => {
				for (const p of this._particles) {
					p.x += this._surface.camera.x - lastCameraPosition.x;
					p.y += this._surface.camera.y - lastCameraPosition.y;
				}
				lastCameraPosition = new Point(this._surface.camera.x, this._surface.camera.y);
			})
		}, 2)
	}

	render(context: CanvasRenderingContext2D) {
		context.save();
		context.fillStyle = "rgba(255, 255, 255, 0.8)";
		context.beginPath();
		for (const p of this._particles) {
			context.moveTo(p.x, p.y);
			context.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
		}
	
		context.fill();
		context.restore();
	}

	update() {
		this._angle += 0.01;

		const W = this._surface.canvas.width;
		const H = this._surface.canvas.height;

		for (let index = 0; index < this._particles.length; index++) {
			const p = this._particles[index];
			p.y += Math.cos(this._angle + p.d) + 1 + (p.r / 2);
			p.x += Math.sin(this._angle) * 2;

			if (p.x > W) p.x = p.x - W;
			if (p.x < 0) p.x = W + p.x;
			if (p.y > H) p.y = p.y - H;
			if (p.y < 0) p.y = H + p.y;
		}
		
		this._surface.dirty = true;
	}
}
