import { Color } from '@celonis/surface-core';
import { Cube } from '../Cube';
import { Car } from '../Car';

export class Porsche extends Car {
  private _wheel1: Cube;
  private _wheel2: Cube;
  private _body: Cube;
  private _top: Cube;
  private _lightBar: Cube;
  private _shade: Cube;

  constructor(x, y, z, color = new Color('#0051e8')) {
    const width = 20;
    const height = 30;
    const wheelColor = color.luminance < .5 ? new Color('#aaa') : new Color('#000')
  
    super(x, y, z, width, height);
    
    this._shade = new Cube(0, 0, 0, width, height, 0);
    this._shade.style.color = new Color('#000').transparent(.1);
    this.attach(this._shade);
  
    this._body = new Cube(0, -8, 3, width, height + 8, 10);
    this._body.style.color = color;
    this.attach(this._body);

    this._top = new Cube(1, 7, 13, 18, 15, 5);
    this._top.style.color = color.darker(.2);
    this.attach(this._top);
  
    this._lightBar = new Cube(0, 29, 7, 20, 1, 2);
    this._lightBar.style.color = new Color('#f00');
    this.attach(this._lightBar);
    
    this._wheel1 = new Cube(20, -2, 0, 2, 6, 6);
    this._wheel1.style.color = wheelColor;
    this.attach(this._wheel1);

	  this._wheel2 = new Cube(20, 18, 0, 2, 6, 6);
    this._wheel2.style.color = wheelColor;
    this.attach(this._wheel2);
  }

  protected override update(): void {
    if (this.direction === 'up' || this.direction === 'down') {
      this._shade.resize(20, 30);
      this._shade.move(0, 0);
      this._body.resize(20, 38);
      this._body.move(0, -8);
      this._top.resize(18, 15);
      this._top.move(1, 7);
      this._wheel1.resize(2, 6);
      this._wheel1.move(20, -2);
      this._wheel2.resize(2, 6);
      this._wheel2.move(20, 18);

      if (this.direction === 'up') {
        this._lightBar.visible = true;
        this._lightBar.resize(20, 1);
        this._lightBar.move(0, 29);
      } else {
        this._lightBar.visible = false;
      }
    } else {
      this._shade.resize(30, 20);
      this._shade.move(0, 0);
      this._body.resize(38, 20);
      this._body.move(-8, 0);
      this._top.resize(15, 18);
      this._top.move(7, 1);
      this._wheel1.resize(6, 2);
      this._wheel1.move(-2, 20);
      this._wheel2.resize(6, 2);
      this._wheel2.move(18, 20);

      if (this.direction === 'left') {
        this._lightBar.visible = true;
        this._lightBar.resize(1, 20);
        this._lightBar.move(29, 0);
      } else {
        this._lightBar.visible = false;
      }
    }
  }
}