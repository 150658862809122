import { Animation, Color } from '@celonis/surface-core';
import { Cube } from './Cube';
import { TILE_SIZE, LAYER_DEPTH } from './constants';
import { Tile } from './Tile';
import { rand } from './utils';

export class Tree extends Tile {
  constructor(cx, cy, cz = 0, size = 30) {
    super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, size, size, 0);

    const offset = (TILE_SIZE - size) / 2;
    this.regX = -offset
    this.regY = -offset;
    this.cube.style.color = new Color('#000').transparent(.1);
    this.cube.style.radius = this.width / 2;
  
    const trunk = new Cube(size / 4, size / 4, 0, size / 3, size / 3, 20);
    trunk.style.color = new Color('#896a57');
    trunk.style.radius = size / 6;
    this.attach(trunk);
  
    let z = 1;
    for (let l = 1; l > .2; l -= .2) {
      const offset = (size - size * l) / 2
      const level = new Cube(offset, offset, 10 + 8 * z++, size * l, size * l, 5);
      level.style.color = Math.round(Math.random()) === 0 ? new Color('#60d9d5') : new Color('#ffffff');
      level.style.radius = size * l / 2;
      this.attach(level);

      const animate = () => {
        if (!this.stage) return;
        
        const delta = rand(3);
        new Animation(level, {
          z: { plus: delta }
        }, {
          duration: 500 + rand(500),
          easing: 'easeInOutQuad',
          complete: () => {
            new Animation(level, {
              z: { minus: delta }
            }, {
              duration: 500 + rand(500),
              easing: 'easeInOutQuad',
              complete: () => {
                animate();
              }
            });
          }
        });
      }

      this.on('attachtostage', animate);
    }
  }
}