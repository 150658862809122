import { Color } from '@celonis/surface-core';
import { TILE_SIZE, LAYER_DEPTH } from './constants';
import { Tile } from './Tile';

const LAYER_COLORS = [
  new Color('#6d5c77'),
  new Color('#d1dff0')
]

export class Ground extends Tile {
  constructor(cx, cy, cz = 0) {
    super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, TILE_SIZE, TILE_SIZE, LAYER_DEPTH);
    this.cube.style.color = LAYER_COLORS[cz];
  }

  get walkable() {
    return true;
  }

  prepare() {
    const neighbors = this.neighbors;

    if (!neighbors.bottom && !neighbors.right) this.cube.style.radiusBottomRight = 20;
    if (!neighbors.top && !neighbors.left) this.cube.style.radiusTopLeft = 20;
    if (!neighbors.top && !neighbors.right) this.cube.style.radiusTopRight = 20;
    if (!neighbors.bottom && !neighbors.left) this.cube.style.radiusBottomLeft = 20;
  }
}