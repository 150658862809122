import { Animation, Color } from '@celonis/surface-core';
import { TILE_SIZE, LAYER_DEPTH, COIN_SIZE, COIN_COLOR } from './constants';
import { Tile } from './Tile';
import { Sphere } from './Sphere';

const COIN_ANIMATION_DELTA = 5
const COIN_ANIMATION_DURATION = 1000

const collectSound = new URL("../../assets/sounds/collect.mp3", import.meta.url).toString();

export class Coin extends Tile {
	private _coin: Sphere;

	constructor(cx, cy, cz = 0) {
		super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, COIN_SIZE / 1.5, COIN_SIZE / 1.5, 0);

		const offset = (TILE_SIZE - COIN_SIZE) / 2;
		this.regX = -offset
		this.regY = -offset;
		this.cube.style.color = new Color('#000').transparent(.1);
		this.cube.style.radius = this.width / 2;

		this._coin = new Sphere(COIN_SIZE / 2, COIN_SIZE / 2, 5, COIN_SIZE);
		this._coin.snapToPixel = false;
		this._coin.style.backgroundColor = new Color(COIN_COLOR);
		this._coin.style.borderColor = new Color(COIN_COLOR).darker(30);
		this._coin.style.borderWidth = 2;
		this.attach(this._coin);

		const animate = () => {
			if (!this.stage) return;

			new Animation(this._coin, {
				z: { plus: COIN_ANIMATION_DELTA }
			}, {
				duration: COIN_ANIMATION_DURATION,
				easing: 'easeInOutQuad',
				complete: () => {
				new Animation(this._coin, {
					z: { minus: COIN_ANIMATION_DELTA }
				}, {
					duration: COIN_ANIMATION_DURATION,
					easing: 'easeInOutQuad',
					complete: () => {
						animate();
					}
				});
				}
			});
		}
	
		this.on('attachtostage', animate);
	}

	get walkable() {
		return true;
	}

	collect() {
		new Audio(collectSound).play();
		this.detach();
	}
}