import { Hygge } from './Hygge';
import * as level1 from './levels/level1';
import * as level2 from './levels/level2';
import * as level3 from './levels/level3';
import * as level4 from './levels/level4';
import * as level5 from './levels/level5';
import * as level6 from './levels/level6';
import * as level7 from './levels/level7';
import * as level8 from './levels/level8';
import * as level9 from './levels/level9';
import * as level10 from './levels/level10';
import * as level11 from './levels/level11';

const debug = document.location.hash === '#debug';
const hygge = window.hygge = new Hygge();
const levels = [level1, level2, level3, level4, level5, level6, level7, level8, level9, level10, level11];
let level = 0;

document.getElementsByTagName('main')[0].appendChild(hygge.surface.canvas);

document.getElementById('isometry')?.addEventListener('input', (e) => {
	hygge.surface.isometry = e.target.value / 100;
})
document.getElementById('debug')?.addEventListener('input', (e) => {
	hygge.surface.debug = e.target.checked;
})
if (debug) {
	document.getElementsByTagName('footer')[0].classList.remove('d-none');
	hygge.surface.toggleStats();
}

hygge.on('stop', event => {
	const finished = event.payload;
	level = finished ? level + 1 : 0;
	if (!levels[level]) level = 0;
	hygge.ui.level = level + 1;
	hygge.start(levels[level]);
});

setTimeout(() => {
	document.getElementsByClassName('logo')[0].classList.remove('jumbo');
	hygge.start(levels[level]);
	//hygge.showcase();
}, debug ? 0 : 2000);