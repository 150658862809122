export const chase = false;
export const clouds = false;
export const hint = 'Use arrow keys to move Hygge.';
export const map = `
xtx
xfx
txx
xxt
xhx
xxx
`