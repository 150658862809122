import { ISOMETRIC_LENGTH } from "@celonis/surface-core";
import { DisplayObject3D } from "./DisplayObject3D";

export class Sphere extends DisplayObject3D {
	constructor(x: number, y: number, z: number, size: number) {
		super(x, y, z, size, size);
	}

	render(context: CanvasRenderingContext2D, options) {
		super.render(context, options);
		
		context.beginPath();
		context.arc(0, 0, this.width / 2, 0, Math.PI * 2);

		if (this.style.actual.backgroundColor) {
			context.fillStyle = this.style.actual.backgroundColor?.toRGBA();
			context.fill();
		}

		if (this.style.actual.borderColor && this.style.actual.borderWidth) {
			context.strokeStyle = this.style.actual.borderColor?.toRGBA();
			context.lineWidth = this.style.actual.borderWidth;
			context.stroke();
		}

	}

	updateTransform() {
		super.updateTransform();

		if (!this.stage) return;

		const depth = -this.width / 2 * ISOMETRIC_LENGTH * this.stage.surface.camera.projectionMatrix.strength;
		this.transform.appendMatrix(this.stage.surface.camera.projectionMatrix.inverted);
		this.transform.translate(0, depth);
	}
}