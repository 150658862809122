var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

var check = function (it) {
  return it && it.Math == Math && it;
};

// https://github.com/zloirock/core-js/issues/86#issuecomment-115759028
var global$i =
  // eslint-disable-next-line es/no-global-this -- safe
  check(typeof globalThis == 'object' && globalThis) ||
  check(typeof window == 'object' && window) ||
  // eslint-disable-next-line no-restricted-globals -- safe
  check(typeof self == 'object' && self) ||
  check(typeof commonjsGlobal == 'object' && commonjsGlobal) ||
  // eslint-disable-next-line no-new-func -- fallback
  (function () { return this; })() || Function('return this')();

var objectGetOwnPropertyDescriptor = {};

var fails$q = function (exec) {
  try {
    return !!exec();
  } catch (error) {
    return true;
  }
};

var fails$p = fails$q;

// Detect IE8's incomplete defineProperty implementation
var descriptors = !fails$p(function () {
  // eslint-disable-next-line es/no-object-defineproperty -- required for testing
  return Object.defineProperty({}, 1, { get: function () { return 7; } })[1] != 7;
});

var fails$o = fails$q;

var functionBindNative = !fails$o(function () {
  // eslint-disable-next-line es/no-function-prototype-bind -- safe
  var test = (function () { /* empty */ }).bind();
  // eslint-disable-next-line no-prototype-builtins -- safe
  return typeof test != 'function' || test.hasOwnProperty('prototype');
});

var NATIVE_BIND$2 = functionBindNative;

var call$c = Function.prototype.call;

var functionCall = NATIVE_BIND$2 ? call$c.bind(call$c) : function () {
  return call$c.apply(call$c, arguments);
};

var objectPropertyIsEnumerable = {};

var $propertyIsEnumerable = {}.propertyIsEnumerable;
// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var getOwnPropertyDescriptor$1 = Object.getOwnPropertyDescriptor;

// Nashorn ~ JDK8 bug
var NASHORN_BUG = getOwnPropertyDescriptor$1 && !$propertyIsEnumerable.call({ 1: 2 }, 1);

// `Object.prototype.propertyIsEnumerable` method implementation
// https://tc39.es/ecma262/#sec-object.prototype.propertyisenumerable
objectPropertyIsEnumerable.f = NASHORN_BUG ? function propertyIsEnumerable(V) {
  var descriptor = getOwnPropertyDescriptor$1(this, V);
  return !!descriptor && descriptor.enumerable;
} : $propertyIsEnumerable;

var createPropertyDescriptor$4 = function (bitmap, value) {
  return {
    enumerable: !(bitmap & 1),
    configurable: !(bitmap & 2),
    writable: !(bitmap & 4),
    value: value
  };
};

var NATIVE_BIND$1 = functionBindNative;

var FunctionPrototype$2 = Function.prototype;
var call$b = FunctionPrototype$2.call;
var uncurryThisWithBind = NATIVE_BIND$1 && FunctionPrototype$2.bind.bind(call$b, call$b);

var functionUncurryThis = NATIVE_BIND$1 ? uncurryThisWithBind : function (fn) {
  return function () {
    return call$b.apply(fn, arguments);
  };
};

var uncurryThis$p = functionUncurryThis;

var toString$d = uncurryThis$p({}.toString);
var stringSlice$7 = uncurryThis$p(''.slice);

var classofRaw$2 = function (it) {
  return stringSlice$7(toString$d(it), 8, -1);
};

var uncurryThis$o = functionUncurryThis;
var fails$n = fails$q;
var classof$6 = classofRaw$2;

var $Object$4 = Object;
var split = uncurryThis$o(''.split);

// fallback for non-array-like ES3 and non-enumerable old V8 strings
var indexedObject = fails$n(function () {
  // throws an error in rhino, see https://github.com/mozilla/rhino/issues/346
  // eslint-disable-next-line no-prototype-builtins -- safe
  return !$Object$4('z').propertyIsEnumerable(0);
}) ? function (it) {
  return classof$6(it) == 'String' ? split(it, '') : $Object$4(it);
} : $Object$4;

// we can't use just `it == null` since of `document.all` special case
// https://tc39.es/ecma262/#sec-IsHTMLDDA-internal-slot-aec
var isNullOrUndefined$5 = function (it) {
  return it === null || it === undefined;
};

var isNullOrUndefined$4 = isNullOrUndefined$5;

var $TypeError$b = TypeError;

// `RequireObjectCoercible` abstract operation
// https://tc39.es/ecma262/#sec-requireobjectcoercible
var requireObjectCoercible$8 = function (it) {
  if (isNullOrUndefined$4(it)) throw $TypeError$b("Can't call method on " + it);
  return it;
};

// toObject with fallback for non-array-like ES3 strings
var IndexedObject$2 = indexedObject;
var requireObjectCoercible$7 = requireObjectCoercible$8;

var toIndexedObject$5 = function (it) {
  return IndexedObject$2(requireObjectCoercible$7(it));
};

var documentAll$2 = typeof document == 'object' && document.all;

// https://tc39.es/ecma262/#sec-IsHTMLDDA-internal-slot
var IS_HTMLDDA = typeof documentAll$2 == 'undefined' && documentAll$2 !== undefined;

var documentAll_1 = {
  all: documentAll$2,
  IS_HTMLDDA: IS_HTMLDDA
};

var $documentAll$1 = documentAll_1;

var documentAll$1 = $documentAll$1.all;

// `IsCallable` abstract operation
// https://tc39.es/ecma262/#sec-iscallable
var isCallable$j = $documentAll$1.IS_HTMLDDA ? function (argument) {
  return typeof argument == 'function' || argument === documentAll$1;
} : function (argument) {
  return typeof argument == 'function';
};

var isCallable$i = isCallable$j;
var $documentAll = documentAll_1;

var documentAll = $documentAll.all;

var isObject$8 = $documentAll.IS_HTMLDDA ? function (it) {
  return typeof it == 'object' ? it !== null : isCallable$i(it) || it === documentAll;
} : function (it) {
  return typeof it == 'object' ? it !== null : isCallable$i(it);
};

var global$h = global$i;
var isCallable$h = isCallable$j;

var aFunction = function (argument) {
  return isCallable$h(argument) ? argument : undefined;
};

var getBuiltIn$6 = function (namespace, method) {
  return arguments.length < 2 ? aFunction(global$h[namespace]) : global$h[namespace] && global$h[namespace][method];
};

var uncurryThis$n = functionUncurryThis;

var objectIsPrototypeOf = uncurryThis$n({}.isPrototypeOf);

var getBuiltIn$5 = getBuiltIn$6;

var engineUserAgent = getBuiltIn$5('navigator', 'userAgent') || '';

var global$g = global$i;
var userAgent$2 = engineUserAgent;

var process = global$g.process;
var Deno = global$g.Deno;
var versions = process && process.versions || Deno && Deno.version;
var v8 = versions && versions.v8;
var match, version;

if (v8) {
  match = v8.split('.');
  // in old Chrome, versions of V8 isn't V8 = Chrome / 10
  // but their correct versions are not interesting for us
  version = match[0] > 0 && match[0] < 4 ? 1 : +(match[0] + match[1]);
}

// BrowserFS NodeJS `process` polyfill incorrectly set `.v8` to `0.0`
// so check `userAgent` even if `.v8` exists, but 0
if (!version && userAgent$2) {
  match = userAgent$2.match(/Edge\/(\d+)/);
  if (!match || match[1] >= 74) {
    match = userAgent$2.match(/Chrome\/(\d+)/);
    if (match) version = +match[1];
  }
}

var engineV8Version = version;

/* eslint-disable es/no-symbol -- required for testing */

var V8_VERSION = engineV8Version;
var fails$m = fails$q;

// eslint-disable-next-line es/no-object-getownpropertysymbols -- required for testing
var symbolConstructorDetection = !!Object.getOwnPropertySymbols && !fails$m(function () {
  var symbol = Symbol();
  // Chrome 38 Symbol has incorrect toString conversion
  // `get-own-property-symbols` polyfill symbols converted to object are not Symbol instances
  return !String(symbol) || !(Object(symbol) instanceof Symbol) ||
    // Chrome 38-40 symbols are not inherited from DOM collections prototypes to instances
    !Symbol.sham && V8_VERSION && V8_VERSION < 41;
});

/* eslint-disable es/no-symbol -- required for testing */

var NATIVE_SYMBOL$1 = symbolConstructorDetection;

var useSymbolAsUid = NATIVE_SYMBOL$1
  && !Symbol.sham
  && typeof Symbol.iterator == 'symbol';

var getBuiltIn$4 = getBuiltIn$6;
var isCallable$g = isCallable$j;
var isPrototypeOf$2 = objectIsPrototypeOf;
var USE_SYMBOL_AS_UID$1 = useSymbolAsUid;

var $Object$3 = Object;

var isSymbol$2 = USE_SYMBOL_AS_UID$1 ? function (it) {
  return typeof it == 'symbol';
} : function (it) {
  var $Symbol = getBuiltIn$4('Symbol');
  return isCallable$g($Symbol) && isPrototypeOf$2($Symbol.prototype, $Object$3(it));
};

var $String$4 = String;

var tryToString$3 = function (argument) {
  try {
    return $String$4(argument);
  } catch (error) {
    return 'Object';
  }
};

var isCallable$f = isCallable$j;
var tryToString$2 = tryToString$3;

var $TypeError$a = TypeError;

// `Assert: IsCallable(argument) is true`
var aCallable$3 = function (argument) {
  if (isCallable$f(argument)) return argument;
  throw $TypeError$a(tryToString$2(argument) + ' is not a function');
};

var aCallable$2 = aCallable$3;
var isNullOrUndefined$3 = isNullOrUndefined$5;

// `GetMethod` abstract operation
// https://tc39.es/ecma262/#sec-getmethod
var getMethod$3 = function (V, P) {
  var func = V[P];
  return isNullOrUndefined$3(func) ? undefined : aCallable$2(func);
};

var call$a = functionCall;
var isCallable$e = isCallable$j;
var isObject$7 = isObject$8;

var $TypeError$9 = TypeError;

// `OrdinaryToPrimitive` abstract operation
// https://tc39.es/ecma262/#sec-ordinarytoprimitive
var ordinaryToPrimitive$1 = function (input, pref) {
  var fn, val;
  if (pref === 'string' && isCallable$e(fn = input.toString) && !isObject$7(val = call$a(fn, input))) return val;
  if (isCallable$e(fn = input.valueOf) && !isObject$7(val = call$a(fn, input))) return val;
  if (pref !== 'string' && isCallable$e(fn = input.toString) && !isObject$7(val = call$a(fn, input))) return val;
  throw $TypeError$9("Can't convert object to primitive value");
};

var shared$4 = {exports: {}};

var global$f = global$i;

// eslint-disable-next-line es/no-object-defineproperty -- safe
var defineProperty$6 = Object.defineProperty;

var defineGlobalProperty$3 = function (key, value) {
  try {
    defineProperty$6(global$f, key, { value: value, configurable: true, writable: true });
  } catch (error) {
    global$f[key] = value;
  } return value;
};

var global$e = global$i;
var defineGlobalProperty$2 = defineGlobalProperty$3;

var SHARED = '__core-js_shared__';
var store$3 = global$e[SHARED] || defineGlobalProperty$2(SHARED, {});

var sharedStore = store$3;

var store$2 = sharedStore;

(shared$4.exports = function (key, value) {
  return store$2[key] || (store$2[key] = value !== undefined ? value : {});
})('versions', []).push({
  version: '3.26.1',
  mode: 'global',
  copyright: '© 2014-2022 Denis Pushkarev (zloirock.ru)',
  license: 'https://github.com/zloirock/core-js/blob/v3.26.1/LICENSE',
  source: 'https://github.com/zloirock/core-js'
});

var requireObjectCoercible$6 = requireObjectCoercible$8;

var $Object$2 = Object;

// `ToObject` abstract operation
// https://tc39.es/ecma262/#sec-toobject
var toObject$6 = function (argument) {
  return $Object$2(requireObjectCoercible$6(argument));
};

var uncurryThis$m = functionUncurryThis;
var toObject$5 = toObject$6;

var hasOwnProperty = uncurryThis$m({}.hasOwnProperty);

// `HasOwnProperty` abstract operation
// https://tc39.es/ecma262/#sec-hasownproperty
// eslint-disable-next-line es/no-object-hasown -- safe
var hasOwnProperty_1 = Object.hasOwn || function hasOwn(it, key) {
  return hasOwnProperty(toObject$5(it), key);
};

var uncurryThis$l = functionUncurryThis;

var id = 0;
var postfix = Math.random();
var toString$c = uncurryThis$l(1.0.toString);

var uid$2 = function (key) {
  return 'Symbol(' + (key === undefined ? '' : key) + ')_' + toString$c(++id + postfix, 36);
};

var global$d = global$i;
var shared$3 = shared$4.exports;
var hasOwn$a = hasOwnProperty_1;
var uid$1 = uid$2;
var NATIVE_SYMBOL = symbolConstructorDetection;
var USE_SYMBOL_AS_UID = useSymbolAsUid;

var WellKnownSymbolsStore = shared$3('wks');
var Symbol$3 = global$d.Symbol;
var symbolFor = Symbol$3 && Symbol$3['for'];
var createWellKnownSymbol = USE_SYMBOL_AS_UID ? Symbol$3 : Symbol$3 && Symbol$3.withoutSetter || uid$1;

var wellKnownSymbol$f = function (name) {
  if (!hasOwn$a(WellKnownSymbolsStore, name) || !(NATIVE_SYMBOL || typeof WellKnownSymbolsStore[name] == 'string')) {
    var description = 'Symbol.' + name;
    if (NATIVE_SYMBOL && hasOwn$a(Symbol$3, name)) {
      WellKnownSymbolsStore[name] = Symbol$3[name];
    } else if (USE_SYMBOL_AS_UID && symbolFor) {
      WellKnownSymbolsStore[name] = symbolFor(description);
    } else {
      WellKnownSymbolsStore[name] = createWellKnownSymbol(description);
    }
  } return WellKnownSymbolsStore[name];
};

var call$9 = functionCall;
var isObject$6 = isObject$8;
var isSymbol$1 = isSymbol$2;
var getMethod$2 = getMethod$3;
var ordinaryToPrimitive = ordinaryToPrimitive$1;
var wellKnownSymbol$e = wellKnownSymbol$f;

var $TypeError$8 = TypeError;
var TO_PRIMITIVE = wellKnownSymbol$e('toPrimitive');

// `ToPrimitive` abstract operation
// https://tc39.es/ecma262/#sec-toprimitive
var toPrimitive$1 = function (input, pref) {
  if (!isObject$6(input) || isSymbol$1(input)) return input;
  var exoticToPrim = getMethod$2(input, TO_PRIMITIVE);
  var result;
  if (exoticToPrim) {
    if (pref === undefined) pref = 'default';
    result = call$9(exoticToPrim, input, pref);
    if (!isObject$6(result) || isSymbol$1(result)) return result;
    throw $TypeError$8("Can't convert object to primitive value");
  }
  if (pref === undefined) pref = 'number';
  return ordinaryToPrimitive(input, pref);
};

var toPrimitive = toPrimitive$1;
var isSymbol = isSymbol$2;

// `ToPropertyKey` abstract operation
// https://tc39.es/ecma262/#sec-topropertykey
var toPropertyKey$3 = function (argument) {
  var key = toPrimitive(argument, 'string');
  return isSymbol(key) ? key : key + '';
};

var global$c = global$i;
var isObject$5 = isObject$8;

var document$1 = global$c.document;
// typeof document.createElement is 'object' in old IE
var EXISTS$1 = isObject$5(document$1) && isObject$5(document$1.createElement);

var documentCreateElement$2 = function (it) {
  return EXISTS$1 ? document$1.createElement(it) : {};
};

var DESCRIPTORS$b = descriptors;
var fails$l = fails$q;
var createElement = documentCreateElement$2;

// Thanks to IE8 for its funny defineProperty
var ie8DomDefine = !DESCRIPTORS$b && !fails$l(function () {
  // eslint-disable-next-line es/no-object-defineproperty -- required for testing
  return Object.defineProperty(createElement('div'), 'a', {
    get: function () { return 7; }
  }).a != 7;
});

var DESCRIPTORS$a = descriptors;
var call$8 = functionCall;
var propertyIsEnumerableModule$1 = objectPropertyIsEnumerable;
var createPropertyDescriptor$3 = createPropertyDescriptor$4;
var toIndexedObject$4 = toIndexedObject$5;
var toPropertyKey$2 = toPropertyKey$3;
var hasOwn$9 = hasOwnProperty_1;
var IE8_DOM_DEFINE$1 = ie8DomDefine;

// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var $getOwnPropertyDescriptor$1 = Object.getOwnPropertyDescriptor;

// `Object.getOwnPropertyDescriptor` method
// https://tc39.es/ecma262/#sec-object.getownpropertydescriptor
objectGetOwnPropertyDescriptor.f = DESCRIPTORS$a ? $getOwnPropertyDescriptor$1 : function getOwnPropertyDescriptor(O, P) {
  O = toIndexedObject$4(O);
  P = toPropertyKey$2(P);
  if (IE8_DOM_DEFINE$1) try {
    return $getOwnPropertyDescriptor$1(O, P);
  } catch (error) { /* empty */ }
  if (hasOwn$9(O, P)) return createPropertyDescriptor$3(!call$8(propertyIsEnumerableModule$1.f, O, P), O[P]);
};

var objectDefineProperty = {};

var DESCRIPTORS$9 = descriptors;
var fails$k = fails$q;

// V8 ~ Chrome 36-
// https://bugs.chromium.org/p/v8/issues/detail?id=3334
var v8PrototypeDefineBug = DESCRIPTORS$9 && fails$k(function () {
  // eslint-disable-next-line es/no-object-defineproperty -- required for testing
  return Object.defineProperty(function () { /* empty */ }, 'prototype', {
    value: 42,
    writable: false
  }).prototype != 42;
});

var isObject$4 = isObject$8;

var $String$3 = String;
var $TypeError$7 = TypeError;

// `Assert: Type(argument) is Object`
var anObject$b = function (argument) {
  if (isObject$4(argument)) return argument;
  throw $TypeError$7($String$3(argument) + ' is not an object');
};

var DESCRIPTORS$8 = descriptors;
var IE8_DOM_DEFINE = ie8DomDefine;
var V8_PROTOTYPE_DEFINE_BUG$1 = v8PrototypeDefineBug;
var anObject$a = anObject$b;
var toPropertyKey$1 = toPropertyKey$3;

var $TypeError$6 = TypeError;
// eslint-disable-next-line es/no-object-defineproperty -- safe
var $defineProperty = Object.defineProperty;
// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var $getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
var ENUMERABLE = 'enumerable';
var CONFIGURABLE$1 = 'configurable';
var WRITABLE = 'writable';

// `Object.defineProperty` method
// https://tc39.es/ecma262/#sec-object.defineproperty
objectDefineProperty.f = DESCRIPTORS$8 ? V8_PROTOTYPE_DEFINE_BUG$1 ? function defineProperty(O, P, Attributes) {
  anObject$a(O);
  P = toPropertyKey$1(P);
  anObject$a(Attributes);
  if (typeof O === 'function' && P === 'prototype' && 'value' in Attributes && WRITABLE in Attributes && !Attributes[WRITABLE]) {
    var current = $getOwnPropertyDescriptor(O, P);
    if (current && current[WRITABLE]) {
      O[P] = Attributes.value;
      Attributes = {
        configurable: CONFIGURABLE$1 in Attributes ? Attributes[CONFIGURABLE$1] : current[CONFIGURABLE$1],
        enumerable: ENUMERABLE in Attributes ? Attributes[ENUMERABLE] : current[ENUMERABLE],
        writable: false
      };
    }
  } return $defineProperty(O, P, Attributes);
} : $defineProperty : function defineProperty(O, P, Attributes) {
  anObject$a(O);
  P = toPropertyKey$1(P);
  anObject$a(Attributes);
  if (IE8_DOM_DEFINE) try {
    return $defineProperty(O, P, Attributes);
  } catch (error) { /* empty */ }
  if ('get' in Attributes || 'set' in Attributes) throw $TypeError$6('Accessors not supported');
  if ('value' in Attributes) O[P] = Attributes.value;
  return O;
};

var DESCRIPTORS$7 = descriptors;
var definePropertyModule$5 = objectDefineProperty;
var createPropertyDescriptor$2 = createPropertyDescriptor$4;

var createNonEnumerableProperty$6 = DESCRIPTORS$7 ? function (object, key, value) {
  return definePropertyModule$5.f(object, key, createPropertyDescriptor$2(1, value));
} : function (object, key, value) {
  object[key] = value;
  return object;
};

var makeBuiltIn$2 = {exports: {}};

var DESCRIPTORS$6 = descriptors;
var hasOwn$8 = hasOwnProperty_1;

var FunctionPrototype$1 = Function.prototype;
// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var getDescriptor = DESCRIPTORS$6 && Object.getOwnPropertyDescriptor;

var EXISTS = hasOwn$8(FunctionPrototype$1, 'name');
// additional protection from minified / mangled / dropped function names
var PROPER = EXISTS && (function something() { /* empty */ }).name === 'something';
var CONFIGURABLE = EXISTS && (!DESCRIPTORS$6 || (DESCRIPTORS$6 && getDescriptor(FunctionPrototype$1, 'name').configurable));

var functionName = {
  EXISTS: EXISTS,
  PROPER: PROPER,
  CONFIGURABLE: CONFIGURABLE
};

var uncurryThis$k = functionUncurryThis;
var isCallable$d = isCallable$j;
var store$1 = sharedStore;

var functionToString = uncurryThis$k(Function.toString);

// this helper broken in `core-js@3.4.1-3.4.4`, so we can't use `shared` helper
if (!isCallable$d(store$1.inspectSource)) {
  store$1.inspectSource = function (it) {
    return functionToString(it);
  };
}

var inspectSource$2 = store$1.inspectSource;

var global$b = global$i;
var isCallable$c = isCallable$j;

var WeakMap$1 = global$b.WeakMap;

var weakMapBasicDetection = isCallable$c(WeakMap$1) && /native code/.test(String(WeakMap$1));

var shared$2 = shared$4.exports;
var uid = uid$2;

var keys$1 = shared$2('keys');

var sharedKey$3 = function (key) {
  return keys$1[key] || (keys$1[key] = uid(key));
};

var hiddenKeys$4 = {};

var NATIVE_WEAK_MAP = weakMapBasicDetection;
var global$a = global$i;
var isObject$3 = isObject$8;
var createNonEnumerableProperty$5 = createNonEnumerableProperty$6;
var hasOwn$7 = hasOwnProperty_1;
var shared$1 = sharedStore;
var sharedKey$2 = sharedKey$3;
var hiddenKeys$3 = hiddenKeys$4;

var OBJECT_ALREADY_INITIALIZED = 'Object already initialized';
var TypeError$1 = global$a.TypeError;
var WeakMap = global$a.WeakMap;
var set, get, has;

var enforce = function (it) {
  return has(it) ? get(it) : set(it, {});
};

var getterFor = function (TYPE) {
  return function (it) {
    var state;
    if (!isObject$3(it) || (state = get(it)).type !== TYPE) {
      throw TypeError$1('Incompatible receiver, ' + TYPE + ' required');
    } return state;
  };
};

if (NATIVE_WEAK_MAP || shared$1.state) {
  var store = shared$1.state || (shared$1.state = new WeakMap());
  /* eslint-disable no-self-assign -- prototype methods protection */
  store.get = store.get;
  store.has = store.has;
  store.set = store.set;
  /* eslint-enable no-self-assign -- prototype methods protection */
  set = function (it, metadata) {
    if (store.has(it)) throw TypeError$1(OBJECT_ALREADY_INITIALIZED);
    metadata.facade = it;
    store.set(it, metadata);
    return metadata;
  };
  get = function (it) {
    return store.get(it) || {};
  };
  has = function (it) {
    return store.has(it);
  };
} else {
  var STATE = sharedKey$2('state');
  hiddenKeys$3[STATE] = true;
  set = function (it, metadata) {
    if (hasOwn$7(it, STATE)) throw TypeError$1(OBJECT_ALREADY_INITIALIZED);
    metadata.facade = it;
    createNonEnumerableProperty$5(it, STATE, metadata);
    return metadata;
  };
  get = function (it) {
    return hasOwn$7(it, STATE) ? it[STATE] : {};
  };
  has = function (it) {
    return hasOwn$7(it, STATE);
  };
}

var internalState = {
  set: set,
  get: get,
  has: has,
  enforce: enforce,
  getterFor: getterFor
};

var fails$j = fails$q;
var isCallable$b = isCallable$j;
var hasOwn$6 = hasOwnProperty_1;
var DESCRIPTORS$5 = descriptors;
var CONFIGURABLE_FUNCTION_NAME$1 = functionName.CONFIGURABLE;
var inspectSource$1 = inspectSource$2;
var InternalStateModule$1 = internalState;

var enforceInternalState$1 = InternalStateModule$1.enforce;
var getInternalState$2 = InternalStateModule$1.get;
// eslint-disable-next-line es/no-object-defineproperty -- safe
var defineProperty$5 = Object.defineProperty;

var CONFIGURABLE_LENGTH = DESCRIPTORS$5 && !fails$j(function () {
  return defineProperty$5(function () { /* empty */ }, 'length', { value: 8 }).length !== 8;
});

var TEMPLATE = String(String).split('String');

var makeBuiltIn$1 = makeBuiltIn$2.exports = function (value, name, options) {
  if (String(name).slice(0, 7) === 'Symbol(') {
    name = '[' + String(name).replace(/^Symbol\(([^)]*)\)/, '$1') + ']';
  }
  if (options && options.getter) name = 'get ' + name;
  if (options && options.setter) name = 'set ' + name;
  if (!hasOwn$6(value, 'name') || (CONFIGURABLE_FUNCTION_NAME$1 && value.name !== name)) {
    if (DESCRIPTORS$5) defineProperty$5(value, 'name', { value: name, configurable: true });
    else value.name = name;
  }
  if (CONFIGURABLE_LENGTH && options && hasOwn$6(options, 'arity') && value.length !== options.arity) {
    defineProperty$5(value, 'length', { value: options.arity });
  }
  try {
    if (options && hasOwn$6(options, 'constructor') && options.constructor) {
      if (DESCRIPTORS$5) defineProperty$5(value, 'prototype', { writable: false });
    // in V8 ~ Chrome 53, prototypes of some methods, like `Array.prototype.values`, are non-writable
    } else if (value.prototype) value.prototype = undefined;
  } catch (error) { /* empty */ }
  var state = enforceInternalState$1(value);
  if (!hasOwn$6(state, 'source')) {
    state.source = TEMPLATE.join(typeof name == 'string' ? name : '');
  } return value;
};

// add fake Function#toString for correct work wrapped methods / constructors with methods like LoDash isNative
// eslint-disable-next-line no-extend-native -- required
Function.prototype.toString = makeBuiltIn$1(function toString() {
  return isCallable$b(this) && getInternalState$2(this).source || inspectSource$1(this);
}, 'toString');

var isCallable$a = isCallable$j;
var definePropertyModule$4 = objectDefineProperty;
var makeBuiltIn = makeBuiltIn$2.exports;
var defineGlobalProperty$1 = defineGlobalProperty$3;

var defineBuiltIn$6 = function (O, key, value, options) {
  if (!options) options = {};
  var simple = options.enumerable;
  var name = options.name !== undefined ? options.name : key;
  if (isCallable$a(value)) makeBuiltIn(value, name, options);
  if (options.global) {
    if (simple) O[key] = value;
    else defineGlobalProperty$1(key, value);
  } else {
    try {
      if (!options.unsafe) delete O[key];
      else if (O[key]) simple = true;
    } catch (error) { /* empty */ }
    if (simple) O[key] = value;
    else definePropertyModule$4.f(O, key, {
      value: value,
      enumerable: false,
      configurable: !options.nonConfigurable,
      writable: !options.nonWritable
    });
  } return O;
};

var objectGetOwnPropertyNames = {};

var ceil = Math.ceil;
var floor$3 = Math.floor;

// `Math.trunc` method
// https://tc39.es/ecma262/#sec-math.trunc
// eslint-disable-next-line es/no-math-trunc -- safe
var mathTrunc = Math.trunc || function trunc(x) {
  var n = +x;
  return (n > 0 ? floor$3 : ceil)(n);
};

var trunc = mathTrunc;

// `ToIntegerOrInfinity` abstract operation
// https://tc39.es/ecma262/#sec-tointegerorinfinity
var toIntegerOrInfinity$6 = function (argument) {
  var number = +argument;
  // eslint-disable-next-line no-self-compare -- NaN check
  return number !== number || number === 0 ? 0 : trunc(number);
};

var toIntegerOrInfinity$5 = toIntegerOrInfinity$6;

var max$2 = Math.max;
var min$3 = Math.min;

// Helper for a popular repeating case of the spec:
// Let integer be ? ToInteger(index).
// If integer < 0, let result be max((length + integer), 0); else let result be min(integer, length).
var toAbsoluteIndex$2 = function (index, length) {
  var integer = toIntegerOrInfinity$5(index);
  return integer < 0 ? max$2(integer + length, 0) : min$3(integer, length);
};

var toIntegerOrInfinity$4 = toIntegerOrInfinity$6;

var min$2 = Math.min;

// `ToLength` abstract operation
// https://tc39.es/ecma262/#sec-tolength
var toLength$3 = function (argument) {
  return argument > 0 ? min$2(toIntegerOrInfinity$4(argument), 0x1FFFFFFFFFFFFF) : 0; // 2 ** 53 - 1 == 9007199254740991
};

var toLength$2 = toLength$3;

// `LengthOfArrayLike` abstract operation
// https://tc39.es/ecma262/#sec-lengthofarraylike
var lengthOfArrayLike$4 = function (obj) {
  return toLength$2(obj.length);
};

var toIndexedObject$3 = toIndexedObject$5;
var toAbsoluteIndex$1 = toAbsoluteIndex$2;
var lengthOfArrayLike$3 = lengthOfArrayLike$4;

// `Array.prototype.{ indexOf, includes }` methods implementation
var createMethod$3 = function (IS_INCLUDES) {
  return function ($this, el, fromIndex) {
    var O = toIndexedObject$3($this);
    var length = lengthOfArrayLike$3(O);
    var index = toAbsoluteIndex$1(fromIndex, length);
    var value;
    // Array#includes uses SameValueZero equality algorithm
    // eslint-disable-next-line no-self-compare -- NaN check
    if (IS_INCLUDES && el != el) while (length > index) {
      value = O[index++];
      // eslint-disable-next-line no-self-compare -- NaN check
      if (value != value) return true;
    // Array#indexOf ignores holes, Array#includes - not
    } else for (;length > index; index++) {
      if ((IS_INCLUDES || index in O) && O[index] === el) return IS_INCLUDES || index || 0;
    } return !IS_INCLUDES && -1;
  };
};

var arrayIncludes = {
  // `Array.prototype.includes` method
  // https://tc39.es/ecma262/#sec-array.prototype.includes
  includes: createMethod$3(true),
  // `Array.prototype.indexOf` method
  // https://tc39.es/ecma262/#sec-array.prototype.indexof
  indexOf: createMethod$3(false)
};

var uncurryThis$j = functionUncurryThis;
var hasOwn$5 = hasOwnProperty_1;
var toIndexedObject$2 = toIndexedObject$5;
var indexOf$1 = arrayIncludes.indexOf;
var hiddenKeys$2 = hiddenKeys$4;

var push$3 = uncurryThis$j([].push);

var objectKeysInternal = function (object, names) {
  var O = toIndexedObject$2(object);
  var i = 0;
  var result = [];
  var key;
  for (key in O) !hasOwn$5(hiddenKeys$2, key) && hasOwn$5(O, key) && push$3(result, key);
  // Don't enum bug & hidden keys
  while (names.length > i) if (hasOwn$5(O, key = names[i++])) {
    ~indexOf$1(result, key) || push$3(result, key);
  }
  return result;
};

// IE8- don't enum bug keys
var enumBugKeys$3 = [
  'constructor',
  'hasOwnProperty',
  'isPrototypeOf',
  'propertyIsEnumerable',
  'toLocaleString',
  'toString',
  'valueOf'
];

var internalObjectKeys$1 = objectKeysInternal;
var enumBugKeys$2 = enumBugKeys$3;

var hiddenKeys$1 = enumBugKeys$2.concat('length', 'prototype');

// `Object.getOwnPropertyNames` method
// https://tc39.es/ecma262/#sec-object.getownpropertynames
// eslint-disable-next-line es/no-object-getownpropertynames -- safe
objectGetOwnPropertyNames.f = Object.getOwnPropertyNames || function getOwnPropertyNames(O) {
  return internalObjectKeys$1(O, hiddenKeys$1);
};

var objectGetOwnPropertySymbols = {};

// eslint-disable-next-line es/no-object-getownpropertysymbols -- safe
objectGetOwnPropertySymbols.f = Object.getOwnPropertySymbols;

var getBuiltIn$3 = getBuiltIn$6;
var uncurryThis$i = functionUncurryThis;
var getOwnPropertyNamesModule = objectGetOwnPropertyNames;
var getOwnPropertySymbolsModule$1 = objectGetOwnPropertySymbols;
var anObject$9 = anObject$b;

var concat$2 = uncurryThis$i([].concat);

// all object keys, includes non-enumerable and symbols
var ownKeys$1 = getBuiltIn$3('Reflect', 'ownKeys') || function ownKeys(it) {
  var keys = getOwnPropertyNamesModule.f(anObject$9(it));
  var getOwnPropertySymbols = getOwnPropertySymbolsModule$1.f;
  return getOwnPropertySymbols ? concat$2(keys, getOwnPropertySymbols(it)) : keys;
};

var hasOwn$4 = hasOwnProperty_1;
var ownKeys = ownKeys$1;
var getOwnPropertyDescriptorModule = objectGetOwnPropertyDescriptor;
var definePropertyModule$3 = objectDefineProperty;

var copyConstructorProperties$1 = function (target, source, exceptions) {
  var keys = ownKeys(source);
  var defineProperty = definePropertyModule$3.f;
  var getOwnPropertyDescriptor = getOwnPropertyDescriptorModule.f;
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    if (!hasOwn$4(target, key) && !(exceptions && hasOwn$4(exceptions, key))) {
      defineProperty(target, key, getOwnPropertyDescriptor(source, key));
    }
  }
};

var fails$i = fails$q;
var isCallable$9 = isCallable$j;

var replacement = /#|\.prototype\./;

var isForced$2 = function (feature, detection) {
  var value = data[normalize(feature)];
  return value == POLYFILL ? true
    : value == NATIVE ? false
    : isCallable$9(detection) ? fails$i(detection)
    : !!detection;
};

var normalize = isForced$2.normalize = function (string) {
  return String(string).replace(replacement, '.').toLowerCase();
};

var data = isForced$2.data = {};
var NATIVE = isForced$2.NATIVE = 'N';
var POLYFILL = isForced$2.POLYFILL = 'P';

var isForced_1 = isForced$2;

var global$9 = global$i;
var getOwnPropertyDescriptor = objectGetOwnPropertyDescriptor.f;
var createNonEnumerableProperty$4 = createNonEnumerableProperty$6;
var defineBuiltIn$5 = defineBuiltIn$6;
var defineGlobalProperty = defineGlobalProperty$3;
var copyConstructorProperties = copyConstructorProperties$1;
var isForced$1 = isForced_1;

/*
  options.target         - name of the target object
  options.global         - target is the global object
  options.stat           - export as static methods of target
  options.proto          - export as prototype methods of target
  options.real           - real prototype method for the `pure` version
  options.forced         - export even if the native feature is available
  options.bind           - bind methods to the target, required for the `pure` version
  options.wrap           - wrap constructors to preventing global pollution, required for the `pure` version
  options.unsafe         - use the simple assignment of property instead of delete + defineProperty
  options.sham           - add a flag to not completely full polyfills
  options.enumerable     - export as enumerable property
  options.dontCallGetSet - prevent calling a getter on target
  options.name           - the .name of the function if it does not match the key
*/
var _export = function (options, source) {
  var TARGET = options.target;
  var GLOBAL = options.global;
  var STATIC = options.stat;
  var FORCED, target, key, targetProperty, sourceProperty, descriptor;
  if (GLOBAL) {
    target = global$9;
  } else if (STATIC) {
    target = global$9[TARGET] || defineGlobalProperty(TARGET, {});
  } else {
    target = (global$9[TARGET] || {}).prototype;
  }
  if (target) for (key in source) {
    sourceProperty = source[key];
    if (options.dontCallGetSet) {
      descriptor = getOwnPropertyDescriptor(target, key);
      targetProperty = descriptor && descriptor.value;
    } else targetProperty = target[key];
    FORCED = isForced$1(GLOBAL ? key : TARGET + (STATIC ? '.' : '#') + key, options.forced);
    // contained in target
    if (!FORCED && targetProperty !== undefined) {
      if (typeof sourceProperty == typeof targetProperty) continue;
      copyConstructorProperties(sourceProperty, targetProperty);
    }
    // add a flag to not completely full polyfills
    if (options.sham || (targetProperty && targetProperty.sham)) {
      createNonEnumerableProperty$4(sourceProperty, 'sham', true);
    }
    defineBuiltIn$5(target, key, sourceProperty, options);
  }
};

var internalObjectKeys = objectKeysInternal;
var enumBugKeys$1 = enumBugKeys$3;

// `Object.keys` method
// https://tc39.es/ecma262/#sec-object.keys
// eslint-disable-next-line es/no-object-keys -- safe
var objectKeys$2 = Object.keys || function keys(O) {
  return internalObjectKeys(O, enumBugKeys$1);
};

var DESCRIPTORS$4 = descriptors;
var uncurryThis$h = functionUncurryThis;
var call$7 = functionCall;
var fails$h = fails$q;
var objectKeys$1 = objectKeys$2;
var getOwnPropertySymbolsModule = objectGetOwnPropertySymbols;
var propertyIsEnumerableModule = objectPropertyIsEnumerable;
var toObject$4 = toObject$6;
var IndexedObject$1 = indexedObject;

// eslint-disable-next-line es/no-object-assign -- safe
var $assign = Object.assign;
// eslint-disable-next-line es/no-object-defineproperty -- required for testing
var defineProperty$4 = Object.defineProperty;
var concat$1 = uncurryThis$h([].concat);

// `Object.assign` method
// https://tc39.es/ecma262/#sec-object.assign
var objectAssign = !$assign || fails$h(function () {
  // should have correct order of operations (Edge bug)
  if (DESCRIPTORS$4 && $assign({ b: 1 }, $assign(defineProperty$4({}, 'a', {
    enumerable: true,
    get: function () {
      defineProperty$4(this, 'b', {
        value: 3,
        enumerable: false
      });
    }
  }), { b: 2 })).b !== 1) return true;
  // should work with symbols and should have deterministic property order (V8 bug)
  var A = {};
  var B = {};
  // eslint-disable-next-line es/no-symbol -- safe
  var symbol = Symbol();
  var alphabet = 'abcdefghijklmnopqrst';
  A[symbol] = 7;
  alphabet.split('').forEach(function (chr) { B[chr] = chr; });
  return $assign({}, A)[symbol] != 7 || objectKeys$1($assign({}, B)).join('') != alphabet;
}) ? function assign(target, source) { // eslint-disable-line no-unused-vars -- required for `.length`
  var T = toObject$4(target);
  var argumentsLength = arguments.length;
  var index = 1;
  var getOwnPropertySymbols = getOwnPropertySymbolsModule.f;
  var propertyIsEnumerable = propertyIsEnumerableModule.f;
  while (argumentsLength > index) {
    var S = IndexedObject$1(arguments[index++]);
    var keys = getOwnPropertySymbols ? concat$1(objectKeys$1(S), getOwnPropertySymbols(S)) : objectKeys$1(S);
    var length = keys.length;
    var j = 0;
    var key;
    while (length > j) {
      key = keys[j++];
      if (!DESCRIPTORS$4 || call$7(propertyIsEnumerable, S, key)) T[key] = S[key];
    }
  } return T;
} : $assign;

var $$9 = _export;
var assign = objectAssign;

// `Object.assign` method
// https://tc39.es/ecma262/#sec-object.assign
// eslint-disable-next-line es/no-object-assign -- required for testing
$$9({ target: 'Object', stat: true, arity: 2, forced: Object.assign !== assign }, {
  assign: assign
});

var objectDefineProperties = {};

var DESCRIPTORS$3 = descriptors;
var V8_PROTOTYPE_DEFINE_BUG = v8PrototypeDefineBug;
var definePropertyModule$2 = objectDefineProperty;
var anObject$8 = anObject$b;
var toIndexedObject$1 = toIndexedObject$5;
var objectKeys = objectKeys$2;

// `Object.defineProperties` method
// https://tc39.es/ecma262/#sec-object.defineproperties
// eslint-disable-next-line es/no-object-defineproperties -- safe
objectDefineProperties.f = DESCRIPTORS$3 && !V8_PROTOTYPE_DEFINE_BUG ? Object.defineProperties : function defineProperties(O, Properties) {
  anObject$8(O);
  var props = toIndexedObject$1(Properties);
  var keys = objectKeys(Properties);
  var length = keys.length;
  var index = 0;
  var key;
  while (length > index) definePropertyModule$2.f(O, key = keys[index++], props[key]);
  return O;
};

var getBuiltIn$2 = getBuiltIn$6;

var html$1 = getBuiltIn$2('document', 'documentElement');

/* global ActiveXObject -- old IE, WSH */

var anObject$7 = anObject$b;
var definePropertiesModule = objectDefineProperties;
var enumBugKeys = enumBugKeys$3;
var hiddenKeys = hiddenKeys$4;
var html = html$1;
var documentCreateElement$1 = documentCreateElement$2;
var sharedKey$1 = sharedKey$3;

var GT = '>';
var LT = '<';
var PROTOTYPE = 'prototype';
var SCRIPT = 'script';
var IE_PROTO$1 = sharedKey$1('IE_PROTO');

var EmptyConstructor = function () { /* empty */ };

var scriptTag = function (content) {
  return LT + SCRIPT + GT + content + LT + '/' + SCRIPT + GT;
};

// Create object with fake `null` prototype: use ActiveX Object with cleared prototype
var NullProtoObjectViaActiveX = function (activeXDocument) {
  activeXDocument.write(scriptTag(''));
  activeXDocument.close();
  var temp = activeXDocument.parentWindow.Object;
  activeXDocument = null; // avoid memory leak
  return temp;
};

// Create object with fake `null` prototype: use iframe Object with cleared prototype
var NullProtoObjectViaIFrame = function () {
  // Thrash, waste and sodomy: IE GC bug
  var iframe = documentCreateElement$1('iframe');
  var JS = 'java' + SCRIPT + ':';
  var iframeDocument;
  iframe.style.display = 'none';
  html.appendChild(iframe);
  // https://github.com/zloirock/core-js/issues/475
  iframe.src = String(JS);
  iframeDocument = iframe.contentWindow.document;
  iframeDocument.open();
  iframeDocument.write(scriptTag('document.F=Object'));
  iframeDocument.close();
  return iframeDocument.F;
};

// Check for document.domain and active x support
// No need to use active x approach when document.domain is not set
// see https://github.com/es-shims/es5-shim/issues/150
// variation of https://github.com/kitcambridge/es5-shim/commit/4f738ac066346
// avoid IE GC bug
var activeXDocument;
var NullProtoObject = function () {
  try {
    activeXDocument = new ActiveXObject('htmlfile');
  } catch (error) { /* ignore */ }
  NullProtoObject = typeof document != 'undefined'
    ? document.domain && activeXDocument
      ? NullProtoObjectViaActiveX(activeXDocument) // old IE
      : NullProtoObjectViaIFrame()
    : NullProtoObjectViaActiveX(activeXDocument); // WSH
  var length = enumBugKeys.length;
  while (length--) delete NullProtoObject[PROTOTYPE][enumBugKeys[length]];
  return NullProtoObject();
};

hiddenKeys[IE_PROTO$1] = true;

// `Object.create` method
// https://tc39.es/ecma262/#sec-object.create
// eslint-disable-next-line es/no-object-create -- safe
var objectCreate = Object.create || function create(O, Properties) {
  var result;
  if (O !== null) {
    EmptyConstructor[PROTOTYPE] = anObject$7(O);
    result = new EmptyConstructor();
    EmptyConstructor[PROTOTYPE] = null;
    // add "__proto__" for Object.getPrototypeOf polyfill
    result[IE_PROTO$1] = O;
  } else result = NullProtoObject();
  return Properties === undefined ? result : definePropertiesModule.f(result, Properties);
};

var wellKnownSymbol$d = wellKnownSymbol$f;
var create$2 = objectCreate;
var defineProperty$3 = objectDefineProperty.f;

var UNSCOPABLES = wellKnownSymbol$d('unscopables');
var ArrayPrototype = Array.prototype;

// Array.prototype[@@unscopables]
// https://tc39.es/ecma262/#sec-array.prototype-@@unscopables
if (ArrayPrototype[UNSCOPABLES] == undefined) {
  defineProperty$3(ArrayPrototype, UNSCOPABLES, {
    configurable: true,
    value: create$2(null)
  });
}

// add a key to Array.prototype[@@unscopables]
var addToUnscopables$2 = function (key) {
  ArrayPrototype[UNSCOPABLES][key] = true;
};

var iterators = {};

var fails$g = fails$q;

var correctPrototypeGetter = !fails$g(function () {
  function F() { /* empty */ }
  F.prototype.constructor = null;
  // eslint-disable-next-line es/no-object-getprototypeof -- required for testing
  return Object.getPrototypeOf(new F()) !== F.prototype;
});

var hasOwn$3 = hasOwnProperty_1;
var isCallable$8 = isCallable$j;
var toObject$3 = toObject$6;
var sharedKey = sharedKey$3;
var CORRECT_PROTOTYPE_GETTER = correctPrototypeGetter;

var IE_PROTO = sharedKey('IE_PROTO');
var $Object$1 = Object;
var ObjectPrototype = $Object$1.prototype;

// `Object.getPrototypeOf` method
// https://tc39.es/ecma262/#sec-object.getprototypeof
// eslint-disable-next-line es/no-object-getprototypeof -- safe
var objectGetPrototypeOf = CORRECT_PROTOTYPE_GETTER ? $Object$1.getPrototypeOf : function (O) {
  var object = toObject$3(O);
  if (hasOwn$3(object, IE_PROTO)) return object[IE_PROTO];
  var constructor = object.constructor;
  if (isCallable$8(constructor) && object instanceof constructor) {
    return constructor.prototype;
  } return object instanceof $Object$1 ? ObjectPrototype : null;
};

var fails$f = fails$q;
var isCallable$7 = isCallable$j;
var isObject$2 = isObject$8;
var getPrototypeOf$1 = objectGetPrototypeOf;
var defineBuiltIn$4 = defineBuiltIn$6;
var wellKnownSymbol$c = wellKnownSymbol$f;

var ITERATOR$4 = wellKnownSymbol$c('iterator');
var BUGGY_SAFARI_ITERATORS$1 = false;

// `%IteratorPrototype%` object
// https://tc39.es/ecma262/#sec-%iteratorprototype%-object
var IteratorPrototype$2, PrototypeOfArrayIteratorPrototype, arrayIterator;

/* eslint-disable es/no-array-prototype-keys -- safe */
if ([].keys) {
  arrayIterator = [].keys();
  // Safari 8 has buggy iterators w/o `next`
  if (!('next' in arrayIterator)) BUGGY_SAFARI_ITERATORS$1 = true;
  else {
    PrototypeOfArrayIteratorPrototype = getPrototypeOf$1(getPrototypeOf$1(arrayIterator));
    if (PrototypeOfArrayIteratorPrototype !== Object.prototype) IteratorPrototype$2 = PrototypeOfArrayIteratorPrototype;
  }
}

var NEW_ITERATOR_PROTOTYPE = !isObject$2(IteratorPrototype$2) || fails$f(function () {
  var test = {};
  // FF44- legacy iterators case
  return IteratorPrototype$2[ITERATOR$4].call(test) !== test;
});

if (NEW_ITERATOR_PROTOTYPE) IteratorPrototype$2 = {};

// `%IteratorPrototype%[@@iterator]()` method
// https://tc39.es/ecma262/#sec-%iteratorprototype%-@@iterator
if (!isCallable$7(IteratorPrototype$2[ITERATOR$4])) {
  defineBuiltIn$4(IteratorPrototype$2, ITERATOR$4, function () {
    return this;
  });
}

var iteratorsCore = {
  IteratorPrototype: IteratorPrototype$2,
  BUGGY_SAFARI_ITERATORS: BUGGY_SAFARI_ITERATORS$1
};

var defineProperty$2 = objectDefineProperty.f;
var hasOwn$2 = hasOwnProperty_1;
var wellKnownSymbol$b = wellKnownSymbol$f;

var TO_STRING_TAG$3 = wellKnownSymbol$b('toStringTag');

var setToStringTag$2 = function (target, TAG, STATIC) {
  if (target && !STATIC) target = target.prototype;
  if (target && !hasOwn$2(target, TO_STRING_TAG$3)) {
    defineProperty$2(target, TO_STRING_TAG$3, { configurable: true, value: TAG });
  }
};

var IteratorPrototype$1 = iteratorsCore.IteratorPrototype;
var create$1 = objectCreate;
var createPropertyDescriptor$1 = createPropertyDescriptor$4;
var setToStringTag$1 = setToStringTag$2;
var Iterators$2 = iterators;

var returnThis$1 = function () { return this; };

var iteratorCreateConstructor = function (IteratorConstructor, NAME, next, ENUMERABLE_NEXT) {
  var TO_STRING_TAG = NAME + ' Iterator';
  IteratorConstructor.prototype = create$1(IteratorPrototype$1, { next: createPropertyDescriptor$1(+!ENUMERABLE_NEXT, next) });
  setToStringTag$1(IteratorConstructor, TO_STRING_TAG, false);
  Iterators$2[TO_STRING_TAG] = returnThis$1;
  return IteratorConstructor;
};

var isCallable$6 = isCallable$j;

var $String$2 = String;
var $TypeError$5 = TypeError;

var aPossiblePrototype$1 = function (argument) {
  if (typeof argument == 'object' || isCallable$6(argument)) return argument;
  throw $TypeError$5("Can't set " + $String$2(argument) + ' as a prototype');
};

/* eslint-disable no-proto -- safe */

var uncurryThis$g = functionUncurryThis;
var anObject$6 = anObject$b;
var aPossiblePrototype = aPossiblePrototype$1;

// `Object.setPrototypeOf` method
// https://tc39.es/ecma262/#sec-object.setprototypeof
// Works with __proto__ only. Old v8 can't work with null proto objects.
// eslint-disable-next-line es/no-object-setprototypeof -- safe
var objectSetPrototypeOf = Object.setPrototypeOf || ('__proto__' in {} ? function () {
  var CORRECT_SETTER = false;
  var test = {};
  var setter;
  try {
    // eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
    setter = uncurryThis$g(Object.getOwnPropertyDescriptor(Object.prototype, '__proto__').set);
    setter(test, []);
    CORRECT_SETTER = test instanceof Array;
  } catch (error) { /* empty */ }
  return function setPrototypeOf(O, proto) {
    anObject$6(O);
    aPossiblePrototype(proto);
    if (CORRECT_SETTER) setter(O, proto);
    else O.__proto__ = proto;
    return O;
  };
}() : undefined);

var $$8 = _export;
var call$6 = functionCall;
var FunctionName = functionName;
var isCallable$5 = isCallable$j;
var createIteratorConstructor = iteratorCreateConstructor;
var getPrototypeOf = objectGetPrototypeOf;
var setPrototypeOf$1 = objectSetPrototypeOf;
var setToStringTag = setToStringTag$2;
var createNonEnumerableProperty$3 = createNonEnumerableProperty$6;
var defineBuiltIn$3 = defineBuiltIn$6;
var wellKnownSymbol$a = wellKnownSymbol$f;
var Iterators$1 = iterators;
var IteratorsCore = iteratorsCore;

var PROPER_FUNCTION_NAME$1 = FunctionName.PROPER;
var CONFIGURABLE_FUNCTION_NAME = FunctionName.CONFIGURABLE;
var IteratorPrototype = IteratorsCore.IteratorPrototype;
var BUGGY_SAFARI_ITERATORS = IteratorsCore.BUGGY_SAFARI_ITERATORS;
var ITERATOR$3 = wellKnownSymbol$a('iterator');
var KEYS = 'keys';
var VALUES = 'values';
var ENTRIES = 'entries';

var returnThis = function () { return this; };

var iteratorDefine = function (Iterable, NAME, IteratorConstructor, next, DEFAULT, IS_SET, FORCED) {
  createIteratorConstructor(IteratorConstructor, NAME, next);

  var getIterationMethod = function (KIND) {
    if (KIND === DEFAULT && defaultIterator) return defaultIterator;
    if (!BUGGY_SAFARI_ITERATORS && KIND in IterablePrototype) return IterablePrototype[KIND];
    switch (KIND) {
      case KEYS: return function keys() { return new IteratorConstructor(this, KIND); };
      case VALUES: return function values() { return new IteratorConstructor(this, KIND); };
      case ENTRIES: return function entries() { return new IteratorConstructor(this, KIND); };
    } return function () { return new IteratorConstructor(this); };
  };

  var TO_STRING_TAG = NAME + ' Iterator';
  var INCORRECT_VALUES_NAME = false;
  var IterablePrototype = Iterable.prototype;
  var nativeIterator = IterablePrototype[ITERATOR$3]
    || IterablePrototype['@@iterator']
    || DEFAULT && IterablePrototype[DEFAULT];
  var defaultIterator = !BUGGY_SAFARI_ITERATORS && nativeIterator || getIterationMethod(DEFAULT);
  var anyNativeIterator = NAME == 'Array' ? IterablePrototype.entries || nativeIterator : nativeIterator;
  var CurrentIteratorPrototype, methods, KEY;

  // fix native
  if (anyNativeIterator) {
    CurrentIteratorPrototype = getPrototypeOf(anyNativeIterator.call(new Iterable()));
    if (CurrentIteratorPrototype !== Object.prototype && CurrentIteratorPrototype.next) {
      if (getPrototypeOf(CurrentIteratorPrototype) !== IteratorPrototype) {
        if (setPrototypeOf$1) {
          setPrototypeOf$1(CurrentIteratorPrototype, IteratorPrototype);
        } else if (!isCallable$5(CurrentIteratorPrototype[ITERATOR$3])) {
          defineBuiltIn$3(CurrentIteratorPrototype, ITERATOR$3, returnThis);
        }
      }
      // Set @@toStringTag to native iterators
      setToStringTag(CurrentIteratorPrototype, TO_STRING_TAG, true);
    }
  }

  // fix Array.prototype.{ values, @@iterator }.name in V8 / FF
  if (PROPER_FUNCTION_NAME$1 && DEFAULT == VALUES && nativeIterator && nativeIterator.name !== VALUES) {
    if (CONFIGURABLE_FUNCTION_NAME) {
      createNonEnumerableProperty$3(IterablePrototype, 'name', VALUES);
    } else {
      INCORRECT_VALUES_NAME = true;
      defaultIterator = function values() { return call$6(nativeIterator, this); };
    }
  }

  // export additional methods
  if (DEFAULT) {
    methods = {
      values: getIterationMethod(VALUES),
      keys: IS_SET ? defaultIterator : getIterationMethod(KEYS),
      entries: getIterationMethod(ENTRIES)
    };
    if (FORCED) for (KEY in methods) {
      if (BUGGY_SAFARI_ITERATORS || INCORRECT_VALUES_NAME || !(KEY in IterablePrototype)) {
        defineBuiltIn$3(IterablePrototype, KEY, methods[KEY]);
      }
    } else $$8({ target: NAME, proto: true, forced: BUGGY_SAFARI_ITERATORS || INCORRECT_VALUES_NAME }, methods);
  }

  // define iterator
  if (IterablePrototype[ITERATOR$3] !== defaultIterator) {
    defineBuiltIn$3(IterablePrototype, ITERATOR$3, defaultIterator, { name: DEFAULT });
  }
  Iterators$1[NAME] = defaultIterator;

  return methods;
};

// `CreateIterResultObject` abstract operation
// https://tc39.es/ecma262/#sec-createiterresultobject
var createIterResultObject$1 = function (value, done) {
  return { value: value, done: done };
};

var toIndexedObject = toIndexedObject$5;
var addToUnscopables$1 = addToUnscopables$2;
var Iterators = iterators;
var InternalStateModule = internalState;
var defineProperty$1 = objectDefineProperty.f;
var defineIterator = iteratorDefine;
var createIterResultObject = createIterResultObject$1;
var DESCRIPTORS$2 = descriptors;

var ARRAY_ITERATOR = 'Array Iterator';
var setInternalState = InternalStateModule.set;
var getInternalState$1 = InternalStateModule.getterFor(ARRAY_ITERATOR);

// `Array.prototype.entries` method
// https://tc39.es/ecma262/#sec-array.prototype.entries
// `Array.prototype.keys` method
// https://tc39.es/ecma262/#sec-array.prototype.keys
// `Array.prototype.values` method
// https://tc39.es/ecma262/#sec-array.prototype.values
// `Array.prototype[@@iterator]` method
// https://tc39.es/ecma262/#sec-array.prototype-@@iterator
// `CreateArrayIterator` internal method
// https://tc39.es/ecma262/#sec-createarrayiterator
var es_array_iterator = defineIterator(Array, 'Array', function (iterated, kind) {
  setInternalState(this, {
    type: ARRAY_ITERATOR,
    target: toIndexedObject(iterated), // target
    index: 0,                          // next index
    kind: kind                         // kind
  });
// `%ArrayIteratorPrototype%.next` method
// https://tc39.es/ecma262/#sec-%arrayiteratorprototype%.next
}, function () {
  var state = getInternalState$1(this);
  var target = state.target;
  var kind = state.kind;
  var index = state.index++;
  if (!target || index >= target.length) {
    state.target = undefined;
    return createIterResultObject(undefined, true);
  }
  if (kind == 'keys') return createIterResultObject(index, false);
  if (kind == 'values') return createIterResultObject(target[index], false);
  return createIterResultObject([index, target[index]], false);
}, 'values');

// argumentsList[@@iterator] is %ArrayProto_values%
// https://tc39.es/ecma262/#sec-createunmappedargumentsobject
// https://tc39.es/ecma262/#sec-createmappedargumentsobject
var values = Iterators.Arguments = Iterators.Array;

// https://tc39.es/ecma262/#sec-array.prototype-@@unscopables
addToUnscopables$1('keys');
addToUnscopables$1('values');
addToUnscopables$1('entries');

// V8 ~ Chrome 45- bug
if (DESCRIPTORS$2 && values.name !== 'values') try {
  defineProperty$1(values, 'name', { value: 'values' });
} catch (error) { /* empty */ }

// iterable DOM collections
// flag - `iterable` interface - 'entries', 'keys', 'values', 'forEach' methods
var domIterables = {
  CSSRuleList: 0,
  CSSStyleDeclaration: 0,
  CSSValueList: 0,
  ClientRectList: 0,
  DOMRectList: 0,
  DOMStringList: 0,
  DOMTokenList: 1,
  DataTransferItemList: 0,
  FileList: 0,
  HTMLAllCollection: 0,
  HTMLCollection: 0,
  HTMLFormElement: 0,
  HTMLSelectElement: 0,
  MediaList: 0,
  MimeTypeArray: 0,
  NamedNodeMap: 0,
  NodeList: 1,
  PaintRequestList: 0,
  Plugin: 0,
  PluginArray: 0,
  SVGLengthList: 0,
  SVGNumberList: 0,
  SVGPathSegList: 0,
  SVGPointList: 0,
  SVGStringList: 0,
  SVGTransformList: 0,
  SourceBufferList: 0,
  StyleSheetList: 0,
  TextTrackCueList: 0,
  TextTrackList: 0,
  TouchList: 0
};

// in old WebKit versions, `element.classList` is not an instance of global `DOMTokenList`
var documentCreateElement = documentCreateElement$2;

var classList = documentCreateElement('span').classList;
var DOMTokenListPrototype$1 = classList && classList.constructor && classList.constructor.prototype;

var domTokenListPrototype = DOMTokenListPrototype$1 === Object.prototype ? undefined : DOMTokenListPrototype$1;

var global$8 = global$i;
var DOMIterables = domIterables;
var DOMTokenListPrototype = domTokenListPrototype;
var ArrayIteratorMethods = es_array_iterator;
var createNonEnumerableProperty$2 = createNonEnumerableProperty$6;
var wellKnownSymbol$9 = wellKnownSymbol$f;

var ITERATOR$2 = wellKnownSymbol$9('iterator');
var TO_STRING_TAG$2 = wellKnownSymbol$9('toStringTag');
var ArrayValues = ArrayIteratorMethods.values;

var handlePrototype = function (CollectionPrototype, COLLECTION_NAME) {
  if (CollectionPrototype) {
    // some Chrome versions have non-configurable methods on DOMTokenList
    if (CollectionPrototype[ITERATOR$2] !== ArrayValues) try {
      createNonEnumerableProperty$2(CollectionPrototype, ITERATOR$2, ArrayValues);
    } catch (error) {
      CollectionPrototype[ITERATOR$2] = ArrayValues;
    }
    if (!CollectionPrototype[TO_STRING_TAG$2]) {
      createNonEnumerableProperty$2(CollectionPrototype, TO_STRING_TAG$2, COLLECTION_NAME);
    }
    if (DOMIterables[COLLECTION_NAME]) for (var METHOD_NAME in ArrayIteratorMethods) {
      // some Chrome versions have non-configurable methods on DOMTokenList
      if (CollectionPrototype[METHOD_NAME] !== ArrayIteratorMethods[METHOD_NAME]) try {
        createNonEnumerableProperty$2(CollectionPrototype, METHOD_NAME, ArrayIteratorMethods[METHOD_NAME]);
      } catch (error) {
        CollectionPrototype[METHOD_NAME] = ArrayIteratorMethods[METHOD_NAME];
      }
    }
  }
};

for (var COLLECTION_NAME in DOMIterables) {
  handlePrototype(global$8[COLLECTION_NAME] && global$8[COLLECTION_NAME].prototype, COLLECTION_NAME);
}

handlePrototype(DOMTokenListPrototype, 'DOMTokenList');

var $$7 = _export;
var $includes = arrayIncludes.includes;
var fails$e = fails$q;
var addToUnscopables = addToUnscopables$2;

// FF99+ bug
var BROKEN_ON_SPARSE = fails$e(function () {
  return !Array(1).includes();
});

// `Array.prototype.includes` method
// https://tc39.es/ecma262/#sec-array.prototype.includes
$$7({ target: 'Array', proto: true, forced: BROKEN_ON_SPARSE }, {
  includes: function includes(el /* , fromIndex = 0 */) {
    return $includes(this, el, arguments.length > 1 ? arguments[1] : undefined);
  }
});

// https://tc39.es/ecma262/#sec-array.prototype-@@unscopables
addToUnscopables('includes');

var isObject$1 = isObject$8;
var classof$5 = classofRaw$2;
var wellKnownSymbol$8 = wellKnownSymbol$f;

var MATCH$2 = wellKnownSymbol$8('match');

// `IsRegExp` abstract operation
// https://tc39.es/ecma262/#sec-isregexp
var isRegexp = function (it) {
  var isRegExp;
  return isObject$1(it) && ((isRegExp = it[MATCH$2]) !== undefined ? !!isRegExp : classof$5(it) == 'RegExp');
};

var isRegExp$2 = isRegexp;

var $TypeError$4 = TypeError;

var notARegexp = function (it) {
  if (isRegExp$2(it)) {
    throw $TypeError$4("The method doesn't accept regular expressions");
  } return it;
};

var wellKnownSymbol$7 = wellKnownSymbol$f;

var TO_STRING_TAG$1 = wellKnownSymbol$7('toStringTag');
var test$1 = {};

test$1[TO_STRING_TAG$1] = 'z';

var toStringTagSupport = String(test$1) === '[object z]';

var TO_STRING_TAG_SUPPORT = toStringTagSupport;
var isCallable$4 = isCallable$j;
var classofRaw$1 = classofRaw$2;
var wellKnownSymbol$6 = wellKnownSymbol$f;

var TO_STRING_TAG = wellKnownSymbol$6('toStringTag');
var $Object = Object;

// ES3 wrong here
var CORRECT_ARGUMENTS = classofRaw$1(function () { return arguments; }()) == 'Arguments';

// fallback for IE11 Script Access Denied error
var tryGet = function (it, key) {
  try {
    return it[key];
  } catch (error) { /* empty */ }
};

// getting tag from ES6+ `Object.prototype.toString`
var classof$4 = TO_STRING_TAG_SUPPORT ? classofRaw$1 : function (it) {
  var O, tag, result;
  return it === undefined ? 'Undefined' : it === null ? 'Null'
    // @@toStringTag case
    : typeof (tag = tryGet(O = $Object(it), TO_STRING_TAG)) == 'string' ? tag
    // builtinTag case
    : CORRECT_ARGUMENTS ? classofRaw$1(O)
    // ES3 arguments fallback
    : (result = classofRaw$1(O)) == 'Object' && isCallable$4(O.callee) ? 'Arguments' : result;
};

var classof$3 = classof$4;

var $String$1 = String;

var toString$b = function (argument) {
  if (classof$3(argument) === 'Symbol') throw TypeError('Cannot convert a Symbol value to a string');
  return $String$1(argument);
};

var wellKnownSymbol$5 = wellKnownSymbol$f;

var MATCH$1 = wellKnownSymbol$5('match');

var correctIsRegexpLogic = function (METHOD_NAME) {
  var regexp = /./;
  try {
    '/./'[METHOD_NAME](regexp);
  } catch (error1) {
    try {
      regexp[MATCH$1] = false;
      return '/./'[METHOD_NAME](regexp);
    } catch (error2) { /* empty */ }
  } return false;
};

var $$6 = _export;
var uncurryThis$f = functionUncurryThis;
var notARegExp = notARegexp;
var requireObjectCoercible$5 = requireObjectCoercible$8;
var toString$a = toString$b;
var correctIsRegExpLogic = correctIsRegexpLogic;

var stringIndexOf$2 = uncurryThis$f(''.indexOf);

// `String.prototype.includes` method
// https://tc39.es/ecma262/#sec-string.prototype.includes
$$6({ target: 'String', proto: true, forced: !correctIsRegExpLogic('includes') }, {
  includes: function includes(searchString /* , position = 0 */) {
    return !!~stringIndexOf$2(
      toString$a(requireObjectCoercible$5(this)),
      toString$a(notARegExp(searchString)),
      arguments.length > 1 ? arguments[1] : undefined
    );
  }
});

var tryToString$1 = tryToString$3;

var $TypeError$3 = TypeError;

var deletePropertyOrThrow$1 = function (O, P) {
  if (!delete O[P]) throw $TypeError$3('Cannot delete property ' + tryToString$1(P) + ' of ' + tryToString$1(O));
};

var toPropertyKey = toPropertyKey$3;
var definePropertyModule$1 = objectDefineProperty;
var createPropertyDescriptor = createPropertyDescriptor$4;

var createProperty$1 = function (object, key, value) {
  var propertyKey = toPropertyKey(key);
  if (propertyKey in object) definePropertyModule$1.f(object, propertyKey, createPropertyDescriptor(0, value));
  else object[propertyKey] = value;
};

var toAbsoluteIndex = toAbsoluteIndex$2;
var lengthOfArrayLike$2 = lengthOfArrayLike$4;
var createProperty = createProperty$1;

var $Array = Array;
var max$1 = Math.max;

var arraySliceSimple = function (O, start, end) {
  var length = lengthOfArrayLike$2(O);
  var k = toAbsoluteIndex(start, length);
  var fin = toAbsoluteIndex(end === undefined ? length : end, length);
  var result = $Array(max$1(fin - k, 0));
  for (var n = 0; k < fin; k++, n++) createProperty(result, n, O[k]);
  result.length = n;
  return result;
};

var arraySlice$1 = arraySliceSimple;

var floor$2 = Math.floor;

var mergeSort = function (array, comparefn) {
  var length = array.length;
  var middle = floor$2(length / 2);
  return length < 8 ? insertionSort(array, comparefn) : merge(
    array,
    mergeSort(arraySlice$1(array, 0, middle), comparefn),
    mergeSort(arraySlice$1(array, middle), comparefn),
    comparefn
  );
};

var insertionSort = function (array, comparefn) {
  var length = array.length;
  var i = 1;
  var element, j;

  while (i < length) {
    j = i;
    element = array[i];
    while (j && comparefn(array[j - 1], element) > 0) {
      array[j] = array[--j];
    }
    if (j !== i++) array[j] = element;
  } return array;
};

var merge = function (array, left, right, comparefn) {
  var llength = left.length;
  var rlength = right.length;
  var lindex = 0;
  var rindex = 0;

  while (lindex < llength || rindex < rlength) {
    array[lindex + rindex] = (lindex < llength && rindex < rlength)
      ? comparefn(left[lindex], right[rindex]) <= 0 ? left[lindex++] : right[rindex++]
      : lindex < llength ? left[lindex++] : right[rindex++];
  } return array;
};

var arraySort = mergeSort;

var fails$d = fails$q;

var arrayMethodIsStrict$2 = function (METHOD_NAME, argument) {
  var method = [][METHOD_NAME];
  return !!method && fails$d(function () {
    // eslint-disable-next-line no-useless-call -- required for testing
    method.call(null, argument || function () { return 1; }, 1);
  });
};

var userAgent$1 = engineUserAgent;

var firefox = userAgent$1.match(/firefox\/(\d+)/i);

var engineFfVersion = !!firefox && +firefox[1];

var UA = engineUserAgent;

var engineIsIeOrEdge = /MSIE|Trident/.test(UA);

var userAgent = engineUserAgent;

var webkit = userAgent.match(/AppleWebKit\/(\d+)\./);

var engineWebkitVersion = !!webkit && +webkit[1];

var $$5 = _export;
var uncurryThis$e = functionUncurryThis;
var aCallable$1 = aCallable$3;
var toObject$2 = toObject$6;
var lengthOfArrayLike$1 = lengthOfArrayLike$4;
var deletePropertyOrThrow = deletePropertyOrThrow$1;
var toString$9 = toString$b;
var fails$c = fails$q;
var internalSort = arraySort;
var arrayMethodIsStrict$1 = arrayMethodIsStrict$2;
var FF = engineFfVersion;
var IE_OR_EDGE = engineIsIeOrEdge;
var V8 = engineV8Version;
var WEBKIT = engineWebkitVersion;

var test = [];
var nativeSort = uncurryThis$e(test.sort);
var push$2 = uncurryThis$e(test.push);

// IE8-
var FAILS_ON_UNDEFINED = fails$c(function () {
  test.sort(undefined);
});
// V8 bug
var FAILS_ON_NULL = fails$c(function () {
  test.sort(null);
});
// Old WebKit
var STRICT_METHOD$1 = arrayMethodIsStrict$1('sort');

var STABLE_SORT = !fails$c(function () {
  // feature detection can be too slow, so check engines versions
  if (V8) return V8 < 70;
  if (FF && FF > 3) return;
  if (IE_OR_EDGE) return true;
  if (WEBKIT) return WEBKIT < 603;

  var result = '';
  var code, chr, value, index;

  // generate an array with more 512 elements (Chakra and old V8 fails only in this case)
  for (code = 65; code < 76; code++) {
    chr = String.fromCharCode(code);

    switch (code) {
      case 66: case 69: case 70: case 72: value = 3; break;
      case 68: case 71: value = 4; break;
      default: value = 2;
    }

    for (index = 0; index < 47; index++) {
      test.push({ k: chr + index, v: value });
    }
  }

  test.sort(function (a, b) { return b.v - a.v; });

  for (index = 0; index < test.length; index++) {
    chr = test[index].k.charAt(0);
    if (result.charAt(result.length - 1) !== chr) result += chr;
  }

  return result !== 'DGBEFHACIJK';
});

var FORCED$3 = FAILS_ON_UNDEFINED || !FAILS_ON_NULL || !STRICT_METHOD$1 || !STABLE_SORT;

var getSortCompare = function (comparefn) {
  return function (x, y) {
    if (y === undefined) return -1;
    if (x === undefined) return 1;
    if (comparefn !== undefined) return +comparefn(x, y) || 0;
    return toString$9(x) > toString$9(y) ? 1 : -1;
  };
};

// `Array.prototype.sort` method
// https://tc39.es/ecma262/#sec-array.prototype.sort
$$5({ target: 'Array', proto: true, forced: FORCED$3 }, {
  sort: function sort(comparefn) {
    if (comparefn !== undefined) aCallable$1(comparefn);

    var array = toObject$2(this);

    if (STABLE_SORT) return comparefn === undefined ? nativeSort(array) : nativeSort(array, comparefn);

    var items = [];
    var arrayLength = lengthOfArrayLike$1(array);
    var itemsLength, index;

    for (index = 0; index < arrayLength; index++) {
      if (index in array) push$2(items, array[index]);
    }

    internalSort(items, getSortCompare(comparefn));

    itemsLength = lengthOfArrayLike$1(items);
    index = 0;

    while (index < itemsLength) array[index] = items[index++];
    while (index < arrayLength) deletePropertyOrThrow(array, index++);

    return array;
  }
});

var aCallable = aCallable$3;
var toObject$1 = toObject$6;
var IndexedObject = indexedObject;
var lengthOfArrayLike = lengthOfArrayLike$4;

var $TypeError$2 = TypeError;

// `Array.prototype.{ reduce, reduceRight }` methods implementation
var createMethod$2 = function (IS_RIGHT) {
  return function (that, callbackfn, argumentsLength, memo) {
    aCallable(callbackfn);
    var O = toObject$1(that);
    var self = IndexedObject(O);
    var length = lengthOfArrayLike(O);
    var index = IS_RIGHT ? length - 1 : 0;
    var i = IS_RIGHT ? -1 : 1;
    if (argumentsLength < 2) while (true) {
      if (index in self) {
        memo = self[index];
        index += i;
        break;
      }
      index += i;
      if (IS_RIGHT ? index < 0 : length <= index) {
        throw $TypeError$2('Reduce of empty array with no initial value');
      }
    }
    for (;IS_RIGHT ? index >= 0 : length > index; index += i) if (index in self) {
      memo = callbackfn(memo, self[index], index, O);
    }
    return memo;
  };
};

var arrayReduce = {
  // `Array.prototype.reduce` method
  // https://tc39.es/ecma262/#sec-array.prototype.reduce
  left: createMethod$2(false),
  // `Array.prototype.reduceRight` method
  // https://tc39.es/ecma262/#sec-array.prototype.reduceright
  right: createMethod$2(true)
};

var classof$2 = classofRaw$2;
var global$7 = global$i;

var engineIsNode = classof$2(global$7.process) == 'process';

var $$4 = _export;
var $reduce = arrayReduce.left;
var arrayMethodIsStrict = arrayMethodIsStrict$2;
var CHROME_VERSION = engineV8Version;
var IS_NODE = engineIsNode;

var STRICT_METHOD = arrayMethodIsStrict('reduce');
// Chrome 80-82 has a critical bug
// https://bugs.chromium.org/p/chromium/issues/detail?id=1049982
var CHROME_BUG = !IS_NODE && CHROME_VERSION > 79 && CHROME_VERSION < 83;

// `Array.prototype.reduce` method
// https://tc39.es/ecma262/#sec-array.prototype.reduce
$$4({ target: 'Array', proto: true, forced: !STRICT_METHOD || CHROME_BUG }, {
  reduce: function reduce(callbackfn /* , initialValue */) {
    var length = arguments.length;
    return $reduce(this, callbackfn, length, length > 1 ? arguments[1] : undefined);
  }
});

var uncurryThis$d = functionUncurryThis;

// `thisNumberValue` abstract operation
// https://tc39.es/ecma262/#sec-thisnumbervalue
var thisNumberValue$1 = uncurryThis$d(1.0.valueOf);

var toIntegerOrInfinity$3 = toIntegerOrInfinity$6;
var toString$8 = toString$b;
var requireObjectCoercible$4 = requireObjectCoercible$8;

var $RangeError$1 = RangeError;

// `String.prototype.repeat` method implementation
// https://tc39.es/ecma262/#sec-string.prototype.repeat
var stringRepeat = function repeat(count) {
  var str = toString$8(requireObjectCoercible$4(this));
  var result = '';
  var n = toIntegerOrInfinity$3(count);
  if (n < 0 || n == Infinity) throw $RangeError$1('Wrong number of repetitions');
  for (;n > 0; (n >>>= 1) && (str += str)) if (n & 1) result += str;
  return result;
};

var $$3 = _export;
var uncurryThis$c = functionUncurryThis;
var toIntegerOrInfinity$2 = toIntegerOrInfinity$6;
var thisNumberValue = thisNumberValue$1;
var $repeat = stringRepeat;
var fails$b = fails$q;

var $RangeError = RangeError;
var $String = String;
var floor$1 = Math.floor;
var repeat = uncurryThis$c($repeat);
var stringSlice$6 = uncurryThis$c(''.slice);
var nativeToFixed = uncurryThis$c(1.0.toFixed);

var pow = function (x, n, acc) {
  return n === 0 ? acc : n % 2 === 1 ? pow(x, n - 1, acc * x) : pow(x * x, n / 2, acc);
};

var log = function (x) {
  var n = 0;
  var x2 = x;
  while (x2 >= 4096) {
    n += 12;
    x2 /= 4096;
  }
  while (x2 >= 2) {
    n += 1;
    x2 /= 2;
  } return n;
};

var multiply = function (data, n, c) {
  var index = -1;
  var c2 = c;
  while (++index < 6) {
    c2 += n * data[index];
    data[index] = c2 % 1e7;
    c2 = floor$1(c2 / 1e7);
  }
};

var divide = function (data, n) {
  var index = 6;
  var c = 0;
  while (--index >= 0) {
    c += data[index];
    data[index] = floor$1(c / n);
    c = (c % n) * 1e7;
  }
};

var dataToString = function (data) {
  var index = 6;
  var s = '';
  while (--index >= 0) {
    if (s !== '' || index === 0 || data[index] !== 0) {
      var t = $String(data[index]);
      s = s === '' ? t : s + repeat('0', 7 - t.length) + t;
    }
  } return s;
};

var FORCED$2 = fails$b(function () {
  return nativeToFixed(0.00008, 3) !== '0.000' ||
    nativeToFixed(0.9, 0) !== '1' ||
    nativeToFixed(1.255, 2) !== '1.25' ||
    nativeToFixed(1000000000000000128.0, 0) !== '1000000000000000128';
}) || !fails$b(function () {
  // V8 ~ Android 4.3-
  nativeToFixed({});
});

// `Number.prototype.toFixed` method
// https://tc39.es/ecma262/#sec-number.prototype.tofixed
$$3({ target: 'Number', proto: true, forced: FORCED$2 }, {
  toFixed: function toFixed(fractionDigits) {
    var number = thisNumberValue(this);
    var fractDigits = toIntegerOrInfinity$2(fractionDigits);
    var data = [0, 0, 0, 0, 0, 0];
    var sign = '';
    var result = '0';
    var e, z, j, k;

    // TODO: ES2018 increased the maximum number of fraction digits to 100, need to improve the implementation
    if (fractDigits < 0 || fractDigits > 20) throw $RangeError('Incorrect fraction digits');
    // eslint-disable-next-line no-self-compare -- NaN check
    if (number != number) return 'NaN';
    if (number <= -1e21 || number >= 1e21) return $String(number);
    if (number < 0) {
      sign = '-';
      number = -number;
    }
    if (number > 1e-21) {
      e = log(number * pow(2, 69, 1)) - 69;
      z = e < 0 ? number * pow(2, -e, 1) : number / pow(2, e, 1);
      z *= 0x10000000000000;
      e = 52 - e;
      if (e > 0) {
        multiply(data, 0, z);
        j = fractDigits;
        while (j >= 7) {
          multiply(data, 1e7, 0);
          j -= 7;
        }
        multiply(data, pow(10, j, 1), 0);
        j = e - 1;
        while (j >= 23) {
          divide(data, 1 << 23);
          j -= 23;
        }
        divide(data, 1 << j);
        multiply(data, 1, 1);
        divide(data, 2);
        result = dataToString(data);
      } else {
        multiply(data, 0, z);
        multiply(data, 1 << -e, 0);
        result = dataToString(data) + repeat('0', fractDigits);
      }
    }
    if (fractDigits > 0) {
      k = result.length;
      result = sign + (k <= fractDigits
        ? '0.' + repeat('0', fractDigits - k) + result
        : stringSlice$6(result, 0, k - fractDigits) + '.' + stringSlice$6(result, k - fractDigits));
    } else {
      result = sign + result;
    } return result;
  }
});

/**
 * This class represents a 2D Point in a cartesian plane ( x / y coordinate system).
 * @example
 * ```
 * const point = new Point(50, 100);
 * ```
 */
class Point {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   */
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
  /**
   * Returns a clone of the {@link Point} instance.
   * @example
   * ```
   * const point1 = new Point(50, 100);
   * const point2 = point1.clone();
   * console.log(point1.toString(), point2.toString());
   * // Outputs: "[50, 100] [50, 100]"
   * ```
   * @returns {@link Point } A clone of the {@link Point} instance.
   */
  clone() {
    return new Point(this.x, this.y);
  }
  /**
   * Checks if the {@link Point} passed as parameter is in the exact same {@link x} and {@link y} coordinates as the {@link Point} instance.
   * @example
   * ```
   * const point1 = new Point(50, 100);
   * const point2 = new Point(100, 225);
   * const isEqual = point1.equal(point2);
   * console.log(isEqual);
   * // Outputs: "false"
   *
   * const point1 = new Point(50, 100);
   * const point2 = new Point(50, 100);
   * const isEqual = point1.equal(point2);
   * console.log(isEqual);
   * // Outputs: "true"
   * ```
   * @param point - A {@link Point} instance.
   */
  equal(point) {
    return this.x === point.x && this.y === point.y;
  }
  /**
   * Returns the distance between the {@link Point} passed in the parameter and the {@link Point} instance.
   * @example
   * ```
   * const point1 = new Point(50, 100);
   * const point2 = new Point(100, 225);
   * const distance = point1.distance(point2);
   * console.log(distance);
   * // Outputs: "134.6291201783626"
   * ```
   * @param point - A {@link Point} instance.
   */
  distance(point) {
    return Math.sqrt(Math.pow(this.x - point.x, 2) + Math.pow(this.y - point.y, 2));
  }
  /**
   * Adds to the {@link x} and {@link y} coordinates the input values.
   * @example
   * ```
   * const point = new Point(50, 100);
   * point.translate(-25, 150);
   * console.log(point.toString());
   * // Outputs: "[25, 250]"
   * ```
   * @param x - The value of the translation for the {@link x} coordinate.
   * @param y - The value of the translation for the {@link y} coordinate.
   * @returns This {@link Point} instance. Useful for chaining methods.
   */
  translate(x, y) {
    this.x += x;
    this.y += y;
    return this;
  }
  /**
   * Multiplies the {@link x} and {@link y} coordinates by the input value.
   * @example
   * ```
   * const point = new Point(50, 100);
   * point.scale(2);
   * console.log(point.toString());
   * // Outputs: "[100, 200]"
   * ```
   * @param value - The value of the scale to be applied to the {@link x} and {@link y} coordinates.
   * @returns This {@link Point} instance. Useful for chaining methods.
   */
  scale(value) {
    this.x *= value;
    this.y *= value;
    return this;
  }
  /**
   * Returns a string representation of this object.
   * @example
   * ```
   * const point = new Point(50, 100);
   * console.log(point.toString());
   * // Outputs: "[50, 100]"
   * ```
   */
  toString() {
    return `[${this.x}, ${this.y}]`;
  }
  /**
   * Returns the {@link Point} at a given `distance` from this {@link Point} instance, in the line created by this {@link Point} instance and `commonPoint`.
   * @example
   * ```
   * const point1 = new Point(50, 100);
   * const point2 = new Point(100, 200);
   * const pointResult = point1.getPointOfDistance(30, point2);
   * console.log(pointResult.toString());
   * // Outputs: "[63.41640786499874, 126.83281572999748]"
   * ```
   * @param distance - distance from this {@link Point} instance
   * @param commonPoint - the {@link Point} that will be used to create a line with this {@link Point} instance
   * @returns {@link Point}
   */
  getPointOfDistance(distance, commonPoint) {
    const x = distance * ((commonPoint.x - this.x) / commonPoint.distance(this));
    const y = distance * ((commonPoint.y - this.y) / commonPoint.distance(this));
    return new Point(this.x, this.y).translate(x, y);
  }
}

/**
 * This class represents a Rectangle in a cartesian plane ( x / y coordinate system).
 * @example
 * ```
 * const rect = new Rectangle(50, 100, 200, 225);
 * // creates a rectangle at Point(50, 100) with width = 200 and height = 225
 * ```
 */
class Rectangle {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (horizontal) in a cartesian plane.
   * @param width - width of the rectangle.
   * @param height - height of the rectangle.
   */
  constructor(x = 0, y = 0, width = 0, height = 0) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  /**
   * Sets all the {@link Rectangle} instance properties at once.
   * @example
   * ```
   * const rect = new Rectangle(50, 100, 200, 225);
   * rect.setValues(-20, 35, 100, 50);
   * console.log(rect.toString());
   * // Outputs: "{x: -20, y: 35, width: 100, height: 50}"
   * ```
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (horizontal) in a cartesian plane.
   * @param width - width of the rectangle.
   * @param height - height of the rectangle.
   * @returns This {@link Rectangle} instance. Useful for chaining methods.
   */
  setValues(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    return this;
  }
  /**
   * Returns a clone of the {@link Rectangle} instance.
   * @example
   * ```
   * const rect1 = new Rectangle(50, 100, 200, 225);
   * const rect2 = rect1.clone();
   * console.log(rect1.toString(), rect2.toString());
   * // Outputs: "{x: 50, y: 100, width: 200, height: 225} {x: 50, y: 100, width: 200, height: 225}"
   * ```
   * @returns {@link Rectangle } A clone of the {@link Rectangle} instance.
   */
  clone() {
    return new Rectangle(this.x, this.y, this.width, this.height);
  }
  /**
   * Gets the center {@link Point} in the {@link Rectangle} instance
   * @example
   * ```
   * const rect = new Rectangle(50, 100, 200, 225);
   * console.log(rect.center.toString());
   * // Outputs: "[150, 212.5]"
   * ```
   */
  get center() {
    return new Point(this.x + this.width / 2, this.y + this.height / 2);
  }
  /**
   * Returns true if this rectangle fully encloses the described point or rectangle.
   * @example
   * ```
   * const rect = new Rectangle(50, 100, 200, 225);
   * const point = new Point(75, 125);
   * const containsPoint = rect.contains(point);
   * console.log(containsPoint);
   * // Outputs: "true"
   *
   * const rect1 = new Rectangle(50, 100, 200, 225);
   * const rect2 = new Rectangle(10, 10, 25, 25);
   * const containsRect = rect1.contains(rect2);
   * console.log(containsRect);
   * // Outputs: "false"
   * ```
   * @param elem - a {@link Point} or a {@link Rectangle}
   */
  contains(elem) {
    let width = 0;
    let height = 0;
    if (elem instanceof Rectangle) {
      width = elem.width;
      height = elem.height;
    }
    return elem.x >= this.x && elem.x + width <= this.x + this.width && elem.y >= this.y && elem.y + height <= this.y + this.height;
  }
  /**
   * Extends the {@link Rectangle} instance with the given {@link Rectangle} properties
   * @example
   * ```
   * const rect1 = new Rectangle(50, 100, 200, 225);
   * const rect2 = new Rectangle(25, -50, 100, 400);
   * rect1.extend(rect2.x, rect2.y, rect2.width, rect2.height);
   * console.log(rect1.toString());
   * // Outputs: "{x: 25, y: -50, width: 225, height: 400}"
   * ```
   * @param x - x coordinate (horizontal) of the {@link Rectangle} to be extended.
   * @param y - y coordinate (horizontal) of the {@link Rectangle} to be extended.
   * @param width - width of the {@link Rectangle} to be extended.
   * @param height - height of the {@link Rectangle} to be extended.
   * @returns This {@link Rectangle} instance. Useful for chaining methods.
   */
  extend(x, y, width, height) {
    x = x || 0;
    y = y || 0;
    width = width || 0;
    height = height || 0;
    if (x + width > this.x + this.width) {
      this.width = x + width - this.x;
    }
    if (y + height > this.y + this.height) {
      this.height = y + height - this.y;
    }
    if (x < this.x) {
      this.width += this.x - x;
      this.x = x;
    }
    if (y < this.y) {
      this.height += this.y - y;
      this.y = y;
    }
    return this;
  }
  /**
   * Checks if the {@link Rectangle} passed as parameter intersects  with the {@link Rectangle} instance.
   * Important: This method expects both rectangles to have positive width and height in order to work properly.
   * @example
   * ```
   * const rect1 = new Rectangle(50, 100, 200, 225);
   * const rect2 = new Rectangle(350, 400, 200, 225);
   * const isIntersect = rect1.intersect(rect2);
   * console.log(isIntersect);
   * // Outputs: "false"
   *
   * const rect1 = new Rectangle(50, 100, 200, 225);
   * const rect2 = new Rectangle(200, 200, 200, 225);
   * const isIntersect = rect1.intersect(rect2);
   * console.log(isIntersect);
   * // Outputs: "true"
   * ```
   */
  intersect(rect) {
    let x1 = rect.x;
    let y1 = rect.y;
    let x2 = x1 + rect.width;
    let y2 = y1 + rect.height;
    if (this.x > x1) {
      x1 = this.x;
    }
    if (this.y > y1) {
      y1 = this.y;
    }
    if (this.x + this.width < x2) {
      x2 = this.x + this.width;
    }
    if (this.y + this.height < y2) {
      y2 = this.y + this.height;
    }
    return !(x2 <= x1 || y2 <= y1);
  }
  /**
   * Multiplies the {@link x}, {@link y}, {@link width} and {@link height} defining this {@link Rectangle} instance by the input value.
   * @example
   * ```
   * const rect = new Rectangle(50, 100, 200, 225);
   * rect.scale(2);
   * console.log(rect.toString());
   * // Outputs: "{x: 100, y: 200, width: 400, height: 450}"
   * ```
   * @param value - The value of the scale to be applied to {@link x}, {@link y}, {@link width} and {@link height}.
   * @returns This {@link Rectangle} instance. Useful for chaining methods.
   */
  scale(scale = 1) {
    this.x *= scale;
    this.y *= scale;
    this.width *= scale;
    this.height *= scale;
    return this;
  }
  /**
   * Adds to the {@link x} and {@link y} coordinates defining this {@link Rectangle} instance the input values.
   * @example
   * ```
   * const rect = new Rectangle(50, 100, 200, 225);
   * rect.translate(-25, 150);
   * console.log(rect.toString());
   * // Outputs: "{x: 25, y: 250, width: 200, height: 225}"
   * ```
   * @param x - The value of the translation for the {@link x} coordinate.
   * @param y - The value of the translation for the {@link y} coordinate.
   * @returns This {@link Rectangle} instance. Useful for chaining methods.
   */
  translate(x = 0, y = 0) {
    if (x instanceof Point) {
      this.x += x.x;
      this.y += x.y;
    } else {
      this.x += x;
      this.y += y;
    }
    return this;
  }
  /**
   * Returns a string representation of this object.
   * @example
   * ```
   * const rect = new Rectangle(50, 100, 200, 225);
   * console.log(rect.toString());
   * // Outputs: "{x: 50, y: 100, width: 200, height: 225}"
   * ```
   */
  toString() {
    return `{x: ${this.x.toFixed(0)}, y: ${this.y.toFixed(0)}, width: ${this.width.toFixed(0)}, height: ${this.height.toFixed(0)}}`;
  }
}

const ISOMETRIC_LENGTH = 0.816;
const DEFAULT_TEXT_FONT = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
const CAPABILITIES = {
  offscreenCanvas: Boolean(window.OffscreenCanvas)
};

var anObject$5 = anObject$b;

// `RegExp.prototype.flags` getter implementation
// https://tc39.es/ecma262/#sec-get-regexp.prototype.flags
var regexpFlags$1 = function () {
  var that = anObject$5(this);
  var result = '';
  if (that.hasIndices) result += 'd';
  if (that.global) result += 'g';
  if (that.ignoreCase) result += 'i';
  if (that.multiline) result += 'm';
  if (that.dotAll) result += 's';
  if (that.unicode) result += 'u';
  if (that.unicodeSets) result += 'v';
  if (that.sticky) result += 'y';
  return result;
};

var fails$a = fails$q;
var global$6 = global$i;

// babel-minify and Closure Compiler transpiles RegExp('a', 'y') -> /a/y and it causes SyntaxError
var $RegExp$2 = global$6.RegExp;

var UNSUPPORTED_Y$3 = fails$a(function () {
  var re = $RegExp$2('a', 'y');
  re.lastIndex = 2;
  return re.exec('abcd') != null;
});

// UC Browser bug
// https://github.com/zloirock/core-js/issues/1008
var MISSED_STICKY$1 = UNSUPPORTED_Y$3 || fails$a(function () {
  return !$RegExp$2('a', 'y').sticky;
});

var BROKEN_CARET = UNSUPPORTED_Y$3 || fails$a(function () {
  // https://bugzilla.mozilla.org/show_bug.cgi?id=773687
  var re = $RegExp$2('^r', 'gy');
  re.lastIndex = 2;
  return re.exec('str') != null;
});

var regexpStickyHelpers = {
  BROKEN_CARET: BROKEN_CARET,
  MISSED_STICKY: MISSED_STICKY$1,
  UNSUPPORTED_Y: UNSUPPORTED_Y$3
};

var fails$9 = fails$q;
var global$5 = global$i;

// babel-minify and Closure Compiler transpiles RegExp('.', 's') -> /./s and it causes SyntaxError
var $RegExp$1 = global$5.RegExp;

var regexpUnsupportedDotAll = fails$9(function () {
  var re = $RegExp$1('.', 's');
  return !(re.dotAll && re.exec('\n') && re.flags === 's');
});

var fails$8 = fails$q;
var global$4 = global$i;

// babel-minify and Closure Compiler transpiles RegExp('(?<a>b)', 'g') -> /(?<a>b)/g and it causes SyntaxError
var $RegExp = global$4.RegExp;

var regexpUnsupportedNcg = fails$8(function () {
  var re = $RegExp('(?<a>b)', 'g');
  return re.exec('b').groups.a !== 'b' ||
    'b'.replace(re, '$<a>c') !== 'bc';
});

/* eslint-disable regexp/no-empty-capturing-group, regexp/no-empty-group, regexp/no-lazy-ends -- testing */
/* eslint-disable regexp/no-useless-quantifier -- testing */
var call$5 = functionCall;
var uncurryThis$b = functionUncurryThis;
var toString$7 = toString$b;
var regexpFlags = regexpFlags$1;
var stickyHelpers$2 = regexpStickyHelpers;
var shared = shared$4.exports;
var create = objectCreate;
var getInternalState = internalState.get;
var UNSUPPORTED_DOT_ALL$1 = regexpUnsupportedDotAll;
var UNSUPPORTED_NCG$1 = regexpUnsupportedNcg;

var nativeReplace = shared('native-string-replace', String.prototype.replace);
var nativeExec = RegExp.prototype.exec;
var patchedExec = nativeExec;
var charAt$5 = uncurryThis$b(''.charAt);
var indexOf = uncurryThis$b(''.indexOf);
var replace$3 = uncurryThis$b(''.replace);
var stringSlice$5 = uncurryThis$b(''.slice);

var UPDATES_LAST_INDEX_WRONG = (function () {
  var re1 = /a/;
  var re2 = /b*/g;
  call$5(nativeExec, re1, 'a');
  call$5(nativeExec, re2, 'a');
  return re1.lastIndex !== 0 || re2.lastIndex !== 0;
})();

var UNSUPPORTED_Y$2 = stickyHelpers$2.BROKEN_CARET;

// nonparticipating capturing group, copied from es5-shim's String#split patch.
var NPCG_INCLUDED = /()??/.exec('')[1] !== undefined;

var PATCH = UPDATES_LAST_INDEX_WRONG || NPCG_INCLUDED || UNSUPPORTED_Y$2 || UNSUPPORTED_DOT_ALL$1 || UNSUPPORTED_NCG$1;

if (PATCH) {
  patchedExec = function exec(string) {
    var re = this;
    var state = getInternalState(re);
    var str = toString$7(string);
    var raw = state.raw;
    var result, reCopy, lastIndex, match, i, object, group;

    if (raw) {
      raw.lastIndex = re.lastIndex;
      result = call$5(patchedExec, raw, str);
      re.lastIndex = raw.lastIndex;
      return result;
    }

    var groups = state.groups;
    var sticky = UNSUPPORTED_Y$2 && re.sticky;
    var flags = call$5(regexpFlags, re);
    var source = re.source;
    var charsAdded = 0;
    var strCopy = str;

    if (sticky) {
      flags = replace$3(flags, 'y', '');
      if (indexOf(flags, 'g') === -1) {
        flags += 'g';
      }

      strCopy = stringSlice$5(str, re.lastIndex);
      // Support anchored sticky behavior.
      if (re.lastIndex > 0 && (!re.multiline || re.multiline && charAt$5(str, re.lastIndex - 1) !== '\n')) {
        source = '(?: ' + source + ')';
        strCopy = ' ' + strCopy;
        charsAdded++;
      }
      // ^(? + rx + ) is needed, in combination with some str slicing, to
      // simulate the 'y' flag.
      reCopy = new RegExp('^(?:' + source + ')', flags);
    }

    if (NPCG_INCLUDED) {
      reCopy = new RegExp('^' + source + '$(?!\\s)', flags);
    }
    if (UPDATES_LAST_INDEX_WRONG) lastIndex = re.lastIndex;

    match = call$5(nativeExec, sticky ? reCopy : re, strCopy);

    if (sticky) {
      if (match) {
        match.input = stringSlice$5(match.input, charsAdded);
        match[0] = stringSlice$5(match[0], charsAdded);
        match.index = re.lastIndex;
        re.lastIndex += match[0].length;
      } else re.lastIndex = 0;
    } else if (UPDATES_LAST_INDEX_WRONG && match) {
      re.lastIndex = re.global ? match.index + match[0].length : lastIndex;
    }
    if (NPCG_INCLUDED && match && match.length > 1) {
      // Fix browsers whose `exec` methods don't consistently return `undefined`
      // for NPCG, like IE8. NOTE: This doesn't work for /(.?)?/
      call$5(nativeReplace, match[0], reCopy, function () {
        for (i = 1; i < arguments.length - 2; i++) {
          if (arguments[i] === undefined) match[i] = undefined;
        }
      });
    }

    if (match && groups) {
      match.groups = object = create(null);
      for (i = 0; i < groups.length; i++) {
        group = groups[i];
        object[group[0]] = match[group[1]];
      }
    }

    return match;
  };
}

var regexpExec$3 = patchedExec;

var $$2 = _export;
var exec$4 = regexpExec$3;

// `RegExp.prototype.exec` method
// https://tc39.es/ecma262/#sec-regexp.prototype.exec
$$2({ target: 'RegExp', proto: true, forced: /./.exec !== exec$4 }, {
  exec: exec$4
});

var NATIVE_BIND = functionBindNative;

var FunctionPrototype = Function.prototype;
var apply$2 = FunctionPrototype.apply;
var call$4 = FunctionPrototype.call;

// eslint-disable-next-line es/no-reflect -- safe
var functionApply = typeof Reflect == 'object' && Reflect.apply || (NATIVE_BIND ? call$4.bind(apply$2) : function () {
  return call$4.apply(apply$2, arguments);
});

var classofRaw = classofRaw$2;
var uncurryThis$a = functionUncurryThis;

var functionUncurryThisClause = function (fn) {
  // Nashorn bug:
  //   https://github.com/zloirock/core-js/issues/1128
  //   https://github.com/zloirock/core-js/issues/1130
  if (classofRaw(fn) === 'Function') return uncurryThis$a(fn);
};

// TODO: Remove from `core-js@4` since it's moved to entry points

var uncurryThis$9 = functionUncurryThisClause;
var defineBuiltIn$2 = defineBuiltIn$6;
var regexpExec$2 = regexpExec$3;
var fails$7 = fails$q;
var wellKnownSymbol$4 = wellKnownSymbol$f;
var createNonEnumerableProperty$1 = createNonEnumerableProperty$6;

var SPECIES$2 = wellKnownSymbol$4('species');
var RegExpPrototype$3 = RegExp.prototype;

var fixRegexpWellKnownSymbolLogic = function (KEY, exec, FORCED, SHAM) {
  var SYMBOL = wellKnownSymbol$4(KEY);

  var DELEGATES_TO_SYMBOL = !fails$7(function () {
    // String methods call symbol-named RegEp methods
    var O = {};
    O[SYMBOL] = function () { return 7; };
    return ''[KEY](O) != 7;
  });

  var DELEGATES_TO_EXEC = DELEGATES_TO_SYMBOL && !fails$7(function () {
    // Symbol-named RegExp methods call .exec
    var execCalled = false;
    var re = /a/;

    if (KEY === 'split') {
      // We can't use real regex here since it causes deoptimization
      // and serious performance degradation in V8
      // https://github.com/zloirock/core-js/issues/306
      re = {};
      // RegExp[@@split] doesn't call the regex's exec method, but first creates
      // a new one. We need to return the patched regex when creating the new one.
      re.constructor = {};
      re.constructor[SPECIES$2] = function () { return re; };
      re.flags = '';
      re[SYMBOL] = /./[SYMBOL];
    }

    re.exec = function () { execCalled = true; return null; };

    re[SYMBOL]('');
    return !execCalled;
  });

  if (
    !DELEGATES_TO_SYMBOL ||
    !DELEGATES_TO_EXEC ||
    FORCED
  ) {
    var uncurriedNativeRegExpMethod = uncurryThis$9(/./[SYMBOL]);
    var methods = exec(SYMBOL, ''[KEY], function (nativeMethod, regexp, str, arg2, forceStringMethod) {
      var uncurriedNativeMethod = uncurryThis$9(nativeMethod);
      var $exec = regexp.exec;
      if ($exec === regexpExec$2 || $exec === RegExpPrototype$3.exec) {
        if (DELEGATES_TO_SYMBOL && !forceStringMethod) {
          // The native String method already delegates to @@method (this
          // polyfilled function), leasing to infinite recursion.
          // We avoid it by directly calling the native @@method method.
          return { done: true, value: uncurriedNativeRegExpMethod(regexp, str, arg2) };
        }
        return { done: true, value: uncurriedNativeMethod(str, regexp, arg2) };
      }
      return { done: false };
    });

    defineBuiltIn$2(String.prototype, KEY, methods[0]);
    defineBuiltIn$2(RegExpPrototype$3, SYMBOL, methods[1]);
  }

  if (SHAM) createNonEnumerableProperty$1(RegExpPrototype$3[SYMBOL], 'sham', true);
};

var uncurryThis$8 = functionUncurryThis;
var toIntegerOrInfinity$1 = toIntegerOrInfinity$6;
var toString$6 = toString$b;
var requireObjectCoercible$3 = requireObjectCoercible$8;

var charAt$4 = uncurryThis$8(''.charAt);
var charCodeAt = uncurryThis$8(''.charCodeAt);
var stringSlice$4 = uncurryThis$8(''.slice);

var createMethod$1 = function (CONVERT_TO_STRING) {
  return function ($this, pos) {
    var S = toString$6(requireObjectCoercible$3($this));
    var position = toIntegerOrInfinity$1(pos);
    var size = S.length;
    var first, second;
    if (position < 0 || position >= size) return CONVERT_TO_STRING ? '' : undefined;
    first = charCodeAt(S, position);
    return first < 0xD800 || first > 0xDBFF || position + 1 === size
      || (second = charCodeAt(S, position + 1)) < 0xDC00 || second > 0xDFFF
        ? CONVERT_TO_STRING
          ? charAt$4(S, position)
          : first
        : CONVERT_TO_STRING
          ? stringSlice$4(S, position, position + 2)
          : (first - 0xD800 << 10) + (second - 0xDC00) + 0x10000;
  };
};

var stringMultibyte = {
  // `String.prototype.codePointAt` method
  // https://tc39.es/ecma262/#sec-string.prototype.codepointat
  codeAt: createMethod$1(false),
  // `String.prototype.at` method
  // https://github.com/mathiasbynens/String.prototype.at
  charAt: createMethod$1(true)
};

var charAt$3 = stringMultibyte.charAt;

// `AdvanceStringIndex` abstract operation
// https://tc39.es/ecma262/#sec-advancestringindex
var advanceStringIndex$2 = function (S, index, unicode) {
  return index + (unicode ? charAt$3(S, index).length : 1);
};

var uncurryThis$7 = functionUncurryThis;
var toObject = toObject$6;

var floor = Math.floor;
var charAt$2 = uncurryThis$7(''.charAt);
var replace$2 = uncurryThis$7(''.replace);
var stringSlice$3 = uncurryThis$7(''.slice);
var SUBSTITUTION_SYMBOLS = /\$([$&'`]|\d{1,2}|<[^>]*>)/g;
var SUBSTITUTION_SYMBOLS_NO_NAMED = /\$([$&'`]|\d{1,2})/g;

// `GetSubstitution` abstract operation
// https://tc39.es/ecma262/#sec-getsubstitution
var getSubstitution$1 = function (matched, str, position, captures, namedCaptures, replacement) {
  var tailPos = position + matched.length;
  var m = captures.length;
  var symbols = SUBSTITUTION_SYMBOLS_NO_NAMED;
  if (namedCaptures !== undefined) {
    namedCaptures = toObject(namedCaptures);
    symbols = SUBSTITUTION_SYMBOLS;
  }
  return replace$2(replacement, symbols, function (match, ch) {
    var capture;
    switch (charAt$2(ch, 0)) {
      case '$': return '$';
      case '&': return matched;
      case '`': return stringSlice$3(str, 0, position);
      case "'": return stringSlice$3(str, tailPos);
      case '<':
        capture = namedCaptures[stringSlice$3(ch, 1, -1)];
        break;
      default: // \d\d?
        var n = +ch;
        if (n === 0) return match;
        if (n > m) {
          var f = floor(n / 10);
          if (f === 0) return match;
          if (f <= m) return captures[f - 1] === undefined ? charAt$2(ch, 1) : captures[f - 1] + charAt$2(ch, 1);
          return match;
        }
        capture = captures[n - 1];
    }
    return capture === undefined ? '' : capture;
  });
};

var call$3 = functionCall;
var anObject$4 = anObject$b;
var isCallable$3 = isCallable$j;
var classof$1 = classofRaw$2;
var regexpExec$1 = regexpExec$3;

var $TypeError$1 = TypeError;

// `RegExpExec` abstract operation
// https://tc39.es/ecma262/#sec-regexpexec
var regexpExecAbstract = function (R, S) {
  var exec = R.exec;
  if (isCallable$3(exec)) {
    var result = call$3(exec, R, S);
    if (result !== null) anObject$4(result);
    return result;
  }
  if (classof$1(R) === 'RegExp') return call$3(regexpExec$1, R, S);
  throw $TypeError$1('RegExp#exec called on incompatible receiver');
};

var apply$1 = functionApply;
var call$2 = functionCall;
var uncurryThis$6 = functionUncurryThis;
var fixRegExpWellKnownSymbolLogic$1 = fixRegexpWellKnownSymbolLogic;
var fails$6 = fails$q;
var anObject$3 = anObject$b;
var isCallable$2 = isCallable$j;
var isNullOrUndefined$2 = isNullOrUndefined$5;
var toIntegerOrInfinity = toIntegerOrInfinity$6;
var toLength$1 = toLength$3;
var toString$5 = toString$b;
var requireObjectCoercible$2 = requireObjectCoercible$8;
var advanceStringIndex$1 = advanceStringIndex$2;
var getMethod$1 = getMethod$3;
var getSubstitution = getSubstitution$1;
var regExpExec = regexpExecAbstract;
var wellKnownSymbol$3 = wellKnownSymbol$f;

var REPLACE = wellKnownSymbol$3('replace');
var max = Math.max;
var min$1 = Math.min;
var concat = uncurryThis$6([].concat);
var push$1 = uncurryThis$6([].push);
var stringIndexOf$1 = uncurryThis$6(''.indexOf);
var stringSlice$2 = uncurryThis$6(''.slice);

var maybeToString = function (it) {
  return it === undefined ? it : String(it);
};

// IE <= 11 replaces $0 with the whole match, as if it was $&
// https://stackoverflow.com/questions/6024666/getting-ie-to-replace-a-regex-with-the-literal-string-0
var REPLACE_KEEPS_$0 = (function () {
  // eslint-disable-next-line regexp/prefer-escape-replacement-dollar-char -- required for testing
  return 'a'.replace(/./, '$0') === '$0';
})();

// Safari <= 13.0.3(?) substitutes nth capture where n>m with an empty string
var REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE = (function () {
  if (/./[REPLACE]) {
    return /./[REPLACE]('a', '$0') === '';
  }
  return false;
})();

var REPLACE_SUPPORTS_NAMED_GROUPS = !fails$6(function () {
  var re = /./;
  re.exec = function () {
    var result = [];
    result.groups = { a: '7' };
    return result;
  };
  // eslint-disable-next-line regexp/no-useless-dollar-replacements -- false positive
  return ''.replace(re, '$<a>') !== '7';
});

// @@replace logic
fixRegExpWellKnownSymbolLogic$1('replace', function (_, nativeReplace, maybeCallNative) {
  var UNSAFE_SUBSTITUTE = REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE ? '$' : '$0';

  return [
    // `String.prototype.replace` method
    // https://tc39.es/ecma262/#sec-string.prototype.replace
    function replace(searchValue, replaceValue) {
      var O = requireObjectCoercible$2(this);
      var replacer = isNullOrUndefined$2(searchValue) ? undefined : getMethod$1(searchValue, REPLACE);
      return replacer
        ? call$2(replacer, searchValue, O, replaceValue)
        : call$2(nativeReplace, toString$5(O), searchValue, replaceValue);
    },
    // `RegExp.prototype[@@replace]` method
    // https://tc39.es/ecma262/#sec-regexp.prototype-@@replace
    function (string, replaceValue) {
      var rx = anObject$3(this);
      var S = toString$5(string);

      if (
        typeof replaceValue == 'string' &&
        stringIndexOf$1(replaceValue, UNSAFE_SUBSTITUTE) === -1 &&
        stringIndexOf$1(replaceValue, '$<') === -1
      ) {
        var res = maybeCallNative(nativeReplace, rx, S, replaceValue);
        if (res.done) return res.value;
      }

      var functionalReplace = isCallable$2(replaceValue);
      if (!functionalReplace) replaceValue = toString$5(replaceValue);

      var global = rx.global;
      if (global) {
        var fullUnicode = rx.unicode;
        rx.lastIndex = 0;
      }
      var results = [];
      while (true) {
        var result = regExpExec(rx, S);
        if (result === null) break;

        push$1(results, result);
        if (!global) break;

        var matchStr = toString$5(result[0]);
        if (matchStr === '') rx.lastIndex = advanceStringIndex$1(S, toLength$1(rx.lastIndex), fullUnicode);
      }

      var accumulatedResult = '';
      var nextSourcePosition = 0;
      for (var i = 0; i < results.length; i++) {
        result = results[i];

        var matched = toString$5(result[0]);
        var position = max(min$1(toIntegerOrInfinity(result.index), S.length), 0);
        var captures = [];
        // NOTE: This is equivalent to
        //   captures = result.slice(1).map(maybeToString)
        // but for some reason `nativeSlice.call(result, 1, result.length)` (called in
        // the slice polyfill when slicing native arrays) "doesn't work" in safari 9 and
        // causes a crash (https://pastebin.com/N21QzeQA) when trying to debug it.
        for (var j = 1; j < result.length; j++) push$1(captures, maybeToString(result[j]));
        var namedCaptures = result.groups;
        if (functionalReplace) {
          var replacerArgs = concat([matched], captures, position, S);
          if (namedCaptures !== undefined) push$1(replacerArgs, namedCaptures);
          var replacement = toString$5(apply$1(replaceValue, undefined, replacerArgs));
        } else {
          replacement = getSubstitution(matched, S, position, captures, namedCaptures, replaceValue);
        }
        if (position >= nextSourcePosition) {
          accumulatedResult += stringSlice$2(S, nextSourcePosition, position) + replacement;
          nextSourcePosition = position + matched.length;
        }
      }
      return accumulatedResult + stringSlice$2(S, nextSourcePosition);
    }
  ];
}, !REPLACE_SUPPORTS_NAMED_GROUPS || !REPLACE_KEEPS_$0 || REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE);

// a string of all valid unicode whitespaces
var whitespaces$3 = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u2000\u2001\u2002' +
  '\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000\u2028\u2029\uFEFF';

var uncurryThis$5 = functionUncurryThis;
var requireObjectCoercible$1 = requireObjectCoercible$8;
var toString$4 = toString$b;
var whitespaces$2 = whitespaces$3;

var replace$1 = uncurryThis$5(''.replace);
var whitespace = '[' + whitespaces$2 + ']';
var ltrim = RegExp('^' + whitespace + whitespace + '*');
var rtrim = RegExp(whitespace + whitespace + '*$');

// `String.prototype.{ trim, trimStart, trimEnd, trimLeft, trimRight }` methods implementation
var createMethod = function (TYPE) {
  return function ($this) {
    var string = toString$4(requireObjectCoercible$1($this));
    if (TYPE & 1) string = replace$1(string, ltrim, '');
    if (TYPE & 2) string = replace$1(string, rtrim, '');
    return string;
  };
};

var stringTrim = {
  // `String.prototype.{ trimLeft, trimStart }` methods
  // https://tc39.es/ecma262/#sec-string.prototype.trimstart
  start: createMethod(1),
  // `String.prototype.{ trimRight, trimEnd }` methods
  // https://tc39.es/ecma262/#sec-string.prototype.trimend
  end: createMethod(2),
  // `String.prototype.trim` method
  // https://tc39.es/ecma262/#sec-string.prototype.trim
  trim: createMethod(3)
};

var global$3 = global$i;
var fails$5 = fails$q;
var uncurryThis$4 = functionUncurryThis;
var toString$3 = toString$b;
var trim$1 = stringTrim.trim;
var whitespaces$1 = whitespaces$3;

var $parseInt$1 = global$3.parseInt;
var Symbol$2 = global$3.Symbol;
var ITERATOR$1 = Symbol$2 && Symbol$2.iterator;
var hex = /^[+-]?0x/i;
var exec$3 = uncurryThis$4(hex.exec);
var FORCED$1 = $parseInt$1(whitespaces$1 + '08') !== 8 || $parseInt$1(whitespaces$1 + '0x16') !== 22
  // MS Edge 18- broken with boxed symbols
  || (ITERATOR$1 && !fails$5(function () { $parseInt$1(Object(ITERATOR$1)); }));

// `parseInt` method
// https://tc39.es/ecma262/#sec-parseint-string-radix
var numberParseInt = FORCED$1 ? function parseInt(string, radix) {
  var S = trim$1(toString$3(string));
  return $parseInt$1(S, (radix >>> 0) || (exec$3(hex, S) ? 16 : 10));
} : $parseInt$1;

var $$1 = _export;
var $parseInt = numberParseInt;

// `parseInt` method
// https://tc39.es/ecma262/#sec-parseint-string-radix
$$1({ global: true, forced: parseInt != $parseInt }, {
  parseInt: $parseInt
});

var isCallable$1 = isCallable$j;
var isObject = isObject$8;
var setPrototypeOf = objectSetPrototypeOf;

// makes subclassing work correct for wrapped built-ins
var inheritIfRequired$1 = function ($this, dummy, Wrapper) {
  var NewTarget, NewTargetPrototype;
  if (
    // it can work only with native `setPrototypeOf`
    setPrototypeOf &&
    // we haven't completely correct pre-ES6 way for getting `new.target`, so use this
    isCallable$1(NewTarget = dummy.constructor) &&
    NewTarget !== Wrapper &&
    isObject(NewTargetPrototype = NewTarget.prototype) &&
    NewTargetPrototype !== Wrapper.prototype
  ) setPrototypeOf($this, NewTargetPrototype);
  return $this;
};

var call$1 = functionCall;
var hasOwn$1 = hasOwnProperty_1;
var isPrototypeOf$1 = objectIsPrototypeOf;
var regExpFlags = regexpFlags$1;

var RegExpPrototype$2 = RegExp.prototype;

var regexpGetFlags = function (R) {
  var flags = R.flags;
  return flags === undefined && !('flags' in RegExpPrototype$2) && !hasOwn$1(R, 'flags') && isPrototypeOf$1(RegExpPrototype$2, R)
    ? call$1(regExpFlags, R) : flags;
};

var defineProperty = objectDefineProperty.f;

var proxyAccessor$1 = function (Target, Source, key) {
  key in Target || defineProperty(Target, key, {
    configurable: true,
    get: function () { return Source[key]; },
    set: function (it) { Source[key] = it; }
  });
};

var getBuiltIn$1 = getBuiltIn$6;
var definePropertyModule = objectDefineProperty;
var wellKnownSymbol$2 = wellKnownSymbol$f;
var DESCRIPTORS$1 = descriptors;

var SPECIES$1 = wellKnownSymbol$2('species');

var setSpecies$1 = function (CONSTRUCTOR_NAME) {
  var Constructor = getBuiltIn$1(CONSTRUCTOR_NAME);
  var defineProperty = definePropertyModule.f;

  if (DESCRIPTORS$1 && Constructor && !Constructor[SPECIES$1]) {
    defineProperty(Constructor, SPECIES$1, {
      configurable: true,
      get: function () { return this; }
    });
  }
};

var DESCRIPTORS = descriptors;
var global$2 = global$i;
var uncurryThis$3 = functionUncurryThis;
var isForced = isForced_1;
var inheritIfRequired = inheritIfRequired$1;
var createNonEnumerableProperty = createNonEnumerableProperty$6;
var getOwnPropertyNames = objectGetOwnPropertyNames.f;
var isPrototypeOf = objectIsPrototypeOf;
var isRegExp$1 = isRegexp;
var toString$2 = toString$b;
var getRegExpFlags$1 = regexpGetFlags;
var stickyHelpers$1 = regexpStickyHelpers;
var proxyAccessor = proxyAccessor$1;
var defineBuiltIn$1 = defineBuiltIn$6;
var fails$4 = fails$q;
var hasOwn = hasOwnProperty_1;
var enforceInternalState = internalState.enforce;
var setSpecies = setSpecies$1;
var wellKnownSymbol$1 = wellKnownSymbol$f;
var UNSUPPORTED_DOT_ALL = regexpUnsupportedDotAll;
var UNSUPPORTED_NCG = regexpUnsupportedNcg;

var MATCH = wellKnownSymbol$1('match');
var NativeRegExp = global$2.RegExp;
var RegExpPrototype$1 = NativeRegExp.prototype;
var SyntaxError = global$2.SyntaxError;
var exec$2 = uncurryThis$3(RegExpPrototype$1.exec);
var charAt$1 = uncurryThis$3(''.charAt);
var replace = uncurryThis$3(''.replace);
var stringIndexOf = uncurryThis$3(''.indexOf);
var stringSlice$1 = uncurryThis$3(''.slice);
// TODO: Use only proper RegExpIdentifierName
var IS_NCG = /^\?<[^\s\d!#%&*+<=>@^][^\s!#%&*+<=>@^]*>/;
var re1 = /a/g;
var re2 = /a/g;

// "new" should create a new object, old webkit bug
var CORRECT_NEW = new NativeRegExp(re1) !== re1;

var MISSED_STICKY = stickyHelpers$1.MISSED_STICKY;
var UNSUPPORTED_Y$1 = stickyHelpers$1.UNSUPPORTED_Y;

var BASE_FORCED = DESCRIPTORS &&
  (!CORRECT_NEW || MISSED_STICKY || UNSUPPORTED_DOT_ALL || UNSUPPORTED_NCG || fails$4(function () {
    re2[MATCH] = false;
    // RegExp constructor can alter flags and IsRegExp works correct with @@match
    return NativeRegExp(re1) != re1 || NativeRegExp(re2) == re2 || NativeRegExp(re1, 'i') != '/a/i';
  }));

var handleDotAll = function (string) {
  var length = string.length;
  var index = 0;
  var result = '';
  var brackets = false;
  var chr;
  for (; index <= length; index++) {
    chr = charAt$1(string, index);
    if (chr === '\\') {
      result += chr + charAt$1(string, ++index);
      continue;
    }
    if (!brackets && chr === '.') {
      result += '[\\s\\S]';
    } else {
      if (chr === '[') {
        brackets = true;
      } else if (chr === ']') {
        brackets = false;
      } result += chr;
    }
  } return result;
};

var handleNCG = function (string) {
  var length = string.length;
  var index = 0;
  var result = '';
  var named = [];
  var names = {};
  var brackets = false;
  var ncg = false;
  var groupid = 0;
  var groupname = '';
  var chr;
  for (; index <= length; index++) {
    chr = charAt$1(string, index);
    if (chr === '\\') {
      chr = chr + charAt$1(string, ++index);
    } else if (chr === ']') {
      brackets = false;
    } else if (!brackets) switch (true) {
      case chr === '[':
        brackets = true;
        break;
      case chr === '(':
        if (exec$2(IS_NCG, stringSlice$1(string, index + 1))) {
          index += 2;
          ncg = true;
        }
        result += chr;
        groupid++;
        continue;
      case chr === '>' && ncg:
        if (groupname === '' || hasOwn(names, groupname)) {
          throw new SyntaxError('Invalid capture group name');
        }
        names[groupname] = true;
        named[named.length] = [groupname, groupid];
        ncg = false;
        groupname = '';
        continue;
    }
    if (ncg) groupname += chr;
    else result += chr;
  } return [result, named];
};

// `RegExp` constructor
// https://tc39.es/ecma262/#sec-regexp-constructor
if (isForced('RegExp', BASE_FORCED)) {
  var RegExpWrapper = function RegExp(pattern, flags) {
    var thisIsRegExp = isPrototypeOf(RegExpPrototype$1, this);
    var patternIsRegExp = isRegExp$1(pattern);
    var flagsAreUndefined = flags === undefined;
    var groups = [];
    var rawPattern = pattern;
    var rawFlags, dotAll, sticky, handled, result, state;

    if (!thisIsRegExp && patternIsRegExp && flagsAreUndefined && pattern.constructor === RegExpWrapper) {
      return pattern;
    }

    if (patternIsRegExp || isPrototypeOf(RegExpPrototype$1, pattern)) {
      pattern = pattern.source;
      if (flagsAreUndefined) flags = getRegExpFlags$1(rawPattern);
    }

    pattern = pattern === undefined ? '' : toString$2(pattern);
    flags = flags === undefined ? '' : toString$2(flags);
    rawPattern = pattern;

    if (UNSUPPORTED_DOT_ALL && 'dotAll' in re1) {
      dotAll = !!flags && stringIndexOf(flags, 's') > -1;
      if (dotAll) flags = replace(flags, /s/g, '');
    }

    rawFlags = flags;

    if (MISSED_STICKY && 'sticky' in re1) {
      sticky = !!flags && stringIndexOf(flags, 'y') > -1;
      if (sticky && UNSUPPORTED_Y$1) flags = replace(flags, /y/g, '');
    }

    if (UNSUPPORTED_NCG) {
      handled = handleNCG(pattern);
      pattern = handled[0];
      groups = handled[1];
    }

    result = inheritIfRequired(NativeRegExp(pattern, flags), thisIsRegExp ? this : RegExpPrototype$1, RegExpWrapper);

    if (dotAll || sticky || groups.length) {
      state = enforceInternalState(result);
      if (dotAll) {
        state.dotAll = true;
        state.raw = RegExpWrapper(handleDotAll(pattern), rawFlags);
      }
      if (sticky) state.sticky = true;
      if (groups.length) state.groups = groups;
    }

    if (pattern !== rawPattern) try {
      // fails in old engines, but we have no alternatives for unsupported regex syntax
      createNonEnumerableProperty(result, 'source', rawPattern === '' ? '(?:)' : rawPattern);
    } catch (error) { /* empty */ }

    return result;
  };

  for (var keys = getOwnPropertyNames(NativeRegExp), index = 0; keys.length > index;) {
    proxyAccessor(RegExpWrapper, NativeRegExp, keys[index++]);
  }

  RegExpPrototype$1.constructor = RegExpWrapper;
  RegExpWrapper.prototype = RegExpPrototype$1;
  defineBuiltIn$1(global$2, 'RegExp', RegExpWrapper, { constructor: true });
}

// https://tc39.es/ecma262/#sec-get-regexp-@@species
setSpecies('RegExp');

var PROPER_FUNCTION_NAME = functionName.PROPER;
var defineBuiltIn = defineBuiltIn$6;
var anObject$2 = anObject$b;
var $toString = toString$b;
var fails$3 = fails$q;
var getRegExpFlags = regexpGetFlags;

var TO_STRING = 'toString';
var RegExpPrototype = RegExp.prototype;
var nativeToString = RegExpPrototype[TO_STRING];

var NOT_GENERIC = fails$3(function () { return nativeToString.call({ source: 'a', flags: 'b' }) != '/a/b'; });
// FF44- RegExp#toString has a wrong name
var INCORRECT_NAME = PROPER_FUNCTION_NAME && nativeToString.name != TO_STRING;

// `RegExp.prototype.toString` method
// https://tc39.es/ecma262/#sec-regexp.prototype.tostring
if (NOT_GENERIC || INCORRECT_NAME) {
  defineBuiltIn(RegExp.prototype, TO_STRING, function toString() {
    var R = anObject$2(this);
    var pattern = $toString(R.source);
    var flags = $toString(getRegExpFlags(R));
    return '/' + pattern + '/' + flags;
  }, { unsafe: true });
}

var global$1 = global$i;
var fails$2 = fails$q;
var uncurryThis$2 = functionUncurryThis;
var toString$1 = toString$b;
var trim = stringTrim.trim;
var whitespaces = whitespaces$3;

var charAt = uncurryThis$2(''.charAt);
var $parseFloat$1 = global$1.parseFloat;
var Symbol$1 = global$1.Symbol;
var ITERATOR = Symbol$1 && Symbol$1.iterator;
var FORCED = 1 / $parseFloat$1(whitespaces + '-0') !== -Infinity
  // MS Edge 18- broken with boxed symbols
  || (ITERATOR && !fails$2(function () { $parseFloat$1(Object(ITERATOR)); }));

// `parseFloat` method
// https://tc39.es/ecma262/#sec-parsefloat-string
var numberParseFloat = FORCED ? function parseFloat(string) {
  var trimmedString = trim(toString$1(string));
  var result = $parseFloat$1(trimmedString);
  return result === 0 && charAt(trimmedString, 0) == '-' ? -0 : result;
} : $parseFloat$1;

var $ = _export;
var $parseFloat = numberParseFloat;

// `parseFloat` method
// https://tc39.es/ecma262/#sec-parsefloat-string
$({ global: true, forced: parseFloat != $parseFloat }, {
  parseFloat: $parseFloat
});

/**
 * Represents color and provides useful methods to work with colors.
 */
class Color {
  /**
   * Creates new {@link Color} instance
   * @param red - Hex, rgb or rgba css string, another {@link Color} instance, or numeric value of red part of the color.
   * @param green - Numeric value of green part of the color.
   * @param blue - Numeric value of blue part of the color.
   * @param alpha - Numeric value of the alpha channel.
   */
  constructor(red, green, blue, alpha) {
    this._r = 0;
    this._g = 0;
    this._b = 0;
    this._a = 1;
    if (typeof red === 'string') {
      let color = red;
      if (color.charAt(0) === '#') {
        color = color.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/, (m, r, g, b) => r + r + g + g + b + b);
        const res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        if (res) {
          this._r = parseInt(res[1], 16);
          this._g = parseInt(res[2], 16);
          this._b = parseInt(res[3], 16);
          this._a = 1;
        }
      } else if (/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i.exec(color)) {
        this._r = parseInt(RegExp.$1);
        this._g = parseInt(RegExp.$2);
        this._b = parseInt(RegExp.$3);
        this._a = 1;
      } else if (/^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+\.?\d*)\s*\)$/i.exec(color)) {
        this._r = parseInt(RegExp.$1);
        this._g = parseInt(RegExp.$2);
        this._b = parseInt(RegExp.$3);
        this._a = parseFloat(RegExp.$4);
      }
    } else if (typeof red === 'number') {
      this._r = red !== undefined ? red : 0;
      this._g = green !== undefined ? green : 0;
      this._b = blue !== undefined ? blue : 0;
      this._a = alpha !== undefined ? alpha : 1;
    } else if (red instanceof Color) {
      this._r = red.red;
      this._g = red.green;
      this._b = red.blue;
      this._a = red.alpha;
    }
  }
  /**
   * Creates new {@link Color} instance
   * @param red - Hex, rgb or rgba css string, another {@link Color} instance, or numeric value of red part of the color.
   * @param green - Numeric value of green part of the color.
   * @param blue - Numeric value of blue part of the color.
   * @param alpha - Numeric value of the alpha channel.
   * @returns New {@link Color} instance. If the first argument is color, it's immediately returned instead of creating new instance.
   */
  static from(red, green, blue, alpha) {
    if (red instanceof Color) return red;
    return new Color(red, green, blue, alpha);
  }
  /** Alpha part of the color. */
  get alpha() {
    return this._a;
  }
  /** Alpha part of the color. */
  set alpha(value) {
    this._a = value;
  }
  /** Red part of the color. */
  get red() {
    return this._r;
  }
  /** Red part of the color. */
  set red(value) {
    this._r = value;
  }
  /** Green part of the color. */
  get green() {
    return this._g;
  }
  /** Green part of the color. */
  set green(value) {
    this._g = value;
  }
  /** Blue part of the color. */
  get blue() {
    return this._b;
  }
  /** Blue part of the color. */
  set blue(value) {
    this._b = value;
  }
  /** Computed luminance of the color. */
  get luminance() {
    return (0.3 * this._r + 0.59 * this._g + 0.11 * this._b) / 255;
  }
  /**
   * Clones this color to new instance.
   * @returns New {@link Color} instance of this color.
   */
  clone() {
    return new Color(this);
  }
  /**
   * Darkens color by deducting the amount from the color parts. Alpha channel is unchanged.
   * @param amount - Resulting color will be changed by deducting this amount from all color parts.
   * @returns New {@link Color} instance with darker color.
   */
  darker(amount) {
    if (!amount) return this;
    return new Color(Math.round(Math.max(0, this._r - amount)), Math.round(Math.max(0, this._g - amount)), Math.round(Math.max(0, this._b - amount)), this._a);
  }
  /**
   * Determines curent foreground color based on it's luminance.
   * @returns New {@link Color} instance with the foreground color.
   */
  determineForegroundColor() {
    if (this.alpha < 0.5) return new Color();
    if (this.luminance <= 0.85) {
      return new Color(255, 255, 255);
    }
    return new Color();
  }
  /**
   * Checks equality between 2 colors. Compares all color parts, including alpha channel.
   * @param color - Other color to compare
   */
  equal(color) {
    return this.red === color.red && this.green === color.green && this.blue === color.blue && this.alpha === color.alpha;
  }
  /** Returns true if the color is dark (based on the luminance value). */
  isDark() {
    return this.luminance <= 0.85;
  }
  /** Returns true if the color is light (based on the luminance value). */
  isLight() {
    return this.luminance > 0.85;
  }
  /**
   * Lightens color by adding the amount to the color parts. Alpha channel is unchanged.
   * @param amount - Resulting color will be changed by adding this amount to all color parts.
   * @returns New {@link Color} instance with lighter color.
   */
  lighter(amount) {
    if (!amount) return this;
    return new Color(Math.min(255, Math.round(this._r + amount)), Math.min(255, Math.round(this._g + amount)), Math.min(255, Math.round(this._b + amount)), this._a);
  }
  /**
   * Mixes together 2 colors by specified amount. Function deducts color parts, multiplies the result by amount and adds result to current color parts.
   * @param color - Other color.
   * @param amount - Amount to mix those colors, should be between 0 and 1. If the value is outside those bounds, minimum (or maximum) is set.
   * @returns New mixed {@link Color} instance.
   */
  mix(color, amount = 0.5) {
    amount = Math.max(0, Math.min(amount, 1));
    const r = this._r + (color.red - this._r) * amount;
    const g = this._g + (color.green - this._g) * amount;
    const b = this._b + (color.blue - this._b) * amount;
    const a = this._a + (color.alpha - this._a) * amount;
    return new Color(Math.round(r), Math.round(g), Math.round(b), a);
  }
  /**
   * Returns grayscaled color.
   * @param amount - Amount to grayscale the color by, should be between 0 and 1.
   * @returns New grayscaled {@link Color} instance.
   */
  grayscale(amount = 1) {
    if (amount === 0) return this.clone();
    const avg = Math.floor((this._r + this._g + this._b) / 3);
    const gray = new Color(avg, avg, avg, this._a);
    if (amount === 1) return gray;
    return this.mix(gray, amount);
  }
  /** Returns hex string of the color without alpha channel. */
  toHex() {
    const pad = n => n.length < 2 ? `0${n}` : n;
    return `#${pad(this._r.toString(16))}${pad(this._g.toString(16))}${pad(this._b.toString(16))}`;
  }
  /** Returns rgb string of the color. */
  toRGB() {
    return `rgb(${this._r},${this._g},${this._b})`;
  }
  /** Returns rgba string of the color. */
  toRGBA() {
    return `rgba(${this._r},${this._g},${this._b},${this._a})`;
  }
  /** Returns hex string of the color without alpha channel. */
  toString() {
    return this.toHex();
  }
  /** Returns hex string of the color without alpha channel. */
  toJSON() {
    return this.toHex();
  }
  /**
   * Changes alpha channel without changing this color.
   * @param value - Alpha channel value.
   * @returns New instance of the {@link Color} with the alpha channel changed.
   */
  transparent(value) {
    return new Color(this._r, this._g, this._b, value);
  }
  /** Returns hex string of the color without alpha channel. */
  valueOf() {
    return this.toHex();
  }
  /**
   * Computes interpolation between 2 colors over time.
   * @param to - Target value of the color.
   * @param time - Current time step.
   * @param easing - Easing function to use.
   * @param duration - Duration of the interpolation.
   * @returns New interpolated {@link Color} instance.
   */
  computeAnimationTick(to, time, easing, duration) {
    const minTime = Math.min(time, duration);
    const red = Math.round(easing(minTime, this.red, to.red - this.red, duration));
    const green = Math.round(easing(minTime, this.green, to.green - this.green, duration));
    const blue = Math.round(easing(minTime, this.blue, to.blue - this.blue, duration));
    const alpha = Math.round(easing(minTime, this.alpha, to.alpha - this.alpha, duration));
    return new Color(red, green, blue, alpha);
  }
  /** Returns new instance of {@link Color} with randomized values. Doesn't randomize alpha channel. */
  static random() {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    return new Color(r, g, b, 1);
  }
}

let globalBuffer;
/** Uses off screen canvas to perform cached rendering outside of the main canvas. */
class BackBuffer {
  /**
   * Creates new {@link BackBuffer} with specified dimensions. Off screen canvas is instantiated automatically.
   * @param width - Width of the canvas in pixels.
   * @param height - Height of the canvas in pixels.
   */
  constructor(_width = 0, _height = 0) {
    var _a;
    this._width = _width;
    this._height = _height;
    this.bufferCanvas = document.createElement('canvas');
    this.bufferCanvas.width = this._width;
    this.bufferCanvas.height = this._height;
    if (CAPABILITIES.offscreenCanvas) {
      this.bufferCanvas = this.bufferCanvas.transferControlToOffscreen();
    }
    this.bufferCtx = this.bufferCanvas.getContext('2d');
    (_a = this.bufferCtx) === null || _a === void 0 ? void 0 : _a.save();
  }
  /** Width of the canvas in pixels. */
  get width() {
    return this._width;
  }
  /** Width of the canvas in pixels. */
  set width(value) {
    if (Number.isNaN(value)) return;
    if (value === this._width) return;
    this._width = value;
    this.bufferCanvas.width = value;
  }
  /** Height of the canvas in pixels. */
  get height() {
    return this._height;
  }
  /** Height of the canvas in pixels. */
  set height(value) {
    if (Number.isNaN(value)) return;
    if (this._height === value) return;
    this._height = value;
    this.bufferCanvas.height = value;
  }
  /**
   * Clears away all content rendered on off screen canvas.
   * @returns Instance of {@link BackBuffer}.
   */
  clear() {
    var _a;
    (_a = this.bufferCtx) === null || _a === void 0 ? void 0 : _a.clearRect(0, 0, this._width, this._height);
    return this;
  }
  /**
   * Proxy for {@link CanvasRenderingContext2D.drawImage}.
   * @param args - Arguments for {@link CanvasRenderingContext2D.drawImage}.
   * @returns Instance of {@link BackBuffer}.
   */
  drawImage(...args) {
    var _a;
    (_a = this.bufferCtx) === null || _a === void 0 ? void 0 : _a.drawImage(...args);
    return this;
  }
  measureText(...args) {
    if (!this.bufferCtx) return {
      width: 0
    };
    if (args.length > 1) {
      this.bufferCtx.textBaseline = 'top';
      this.bufferCtx.direction = 'ltr';
      this.bufferCtx.textAlign = 'left';
      this.bufferCtx.font = `${args[1].fontWeight || 'normal'} ${args[1].fontSize || '14px'} ${args[1].fontFamily || DEFAULT_TEXT_FONT}`;
    }
    return this.bufferCtx.measureText(args[0]);
  }
  /**
   * Applies filter to the off screen canvas image data.
   * @param filter - filter to be applied to canvas. Currently, you can pass 2 filters. `invert` - inverts the color data, `colorize:color` - changes the color of the canvas.
   * @returns Instance of {@link TextMetrics}.
   */
  // TODO: consider refactoring this to string union type and optional options object/type (based on the value of the union type))
  filter(filter) {
    const [type, opts] = filter.split(':');
    try {
      if (this.bufferCtx) {
        const data = this.bufferCtx.getImageData(0, 0, this._width, this._height);
        const {
          length
        } = data.data;
        let index = 0;
        switch (type) {
          case 'invert':
            {
              while (index < length) {
                data.data[index] = 255 - data.data[index];
                data.data[index + 1] = 255 - data.data[index + 1];
                data.data[index + 2] = 255 - data.data[index + 2];
                index += 4;
              }
              this.bufferCtx.putImageData(data, 0, 0);
              break;
            }
          case 'colorize':
            {
              const color = new Color(opts);
              while (index < length) {
                data.data[index] = color.red;
                data.data[index + 1] = color.green;
                data.data[index + 2] = color.blue;
                index += 4;
              }
              this.bufferCtx.putImageData(data, 0, 0);
              break;
            }
          default:
            break;
        }
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to apply filter '${type}'. ${ex.message}`);
    }
    return this;
  }
  /**
   * Resets off screen canvas context.
   * @returns Instance of {@link TextMetrics}.
   */
  reset() {
    var _a, _b;
    (_a = this.bufferCtx) === null || _a === void 0 ? void 0 : _a.restore();
    (_b = this.bufferCtx) === null || _b === void 0 ? void 0 : _b.save();
    return this;
  }
  /** Points to global instance of {@link BackBuffer}. Creates new instance if the global doesn't exist. */
  static get global() {
    if (!globalBuffer) globalBuffer = new BackBuffer();
    return globalBuffer;
  }
}

/**
 * Base class for all surface objects that allows them to emit and listen to events.
 */
class EventEmitter {
  constructor() {
    this.events = Object.create(null);
    this.bubbling = Object.create(null);
  }
  assignHandler(store, type, handler) {
    let events = store[type];
    if (!events) {
      store[type] = [];
    }
    events = store[type];
    if (events && !events.includes(handler)) {
      events.push(handler);
    }
    return this;
  }
  unassignHandler(store, type, handler) {
    const events = store[type];
    if (events) {
      if (!handler) {
        store[type] = [];
        return this;
      }
      const index = events.indexOf(handler);
      if (index !== -1) {
        events.splice(index, 1);
        if (events.length === 0) {
          delete store[type];
        }
      }
    }
    return this;
  }
  /**
   * Emit event. All listeners will immediately react to it.
   * @param type - Type of the event.
   * @param payload - Payload of the event.
   */
  emit(type, ...payload) {
    const events = this.events[type];
    if (events) {
      const listeners = events.slice(0);
      listeners.forEach(handler => {
        handler({
          target: this,
          payload: payload[0]
        });
      });
    }
    return this;
  }
  /**
   * Emits surface event and bubbles it up the object hierarchy. All parent object listeners will be called as well.
   * @param type - Type of the event.
   * @param payload - Payload of the event.
   */
  bubble(type, ...payload) {
    this.emit(type, ...payload);
    let level = this;
    while (level instanceof EventEmitter) {
      const bubblers = level.bubbling[type];
      if (bubblers) {
        const listeners = bubblers.slice(0);
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        listeners.forEach(handler => {
          handler({
            bubbledFrom: this,
            target: level,
            payload: payload[0]
          });
        });
      }
      level = level.parent;
    }
    return this;
  }
  /**
   * Registers an event listener. The listener will be called when the surface event is emitted.
   * @param type - Type of the event to listen to.
   * @param handler - Function that handles the event.
   */
  on(type, handler) {
    return this.assignHandler(this.events, type, handler);
  }
  /**
   * Stops listening to an event. You need to specify the same handler you used when calling the `on()` method.
   * @param type - Type of the event to stop listening to.
   * @param handler - Handler that should not be called anymore when the event is emitted.
   */
  off(type, handler) {
    return this.unassignHandler(this.events, type, handler);
  }
  /**
   * Registers an event listener. The listener will be called when the surface event is bubbled.
   * @param type - Type of the event to listen to.
   * @param handler - Function that handles the event.
   */
  onBubbling(type, handler) {
    return this.assignHandler(this.bubbling, type, handler);
  }
  /**
   * Stops listening to an event. You need to specify the same handler you used when calling the `onBubbling()` method.
   * @param type - Type of the event to stop listening to.
   * @param handler - Handler that should not be called anymore when the event is bubbled.
   */
  offBubbling(type, handler) {
    return this.unassignHandler(this.bubbling, type, handler);
  }
  /**
   * Registers an event listener. The listener will be called when the surface event is emitted, but only once. The
   * handler is removed after that.
   * @param type - Type of the event to listen to.
   * @param handler - Function that handles the event.
   */
  once(type, handler) {
    const onceHandler = event => {
      this.off(type, onceHandler);
      handler(event);
    };
    this.on(type, onceHandler);
    return this;
  }
  /**
   * Registers an event listener. The listener will be called when the surface event is bubbled, but only once. The
   * handler is removed after that.
   * @param type - Type of the event to listen to.
   * @param handler - Function that handles the event.
   */
  onceBubbling(type, handler) {
    const onceHandler = event => {
      this.offBubbling(type, onceHandler);
      handler(event);
    };
    this.onBubbling(type, onceHandler);
    return this;
  }
}

/**
 * Delays callback function call.
 * @param interval - Delay for the callback in miliseconds.
 * @param callback - Callback function to delay.
 * @returns Id of the timer. Can be used in the {@link undelay} function to cancel the delay.
 */
function delay(interval, callback) {
  return setTimeout(callback, interval);
}
/**
 * Cancels the delay made in {@link delay}.
 * @param interval - Id of the delay interval.
 */
function undelay(interval) {
  return clearInterval(interval);
}

/**
 * Converts angle in degrees to radians.
 * @param n - Angle in degrees.
 * @returns Angle in radians.
 */
function toRad(n) {
  return n * (Math.PI / 180);
}
/**
 * Converts angle in radians to degrees.
 * @param n - Angle in radians.
 * @returns Angle in degrees.
 */
function toDeg(n) {
  return n * (180 / Math.PI);
}

const fallbackUUID = () => {
  let d = new Date().getTime();
  let d2 = performance && performance.now && performance.now() * 1000 || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : r & 0x7 | 0x8).toString(16);
  });
};
/** Generates unique id using the window crypto object. Falls back to custom implementation if the crypto object is not available (fallback is not cryptographically secure). */
const generateID = () => {
  var _a;
  if ((_a = window === null || window === void 0 ? void 0 : window.crypto) === null || _a === void 0 ? void 0 : _a.randomUUID) {
    return window === null || window === void 0 ? void 0 : window.crypto.randomUUID();
  }
  return fallbackUUID();
};

/**
 * Returns a cached version of the callback function that caches it's result and if called again with the same arguments, returns the result from cache.
 * @param fn - Callback function to cache
 * @returns Result of the callback function call.
 */
const memoize = fn => {
  const cache = new Map();
  return (...args) => {
    const strX = JSON.stringify(args);
    if (!cache.has(strX)) {
      cache.set(strX, fn(...args));
    }
    return cache.get(strX);
  };
};

/**
 * Represents an affine transformation matrix, and provides tools for constructing and concatenating matrices.
 * For more information check: https://en.wikipedia.org/wiki/Affine_transformation
 * And specially: https://en.wikipedia.org/wiki/Affine_transformation#Image_transformation
 *
 * This matrix can be visualized as:
 * ```
 * [ m[0]  m[2]  m[4]
 * 	 m[1]  m[3]  m[5] ]
 * ```
 * Note the locations of m[1] and m[2].
 *
 * or as:
 * ```
 * [ a  c  tx
 * 	 b  d  ty ]
 * ```
 * Note the locations of b and c.
 */
class Matrix {
  constructor() {
    this.m = [1, 0, 0, 1, 0, 0];
  }
  /**
   * Sets the specified values on this instance.
   * @param a - the a property appended to this {@link Matrix} instance.
   * @param b - the b property appended to this {@link Matrix} instance.
   * @param c - the c property appended to this {@link Matrix} instance.
   * @param d - the d property appended to this {@link Matrix} instance.
   * @param tx - the tx property appended to this {@link Matrix} instance.
   * @param ty - the ty property appended to this {@link Matrix} instance.
   * @returns
   */
  setValues(a, b, c, d, tx, ty) {
    // don't forget to update docs in the constructor if these change:
    this.m[0] = a == null ? 1 : a;
    this.m[1] = b || 0;
    this.m[2] = c || 0;
    this.m[3] = d == null ? 1 : d;
    this.m[4] = tx || 0;
    this.m[5] = ty || 0;
    return this;
  }
  /**
   * Sets the properties of the matrix to those of an identity matrix (one that applies a null transformation).
   */
  reset() {
    this.m = [1, 0, 0, 1, 0, 0];
    return this;
  }
  /**
   * Returns a clone of the {@link Matrix} instance.
   * @returns {@link Matrix } A clone of the {@link Matrix} instance.
   */
  clone() {
    const matrix = new Matrix();
    matrix.m = this.m.slice(0);
    return matrix;
  }
  /**
   * Copies all properties from the given {@link Matrix} to the this {@link Matrix} instance.
   * @param matrix - values of this {@link Matrix} will be copied into the {@link Matrix} instance.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  copy(matrix) {
    return this.setValues(matrix.m[0], matrix.m[1], matrix.m[2], matrix.m[3], matrix.m[4], matrix.m[5]);
  }
  /**
   * Transforms a {@link Point} according to this {@link Matrix} instance.
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @returns {@link Point} - A new {@link Point} transformed by the {@link Matrix} instance
   */
  transformPoint(x, y) {
    const point = new Point(0, 0);
    point.x = x * this.m[0] + y * this.m[2] + this.m[4];
    point.y = x * this.m[1] + y * this.m[3] + this.m[5];
    return point;
  }
  /**
   * Appends the specified matrix to this matrix.
   * This is the equivalent of multiplying `(this matrix) * (specified matrix)`.
   * @param matrix - the {@link Matrix} that will be appended to this {@link Matrix} instance
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  appendMatrix(matrix) {
    return this.append(matrix.m[0], matrix.m[1], matrix.m[2], matrix.m[3], matrix.m[4], matrix.m[5]);
  }
  /**
   * Appends the specified {@link Matrix} properties to this {@link Matrix} instance. All parameters are required.
   * This is the equivalent of multiplying `(this matrix) * (specified matrix)`.
   * @param a - the a property appended to this {@link Matrix} instance.
   * @param b - the b property appended to this {@link Matrix} instance.
   * @param c - the c property appended to this {@link Matrix} instance.
   * @param d - the d property appended to this {@link Matrix} instance.
   * @param tx - the tx property appended to this {@link Matrix} instance.
   * @param ty - the ty property appended to this {@link Matrix} instance.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  append(a, b, c, d, tx, ty) {
    const a1 = this.m[0];
    const b1 = this.m[1];
    const c1 = this.m[2];
    const d1 = this.m[3];
    if (a !== 1 || b !== 0 || c !== 0 || d !== 1) {
      this.m[0] = a1 * a + c1 * b;
      this.m[1] = b1 * a + d1 * b;
      this.m[2] = a1 * c + c1 * d;
      this.m[3] = b1 * c + d1 * d;
    }
    this.m[4] = a1 * tx + c1 * ty + this.m[4];
    this.m[5] = b1 * tx + d1 * ty + this.m[5];
    return this;
  }
  /**
   * Generates {@link Matrix} properties from the specified {@link DisplayObject} transform properties, and appends them to this {@link Matrix} instance.
   * Used to generate a {@link Matrix} representing the transformations of a {@link DisplayObject}:
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (horizontal) in a cartesian plane.
   * @param scaleX - scale of the {@link DisplayObject} in the x axis. Same as {@link DisplayObject.scale}.
   * @param scaleY - scale of the {@link DisplayObject} in the y axis. Same as {@link DisplayObject.scale}.
   * @param rotation - rotation of the {@link DisplayObject} in the x axis. Same as {@link DisplayObject.rotation}.
   * @param regX - x coordinate of the registration point of the {@link DisplayObject}.
   * @param regY - y coordinate of the registration point of the {@link DisplayObject}.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  appendTransform(x, y, scaleX, scaleY, rotation, regX, regY) {
    let cos = 1;
    let sin = 0;
    if (rotation % 360) {
      const r = rotation * (Math.PI / 180);
      cos = Math.cos(r);
      sin = Math.sin(r);
    }
    this.append(cos * scaleX, sin * scaleX, -sin * scaleY, cos * scaleY, x, y);
    if (regX || regY) {
      // apply registration/pivot point offset:
      this.m[4] -= regX * this.m[0] + regY * this.m[2];
      this.m[5] -= regX * this.m[1] + regY * this.m[3];
    }
    return this;
  }
  /**
   * Prepends the specified matrix to this matrix.
   * This is the equivalent of multiplying `(specified matrix) * (this matrix)`.
   * @param matrix - the {@link Matrix} that will be appended to this {@link Matrix} instance
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  prependMatrix(matrix) {
    return this.prepend(matrix.m[0], matrix.m[1], matrix.m[2], matrix.m[3], matrix.m[4], matrix.m[5]);
  }
  /**
   * Prepends the specified {@link Matrix} properties to this {@link Matrix} instance. All parameters are required.
   * This is the equivalent of multiplying `(specified matrix) * (this matrix)`.
   * @param a - the a property appended to this {@link Matrix} instance.
   * @param b - the b property appended to this {@link Matrix} instance.
   * @param c - the c property appended to this {@link Matrix} instance.
   * @param d - the d property appended to this {@link Matrix} instance.
   * @param tx - the tx property appended to this {@link Matrix} instance.
   * @param ty - the ty property appended to this {@link Matrix} instance.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  prepend(a, b, c, d, tx, ty) {
    const a1 = this.m[0];
    const c1 = this.m[2];
    const tx1 = this.m[4];
    this.m[0] = a * a1 + c * this.m[1];
    this.m[1] = b * a1 + d * this.m[1];
    this.m[2] = a * c1 + c * this.m[3];
    this.m[3] = b * c1 + d * this.m[3];
    this.m[4] = a * tx1 + c * this.m[5] + tx;
    this.m[5] = b * tx1 + d * this.m[5] + ty;
    return this;
  }
  /**
   * Generates {@link Matrix} properties from the specified {@link DisplayObject} transform properties, and prepends them to this {@link Matrix} instance.
   * Used to calculate the combined transformation for a child object.
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (horizontal) in a cartesian plane.
   * @param scaleX - scale of the {@link DisplayObject} in the x axis. Same as {@link DisplayObject.scale}.
   * @param scaleY - scale of the {@link DisplayObject} in the y axis. Same as {@link DisplayObject.scale}.
   * @param rotation - rotation of the {@link DisplayObject} in the x axis. Same as {@link DisplayObject.rotation}.
   * @param regX - x coordinate of the registration point of the {@link DisplayObject}.
   * @param regY - y coordinate of the registration point of the {@link DisplayObject}.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  prependTransform(x, y, scaleX, scaleY, rotation, regX, regY) {
    let cos = 1;
    let sin = 0;
    if (rotation % 360) {
      const r = rotation * (Math.PI / 180);
      cos = Math.cos(r);
      sin = Math.sin(r);
    }
    if (regX || regY) {
      // prepend the registration offset:
      this.m[4] -= regX;
      this.m[5] -= regY;
    }
    this.prepend(cos * scaleX, sin * scaleX, -sin * scaleY, cos * scaleY, x, y);
    return this;
  }
  /**
   * Multiplies this {@link Matrix} instance with the given {@link Matrix}.
   * @param matrix - the {@link Matrix} that will be multiplied to this {@link Matrix} instance.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  multiply(matrix) {
    const m11 = this.m[0] * matrix.m[0] + this.m[2] * matrix.m[1];
    const m12 = this.m[1] * matrix.m[0] + this.m[3] * matrix.m[1];
    const m21 = this.m[0] * matrix.m[2] + this.m[2] * matrix.m[3];
    const m22 = this.m[1] * matrix.m[2] + this.m[3] * matrix.m[3];
    const dx = this.m[0] * matrix.m[4] + this.m[2] * matrix.m[5] + this.m[4];
    const dy = this.m[1] * matrix.m[4] + this.m[3] * matrix.m[5] + this.m[5];
    this.m[0] = m11;
    this.m[1] = m12;
    this.m[2] = m21;
    this.m[3] = m22;
    this.m[4] = dx;
    this.m[5] = dy;
    return this;
  }
  /**
   * Inverts this {@link Matrix} instance.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  invert() {
    const d = 1 / (this.m[0] * this.m[3] - this.m[1] * this.m[2]);
    const m0 = this.m[3] * d;
    const m1 = -this.m[1] * d;
    const m2 = -this.m[2] * d;
    const m3 = this.m[0] * d;
    const m4 = d * (this.m[2] * this.m[5] - this.m[3] * this.m[4]);
    const m5 = d * (this.m[1] * this.m[4] - this.m[0] * this.m[5]);
    this.m[0] = m0;
    this.m[1] = m1;
    this.m[2] = m2;
    this.m[3] = m3;
    this.m[4] = m4;
    this.m[5] = m5;
    return this;
  }
  /**
   * Applies a rotation to this {@link Matrix} instance.
   * @param angle - The value of the angle in degrees.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  rotation(angle) {
    const angleRad = toRad(angle);
    const cos = Math.cos(angleRad);
    const sin = Math.sin(angleRad);
    const m11 = this.m[0] * cos + this.m[2] * sin;
    const m12 = this.m[1] * cos + this.m[3] * sin;
    const m21 = this.m[0] * -sin + this.m[2] * cos;
    const m22 = this.m[1] * -sin + this.m[3] * cos;
    this.m[0] = m11;
    this.m[1] = m12;
    this.m[2] = m21;
    this.m[3] = m22;
    return this;
  }
  /**
   * Applies a translation to this {@link Matrix} instance.
   * @param x - The value of the translation for the {@link x} coordinate.
   * @param y - The value of the translation for the {@link y} coordinate.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  translate(x, y) {
    this.m[4] += this.m[0] * x + this.m[2] * y;
    this.m[5] += this.m[1] * x + this.m[3] * y;
    return this;
  }
  /**
   * Applies a scale to this {@link Matrix} instance.
   * @param sx - The value of the scale for the x axis.
   * @param sy - The value of the scale for the y axis.
   * @returns This {@link Matrix} instance. Useful for chaining methods.
   */
  scale(sx, sy) {
    if (sy === undefined) {
      sy = sx;
    }
    this.m[0] *= sx;
    this.m[1] *= sx;
    this.m[2] *= sy;
    this.m[3] *= sy;
    return this;
  }
}

/**
 * Contains animation on one `DisplayObject` and controls them.
 */
class Animations {
  constructor(displayObject) {
    this.displayObject = displayObject;
    this.animations = [];
    /**
     * Attaches the animation to the current stage.
     */
    this.attachToStage = () => {
      this.animations.forEach(animation => {
        var _a;
        return (_a = this.displayObject.stage) === null || _a === void 0 ? void 0 : _a.stageAnimations.add(animation);
      });
      this.attachedToStage = this.displayObject.stage;
    };
    /**
     * Detaches the animation to the current stage.
     */
    this.detachFromStage = () => {
      this.animations.forEach(animation => {
        var _a;
        return (_a = this.attachedToStage) === null || _a === void 0 ? void 0 : _a.stageAnimations.remove(animation);
      });
      this.attachedToStage = undefined;
    };
    displayObject.on('attachtostage', this.attachToStage);
    displayObject.on('detachfromstage', this.detachFromStage);
  }
  // TODO Implement this function. To destroy instance of animations
  destroy() {
    this.displayObject.off('attachtostage', this.attachToStage);
    this.displayObject.off('detachfromstage', this.detachFromStage);
    while (this.animations.length > 0) {
      this.remove(this.animations[0]);
    }
  }
  /**
   * Number of animations present.
   */
  get length() {
    return this.animations.length;
  }
  /**
   * Adds animation to this container and forces a surface render.
   * @param animation - Animation to add to the container.
   * @returns Instance of this.
   */
  add(animation) {
    var _a;
    if (this.animations.indexOf(animation) !== -1) return this;
    this.animations.push(animation);
    (_a = this.displayObject.stage) === null || _a === void 0 ? void 0 : _a.stageAnimations.add(animation);
    return this;
  }
  /**
   * Removes an animation from the container.
   * @param animation - Animation to remove from the container.
   * @returns Instance of this or undefined if nothing was removed.
   */
  remove(animation) {
    var _a;
    const index = this.animations.indexOf(animation);
    if (index === -1) {
      return undefined;
    }
    this.animations.splice(index, 1);
    (_a = this.displayObject.stage) === null || _a === void 0 ? void 0 : _a.stageAnimations.remove(animation);
    return this;
  }
  /**
   * Stops all animations on `DisplayObject` that are animating over provided properties.
   * @param properties - property name or map of properties which animations should be stopped
   * @returns Instance of this.
   */
  stop(properties) {
    if (properties != null) {
      let names = [];
      if (typeof properties === 'string') {
        names = [properties];
      } else if (Array.isArray(properties)) {
        names = properties;
      } else {
        names = Object.keys(properties);
      }
      this.animations.slice().forEach(animation => {
        if (animation.properties.some(property => names.includes(property))) {
          animation.stop(true);
        }
      });
      return this;
    }
    this.animations.slice().forEach(animation => animation.stop(true));
    return this;
  }
}

function isNumberAnimationProperty(property) {
  return typeof property.to === 'number' && typeof property.from === 'number';
}
function isAnimatableProp(property) {
  return typeof property.computeAnimationTick !== 'undefined';
}
function isNumberPropertyConfig(property) {
  return typeof property.from === 'number' || typeof property.to === 'number' || typeof property.minus === 'number' || typeof property.plus === 'number';
}

const Easing = {
  linear(time, from, delta, duration) {
    return delta * time / duration + from;
  },
  easeOutElastic(time, from, delta, duration) {
    let s = 1.70158;
    let p = 0;
    let a = delta;
    if (time === 0) {
      return from;
    }
    time /= duration;
    if (time === 1) {
      return from + delta;
    }
    if (!p) {
      p = duration * 0.3;
    }
    if (a < Math.abs(delta)) {
      a = delta;
      s = p / 4;
    } else {
      s = p / (2 * Math.PI) * Math.asin(delta / a);
    }
    return a * Math.pow(2, -10 * time) * Math.sin((time * duration - s) * (2 * Math.PI) / p) + delta + from;
  },
  easeInCirc(time, from, delta, duration) {
    const timeStep = time / duration;
    return -delta * (Math.sqrt(1 - Math.pow(timeStep, 2)) - 1) + from;
  },
  easeOutCirc(time, from, delta, duration) {
    const timeStep = time / duration - 1;
    return delta * Math.sqrt(1 - Math.pow(timeStep, 2)) + from;
  },
  easeInOutQuad(time, from, delta, duration) {
    time /= duration / 2;
    if (time < 1) {
      return delta / 2 * time * time + from;
    }
    return -delta / 2 * ((time - 1) * (time - 3) - 1) + from;
  },
  easeOutBack(time, from, delta, duration) {
    const s = 1.70158;
    const timeStep = time / duration - 1;
    return delta * (Math.pow(timeStep, 2) * ((s + 1) * timeStep + s) + 1) + from;
  }
};

class Animation extends EventEmitter {
  /**
   * Constructs an animation that will animate properties over the target object. The animation is automatically started.
   * @param animationTarget - Object to animate properties on. If you are using `this` as target, you will need to manually
   * typecast it to it's class (see: https://stackoverflow.com/q/72477110/13156397)
   * @param properties - Properties that should be animated and values that should be animated towards.
   * @param options - Animation options. Defaults to `{ duration: 1000, easing: 'linear' }`
   */
  constructor(animationTarget, properties, options) {
    var _a, _b;
    super();
    this.animationTarget = animationTarget;
    this.props = this.prepare(properties);
    this.options = Object.assign(Object.assign({}, options), {
      duration: (_a = options.duration) !== null && _a !== void 0 ? _a : 1000,
      easing: (_b = options.easing) !== null && _b !== void 0 ? _b : 'linear'
    });
    if (options.interrupt) {
      this.animationTarget.animations.stop(properties);
    }
    this.start();
  }
  /**
   * Starts playing the animation.
   * @returns Instance of the animation.
   */
  start() {
    if (this.options.duration === 0) {
      this.setFinal();
      delay(0, () => {
        var _a, _b;
        (_a = this.options.complete) === null || _a === void 0 ? void 0 : _a.call(this.animationTarget, false);
        (_b = this.options.stop) === null || _b === void 0 ? void 0 : _b.call(this.animationTarget);
      });
      return this;
    }
    if (this.options.delay) {
      this.delay = delay(this.options.delay, () => {
        this.startAt = Date.now();
        this.delay = undefined;
      });
    } else {
      this.startAt = Date.now();
    }
    // typecast is required because `Animations` container is not generic
    // TODO: maybe separate animations from stage (and remove `Animations` container, make animations event based)
    this.animationTarget.animations.add(this);
    return this;
  }
  /**
   * Stops playing the animation.
   * @param interrupted - If the animation should be interrupted. If the animation is `completeOnInterrupt`
   * the animation will be completed and `complete` callback called.
   * @returns Instance of the animation.
   */
  stop(interrupted = false) {
    var _a, _b;
    if (this.delay) {
      undelay(this.delay);
    }
    this.startAt = undefined;
    (_a = this.options.stop) === null || _a === void 0 ? void 0 : _a.call(this.animationTarget);
    if (interrupted && this.options.completeOnInterrupt) {
      this.setFinal();
      (_b = this.options.complete) === null || _b === void 0 ? void 0 : _b.call(this.animationTarget, true);
    }
    // typecast is required because `Animations` container is not generic
    // TODO: maybe separate animations from stage (and remove `Animations` container, make animations event based)
    this.animationTarget.animations.remove(this);
    return this;
  }
  /**
   * Progresses the animation based on the delta between current time and the time when the animation started
   */
  tick() {
    var _a, _b;
    if (!this.startAt) {
      return;
    }
    const time = Date.now() - this.startAt;
    this.setAnimated(time);
    (_a = this.options.tick) === null || _a === void 0 ? void 0 : _a.call(this.animationTarget, time);
    if (time >= this.options.duration) {
      if (this.options.repeat) {
        if (!this.options.repeats || this.options.repeats && this.options.repeats > 0) {
          if (this.options.repeats) this.options.repeats -= 1;
          this.startAt = Date.now() - (time - this.options.duration);
        }
      } else {
        (_b = this.options.complete) === null || _b === void 0 ? void 0 : _b.call(this.animationTarget, false);
        this.stop();
      }
    }
  }
  /**
   * Calculates property values to which the animation should start from and finish to.
   * Stores the result into the `props` map.
   * @param properties - Properties that should be animated.
   * @returns Calculated map of properties that are ready to be animated.
   */
  prepare(properties) {
    const parsedProperties = new Map();
    const animatableKeys = Object.keys(properties);
    animatableKeys.forEach(name => {
      var _a;
      const propertyConfig = properties[name];
      if (propertyConfig === null || propertyConfig === undefined) {
        return;
      }
      let property;
      if (typeof propertyConfig === 'number' || isAnimatableProp(propertyConfig)) {
        property = {
          from: (_a = this.animationTarget[name]) !== null && _a !== void 0 ? _a : 0,
          to: propertyConfig
        };
      } else {
        if (isNumberPropertyConfig(propertyConfig)) {
          property = this.calculateNumberConfig(propertyConfig, name);
        }
        if (propertyConfig.from !== undefined && propertyConfig.to !== undefined) {
          property = {
            from: propertyConfig.from,
            to: propertyConfig.to
          };
        }
        if (!property) {
          throw new Error(`Property ${name.toString()} is malformed.`);
        }
      }
      parsedProperties.set(name, property);
    });
    return parsedProperties;
  }
  /**
   * Calculates target value based on the number property config.
   * @param propertyConfig - Property config
   * @param name - Property name
   * @returns Calculated property or undefined if no calculation occurred.
   */
  calculateNumberConfig(propertyConfig, name) {
    var _a, _b, _c, _d, _e, _f;
    if (typeof this.animationTarget[name] !== 'number' && this.animationTarget[name] !== null && this.animationTarget[name] !== undefined) {
      return undefined;
    }
    const from = (_a = this.animationTarget[name]) !== null && _a !== void 0 ? _a : 0;
    const to = (_b = this.animationTarget[name]) !== null && _b !== void 0 ? _b : 0;
    if (propertyConfig.plus) {
      return {
        from,
        to: to + propertyConfig.plus
      };
    }
    if (propertyConfig.minus) {
      return {
        from,
        to: to - propertyConfig.minus
      };
    }
    return {
      from: (_d = (_c = propertyConfig.from) !== null && _c !== void 0 ? _c : from) !== null && _d !== void 0 ? _d : 0,
      to: (_f = (_e = propertyConfig.to) !== null && _e !== void 0 ? _e : to) !== null && _f !== void 0 ? _f : 0
    };
  }
  /**
   * Sets the target property value for all the properties. Ensures that the final value calculated from `compute` is
   * equal to the target value.
   */
  setFinal() {
    this.props.forEach((property, name) => {
      this.animationTarget[name] = property.to;
    });
  }
  /**
   * Calls `compute` for every property based on the time elapsed from the start of the animation.
   * @param time - Time elapsed from the start.
   */
  setAnimated(time) {
    this.props.forEach((property, name) => {
      this.animationTarget[name] = this.compute(property, time);
    });
  }
  /**
   * Computes the current property value based on the time elapsed from the start of the animation.
   * @param property - Property name.
   * @param time - Time elapsed from the start.
   * @returns Current property value.
   */
  compute(property, time) {
    if (isNumberAnimationProperty(property)) {
      return Easing[this.options.easing](Math.min(time, this.options.duration), property.from, property.to - property.from, this.options.duration);
    }
    return property.from.computeAnimationTick(property.to, time, Easing[this.options.easing], this.options.duration);
  }
  /**
   * Gets all the property keys this animation is animating over.
   */
  get properties() {
    return Array.from(this.props.keys());
  }
}

/**
 * Container and controls for animations on stage.
 */
class StageAnimations {
  constructor(stage) {
    this.stage = stage;
    this.animations = [];
  }
  /**
   * Number of animations present.
   */
  get length() {
    return this.animations.length;
  }
  /**
   * Removes all the animations.
   */
  destroy() {
    while (this.animations.length > 0) {
      this.remove(this.animations[0]);
    }
  }
  /**
   * Adds animation to this container and forces a surface render.
   * @param animation - Animation to add to the container.
   * @returns Instance of this.
   */
  add(animation) {
    if (this.animations.indexOf(animation) !== -1) return this;
    this.animations.push(animation);
    this.stage.surface.dirty = true;
    return this;
  }
  /**
   * Removes an animation from the container.
   * @param animation - Animation to remove from the container.
   * @returns Instance of this or undefined if nothing was removed.
   */
  remove(animation) {
    const index = this.animations.indexOf(animation);
    if (index === -1) {
      return this;
    }
    this.animations.splice(index, 1);
    return this;
  }
  /**
   * Ticks through all the animations.
   */
  tick() {
    this.animations.slice().forEach(animation => animation.tick());
  }
}

const NON_STYLE_PROPS = ['hover', 'focus', 'owner', 'actual', 'animations', 'animate', 'assing'];
const onlyStyles = object => {
  const props = Object.keys(object).filter(key => !NON_STYLE_PROPS.includes(key));
  return props.reduce((value, key) => {
    value[key] = object[key];
    return value;
  }, {});
};
/**
 * HOW OPTIMIZED CALCULATIONS WORK
 *
 * To avoid unnecessary updates caused mainly by initial loads, in which most of the properties are assigned
 * in a batch (causing the "stylechange" event triggering the "update" function), we only update actual styles when
 * actual property is accessed. That is done via the "actual" getter.
 */
class Style {
  constructor(owner, root = true) {
    this.owner = owner;
    let pendingUpdate = false;
    let isHovered = false;
    let hasFocus = false;
    const activeTransitions = {
      get animations() {
        return owner.animations;
      }
    };
    const actualStyles = {};
    const update = () => {
      // Clean up all properties
      Object.keys(actualStyles).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(actualStyles, key) && key !== 'animations') {
          delete actualStyles[key];
        }
      });
      // Apply properties in correct order
      Object.assign(actualStyles, onlyStyles(this));
      if (isHovered) Object.assign(actualStyles, onlyStyles(this.hover));
      if (hasFocus) Object.assign(actualStyles, onlyStyles(this.focus));
      Object.assign(actualStyles, onlyStyles(activeTransitions));
    };
    const transition = (stateFrom, stateTo) => {
      Object.keys(this.animate).forEach(prop => {
        new Animation(activeTransitions, {
          [prop]: {
            from: activeTransitions[prop] || stateFrom[prop],
            to: stateTo[prop]
          }
        }, {
          duration: this.animate[prop],
          easing: 'linear',
          completeOnInterrupt: true,
          tick: () => {
            actualStyles[prop] = activeTransitions[prop];
          },
          complete: () => {
            // Clean up when transition finishied
            delete activeTransitions[prop];
          }
        });
      });
    };
    // Assign properties the way so they are non-configurable
    Object.defineProperties(this, {
      hover: {
        value: {
          get animations() {
            return owner.animations;
          }
        },
        writable: false,
        enumerable: true
      },
      focus: {
        value: {
          get animations() {
            return owner.animations;
          }
        },
        writable: false,
        enumerable: true
      },
      animate: {
        value: {},
        writable: false,
        enumerable: true
      },
      actual: {
        get: () => {
          if (pendingUpdate) {
            update();
            pendingUpdate = false;
          }
          return actualStyles;
        },
        enumerable: true
      }
    });
    if (root) {
      owner.on('stylechange', () => {
        pendingUpdate = true;
      });
      const pointerEnterHandler = () => {
        isHovered = true;
        pendingUpdate = true;
        // We only animate properties when not focused
        if (!hasFocus) transition(this, this.hover);
      };
      const pointerLeaveHandle = () => {
        isHovered = false;
        pendingUpdate = true;
        // We only animate properties when not focused
        if (!hasFocus) transition(this.hover, this);
      };
      const focusHandler = () => {
        hasFocus = true;
        pendingUpdate = true;
        transition(isHovered ? this.hover : this, this.focus);
      };
      const blurHandler = () => {
        hasFocus = false;
        pendingUpdate = true;
        transition(this.focus, isHovered ? this.hover : this);
      };
      // TODO: Detect interactivity change on displayobject event are attached to and change handlers accordingly.
      let eventsAttachedTo;
      const attachHandlers = () => {
        let object = owner;
        while (object && !object.interactive) {
          object = object.parent;
        }
        if (!object || object === eventsAttachedTo) return;
        eventsAttachedTo = object;
        eventsAttachedTo.on('pointerenter', pointerEnterHandler);
        eventsAttachedTo.on('pointerleave', pointerLeaveHandle);
        eventsAttachedTo.on('focus', focusHandler);
        eventsAttachedTo.on('blur', blurHandler);
      };
      const detachHandlers = () => {
        if (!eventsAttachedTo) return;
        eventsAttachedTo.off('pointerenter', pointerEnterHandler);
        eventsAttachedTo.off('pointerleave', pointerLeaveHandle);
        eventsAttachedTo.off('focus', focusHandler);
        eventsAttachedTo.off('blur', blurHandler);
        eventsAttachedTo = undefined;
      };
      owner.on('attachtostage', attachHandlers);
      owner.on('attach', attachHandlers);
      owner.on('detachfromstage', detachHandlers);
      owner.on('detach', detachHandlers);
    }
  }
  get animations() {
    return this.owner.animations;
  }
  assign(theme) {
    if (!theme) return;
    if (theme.default) Object.assign(this, theme.default);
    if (theme.hover) Object.assign(this.hover, theme.hover);
    if (theme.focus) Object.assign(this.focus, theme.focus);
    if (theme.animate) Object.assign(this.animate, theme.animate);
  }
}

/**
 * This class creates a Shadow that can then be set in a {@link DisplayObject}
 * @example
 * ```
 * const shadow = new Shadow('black', 8, 10, 10);
 * // creates a shadow object with color 'black', blur 8, and blur offset (10,10)
 * ```
 */
class Shadow {
  constructor(shadowColor, shadowBlur, shadowOffsetX, shadowOffsetY) {
    /**
     * The color of the shadow. This can be any compatible CSS color value (ex. "red", "#FF0000", or "rgba(255,0,0,0.5)")
     */
    this.shadowColor = 'black';
    /**
     * A non-negative float specifying the level of shadow blur, where 0 represents no blur and larger numbers represent increasingly more blur.
     * This value doesn't correspond to a number of pixels, and is not affected by the current transformation matrix.
     */
    this.shadowBlur = 0;
    /**
     * A float specifying the distance that shadows will be offset horizontally.
     * Positive values are to the right, and negative to the left.
     */
    this.shadowOffsetX = 0;
    /**
     * A float specifying the distance that shadows will be offset vertically.
     * Positive values are down, and negative are up.
     */
    this.shadowOffsetY = 0;
    this.shadowColor = shadowColor;
    this.shadowBlur = shadowBlur;
    this.shadowOffsetX = shadowOffsetX;
    this.shadowOffsetY = shadowOffsetY;
  }
  /**
   * Returns a clone of this {@link Shadow} instance.
   * @returns a clone of this {@link Shadow} instance.
   */
  clone() {
    return new Shadow(this.shadowColor, this.shadowBlur, this.shadowOffsetX, this.shadowOffsetY);
  }
  /**
   * Returns a string representation of this {@link Shadow} instance.
   * @returns a string representation of this {@link Shadow} instance.
   */
  toString() {
    return '[Shadow]';
  }
}
Shadow.clear = new Shadow('transparent', 0, 0, 0);

/**
 * This class creates a new Arc graphics command, used in the {@link Graphics} class.
 */
class ArcCmd {
  /**
   * @param x - The x-axis coordinate of the first control point.
   * @param y - The y-axis coordinate of the first control point.
   * @param radius - The arc's radius. Must be positive.
   * @param startAngle - The angle at which the arc starts in radians, measured from the positive x-axis.
   * @param endAngle - The angle at which the arc ends in radians, measured from the positive x-axis.
   * @param counterclockwise - An optional boolean value. If true, draws the arc counter-clockwise between the start and end angles. The default is false (clockwise).
   */
  constructor(x, y, radius, startAngle, endAngle, counterclockwise = false) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.startAngle = startAngle;
    this.endAngle = endAngle;
    this.counterclockwise = counterclockwise;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.arc(this.x, this.y, this.radius, this.startAngle, this.endAngle, this.counterclockwise);
  }
}

/**
 * This class creates a new ArcTo graphics command, used in the {@link Graphics} class.
 */
class ArcToCmd {
  /**
   * @param x1 - The x-axis coordinate of the first control point.
   * @param y1 - The y-axis coordinate of the first control point.
   * @param x2 - The x-axis coordinate of the second control point.
   * @param y2 - The y-axis coordinate of the second control point.
   * @param radius - The arc's radius. Must be positive.
   */
  constructor(x1, y1, x2, y2, radius) {
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
    this.radius = radius;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.arcTo(this.x1, this.y1, this.x2, this.y2, this.radius);
  }
}

/**
 * This class creates a new BeginPath graphics command, used in the {@link Graphics} class.
 */
class BeginPathCmd {
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.beginPath();
  }
}

/**
 * This class creates a new BezierCurveTo graphics command, used in the {@link Graphics} class.
 */
class BezierCurveToCmd {
  /**
   * @param cp1x - The x-axis coordinate of the first control point.
   * @param cp1y - The y-axis coordinate of the first control point.
   * @param cp2x - The x-axis coordinate of the second control point.
   * @param cp2y - The y-axis coordinate of the second control point.
   * @param x - The x-axis coordinate of the end point.
   * @param y - The y-axis coordinate of the end point.
   */
  constructor(cp1x, cp1y, cp2x, cp2y, x, y) {
    this.cp1x = cp1x;
    this.cp1y = cp1y;
    this.cp2x = cp2x;
    this.cp2y = cp2y;
    this.x = x;
    this.y = y;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.bezierCurveTo(this.cp1x, this.cp1y, this.cp2x, this.cp2y, this.x, this.y);
  }
}

/**
 * This class creates a new Circle graphics command, used in the {@link Graphics} class.
 */
class CircleCmd {
  /**
   * @param x - The x-axis coordinate of the center of the Circle.
   * @param y - The y-axis coordinate of the center of the Circle.
   * @param radius - The Cicle's radius. Must be positive.
   */
  constructor(x, y, radius) {
    this.x = x;
    this.y = y;
    this.radius = radius;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
  }
}

/**
 * This class creates a new ClosePath graphics command, used in the {@link Graphics} class.
 */
class ClosePathCmd {
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.closePath();
  }
}

// For more info about this 'magic' constant check this link: http://www.whizkidtech.redprince.net/bezier/circle/kappa/
const KAPPA = 0.5522848;
/**
 * This class creates a new Ellipse graphics command, used in the {@link Graphics} class.
 */
class EllipseCmd {
  /**
   * @param x - The x-axis coordinate of the center of the Ellipse.
   * @param y - The y-axis coordinate of the center of the Ellipse.
   * @param width - The width of the Ellipse.
   * @param height - The height of the Ellipse.
   */
  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    const ox = this.width / 2 * KAPPA;
    const oy = this.height / 2 * KAPPA;
    const xe = this.x + this.width;
    const ye = this.y + this.height;
    const xm = this.x + this.width / 2;
    const ym = this.y + this.height / 2;
    ctx.moveTo(this.x, ym);
    ctx.bezierCurveTo(this.x, ym - oy, xm - ox, this.y, xm, this.y);
    ctx.bezierCurveTo(xm + ox, this.y, xe, ym - oy, xe, ym);
    ctx.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
    ctx.bezierCurveTo(xm - ox, ye, this.x, ym + oy, this.x, ym);
  }
}

/**
 * A static canvas ctx to perform some special operations like 'createLinearGradient', 'createRadialGradient' or 'createPattern'.
 */
const GRAPHIC_CTX = document.createElement('canvas').getContext('2d');

/**
 * This class creates a new Fill graphics command, used in the {@link Graphics} class.
 */
class FillCmd {
  /**
   * @param style - A valid Context2D fillStyle.
   * @param matrix - an optional tranformation matrix to apply before the fill() operation.
   */
  constructor(style, mtx) {
    this.style = style;
    this.mtx = mtx;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw.
   */
  exec(ctx) {
    if (this.style) {
      ctx.fillStyle = this.style;
    }
    if (this.mtx) {
      ctx.save();
      ctx.transform(this.mtx.m[0], this.mtx.m[1], this.mtx.m[2], this.mtx.m[3], this.mtx.m[4], this.mtx.m[5]);
    }
    ctx.fill();
    if (this.mtx) {
      ctx.restore();
    }
  }
  /**
   * Creates a linear gradient style and assigns it this instance {@link Fill.style} property.
   * @param colors - an array containing the color of each color-stop.
   * @param ratios - an array containing the ratio of each color-stop.
   * @param x0 - The x-axis coordinate of the start point of the gradient.
   * @param y0 - The y-axis coordinate of the start point of the gradient.
   * @param x1 - The x-axis coordinate of the end point of the gradient.
   * @param y1 - The y-axis coordinate of the end point of the gradient.
   * @returns - Returns this {@link Fill} command instance for chaining methods.
   */
  linearGradient(colors, ratios, x0, y0, x1, y1) {
    if (!GRAPHIC_CTX) {
      return this;
    }
    const style = GRAPHIC_CTX.createLinearGradient(x0, y0, x1, y1);
    this.addColorStops(style, ratios, colors);
    this.style = style;
    return this;
  }
  /**
   * Creates a radial gradient style and assigns it this instance {@link Fill.style} property.
   * @param colors - an array containing the color of each color-stop.
   * @param ratios - an array containing the ratio of each color-stop.
   * @param x0 - The x-axis coordinate of the start circle.
   * @param y0 - The y-axis coordinate of the start circle.
   * @param r0 - The radius of the start circle. Must be non-negative and finite.
   * @param x1 - The x-axis coordinate of the end circle.
   * @param y1 - The y-axis coordinate of the end circle.
   * @param r1 - The radius of the end circle. Must be non-negative and finite.
   * @returns - Returns this {@link Fill} command instance for chaining methods.
   */
  radialGradient(colors, ratios, x0, y0, r0, x1, y1, r1) {
    if (!GRAPHIC_CTX) {
      return this;
    }
    const style = GRAPHIC_CTX.createRadialGradient(x0, y0, r0, x1, y1, r1);
    this.addColorStops(style, ratios, colors);
    this.style = style;
    return this;
  }
  addColorStops(style, ratios, colors) {
    for (let i = 0; i < colors.length; i++) {
      style.addColorStop(ratios[i], colors[i]);
    }
  }
  /**
   * Creates a bitmap fill style and assigns it this instance {@link Fill.style} property.
   * @param image - Image, Canvas or Video bitmap source. Must be loaded prior to creating a bitmap fill, or the fill will be empty.
   * @param repetition - repeat, repeat-x, repeat-y, or no-repeat.
   * @param matrix - an optional transformation matrix for the bitmap fill. Will be applied relative to the parent transform.
   * @returns - Returns this {@link Fill} command instance for chaining methods.
   */
  bitmap(image, repetition, mtx) {
    if (!GRAPHIC_CTX) {
      return this;
    }
    if (mtx) this.mtx = mtx;
    if (image.naturalWidth || image.getContext || image.readyState >= 2 || image) {
      const style = GRAPHIC_CTX.createPattern(image, repetition || '');
      if (style) {
        this.style = style;
      }
    }
    return this;
  }
}

/**
 * This class creates a new LineTo graphics command, used in the {@link Graphics} class.
 */
class LineToCmd {
  /**
   * @param x - The x-axis coordinate of the line's end point.
   * @param y - The y-axis coordinate of the line's end point.
   */
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.lineTo(this.x, this.y);
  }
}

/**
 * This class creates a new MoveTo graphics command, used in the {@link Graphics} class.
 */
class MoveToCmd {
  /**
   * @param x - The x-axis (horizontal) coordinate of the point.
   * @param y - The y-axis (vertical) coordinate of the point.
   */
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.moveTo(this.x, this.y);
  }
}

/**
 * The IsometricMatrix class extends the Matrix class with the `strength` and `inverted` properties.
 * It's used to create a projectionMatrix in the {@link CameraManager} in order to achieve a 2.5D view.
 *
 * For more information about the isometric projection check: https://en.wikipedia.org/wiki/Isometric_projection.
 * @example
 * ```
 * const mtx = new IsometricMatrix(0.5);
 * // creates an IsometricMatrix of strength = 0.5
 * ```
 */
class IsometricMatrix extends Matrix {
  /**
   * @param strength - strength of the isometric effect. 0 is no effect at all, and 1 full isometric transform applied to the matrix.
   */
  constructor(strength) {
    super();
    /**
     * strength of the isometric effect. 0 is no effect at all, and 1 full isometric transform applied to the matrix.
     */
    this._strength = 1;
    /**
     * stores the inverted matrix of this {@link IsometricMatrix} instance for convinience.
     */
    this.inverted = new Matrix();
    this.strength = strength; // Just because of TypeScript
  }
  /**
   * Gets the strength of the isometric effect. 0 is no effect at all, and 1 full isometric transform applied to the matrix.
   */
  get strength() {
    return this._strength;
  }
  /**
   * Sets the strength of the isometric effect. 0 is no effect at all, and 1 full isometric transform applied to the matrix.
   * Any input value will be clamped to [0,1].
   */
  set strength(value) {
    value = Math.max(0, Math.min(1, value));
    if (value === this._strength) return;
    this._strength = value;
    this.reset();
    this.scale(1, 1 - 0.5 * value);
    this.rotation(45 * value);
    this.inverted = this.clone().invert();
  }
}

/**
 * This class represents a Line in a cartesian plane ( x / y coordinate system).
 * @example
 * ```
 * const line = new Line(50, 100, 75, 150);
 * // creates a line between Point(50,100) and Point(75, 100)
 * ```
 */
class Line {
  /**
   * @param x - x coordinate (horizontal) of the first point defining the line, in a cartesian plane.
   * @param y - y coordinate (horizontal) of the first point defining the line, in a cartesian plane.
   * @param x2 - x coordinate (horizontal) of the second point defining the line, in a cartesian plane.
   * @param y2 - y coordinate (horizontal) of the second point defining the line, in a cartesian plane.
   */
  constructor(x, y, x2, y2) {
    this.x = x;
    this.y = y;
    this.x2 = x2;
    this.y2 = y2;
  }
  /**
   * Gets the angle in degrees from the two points defining the {@link Line} instance.
   * @example
   * ```
   * const line = new Line(50, 100, 75, 150);
   * console.log(line.angle);
   * // Outputs: "63.43494882292201"
   * ```
   */
  get angle() {
    return toDeg(this.angleRad);
  }
  /**
   * Gets the angle in radians from the two points defining the {@link Line} instance.
   * @example
   * ```
   * const line = new Line(50, 100, 75, 150);
   * console.log(line.angleRad);
   * // Outputs: "1.1071487177940904"
   * ```
   */
  get angleRad() {
    return Math.atan2(this.y2 - this.y, this.x2 - this.x);
  }
  /**
   * Gets the center point in the line
   * @example
   * ```
   * const line = new Line(50, 100, 75, 150);
   * console.log(line.center.toString());
   * // Outputs: "[62.5, 125]"
   * ```
   */
  get center() {
    return new Point(this.x + (this.x2 - this.x) / 2, this.y + (this.y2 - this.y) / 2);
  }
  /**
   * Gets the length of the line
   * @example
   * ```
   * const line = new Line(50, 100, 75, 150);
   * console.log(line.length);
   * // Outputs: "55.90169943749474"
   * ```
   */
  get length() {
    return Math.sqrt(Math.pow(this.x - this.x2, 2) + Math.pow(this.y - this.y2, 2));
  }
  /**
   * Gets the x coordinate (horizontal) of the first point defining the line, in a cartesian plane.
   */
  get x1() {
    return this.x;
  }
  /**
   * Gets the y coordinate (horizontal) of the first point defining the line, in a cartesian plane.
   */
  get y1() {
    return this.y;
  }
  /**
   * Returns a clone of the {@link Line} instance.
   * @example
   * ```
   * const line1 = new Line(50, 100, 75, 150);
   * const line2 = line1.clone();
   * console.log(line1.toString(), line2.toString());
   * // Outputs: "[50, 100, 75, 150] [50, 100, 75, 150]"
   * ```
   * @returns {@link Line } A clone of the {@link Line} instance.
   */
  clone() {
    return new Line(this.x, this.y, this.x2, this.y2);
  }
  /**
   * Multiplies the {@link x}, {@link y} and {@link x2}, {@link y2} coordinates defining this {@link Line} instance by the input value.
   * @example
   * ```
   * const line = new Line(50, 100, 75, 150);
   * line.scale(2);
   * console.log(line.toString());
   * // Outputs: "[100, 200, 150, 300]"
   * ```
   * @param value - The value of the scale to be applied to the {@link x} and {@link y} coordinates.
   * @returns This {@link Line} instance. Useful for chaining methods.
   */
  scale(value) {
    this.x *= value;
    this.y *= value;
    this.x2 *= value;
    this.y2 *= value;
    return this;
  }
  /**
   * Applies the given matrix to the {@link x}, {@link y} and {@link x2}, {@link y2} coordinates defining this {@link Line} instance.
   * @param matrix - A transformation {@link Matrix} to be applied.
   * @returns This {@link Line} instance. Useful for chaining methods.
   */
  transform(matrix) {
    const x = this.x * matrix.m[0] + this.y * matrix.m[2] + matrix.m[4];
    const y = this.x * matrix.m[1] + this.y * matrix.m[3] + matrix.m[5];
    const x2 = this.x2 * matrix.m[0] + this.y2 * matrix.m[2] + matrix.m[4];
    const y2 = this.x2 * matrix.m[1] + this.y2 * matrix.m[3] + matrix.m[5];
    this.x = x;
    this.y = y;
    this.x2 = x2;
    this.y2 = y2;
    return this;
  }
  /**
   * Adds to the {@link x}, {@link y} and {@link x2}, {@link y2} coordinates defining this {@link Line} instance the input values.
   * @example
   * ```
   * const line = new Line(50, 100, 75, 150);
   * line.translate(-25, 150);
   * console.log(line.toString());
   * // Outputs: "[25, 250, 50, 300]"
   * ```
   * @param x - The value of the translation for the {@link x} coordinate.
   * @param y - The value of the translation for the {@link y} coordinate.
   * @returns This {@link Line} instance. Useful for chaining methods.
   */
  translate(x, y) {
    this.x += x;
    this.y += y;
    this.x2 += x;
    this.y2 += y;
    return this;
  }
  /**
   * Returns a string representation of this object.
   * @example
   * ```
   * const line = new Line(50, 100, 75, 150);
   * console.log(line.toString());
   * // Outputs: "[50, 100, 75, 150]"
   * ```
   */
  toString() {
    return `[${this.x}, ${this.y}, ${this.x2}, ${this.y2}]`;
  }
}

/**
 * This class represents a Circle in a cartesian plane ( x / y coordinate system).
 * @example
 * ```
 * const circle = new Circle(50, 100, 20);
 * // creates a circle with center 50,100 and radius of 20
 * ```
 */
class Circle extends Point {
  /**
   * @param x - x coordinate (horizontal) of the center of the circle in a cartesian plane.
   * @param y - y coordinate (vertical) of the center of the circle in a cartesian plane.
   * @param radius - radius of the circle.
   */
  constructor(x, y, radius) {
    super(x, y);
    this.radius = radius;
  }
  /**
   * Returns a clone of the {@link Circle} instance.
   * @example
   * ```
   * const circle1 = new Circle(50, 100, 20);
   * const circle2 = circle1.clone();
   * console.log(circle1.toString(), circle2.toString());
   * // Outputs: "[50, 100, 20] [50, 100, 20]"
   * ```
   * @returns {@link Circle } A clone of the {@link Circle} instance.
   */
  clone() {
    return new Circle(this.x, this.y, this.radius);
  }
  /**
   * Returns a string representation of this object.
   * @example
   * ```
   * const circle = new Circle(50, 100, 20);
   * console.log(circle.toString());
   * // Outputs: "[50, 100, 20]"
   * ```
   */
  toString() {
    return `[${this.x}, ${this.y}, ${this.radius}]`;
  }
  /**
   * Calculates common tangents for two circles.
   *
   * How does it work: https://math.stackexchange.com/questions/719758/inner-tangent-between-two-circles-formula
   *
   * @param c2 - Second circle.
   * @returns
   */
  commonTangents(c2) {
    const hypotenuse = this.distance(c2);
    const o1AngleRad = Math.atan2(c2.y - this.y, c2.x - this.x) + Math.acos((this.radius - c2.radius) / hypotenuse);
    const o2AngleRad = Math.atan2(c2.y - this.y, c2.x - this.x) - Math.acos((this.radius - c2.radius) / hypotenuse);
    const i1AngleRad = Math.atan2(c2.y - this.y, c2.x - this.x) + Math.asin((this.radius + c2.radius) / hypotenuse) - Math.PI / 2;
    const i2AngleRad = Math.atan2(c2.y - this.y, c2.x - this.x) - Math.asin((this.radius + c2.radius) / hypotenuse) + Math.PI / 2;
    return {
      outerAngle: [toDeg(o1AngleRad), toDeg(o2AngleRad)],
      innerAngle: [toDeg(i1AngleRad), toDeg(i2AngleRad)],
      outerAngleRad: [o1AngleRad, o2AngleRad],
      innerAngleRad: [i1AngleRad, i2AngleRad],
      outer: [new Line(this.x + this.radius * Math.cos(o1AngleRad), this.y + this.radius * Math.sin(o1AngleRad), c2.x + c2.radius * Math.cos(o1AngleRad), c2.y + c2.radius * Math.sin(o1AngleRad)), new Line(this.x + this.radius * Math.cos(o2AngleRad), this.y + this.radius * Math.sin(o2AngleRad), c2.x + c2.radius * Math.cos(o2AngleRad), c2.y + c2.radius * Math.sin(o2AngleRad))],
      inner: [new Line(this.x + this.radius * Math.cos(i1AngleRad), this.y + this.radius * Math.sin(i1AngleRad), c2.x + c2.radius * Math.cos(i1AngleRad + Math.PI), c2.y + c2.radius * Math.sin(i1AngleRad + Math.PI)), new Line(this.x + this.radius * Math.cos(i2AngleRad), this.y + this.radius * Math.sin(i2AngleRad), c2.x + c2.radius * Math.cos(i2AngleRad + Math.PI), c2.y + c2.radius * Math.sin(i2AngleRad + Math.PI))]
    };
  }
  /**
   * Calculates the {@link Point} that would be in the intersection between the line created by this {@link Circle} instance center and the given {@link Point} in the arguments
   * and the edge of this {@link Circle} instance.
   * @example
   * ```
   * const circle = new Circle(50, 100, 20);
   * const point = new Point(75, 125);
   * const pointInEdge = circle.getPointOnEdge(point);
   * console.log(pointInEdge.toString());
   * // Outputs: "[64.14213562373095, 114.14213562373095]"
   * ```
   * @param commonPoint - the {@link Point} that will be used to create a line with this {@link Circle} instance center and find the intersecting point in the edge.
   */
  getPointOnEdge(commonPoint) {
    const x = this.radius * ((commonPoint.x - this.x) / commonPoint.distance(this));
    const y = this.radius * ((commonPoint.y - this.y) / commonPoint.distance(this));
    return new Point(this.x, this.y).translate(x, y);
  }
  /**
   * Calculates the angle in radians from the center of the {@link Circle} instance to the provided {@link Point} in the arguments
   * @example
   * ```
   * const circle = new Circle(50, 100, 20);
   * const point = new Point(75, 125);
   * const angleFromPoint = circle.getPointAngle(point);
   * console.log(angleFromPoint);
   * // Outputs: "0.7853981633974483"
   * ```
   * @param point - {@link Point} used to calculate the angle
   * @returns angle in radians
   */
  getPointAngle(point) {
    return Math.atan2(point.y - this.y, point.x - this.x);
  }
  /**
   * Calculates a point belonging to the {@link Circle} instance edge at a given angle.
   * The `clockwise` argument determines the rotation direction used to calculate the point, clockwise or counter-clockwise (counter-clockwise by default)
   * @example
   * ```
   * const circle = new Circle(50, 100, 20);
   * const pointFromAngle = circle.getPointFromAngle(Math.PI/2);
   * console.log(pointFromAngle.toString());
   * // Outputs: "[70, 100]"
   *
   * const circle = new Circle(50, 100, 20);
   * const pointFromAngle = circle.getPointFromAngle(Math.PI/2, true);
   * console.log(pointFromAngle.toString());
   * // Outputs: "[50, 120]"
   *
   * ```
   * @param angle - angle in radians
   * @param clockwise - rotation direction to calculate the point
   */
  getPointFromAngle(angle, clockwise = false) {
    if (clockwise) return new Point(this.radius * Math.cos(angle) + this.x, this.radius * Math.sin(angle) + this.y);
    return new Point(this.radius * Math.sin(angle) + this.x, this.radius * Math.cos(angle) + this.y);
  }
  /**
   * Checks if the {@link Circle} passed as parameter intersects  with the {@link Circle} instance.
   * @example
   * ```
   * const circle1 = new Circle(50, 100, 20);
   * const circle2 = new Circle(100, 100, 20);
   * const isIntersect = circle1.intersects(circle2);
   * console.log(isIntersect);
   * // Outputs: "false"
   *
   * const circle1 = new Circle(50, 100, 20);
   * const circle2 = new Circle(100, 100, 60);
   * const isIntersect = circle1.intersects(circle2);
   * console.log(isIntersect);
   * // Outputs: "true"
   * ```
   * @param other - A second {@link Circle} to check if it intersects with the {@link Circle} instance.
   */
  intersects(other) {
    const distance = this.distance(other);
    return distance <= this.radius + other.radius;
  }
}

/**
 * This class creates a new Polygon graphics command, used in the {@link Graphics} class.
 */
class PolygonCmd {
  /**
   * @param points - Array of points creating the Polygon.
   * @param close - Determines if the Polygon should be closed.
   */
  constructor(points, close) {
    this.points = points;
    this.close = close;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    const firstPoint = this.points[0];
    let secondPoint = new Point(0, 0);
    ctx.moveTo(firstPoint.x, firstPoint.y);
    let point;
    for (let i = 1; i < this.points.length; i++) {
      point = this.points[i];
      if (i === 1) secondPoint = point;
      ctx.lineTo(point.x, point.y);
    }
    if (this.close) {
      ctx.lineTo(firstPoint.x, firstPoint.y);
      ctx.lineTo(secondPoint.x, secondPoint.y); // go around to second point to get correct end bevel/miter
      ctx.closePath();
    }
  }
}

/**
 * This class creates a new PolyStar graphics command, used in the {@link Graphics} class.
 */
class PolystarCmd {
  /**
   * @param x - The x-axis coordinate of the center of the PolyStar.
   * @param y - The y-axis coordinate of the center of the PolyStar.
   * @param radius - The PolyStar's radius. Must be positive.
   * @param sides - The number of points on the star or sides on the polygon.
   * @param pointSize - The depth or "pointy-ness" of the star points. A pointSize of 0 will draw a regular
   * polygon (no points), a pointSize of 1 will draw nothing because the points are infinitely pointy.
   * @param angle - The angle of the first point / corner. For example a value of 0 will draw the first point
   * directly to the right of the center.
   */
  constructor(x, y, radius, sides, pointSize, angle) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.sides = sides;
    this.pointSize = pointSize;
    this.angle = angle;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    let angle = (this.angle || 0) / 180 * Math.PI;
    const ps = 1 - (this.pointSize || 0);
    const a = Math.PI / this.sides;
    ctx.moveTo(this.x + Math.cos(angle) * this.radius, this.y + Math.sin(angle) * this.radius);
    for (let i = 0; i < this.sides + 1; i++) {
      angle += a;
      if (ps !== 1) {
        ctx.lineTo(this.x + Math.cos(angle) * this.radius * ps, this.y + Math.sin(angle) * this.radius * ps);
        if (i === this.sides) break;
      }
      angle += a;
      ctx.lineTo(this.x + Math.cos(angle) * this.radius, this.y + Math.sin(angle) * this.radius);
    }
    ctx.closePath();
  }
}

/**
 * This class creates a new QuadraticCurveTo graphics command, used in the {@link Graphics} class.
 */
class QuadraticCurveToCmd {
  /**
   * @param cpx - The x-axis coordinate of the control point.
   * @param cpy - The y-axis coordinate of the control point.
   * @param x - The x-axis coordinate of the end point.
   * @param y - The y-axis coordinate of the end point.
   */
  constructor(cpx, cpy, x, y) {
    this.cpx = cpx;
    this.cpy = cpy;
    this.x = x;
    this.y = y;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.quadraticCurveTo(this.cpx, this.cpy, this.x, this.y);
  }
}

/**
 * This class creates a new Rect graphics command, used in the {@link Graphics} class.
 */
class RectCmd {
  /**
   * @param x - The x-axis coordinate of the rectangle's starting point.
   * @param y - The y-axis coordinate of the rectangle's starting point.
   * @param width - The rectangle's width. Positive values are to the right, and negative to the left.
   * @param height - The rectangle's height. Positive values are down, and negative are up.
   */
  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.rect(this.x, this.y, this.width, this.height);
  }
}

/**
 * This class creates a new RoundRect graphics command, used in the {@link Graphics} class.
 */
class RoundRectCmd {
  /**
   * @param x - The x-axis coordinate of the rectangle's starting point.
   * @param y - The y-axis coordinate of the rectangle's starting point.
   * @param width - The rectangle's width. Positive values are to the right, and negative to the left.
   * @param height - The rectangle's height. Positive values are down, and negative are up.
   * @param radiusTL - Radius of the Top Left corner of the rectangle.
   * @param radiusTR - Radius of the Top Right corner of the rectangle.
   * @param radiusBR - Radius of the Bottom Right corner of the rectangle.
   * @param radiusBL - Radius of the Bottom Left corner of the rectangle.
   */
  constructor(x, y, width, height, radiusTL, radiusTR, radiusBR, radiusBL) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.radiusTL = radiusTL;
    this.radiusTR = radiusTR;
    this.radiusBR = radiusBR;
    this.radiusBL = radiusBL;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    const max = (this.width < this.height ? this.width : this.height) / 2;
    let mTL = 0;
    let mTR = 0;
    let mBR = 0;
    let mBL = 0;
    if (this.radiusTL < 0) {
      mTL = -1;
      this.radiusTL *= mTL;
    }
    if (this.radiusTL > max) {
      this.radiusTL = max;
    }
    if (this.radiusTR < 0) {
      mTR = -1;
      this.radiusTR *= mTR;
    }
    if (this.radiusTR > max) {
      this.radiusTR = max;
    }
    if (this.radiusBR < 0) {
      mBR = -1;
      this.radiusBR *= mBR;
    }
    if (this.radiusBR > max) {
      this.radiusBR = max;
    }
    if (this.radiusBL < 0) {
      mBL = -1;
      this.radiusBL *= mBL;
    }
    if (this.radiusBL > max) {
      this.radiusBL = max;
    }
    ctx.moveTo(this.x + this.width - this.radiusTR, this.y);
    ctx.arcTo(this.x + this.width + this.radiusTR * mTR, this.y - this.radiusTR * mTR, this.x + this.width, this.y + this.radiusTR, this.radiusTR);
    ctx.lineTo(this.x + this.width, this.y + this.height - this.radiusBR);
    ctx.arcTo(this.x + this.width + this.radiusBR * mBR, this.y + this.height + this.radiusBR * mBR, this.x + this.width - this.radiusBR, this.y + this.height, this.radiusBR);
    ctx.lineTo(this.x + this.radiusBL, this.y + this.height);
    ctx.arcTo(this.x - this.radiusBL * mBL, this.y + this.height + this.radiusBL * mBL, this.x, this.y + this.height - this.radiusBL, this.radiusBL);
    ctx.lineTo(this.x, this.y + this.radiusTL);
    ctx.arcTo(this.x - this.radiusTL * mTL, this.y - this.radiusTL * mTL, this.x + this.radiusTL, this.y, this.radiusTL);
    ctx.closePath();
  }
}

/**
 * This class creates a new Stroke graphics command, used in the {@link Graphics} class.
 */
class StrokeCmd {
  /**
   * @param style - A valid Context2D strokeStyle.
   * @param ignoreScale - an optional parameter that if true will reset the transformation matrix of the canvas context.
   */
  constructor(style, ignoreScale) {
    this.style = style;
    this.ignoreScale = ignoreScale;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw.
   */
  exec(ctx) {
    if (this.style) {
      ctx.strokeStyle = this.style;
    }
    if (this.ignoreScale) {
      ctx.save();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }
    ctx.stroke();
    if (this.ignoreScale) {
      ctx.restore();
    }
  }
  /**
   * Creates a linear gradient style and assigns it this instance {@link Fill.style} property.
   * @param colors - an array containing the color of each color-stop.
   * @param ratios - an array containing the ratio of each color-stop.
   * @param x0 - The x-axis coordinate of the start point of the gradient.
   * @param y0 - The y-axis coordinate of the start point of the gradient.
   * @param x1 - The x-axis coordinate of the end point of the gradient.
   * @param y1 - The y-axis coordinate of the end point of the gradient.
   * @returns - Returns this {@link Fill} command instance for chaining methods.
   */
  linearGradient(colors, ratios, x0, y0, x1, y1) {
    if (!GRAPHIC_CTX) {
      return this;
    }
    const style = GRAPHIC_CTX.createLinearGradient(x0, y0, x1, y1);
    this.addColorStops(style, ratios, colors);
    this.style = style;
    return this;
  }
  /**
   * Creates a radial gradient style and assigns it this instance {@link Fill.style} property.
   * @param colors - an array containing the color of each color-stop.
   * @param ratios - an array containing the ratio of each color-stop.
   * @param x0 - The x-axis coordinate of the start circle.
   * @param y0 - The y-axis coordinate of the start circle.
   * @param r0 - The radius of the start circle. Must be non-negative and finite.
   * @param x1 - The x-axis coordinate of the end circle.
   * @param y1 - The y-axis coordinate of the end circle.
   * @param r1 - The radius of the end circle. Must be non-negative and finite.
   * @returns - Returns this {@link Fill} command instance for chaining methods.
   */
  radialGradient(colors, ratios, x0, y0, r0, x1, y1, r1) {
    if (!GRAPHIC_CTX) {
      return this;
    }
    const style = GRAPHIC_CTX.createRadialGradient(x0, y0, r0, x1, y1, r1);
    this.addColorStops(style, ratios, colors);
    this.style = style;
    return this;
  }
  addColorStops(style, ratios, colors) {
    for (let i = 0; i < colors.length; i++) {
      style.addColorStop(ratios[i], colors[i]);
    }
  }
  /**
   * Creates a bitmap fill style and assigns it this instance {@link Fill.style} property.
   * @param image - Image, Canvas or Video bitmap source. Must be loaded prior to creating a bitmap fill, or the fill will be empty.
   * @param repetition - repeat, repeat-x, repeat-y, or no-repeat.
   * @returns - Returns this {@link Fill} command instance for chaining methods.
   */
  bitmap(image, repetition) {
    if (!GRAPHIC_CTX) {
      return this;
    }
    if (image.naturalWidth || image.getContext || image.readyState >= 2 || image) {
      const style = GRAPHIC_CTX.createPattern(image, repetition || '');
      if (style) {
        this.style = style;
      }
    }
    return this;
  }
}

/**
 * This class creates a new StrokeDash graphics command, used in the {@link Graphics} class.
 */
class StrokeDashCmd {
  /**
   * @param segments - An Array of numbers that specify distances to alternately draw a line and a gap (in coordinate space units).
   * If the number of elements in the array is odd, the elements of the array get copied and concatenated. For example, [5, 15, 25] will become [5, 15, 25, 5, 15, 25].
   * If the array is empty or null, the line dash list is cleared and line strokes return to being solid.
   * @param offset - A float specifying the amount of the line dash offset.
   */
  constructor(segments, offset) {
    this.segments = segments;
    this.offset = offset;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw.
   */
  exec(ctx) {
    if (ctx.setLineDash) {
      // feature detection.
      ctx.setLineDash(this.segments || []);
      ctx.lineDashOffset = this.offset || 0;
    }
  }
}

/**
 * This class creates a new StrokeStyle graphics command, used in the {@link Graphics} class.
 */
class StrokeStyleCmd {
  /**
   * @param thickness - thickness of the stroke.
   * @param caps - lineCap type: butt, round, square.
   * @param joints - lineJoin type: round, bevel, miter.
   * @param miterLimit - miterLimit value.
   */
  constructor(thickness, caps, joints, miterLimit) {
    this.thickness = thickness;
    this.caps = caps;
    this.joints = joints;
    this.miterLimit = miterLimit;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw.
   */
  exec(ctx) {
    ctx.lineWidth = !this.thickness ? 1 : this.thickness;
    if (!this.caps) {
      ctx.lineCap = 'butt';
    } else {
      ctx.lineCap = this.caps;
    }
    if (!this.joints) {
      ctx.lineJoin = 'miter';
    } else {
      ctx.lineJoin = this.joints;
    }
    ctx.miterLimit = !this.miterLimit ? 10 : this.miterLimit;
  }
}

/**
 * This class creates a new TextStyle graphics command, used in the {@link Graphics} class.
 */
class TextStyleCmd {
  /**
   * @param font - A string parsed as CSS font value. The default font is 10px sans-serif.
   * @param textAlign - Specifies the current text alignment used when drawing text.
   * @param textBaseline - Specifies the current text baseline used when drawing text.
   * @param direction - Specifies the current text direction used to draw text.
   */
  constructor(font, textAlign, textBaseline, direction) {
    this.font = font;
    this.textAlign = textAlign;
    this.textBaseline = textBaseline;
    this.direction = direction;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw.
   */
  exec(ctx) {
    if (this.font) {
      ctx.font = this.font;
    } else {
      ctx.font = '10px sans-serif';
    }
    if (this.textAlign) {
      ctx.textAlign = this.textAlign;
    } else {
      ctx.textAlign = 'start';
    }
    if (this.textBaseline) {
      ctx.textBaseline = this.textBaseline;
    } else {
      ctx.textBaseline = 'top';
    }
    if (this.direction) {
      ctx.direction = this.direction;
    } else {
      ctx.direction = 'inherit';
    }
  }
}

/**
 * This class creates a new TextFill graphics command, used in the {@link Graphics} class.
 */
class TextFillCmd {
  /**
   * @param x - The x-axis coordinate of the text starting point.
   * @param y - The y-axis coordinate of the text starting point.
   * @param style - A valid Context2D fillStyle.
   */
  constructor(text, x, y, style) {
    this.text = text;
    this.x = x;
    this.y = y;
    this.style = style;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw.
   */
  exec(ctx) {
    if (this.style) {
      ctx.fillStyle = this.style;
    }
    ctx.fillText(this.text, this.x, this.y);
  }
}

/**
 * This class creates a new TextStroke graphics command, used in the {@link Graphics} class.
 */
class TextStrokeCmd {
  /**
   * @param x - The x-axis coordinate of the text starting point.
   * @param y - The y-axis coordinate of the text starting point.
   * @param style - A valid Context2D strokeStyle.
   */
  constructor(text, x, y, style) {
    this.text = text;
    this.x = x;
    this.y = y;
    this.style = style;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw.
   */
  exec(ctx) {
    if (this.style) {
      ctx.strokeStyle = this.style;
    }
    ctx.strokeText(this.text, this.x, this.y);
  }
}

/**
 * This class creates a new Empty graphics command, used in the {@link Graphics} class.
 * It's main purpose is making the instruction system think that a command is pending,
 * so native single instruction commands like strokeText and fillText can be integrated into the system.
 */
class EmptyCmd {
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  exec(ctx) {
    // Draw nothing
  }
}

/**
 * This class creates a new Diamond graphics command, used in the {@link Graphics} class.
 */
class DiamondCmd {
  /**
   * @param x - The x-axis coordinate of the diamond's starting point.
   * @param y - The y-axis coordinate of the diamond's starting point.
   * @param width - The diamond's width. Positive values are to the right, and negative to the left.
   * @param height - The diamond's height. Positive values are to the right, and negative to the left.
   */
  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  /**
   * Execute the Graphics command in the provided Canvas context.
   * @param ctx - canvas context to draw
   */
  exec(ctx) {
    ctx.moveTo(this.x, this.y - this.height / 2);
    ctx.lineTo(this.x + this.width / 2, this.y);
    ctx.lineTo(this.x, this.y + this.height / 2);
    ctx.lineTo(this.x - this.width / 2, this.y);
    ctx.lineTo(this.x, this.y - this.height / 2);
    ctx.closePath();
  }
}

class Graphics {
  constructor() {
    /**
     *
     */
    this.command = null;
    /**
     *
     */
    this.stroke = null;
    /**
     *
     */
    this.strokeStyle = null;
    /**
     *
     */
    this.oldStrokeStyle = null;
    /**
     *
     */
    this.strokeDash = null;
    /**
     *
     */
    this.oldStrokeDash = null;
    /**
     *
     */
    this.textStyle = null;
    /**
     *
     */
    this.oldTextStyle = null;
    /**
     *
     */
    this.fill = null;
    /**
     *
     */
    this.textFill = null;
    /**
     *
     */
    this.textStroke = null;
    /**
     *
     */
    this.instructions = [];
    /**
     * Indicates the last instruction index that was committed.
     */
    this.commitIndex = 0;
    /**
     * Uncommitted instructions.
     */
    this.activeInstructions = [];
    /**
     * This indicates that there have been changes to the activeInstruction list since the last updateInstructions call.
     */
    this.dirty = false;
    /**
     * Index to draw from if a store operation has happened.
     */
    this.storeIndex = 0;
  }
  static getRGB(...args) {
    let b;
    let g;
    let r;
    let hex;
    let alpha;
    if (args.length < 3) {
      [hex, alpha] = args;
      b = hex & 0xff;
      g = hex >> 8 & 0xff;
      r = hex >> 16 & 0xff;
    } else {
      [r, g, b, alpha] = args;
    }
    if (!alpha) {
      return `rgb(${r},${g},${b})`;
    }
    return `rgba(${r},${g},${b},${alpha})`;
  }
  /**
   * Returns a CSS compatible color string based on the specified HSL numeric color values in the format
   * "hsla(360,100,100,1.0)", or if alpha is null then in the format "hsl(360,100,100)".
   * @param hue - The hue component for the color, between 0 and 360.
   * @param saturation - The saturation component for the color, between 0 and 100.
   * @param lightness - The lightness component for the color, between 0 and 100.
   * @param alpha - The alpha component for the color where 0 is fully transparent and 1 is fully opaque.
   * @returns A CSS compatible color string based on the specified HSL numeric color values in the format
   * "hsla(360,100,100,1.0)", or if alpha is null then in the format "hsl(360,100,100)".
   */
  static getHSL(hue, saturation, lightness, alpha) {
    if (!alpha) {
      return `hsl(${hue % 360},${saturation}%,${lightness}%)`;
    }
    return `hsla(${hue % 360},${saturation}%,${lightness}%,${alpha})`;
  }
  /**
   * Returns true if this {@link Graphics} instance has no drawing commands.
   */
  isEmpty() {
    return !(this.instructions.length || this.activeInstructions.length);
  }
  /**
   *
   * @param ctx - The canvas 2D context object to draw into.
   * When called from a Shape instance, the shape passes itself as the data parameter.
   * This can be used by custom graphic commands to insert contextual data.
   */
  draw(ctx) {
    this.updateInstructions();
    if (this.instructions.length > 0) {
      this.instructions.forEach(instruction => instruction.exec(ctx));
    }
  }
  /**
   * Moves the drawing point to the specified position.
   * @param x - The x-axis (horizontal) coordinate of the point.
   * @param y - The y-axis (vertical) coordinate of the point.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  moveTo(x, y) {
    return this.append(new MoveToCmd(x, y), true);
  }
  /**
   * Draws a line from the current drawing point to the specified position, which will then become the new current drawing
   * point.
   * @param x - The x-axis coordinate of the line's end point.
   * @param y - The y-axis coordinate of the line's end point.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  lineTo(x, y) {
    return this.append(new LineToCmd(x, y));
  }
  /**
   * Draws an arc with the specified control points and radius.
   * @param x1 - The x-axis coordinate of the first control point.
   * @param y1 - The y-axis coordinate of the first control point.
   * @param x2 - The x-axis coordinate of the second control point.
   * @param y2 - The y-axis coordinate of the second control point.
   * @param radius - The arc's radius. Must be positive.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  arcTo(x1, y1, x2, y2, radius) {
    return this.append(new ArcToCmd(x1, y1, x2, y2, radius));
  }
  /**
   * Draws an arc defined by the radius, startAngle and endAngle arguments, centered at the position (x, y).
   * @param x - The x-axis coordinate of the first control point.
   * @param y - The y-axis coordinate of the first control point.
   * @param radius - The arc's radius. Must be positive.
   * @param startAngle - The angle at which the arc starts in radians, measured from the positive x-axis.
   * @param endAngle - The angle at which the arc ends in radians, measured from the positive x-axis.
   * @param counterclockwise - An optional boolean value. If true, draws the arc counter-clockwise between the start and end angles. The default is false (clockwise).
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  arc(x, y, radius, startAngle, endAngle, counterclockwise = false) {
    return this.append(new ArcCmd(x, y, radius, startAngle, endAngle, counterclockwise));
  }
  /**
   * Draws a quadratic curve from the current drawing point to (x, y) using the control point (cpx, cpy).
   * @param cpx - The x-axis coordinate of the control point.
   * @param cpy - The y-axis coordinate of the control point.
   * @param x - The x-axis coordinate of the end point.
   * @param y - The y-axis coordinate of the end point.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  quadraticCurveTo(cpx, cpy, x, y) {
    return this.append(new QuadraticCurveToCmd(cpx, cpy, x, y));
  }
  /**
   * Draws a bezier curve from the current drawing point to (x, y) using the control points (cp1x, cp1y) and (cp2x, cp2y).
   * @param cp1x - The x-axis coordinate of the first control point.
   * @param cp1y - The y-axis coordinate of the first control point.
   * @param cp2x - The x-axis coordinate of the second control point.
   * @param cp2y - The y-axis coordinate of the second control point.
   * @param x - The x-axis coordinate of the end point.
   * @param y - The y-axis coordinate of the end point.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y) {
    return this.append(new BezierCurveToCmd(cp1x, cp1y, cp2x, cp2y, x, y));
  }
  /**
   * Draws a rectangle at (x, y) with the specified width and height using the current fill and/or stroke.
   * @param x - The x-axis coordinate of the rectangle's starting point.
   * @param y - The y-axis coordinate of the rectangle's starting point.
   * @param width - The rectangle's width. Positive values are to the right, and negative to the left.
   * @param height - The rectangle's height. Positive values are down, and negative are up.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  rect(x, y, width, height) {
    return this.append(new RectCmd(x, y, width, height));
  }
  /**
   * Closes the current path, effectively drawing a line from the current drawing point
   * to the first drawing point specified since the fill or stroke was last set.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  closePath() {
    return this.activeInstructions.length ? this.append(new ClosePathCmd()) : this;
  }
  /**
   * Begins a fill with the specified color. This ends the current sub-path.
   * @param color - A CSS compatible color value (ex. "red", "#FF0000", or "rgba(255,0,0,0.5)").
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginFill(color) {
    return this.setFill(color ? new FillCmd(color) : null);
  }
  /**
   * Begins a linear gradient fill defined by the line (x0, y0) to (x1, y1). This ends the current sub-path.
   * @param colors - an array containing the color of each color-stop.
   * For example, ["#F00","#00F"] would define a gradient drawing from red to blue.
   * @param ratios - an array containing the ratio of each color-stop.
   * For example, [0.1, 0.9] would draw the first color to 10% then interpolating to the second color at 90%.
   * @param x0 - The x-axis coordinate of the start point of the gradient.
   * @param y0 - The y-axis coordinate of the start point of the gradient.
   * @param x1 - The x-axis coordinate of the end point of the gradient.
   * @param y1 - The y-axis coordinate of the end point of the gradient.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginLinearGradientFill(colors, ratios, x0, y0, x1, y1) {
    return this.setFill(new FillCmd().linearGradient(colors, ratios, x0, y0, x1, y1));
  }
  /**
   * Begins a radial gradient fill. This ends the current sub-path.
   * @param colors - an array containing the color of each color-stop.
   * For example, ["#F00","#00F"] would define a gradient drawing from red to blue.
   * @param ratios - an array containing the ratio of each color-stop.
   * For example, [0.1, 0.9] would draw the first color to 10% then interpolating to the second color at 90%.
   * @param x0 - The x-axis coordinate of the start circle.
   * @param y0 - The y-axis coordinate of the start circle.
   * @param r0 - The radius of the start circle. Must be non-negative and finite.
   * @param x1 - The x-axis coordinate of the end circle.
   * @param y1 - The y-axis coordinate of the end circle.
   * @param r1 - The radius of the end circle. Must be non-negative and finite.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginRadialGradientFill(colors, ratios, x0, y0, r0, x1, y1, r1) {
    return this.setFill(new FillCmd().radialGradient(colors, ratios, x0, y0, r0, x1, y1, r1));
  }
  /**
   * Begins a pattern fill using the specified image. This ends the current sub-path.
   * @param image - Image, Canvas or Video bitmap source. Must be loaded prior to creating a bitmap fill, or the fill will be empty.
   * @param repetition - repeat, repeat-x, repeat-y, or no-repeat.
   * @param matrix - an optional transformation matrix for the bitmap fill. Will be applied relative to the parent transform.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginBitmapFill(image, repetition, matrix) {
    return this.setFill(new FillCmd(null, matrix).bitmap(image, repetition));
  }
  /**
   * Ends the current sub-path, and begins a new one with no fill.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  endFill() {
    return this.beginFill();
  }
  /**
   * Sets the stroke style.
   * @param thickness - thickness of the stroke.
   * @param caps - lineCap type: butt, round, square.
   * @param joints - lineJoin type: round, bevel, miter.
   * @param miterLimit - miterLimit value.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  setStrokeStyle(thickness = 1, caps = 'butt', joints = 'miter', miterLimit = 10) {
    this.updateInstructions(true);
    this.strokeStyle = new StrokeStyleCmd(thickness, caps, joints, miterLimit);
    this.command = this.strokeStyle;
    return this;
  }
  /**
   * Sets or clears the stroke dash pattern.
   * @param segments - An Array of numbers that specify distances to alternately draw a line and a gap (in coordinate space units).
   * If the number of elements in the array is odd, the elements of the array get copied and concatenated. For example, [5, 15, 25] will become [5, 15, 25, 5, 15, 25].
   * If the array is empty or null, the line dash list is cleared and line strokes return to being solid.
   * @param offset - A float specifying the amount of the line dash offset.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  setStrokeDash(segments, offset = 0) {
    this.updateInstructions(true);
    this.strokeDash = new StrokeDashCmd(segments, offset);
    this.command = this.strokeDash;
    return this;
  }
  /**
   * Begins a stroke with the specified color. This ends the current sub-path.
   * @param color - A CSS compatible color value (ex. "red", "#FF0000", or "rgba(255,0,0,0.5)").
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginStroke(color) {
    return this.setStroke(color ? new StrokeCmd(color) : null);
  }
  /**
   * Begins a linear gradient stroke defined by the line (x0, y0) to (x1, y1). This ends the current sub-path.
   * @param colors - an array containing the color of each color-stop.
   * For example, ["#F00","#00F"] would define a gradient drawing from red to blue.
   * @param ratios - an array containing the ratio of each color-stop.
   * For example, [0.1, 0.9] would draw the first color to 10% then interpolating to the second color at 90%.
   * @param x0 - The x-axis coordinate of the start point of the gradient.
   * @param y0 - The y-axis coordinate of the start point of the gradient.
   * @param x1 - The x-axis coordinate of the end point of the gradient.
   * @param y1 - The y-axis coordinate of the end point of the gradient.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginLinearGradientStroke(colors, ratios, x0, y0, x1, y1) {
    return this.setStroke(new StrokeCmd().linearGradient(colors, ratios, x0, y0, x1, y1));
  }
  /**
   * Begins a radial gradient stroke. This ends the current sub-path.
   * @param colors - an array containing the color of each color-stop.
   * For example, ["#F00","#00F"] would define a gradient drawing from red to blue.
   * @param ratios - an array containing the ratio of each color-stop.
   * For example, [0.1, 0.9] would draw the first color to 10% then interpolating to the second color at 90%,
   * then draw the second color to 100%.
   * @param x0 - The x-axis coordinate of the start circle.
   * @param y0 - The y-axis coordinate of the start circle.
   * @param r0 - The radius of the start circle. Must be non-negative and finite.
   * @param x1 - The x-axis coordinate of the end circle.
   * @param y1 - The y-axis coordinate of the end circle.
   * @param r1 - The radius of the end circle. Must be non-negative and finite.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginRadialGradientStroke(colors, ratios, x0, y0, r0, x1, y1, r1) {
    return this.setStroke(new StrokeCmd().radialGradient(colors, ratios, x0, y0, r0, x1, y1, r1));
  }
  /**
   * Begins a pattern fill using the specified image. This ends the current sub-path. Note that unlike bitmap fills,
   * strokes do not currently support a matrix parameter due to limitations in the canvas API.
   * @param image - Image, Canvas or Video bitmap source. Must be loaded prior to creating a bitmap fill, or the fill will be empty.
   * @param repetition - repeat, repeat-x, repeat-y, or no-repeat.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  beginBitmapStroke(image, repetition) {
    // NOTE: matrix is not supported for stroke because transforms on strokes also affect the drawn stroke width.
    return this.setStroke(new StrokeCmd().bitmap(image, repetition));
  }
  /**
   * Ends the current sub-path, and begins a new one with no stroke.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  endStroke() {
    return this.beginStroke();
  }
  /**
   * Sets the text style.
   * @param font - A string parsed as CSS font value. The default font is 10px sans-serif.
   * @param textAlign - Specifies the current text alignment used when drawing text.
   * @param textBaseline - Specifies the current text baseline used when drawing text.
   * @param direction - Specifies the current text direction used to draw text.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  setTextStyle(font = '10px sans-serif', textAlign = 'start', textBaseline = 'top', direction = 'inherit') {
    this.updateInstructions(true);
    this.textStyle = new TextStyleCmd(font, textAlign, textBaseline, direction);
    this.command = this.textStyle;
    return this;
  }
  /**
   * Convinience method that maps to {@link quadraticCurveTo}.
   * Draws a quadratic curve from the current drawing point to (x, y) using the control point (cpx, cpy).
   * @param cpx - The x-axis coordinate of the control point.
   * @param cpy - The y-axis coordinate of the control point.
   * @param x - The x-axis coordinate of the end point.
   * @param y - The y-axis coordinate of the end point.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  curveTo(cpx, cpy, x, y) {
    return this.quadraticCurveTo(cpx, cpy, x, y);
  }
  /**
   * Convinience method that maps to {@link rect}.
   * Draws a rectangle at (x, y) with the specified width and height using the current fill and/or stroke.
   * @param x - The x-axis coordinate of the rectangle's starting point.
   * @param y - The y-axis coordinate of the rectangle's starting point.
   * @param width - The rectangle's width. Positive values are to the right, and negative to the left.
   * @param height - The rectangle's height. Positive values are down, and negative are up.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawRect(x, y, width, height) {
    return this.rect(x, y, width, height);
  }
  /**
   * Draws a rounded rectangle with different corner radii. Supports positive and negative corner radii.
   * @param x - The x-axis coordinate of the rectangle's starting point.
   * @param y - The y-axis coordinate of the rectangle's starting point.
   * @param width - The rectangle's width. Positive values are to the right, and negative to the left.
   * @param height - The rectangle's height. Positive values are down, and negative are up.
   * @param radiusTL - Radius of the Top Left corner of the rectangle.
   * @param radiusTR - Radius of the Top Right corner of the rectangle.
   * @param radiusBR - Radius of the Bottom Right corner of the rectangle.
   * @param radiusBL - Radius of the Bottom Left corner of the rectangle.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawRoundRectComplex(x, y, width, height, radiusTL, radiusTR, radiusBR, radiusBL) {
    return this.append(new RoundRectCmd(x, y, width, height, radiusTL, radiusTR, radiusBR, radiusBL));
  }
  /**
   * Draws a rounded rectangle with all corners with the specified radius.
   * @param x - The x-axis coordinate of the rectangle's starting point.
   * @param y - The y-axis coordinate of the rectangle's starting point.
   * @param width - The rectangle's width. Positive values are to the right, and negative to the left.
   * @param height - The rectangle's height. Positive values are down, and negative are up.
   * @param radius - Corner radius.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawRoundRect(x, y, width, height, radius) {
    return this.drawRoundRectComplex(x, y, width, height, radius, radius, radius, radius);
  }
  /**
   * Draws a diamond shape.
   * @param x - The x-axis coordinate of the diamond's starting point.
   * @param y - The y-axis coordinate of the diamond's starting point.
   * @param width - The diamond's width. Positive values are to the right, and negative to the left.
   * @param height - The diamond's height. Positive values are to the right, and negative to the left.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawDiamond(x, y, width, height) {
    return this.append(new DiamondCmd(x, y, width, height));
  }
  /**
   * Draws a circle with the specified radius at (x, y).
   * @param x - The x-axis coordinate of the center of the Circle.
   * @param y - The y-axis coordinate of the center of the Circle.
   * @param radius - The Cicle's radius. Must be positive.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawCircle(x, y, radius) {
    return this.append(new CircleCmd(x, y, radius));
  }
  /**
   * Draws an ellipse (oval) with a specified width (w) and height (h).
   * Similar to {@link drawCircle}, except the width and height can be different.
   * @param x - The x-axis coordinate of the center of the Ellipse.
   * @param y - The y-axis coordinate of the center of the Ellipse.
   * @param width - The width of the Ellipse.
   * @param height - The height of the Ellipse.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawEllipse(x, y, width, height) {
    return this.append(new EllipseCmd(x, y, width, height));
  }
  /**
   * Draws a star if pointSize is greater than 0, or a regular polygon if pointSize is 0 with
   * the specified number of points.
   * @param x - The x-axis coordinate of the center of the PolyStar.
   * @param y - The y-axis coordinate of the center of the PolyStar.
   * @param radius - The PolyStar's radius. Must be positive.
   * @param sides - The number of points on the star or sides on the polygon.
   * @param pointSize - The depth or "pointy-ness" of the star points. A pointSize of 0 will draw a regular
   * polygon (no points), a pointSize of 1 will draw nothing because the points are infinitely pointy.
   * @param angle - The angle of the first point / corner. For example a value of 0 will draw the first point
   * directly to the right of the center.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawPolystar(x, y, radius, sides, pointSize, angle) {
    return this.append(new PolystarCmd(x, y, radius, sides, pointSize, angle));
  }
  /**
   * Draws a polygon from an array of points.
   * @param points - Array of points creating the Polygon.
   * @param close - Determines if the Polygon should be closed.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawPolygon(points, close) {
    return this.append(new PolygonCmd(points, close));
  }
  /**
   * Draws a text in the specified coordinates.
   *
   * @param text - The text to be drawn.
   * @param x - The x-axis coordinate of the text starting point.
   * @param y - The y-axis coordinate of the text starting point.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  drawText(text, x = 0, y = 0) {
    var _a, _b;
    if (this.fill) {
      this.setTextFill(new TextFillCmd(text, x, y, (_a = this.fill) === null || _a === void 0 ? void 0 : _a.style));
      // We add an empty command to trick the instructions system
      this.append(new EmptyCmd());
    }
    if (this.stroke) {
      this.setTextStroke(new TextStrokeCmd(text, x, y, (_b = this.stroke) === null || _b === void 0 ? void 0 : _b.style));
      // We add an empty command to trick the instructions system
      this.append(new EmptyCmd());
    }
    return this;
  }
  /**
   * Stores all graphics commands so they won't be executed in future draws.
   * Calling store() a second time adds to the existing store.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  store() {
    this.updateInstructions(true);
    this.storeIndex = this.instructions.length;
    return this;
  }
  /**
   * Unstores any graphics commands that were previously stored using {@link store}
   * so that they will be executed in subsequent draw calls.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  unstore() {
    this.storeIndex = 0;
    return this;
  }
  /**
   * Clears all drawing instructions, effectively resetting this Graphics instance.
   * Any line and fill styles will need to be redefined to draw shapes following a clear call.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  clear() {
    this.instructions.length = 0;
    this.activeInstructions.length = 0;
    this.commitIndex = 0;
    this.strokeStyle = null;
    this.oldStrokeStyle = null;
    this.stroke = null;
    this.fill = null;
    this.textFill = null;
    this.textStroke = null;
    this.strokeDash = null;
    this.oldStrokeDash = null;
    this.textStyle = null;
    this.oldTextStyle = null;
    this.dirty = false;
    return this;
  }
  /**
   * Returns a clone of this {@link Graphics} instance.
   * @returns - A clone of this {@link Graphics} instance.
   */
  clone() {
    const elem = new Graphics();
    elem.command = this.command;
    elem.stroke = this.stroke;
    elem.strokeStyle = this.strokeStyle;
    elem.strokeDash = this.strokeDash;
    elem.fill = this.fill;
    elem.textFill = this.textFill;
    elem.textStroke = this.textStroke;
    elem.textStyle = this.textStyle;
    elem.instructions = this.instructions.slice();
    elem.commitIndex = this.commitIndex;
    elem.activeInstructions = this.activeInstructions.slice();
    elem.dirty = this.dirty;
    elem.storeIndex = this.storeIndex;
    return elem;
  }
  /**
   * Appends a graphics command object to the graphics queue.
   * Command objects expose an "exec" method that accepts two parameters: the {@link CanvasRenderingContext2D} to operate on.
   * @param command - A graphics command object exposing an "exec" method.
   * @param clean - A value of true indicates that a command does not generate a path that should be stroked or filled.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  append(command, clean = false) {
    this.activeInstructions.push(command);
    this.command = command;
    if (!clean) {
      this.dirty = true;
    }
    return this;
  }
  /**
   * Updates the instructions lists.
   * @param commit - if true will update the {@link commitIndex} and clear the {@link activeInstructions} list.
   */
  updateInstructions(commit = false) {
    if (this.dirty && this.activeInstructions.length) {
      this.instructions.length = this.commitIndex; // remove old, uncommitted commands
      this.instructions.push(new BeginPathCmd());
      const l = this.activeInstructions.length;
      const ll = this.instructions.length;
      this.instructions.length = ll + l;
      for (let i = 0; i < l; i++) {
        this.instructions[i + ll] = this.activeInstructions[i];
      }
      if (this.fill && this.textFill || this.stroke && this.textStroke) {
        if (this.textStyle && this.textStyle !== this.oldTextStyle) {
          this.instructions.push(this.textStyle);
        }
        if (commit) {
          this.oldTextStyle = this.textStyle;
        }
      }
      if (this.fill) {
        if (this.textFill) {
          this.instructions.push(this.textFill);
        }
        this.instructions.push(this.fill);
      }
      if (this.stroke) {
        if (this.textStroke) {
          this.instructions.push(this.textStroke);
        }
        if (this.strokeDash && this.strokeDash !== this.oldStrokeDash) {
          this.instructions.push(this.strokeDash);
        }
        if (this.strokeStyle && this.strokeStyle !== this.oldStrokeStyle) {
          this.instructions.push(this.strokeStyle);
        }
        if (commit) {
          this.oldStrokeStyle = this.strokeStyle;
          this.oldStrokeDash = this.strokeDash;
        }
        this.instructions.push(this.stroke);
      }
      this.dirty = false;
    }
    if (commit) {
      this.activeInstructions.length = 0;
      this.commitIndex = this.instructions.length;
    }
  }
  /**
   * Adds a {@link FillCmd} command and updates the {@link instructions}.
   * @param fill - a {@link FillCmd} to add.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  setFill(fill) {
    this.updateInstructions(true);
    this.command = fill;
    this.fill = fill;
    return this;
  }
  /**
   * Adds a {@link StrokeCmd} command and updates the {@link instructions}.
   * @param stroke - a {@link StrokeCmd} to add.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  setStroke(stroke) {
    this.updateInstructions(true);
    this.command = stroke;
    this.stroke = stroke;
    return this;
  }
  /**
   * Adds a {@link TextFillCmd} command and updates the {@link instructions}.
   * @param textFill - a {@link TextFillCmd} to add.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  setTextFill(textFill) {
    this.updateInstructions(true);
    this.command = textFill;
    this.textFill = textFill;
    return this;
  }
  /**
   * Adds a {@link TextStrokeCmd} command and updates the {@link instructions}.
   * @param textStroke - a {@link TextStrokeCmd} to add.
   * @returns - Returns this {@link Graphics} instance. Useful to chain calls.
   */
  setTextStroke(textStroke) {
    this.updateInstructions(true);
    this.command = textStroke;
    this.textStroke = textStroke;
    return this;
  }
  /**
   * Returns a string representation of this object.
   * @returns a string representation of this object.
   */
  toString() {
    return '[Graphics]';
  }
}

/**
 * DisplayObject is the base class for all display classes in Surface library. It defines the core properties and
 * methods that are shared between all display objects, such as transformation properties ({@link x}, {@link y}, {@link scale}, {@link rotation},  etc),
 * interactivity ({@link interactive}, {@link draggable}, {@link selectable}), isometry and culling (check: {@link CullingManager}).
 * @example
 * ```
 * const elem = new DisplayObject(50, 100);
 * elem.scale = 2;
 *
 * // creates a DisplayObject at Point(50, 100) and then applies a scale = 2 transformation (twice the size)
 * // All transformations happen 'around' the registration point defined by regX and regY (0,0 by default)
 * ```
 */
class DisplayObject extends EventEmitter {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param width - width of the {@link DisplayObject} instance.
   * @param height - height of the {@link DisplayObject} instance.
   */
  constructor(x = 0, y = 0, width = 10, height = 10) {
    super();
    /**
     * Unique ID of this {@link DisplayObject} instance.
     */
    this.id = generateID();
    /**
     * x coordinate (horizontal) in a cartesian plane.
     */
    this._x = 0;
    /**
     * y coordinate (vertical) in a cartesian plane.
     */
    this._y = 0;
    /**
     * zIndex determines the 'depth' of this {@link DisplayObject} instance in the display list.
     * The zIndex value will determine the position of the {@link DisplayObject} instance in the parent {@link DisplayObjectContainer.children} array,
     * which is used for determining the rendering order.
     */
    this._zIndex = 0;
    /**
     * x coordinate (horizontal) of the registration point in a cartesian plane.
     * The registration point is the 'origin' point used for any transform.
     */
    this._regX = 0;
    /**
     * y coordinate (vertical) of the registration point in a cartesian plane.
     * The registration point is the 'origin' point used for any transform.
     */
    this._regY = 0;
    /**
     * width of the {@link DisplayObject} instance.
     * Used for bounds calculation.
     */
    this._width = 0;
    /**
     * height of the {@link DisplayObject} instance.
     * Used for bounds calculation.
     */
    this._height = 0;
    /**
     * rotation of the {@link DisplayObject} instance.
     */
    this._rotation = 0;
    /**
     * scale of the {@link DisplayObject} instance.
     */
    this._scale = 1;
    /**
     * opacity of the {@link DisplayObject} instance.
     */
    this._opacity = 1;
    /**
     * Determines if the {@link DisplayObject} instance will be rendered or not.
     */
    this._visible = true;
    /**
     * Determines if the {@link DisplayObject} instance will be interactive.
     * If a {@link DisplayObject} is interactive it can receive user interaction events.
     */
    this._interactive = false;
    /**
     * Determines if the {@link DisplayObject} instance will be draggable.
     * If it's set to true then the {@link DisplayObject} can be dragged clicking and holding the left mouse button or touching and holding.
     */
    this._draggable = false;
    /**
     * Determines if the {@link DisplayObject} instance can gain focus.
     */
    this._focusable = false;
    /**
     * Determines if the {@link DisplayObject} instance can be selected.
     */
    this._selectable = false;
    /**
     * Determines if the {@link DisplayObject} uses the {@link CameraManager.projectionMatrix} to apply its transform.
     * If set to false only its position ({@link x}, {@link y}) will be affected by the {@link CameraManager.projectionMatrix}.
     * It's usefull to make objects be in the right place when {@link Surface.isometry} is activated but without deforming the object.
     */
    this._isometric = false;
    /**
     * Determines if the 'intensity' of the isometry applied.
     * Check {@link IsometricMatrix} and {@link IsometricMatrix.strength} for more details.
     */
    this._isometry = 0;
    /** A {@link Shadow} object that defines the shadow to render on this display object. Set to `null` to remove a shadow */
    this._shadow = null;
    /**
     * Determines if the {@link DisplayObject.transform} will be snapped to whole pixels when updating the canvas context
     * Used to prevent antialising blurring/artifacts, especially with Bitmaps.
     */
    this.snapToPixel = true;
    /**
     * Used by the {@link CullingManager}, determines if an object must be culled (rendered when outside the viewport bounds)
     */
    this._culled = false;
    /**
     * {@link Graphics} object to draw the debug.
     */
    this.debugGraphics = new Graphics();
    /**
     * A user defined custom hitArea of type {@link Rectangle} (you can also use array of {@link Rectangle}) that will be used by the hit detection and dragging systems.
     */
    this.hitArea = null;
    this._x = x;
    this._y = y;
    this._regX = 0;
    this._regY = 0;
    this._width = width;
    this._height = height;
    this._transform = new Matrix();
    this._bounds = {
      localBounds: new Rectangle(0, 0, this._width, this._height),
      transformedBounds: new Rectangle(0, 0, this._width, this._height),
      globalBounds: new Rectangle(0, 0, this._width, this._height)
    };
    this.style = new Proxy(new Style(this), {
      set: (target, key, value) => {
        if (target[key] !== value) {
          // without typecast, TS complains about assignment to `never`
          target[key] = value;
          this.update();
          this.emit('stylechange', key);
        }
        return true;
      }
    });
    this.animations = new Animations(this);
    this.invalidate();
  }
  /**
   * Name of the constructor of {@link DisplayObject} instance.
   */
  get name() {
    return this.constructor.name;
  }
  /**
   * Gets the x coordinate (horizontal) in a cartesian plane.
   */
  get x() {
    return this._x;
  }
  /**
   * Sets the x coordinate (horizontal) in a cartesian plane and applies it to the {@link transform} matrix.
   */
  set x(value) {
    if (value === this._x) return;
    this._x = value;
    this.invalidate();
    this.bubble('move');
  }
  /**
   * Gets the y coordinate (horizontal) in a cartesian plane.
   */
  get y() {
    return this._y;
  }
  /**
   * Sets the y coordinate (horizontal) in a cartesian plane and applies it to the {@link transform} matrix.
   */
  set y(value) {
    if (value === this._y) return;
    this._y = value;
    this.invalidate();
    this.bubble('move');
  }
  /**
   * Gets the zIndex of this {@link DisplayObject} instance.
   * The zIndex value will determine the position of the {@link DisplayObject} instance in the parent {@link DisplayObjectContainer.children} array,
   * which is used for determining the rendering order.
   */
  get zIndex() {
    return this._zIndex;
  }
  /**
   * Sets the zIndex of this {@link DisplayObject} instance.
   * The zIndex value will determine the position of the {@link DisplayObject} instance in the parent {@link DisplayObjectContainer.children} array,
   * which is used for determining the rendering order.
   */
  set zIndex(value) {
    if (value === this._zIndex) return;
    this._zIndex = value;
    if (this.parent) {
      this.parent.sortChildren();
    }
    this.invalidate();
    this.bubble('move');
  }
  /**
   * Gets the x coordinate (horizontal) of the registration point in a cartesian plane.
   * The registration point is the 'origin' point used for any transform.
   */
  get regX() {
    return this._regX;
  }
  /**
   * Sets the x coordinate (horizontal) of the registration point in a cartesian plane and applies it to the {@link transform} matrix.
   * The registration point is the 'origin' point used for any transform.
   */
  set regX(value) {
    if (value === this._regX) return;
    this._regX = value;
    this.invalidate();
    this.bubble('update');
  }
  /**
   * Gets the y coordinate (vertical) of the registration point in a cartesian plane.
   * The registration point is the 'origin' point used for any transform.
   */
  get regY() {
    return this._regY;
  }
  /**
   * Sets the y coordinate (vertical) of the registration point in a cartesian plane and applies it to the {@link transform} matrix.
   * The registration point is the 'origin' point used for any transform.
   */
  set regY(value) {
    if (value === this._regY) return;
    this._regY = value;
    this.invalidate();
    this.bubble('update');
  }
  /**
   * Gets the width of the {@link DisplayObject} instance.
   * Used for bounds calculation.
   */
  get width() {
    return this._width;
  }
  /**
   * Sets the width of the {@link DisplayObject} instance.
   * Used for bounds calculation.
   */
  set width(value) {
    if (value === this._width) return;
    this._width = value;
    this.invalidate();
    this.bubble('resize');
  }
  /**
   * Gets the height of the {@link DisplayObject} instance.
   * Used for bounds calculation.
   */
  get height() {
    return this._height;
  }
  /**
   * Sets the height of the {@link DisplayObject} instance.
   * Used for bounds calculation.
   */
  set height(value) {
    if (value === this._height) return;
    this._height = value;
    this.invalidate();
    this.bubble('resize');
  }
  /**
   * Gets the rotation of the {@link DisplayObject} instance.
   */
  get rotation() {
    return this._rotation;
  }
  /**
   * Sets the rotation of the {@link DisplayObject} instance and applies it to the {@link transform} matrix.
   */
  set rotation(value) {
    if (value === this._rotation) return;
    this._rotation = value;
    this.invalidate();
    this.bubble('update');
  }
  /**
   * Gets the scale of the {@link DisplayObject} instance.
   */
  get scale() {
    return this._scale;
  }
  /**
   * Sets the scale of the {@link DisplayObject} instance and applies it to the {@link transform} matrix.
   */
  set scale(value) {
    if (value === this._scale) return;
    this._scale = value;
    this.invalidate();
    this.bubble('update');
  }
  /**
   * Sets the isometry of the {@link DisplayObject} instance.
   * Determines how much 'intensity' of the isometry is applied.
   * Check {@link IsometricMatrix} and {@link IsometricMatrix.strength} for more details.
   */
  set isometry(value) {
    if (value === this._isometry) return;
    this._isometry = value;
    this.invalidate();
    this.bubble('update');
  }
  /**
   * Gets the {@link Shadow} of the {@link DisplayObject} instance.
   */
  get shadow() {
    return this._shadow;
  }
  /**
   * Gets the {@link Shadow} of the {@link DisplayObject} instance.
   */
  set shadow(value) {
    if (value === this._shadow) return;
    this._shadow = value;
    this.invalidate();
    this.bubble('update');
  }
  /**
   * Gets the transform {@link Matrix} of the {@link DisplayObject} instance.
   * The transform matrix will determine how the {@link DisplayObject} is rendered in the canvas.
   */
  get transform() {
    return this._transform;
  }
  /**
   * Gets the bounds object of the {@link DisplayObject} instance, containing the {@link bounds.localBounds}, {@link bounds.transformBounds} and {@link bounds.globalBounds}.
   */
  get bounds() {
    return this._bounds;
  }
  /**
   * Gets the opacity of the {@link DisplayObject} instance.
   */
  get opacity() {
    return this._opacity;
  }
  /**
   * Sets the opacity of the {@link DisplayObject} instance.
   */
  set opacity(value) {
    if (this._opacity === value) return;
    this._opacity = value;
    this.bubble('update');
  }
  /**
   * Gets the visibility state of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} instance will be rendered or not.
   */
  get visible() {
    return this._visible;
  }
  /**
   * Sets the visibility state of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} instance will be rendered or not.
   */
  set visible(value) {
    if (this._visible === value) return;
    this._visible = value;
    this.bubble('update');
  }
  /**
   * Gets the culled value of the {@link DisplayObject} instance.
   * Used by the {@link CullingManager}, determines if an object must be culled (rendered when outside the viewport bounds)
   */
  get culled() {
    return this._culled;
  }
  /**
   * Sets the culled value of the {@link DisplayObject} instance.
   * Used by the {@link CullingManager}, determines if an object must be culled (rendered when outside the viewport bounds)
   */
  set culled(value) {
    if (this._culled === value) return;
    this._culled = value;
  }
  /**
   * Gets the draggable value of the {@link DisplayObject} instance.
   * If it's set to true then the {@link DisplayObject} can be dragged clicking and holding the left mouse button or touching and holding.
   */
  get draggable() {
    return this._draggable;
  }
  /**
   * Sets the draggable value of the {@link DisplayObject} instance.
   * If it's set to true then the {@link DisplayObject} can be dragged clicking and holding the left mouse button or touching and holding.
   */
  set draggable(value) {
    if (this._draggable === value) return;
    this._draggable = value;
  }
  /**
   * Gets the focusable value of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} instance can gain focus.
   */
  get focusable() {
    return this._focusable;
  }
  /**
   * Sets the focusable value of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} instance can gain focus.
   */
  set focusable(value) {
    if (this._focusable === value) return;
    this._focusable = value;
  }
  /**
   * Gets the interactive value of the {@link DisplayObject} instance.
   * If a {@link DisplayObject} is interactive it can receive user interaction events.
   */
  get interactive() {
    return this._interactive;
  }
  /**
   * Sets the interactive value of the {@link DisplayObject} instance.
   * If a {@link DisplayObject} is interactive it can receive user interaction events.
   */
  set interactive(value) {
    if (this._interactive === value) return;
    this._interactive = value;
  }
  /**
   * Gets the isometric value of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} uses the {@link CameraManager.projectionMatrix} to apply its transform.
   * If set to false only its position ({@link x}, {@link y}) will be affected by the {@link CameraManager.projectionMatrix}.
   * It's usefull to make objects be in the right place when {@link Surface.isometry} is activated but without deforming the object.
   */
  get isometric() {
    return this._isometric;
  }
  /**
   * Sets the isometric value of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} uses the {@link CameraManager.projectionMatrix} to apply its transform.
   * If set to false only its position ({@link x}, {@link y}) will be affected by the {@link CameraManager.projectionMatrix}.
   * It's usefull to make objects be in the right place when {@link Surface.isometry} is activated but without deforming the object.
   */
  set isometric(value) {
    if (this._isometric === value) return;
    this._isometric = value;
    this.bubble('update');
  }
  /**
   * Gets the selectable value of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} instance can be selected.
   */
  get selectable() {
    return this._selectable;
  }
  /**
   * Sets the selectable value of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} instance can be selected.
   */
  set selectable(value) {
    if (this._selectable === value) return;
    this._selectable = value;
  }
  /**
   * Gets the selected value of the {@link DisplayObject} instance.
   * Determines if the {@link DisplayObject} instance is selected.
   */
  get selected() {
    var _a;
    return this.stage && ((_a = this.stage) === null || _a === void 0 ? void 0 : _a.surface.input.selected.indexOf(this)) !== -1 || false;
  }
  /**
   * Gets the stage of the {@link DisplayObject} instance.
   * If the {@link DisplayObject} is not attached to the {@link Stage} or a {@link DisplayObjectContainer} with a {@link DisplayObjectContainer.stage} it will return `undefined`.
   */
  get stage() {
    if (!this.parent) return undefined;
    return this.parent.stage;
  }
  /**
   * Gets the top most element in the {@link DisplayObject} instance display list, excluding the {@link Stage}.
   * That means that the value returned can be the {@link DisplayObject} instance if it's directly attached to the {@link Stage}.
   */
  get top() {
    var _a;
    if (!this.parent) return undefined;
    if ((_a = this.parent) === null || _a === void 0 ? void 0 : _a.isStage()) {
      return this;
    }
    return this.parent.top;
  }
  /**
   * Gets the left, right, top and bottom bounds of the {@link DisplayObject} instance in coordinates relative to the Html canvas element (document coordinates).
   */
  get documentAnchors() {
    const localLeft = new Point(0, this.height / 2);
    const localRight = new Point(this.width, this.height / 2);
    const localTop = new Point(this.width / 2, 0);
    const localBottom = new Point(this.width / 2, this.height);
    return {
      left: this.localToDocument(localLeft.x, localLeft.y),
      right: this.localToDocument(localRight.x, localRight.y),
      top: this.localToDocument(localTop.x, localTop.y),
      bottom: this.localToDocument(localBottom.x, localBottom.y)
    };
  }
  /**
   * Returns the the `(0,0)` local coordinates of the {@link DisplayObject} instance relative to the Html canvas element (document coordinates).
   */
  get documentCoords() {
    return this.localToDocument(0, 0);
  }
  /**
   * Clears the animations of {@link DisplayObject} instance.
   */
  destroy() {
    this.animations.destroy();
  }
  /**
   * Attaches the {@link DisplayObject} instance to the {@link DisplayObjectContainer} passed as parameter.
   * @param container - the {@link DisplayObjectContainer} where the {@link DisplayObject} instance will be attached.
   */
  attachTo(container) {
    container.attach(this);
  }
  /**
   * Returns true if this object localBounds fully encloses the described {@link Point} or {@link Rectangle}.
   * {@link localBounds} is the bounds of the object relative to its local coordinate space with no transforms applied.
   */
  contains(point) {
    if (this.hitArea) {
      if (Array.isArray(this.hitArea)) {
        return this.hitArea.some(rect => rect.contains(point));
      }
      return this.hitArea.contains(point);
    }
    return this.bounds.localBounds.contains(point);
  }
  /**
   * Detaches the {@link DisplayObject} instance from its {@link parent}.
   * @returns This {@link DisplayObject} instance. Useful for chaining methods.
   */
  detach() {
    if (!this.parent) return this;
    if (this.parent) {
      this.parent.detach(this);
    }
    return this;
  }
  /**
   * Focuses the {@link DisplayObject} instance.
   */
  focus() {
    /* TODO */
  }
  /**
   * Moves the {@link DisplayObject} instance to the provided {@link x} and {@link y} values.
   * If a `duration` is provided (in milliseconds) a tween animation will be created.
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param duration - duration, in milliseconds, of the tween animation. If this parameter is omited there will be no animation created.
   * @returns This {@link DisplayObject} instance. Useful for chaining methods.
   */
  move(x, y, duration = 0) {
    if (x instanceof Point) {
      y = x.y;
      x = x.x;
    }
    if (this.x === x && this.y === y) return undefined;
    if (y === undefined) {
      throw new Error('Parsing point failed');
    }
    if (duration > 0) {
      new Animation(this, {
        x,
        y
      }, {
        interrupt: true,
        easing: 'easeOutCirc',
        duration
      });
      return this;
    }
    // We're setting private values directly to avoid "move" event to be emitted multiple times
    this._x = x;
    this._y = y;
    this.invalidate();
    this.bubble('move');
    return this;
  }
  /**
   * Resizes the {@link DisplayObject} instance with the provided {@link width} and {@link height} values.
   * If a `duration` is provided (in milliseconds) a tween animation will be created.
   * @param width - width of the {@link DisplayObject} instance.
   * @param height - height of the {@link DisplayObject} instance.
   * @param duration - duration, in milliseconds, of the tween animation. If this parameter is omited there will be no animation created.
   * @returns This {@link DisplayObject} instance. Useful for chaining methods.
   */
  resize(width, height, duration = 0) {
    if (this.width === width && this.height === height) return undefined;
    if (duration > 0) {
      new Animation(this, {
        width,
        height
      }, {
        interrupt: true,
        easing: 'easeOutCirc',
        duration
      });
    }
    // We're setting private values directly to avoid "resize" event to be emitted multiple times
    this._width = width;
    this._height = height;
    this.invalidate();
    this.bubble('resize');
    return this;
  }
  /**
   * Updates the isometry value of the {@link DisplayObject} instance in order to trigger an {@link updateTransform}.
   */
  updateIsometry(value) {
    this.isometry = value;
  }
  /**
   * Recalculates the {@link transform} matrix of the {@link DisplayObject} instance.
   */
  invalidate() {
    this.updateTransform();
  }
  /**
   * Updates the {@link transform} matrix of the {@link DisplayObject} instance taking all local transform
   * properties ({@link x}, {@link y}, {@link regX}, {@link regY}, {@link scale}, {@link rotation}) into account.
   */
  updateTransform() {
    var _a;
    this._transform.reset();
    // Apply the projection matrix
    if (this.stage && ((_a = this.parent) === null || _a === void 0 ? void 0 : _a.isStage())) {
      if (this._isometric) {
        this._transform.prependMatrix(this.stage.surface.camera.projectionMatrix);
      } else {
        // If isometric is false we don't want to deform all the object, just x and y
        const point = this.stage.surface.camera.projectionMatrix.transformPoint(this.x, this.y);
        const matrix = new Matrix();
        matrix.translate(point.x - this.x, point.y - this.y);
        this._transform.prependMatrix(matrix);
      }
    }
    this._transform.appendTransform(this.x, this.y, this.scale, this.scale, this.rotation, this.regX, this.regY);
  }
  /**
   * Transforms the specified x and y position from the coordinate space of this {@link DisplayObject}
   * to the global ({@link Stage}) coordinate space. Returns a {@link Point} instance
   * correlating to the transformed coordinates on the stage.
   * @param x - local (this {@link DisplayObject}) x coordinate (horizontal) in a cartesian plane.
   * @param y - local (this {@link DisplayObject}) y coordinate (vertical) in a cartesian plane.
   */
  localToGlobal(x, y) {
    return this.getConcatenatedMatrix(this._transform).transformPoint(x, y);
  }
  /**
   * Transforms the specified x and y position from the global ({@link Stage}) coordinate space to the
   * coordinate space of this {@link DisplayObject}.
   * For example, this could be used to determine the current pointer position within this {@link DisplayObject}.
   * Returns a {@link Point} instance correlating to the transformed position in this {@link DisplayObject}'s coordinate space.
   * @param x - global ({@link Stage}) x coordinate (horizontal) in a cartesian plane.
   * @param y - global ({@link Stage}) y coordinate (vertical) in a cartesian plane.
   */
  globalToLocal(x, y) {
    return this.getConcatenatedMatrix(this._transform).invert().transformPoint(x, y);
  }
  /**
   * Transforms the specified x and y position from the coordinate space of this {@link DisplayObject} to the coordinate
   * space of the target {@link DisplayObject}. Returns a {@link Point} instance correlating to the
   * transformed position in the target's coordinate space.
   * @param x - local (this {@link DisplayObject}) x coordinate (horizontal) in a cartesian plane.
   * @param y - local (this {@link DisplayObject}) y coordinate (vertical) in a cartesian plane.
   * @param target - target {@link DisplayObject} to make the `local-to-local` coordinate transform.
   */
  localToLocal(x, y, target) {
    const point = this.localToGlobal(x, y);
    return target.globalToLocal(point.x, point.y);
  }
  /**
   * Transforms the specified x and y position from the global ({@link Stage}) coordinate space
   * to the coordinate space of the Html document (relative to the canvas object).
   * For example, this could be used to position an HTML label over a specific point on the {@link Stage}.
   * @param x - global ({@link Stage}) x coordinate (horizontal) in a cartesian plane.
   * @param y - global ({@link Stage}) y coordinate (vertical) in a cartesian plane.
   */
  globalToDocument(x, y) {
    var _a;
    const point = new Point(x, y);
    const matrix = new Matrix();
    const camera = (_a = this.stage) === null || _a === void 0 ? void 0 : _a.surface.camera;
    if (camera) {
      matrix.prependTransform(camera.center.x / window.devicePixelRatio + camera.x, camera.center.y / window.devicePixelRatio + camera.y, camera.zoom, camera.zoom, 0, 0, 0);
    }
    return matrix.transformPoint(point.x, point.y);
  }
  /**
   * Transforms the specified x and y position from the coordinate space of this {@link DisplayObject}
   * to the coordinate space of the Html document (relative to the canvas object).
   * For example, this could be used to position an HTML label over a specific point on a nested {@link DisplayObject}.
   * @param x - local (this {@link DisplayObject}) x coordinate (horizontal) in a cartesian plane.
   * @param y - local (this {@link DisplayObject}) y coordinate (vertical) in a cartesian plane.
   */
  localToDocument(x, y) {
    const point = this.localToGlobal(x, y);
    return this.globalToDocument(point.x, point.y);
  }
  /**
   * Transforms the specified x and y position from the coordinate space of the Html document (relative to the canvas object)
   * to the coordinate space of this {@link DisplayObject}.
   * For example, this could be used to position an object attached to the {@link Stage} under the mouse cursor.
   * @param x - Html document (relative to the canvas object) x coordinate (horizontal) in a cartesian plane.
   * @param y - Html document (relative to the canvas object) y coordinate (vertical) in a cartesian plane.
   */
  documentToGlobal(x, y) {
    var _a;
    let point = new Point(x, y);
    const camera = (_a = this.stage) === null || _a === void 0 ? void 0 : _a.surface.camera;
    if (camera) {
      point = new Point(x, y).translate(-camera.center.x / window.devicePixelRatio, -camera.center.y / window.devicePixelRatio).translate(-camera.x, -camera.y).scale(1 / camera.zoom);
    }
    return point;
  }
  /**
   * Transforms the specified x and y position from the coordinate space of the Html document (relative to the canvas object)
   * to the global ({@link Stage}) coordinate space.
   * For example, this could be used to determine the current mouse pointer position within this {@link DisplayObject}.
   * @param x - Html document (relative to the canvas object) x coordinate (horizontal) in a cartesian plane.
   * @param y - Html document (relative to the canvas object) y coordinate (vertical) in a cartesian plane.
   */
  documentToLocal(x, y) {
    const point = this.documentToGlobal(x, y);
    return this.globalToLocal(point.x, point.y);
  }
  /**
   * Generates a {@link Matrix} object representing the combined transform of the {@link DisplayObject} instance and all of its
   * parents up to the highest level ancestor.
   */
  getConcatenatedMatrix(matrix) {
    let elem = this;
    const mtx = matrix.clone();
    while (elem.parent) {
      elem = elem.parent;
      mtx.prependMatrix(elem.transform);
    }
    return mtx;
  }
  /**
   * Updates the bounds of the {@link DisplayObject} instance, including the {@link localBounds}, {@link transformedBounds} and {@link globalBounds}.
   */
  updateBounds() {
    this._bounds.localBounds = new Rectangle(0, 0, this._width, this._height);
    this._bounds.transformedBounds = this.getTransformedBounds();
    const mtx = this.getConcatenatedMatrix(new Matrix());
    this._bounds.globalBounds = this.transformBounds(this._bounds.localBounds, mtx);
    return this._bounds.transformedBounds;
  }
  /**
   * Recalculates and returns a copy of the {@link transformedBounds} of the {@link DisplayObject} instance.
   */
  getTransformedBounds() {
    return this.transformBounds(this.bounds.localBounds.clone());
  }
  /**
   * Transforms the bounds {@link Rectangle} passed in the argument using the {@link transform} matrix of the {@link DisplayObject} instance.
   * If no bounds are passed then it will take the {@link DisplayObject.bounds.localBounds} of the instance.
   * If a matrix is passed in the parameters it will prepend it to the resulting transform.
   * @param bounds - bounds {@link Rectangle} that will be transformed. If none is provided it will take the {@link DisplayObject.bounds.localBounds} of the instance.
   * @param matrix - a matrix that will be prepended for the transfomed bounds calculation.
   */
  transformBounds(bounds = null, matrix = null) {
    const rect = new Rectangle();
    let width;
    let height;
    let x;
    let y;
    const mtx = this._transform.clone();
    if (!bounds) {
      bounds = this.bounds.localBounds.clone();
      x = bounds.x;
      y = bounds.y;
      width = this.width;
      height = this.height;
    } else {
      x = bounds.x;
      y = bounds.y;
      width = bounds.width;
      height = bounds.height;
    }
    if (x || y) mtx.appendTransform(0, 0, 1, 1, 0, -x, -y);
    if (matrix) mtx.prependMatrix(matrix);
    const xa = width * mtx.m[0];
    const xb = width * mtx.m[1];
    const yc = height * mtx.m[2];
    const yd = height * mtx.m[3];
    const tx = mtx.m[4];
    const ty = mtx.m[5];
    let minX = tx;
    let maxX = tx;
    let minY = ty;
    let maxY = ty;
    x = xa + tx;
    if (x < minX) {
      minX = x;
    } else if (x > maxX) {
      maxX = x;
    }
    x = xa + yc + tx;
    if (x < minX) {
      minX = x;
    } else if (x > maxX) {
      maxX = x;
    }
    x = yc + tx;
    if (x < minX) {
      minX = x;
    } else if (x > maxX) {
      maxX = x;
    }
    y = xb + ty;
    if (y < minY) {
      minY = y;
    } else if (y > maxY) {
      maxY = y;
    }
    y = xb + yd + ty;
    if (y < minY) {
      minY = y;
    } else if (y > maxY) {
      maxY = y;
    }
    y = yd + ty;
    if (y < minY) {
      minY = y;
    } else if (y > maxY) {
      maxY = y;
    }
    return rect.setValues(minX, minY, maxX - minX, maxY - minY);
  }
  /**
   * Applies the {@link DisplayObject.transform} and the {@link opacity} of the instance to the specified Canvas2D context.
   */
  updateContext(ctx) {
    const mtx = this._transform;
    let tx = mtx.m[4];
    let ty = mtx.m[5];
    if (this.snapToPixel) {
      tx = tx + (tx < 0 ? -0.5 : 0.5) | 0;
      ty = ty + (ty < 0 ? -0.5 : 0.5) | 0;
    }
    ctx.transform(mtx.m[0], mtx.m[1], mtx.m[2], mtx.m[3], tx, ty);
    ctx.globalAlpha *= this.opacity;
    if (this.shadow) {
      this.applyShadow(ctx, this.shadow);
    }
  }
  /**
   * Applies the shadow of this {@link DisplayObject} to the canvas context.
   * @param ctx - canvas context.
   * @param shadow - the {@link Shadow} object to apply.
   */
  applyShadow(ctx, shadow) {
    shadow = shadow || Shadow.clear;
    ctx.shadowBlur = shadow.shadowBlur;
    ctx.shadowColor = shadow.shadowColor;
    ctx.shadowOffsetX = shadow.shadowOffsetX;
    ctx.shadowOffsetY = shadow.shadowOffsetY;
  }
  /**
   * Renders the {@link DisplayObject} on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    if ((options === null || options === void 0 ? void 0 : options.debug) && this.stage && !this.isStage()) {
      // draw bounds of the element
      this.debugGraphics.setStrokeStyle(this.stage.surface.input.hover === this ? 3 : 1).setStrokeDash([5]).beginStroke(this.stage.surface.input.hover === this ? 'rgba(255,0,0,0.3)' : 'rgba(51,51,51,0.3)').drawRect(this._bounds.localBounds.x, this._bounds.localBounds.y, this._bounds.localBounds.width, this._bounds.localBounds.height).setStrokeDash().endStroke();
      if (this.hitArea && !Array.isArray(this.hitArea)) {
        this.debugGraphics.setStrokeStyle(this.stage.surface.input.hover === this ? 3 : 1).setStrokeDash([5]).beginStroke(this.stage.surface.input.hover === this ? 'rgba(255,0,0,0.3)' : 'rgba(51,51,51,0.3)').beginFill('rgba(39,219,245,0.2)').drawRect(this.hitArea.x, this.hitArea.y, this.hitArea.width, this.hitArea.height).setStrokeDash().endFill().endStroke();
      }
      // draw overlay on element when hovered
      if (this.stage.surface.input.hover === this) {
        this.debugGraphics.beginFill('rgba(205,217,244,0.2)').drawRect(this._bounds.localBounds.x, this._bounds.localBounds.y, this._bounds.localBounds.width, this._bounds.localBounds.height).endFill();
      }
      // draw class Name of the element
      this.debugGraphics.beginFill('#333').setTextStyle('12px Inter', 'left', 'top').drawText(this.constructor.name).endFill();
      // Show original coordinates if the element is of type Shape
      if (this.constructor.name !== 'Shape') {
        this.debugGraphics.beginFill('#333').setTextStyle('10px Inter', 'left', 'top').drawText(`[${~~this.x},${~~this.y}]`, this.regX + 12, this.regY + 20).endFill();
      }
      // draw the origin of the element
      this.debugGraphics.beginFill('#fecd5f').drawCircle(this.regX, this.regY, 2).endFill();
      this.debugGraphics.draw(ctx);
      this.debugGraphics.clear();
    }
  }
  /**
   * Serializes this {@link DisplayObject} instance
   */
  serialize() {
    return {
      id: this.id,
      surface: {
        type: this.constructor.name,
        position: {
          x: this.x,
          y: this.y
        },
        zIndex: this.zIndex,
        diemnsions: {
          width: this.width,
          height: this.height
        },
        draggable: this.draggable,
        focusable: this.focusable,
        interactive: this.interactive,
        isometric: this.isometric,
        visible: this.visible
      }
    };
  }
  /**
   * A {@link DisplayObject} can't be a {@link Stage}, so this method will always return `false`
   */
  isStage() {
    return false;
  }
  /**
   * Forces a Surface redraw in the next render loop.
   */
  update() {
    this.bubble('update');
  }
}

/**
 * DisplayObjectContainer is a nestable display list that allows you to work with compound display elements.
 * For example you could group several {@link DisplayObject} instances together and
 * transform them as a group, while still being able to move the individual {@link DisplayObject} children relative to each other.
 * Children of containers have their {@link DisplayObject.transfom} and {@link DisplayObject.opacity} properties concatenated with their parent {@link DisplayObjectContainer}.
 * @example
 * ```
 * const container = new DisplayObjectContainer(50, 100);
 * const child1 = new DisplayObject(0,0);
 * const child2 = new DisplayObject(100,100);
 * container.attach(child1);
 * container.attach(child2);
 * surface.stage.attach(container);
 *
 * // creates a DisplayObjectContainer at Point(50, 100), and attaches two children at Point(0, 0) and Point(100,100).
 * // The DisplayObjectContainer is then attached to the stage, so the children will be at Point(0, 0) and Point(150,200) relative to the Stage.
 * ```
 */
class DisplayObjectContainer extends DisplayObject {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param width - width of the {@link DisplayObject} instance.
   * @param height - height of the {@link DisplayObject} instance.
   */
  constructor(x = 0, y = 0, width = 10, height = 10) {
    super(x, y, width, height);
    /**
     * The array of children in the display list.
     * You should usually use the child management methods such as {@link DisplayObjectContainer.attach} or {@link DisplayObjectContainer.detach}, etc,
     * rather than accessing this directly, but it is included for advanced uses.
     */
    this.children = [];
    /**
     * TODO - A placeholder for the bounds. Won't be necessary if we implement the new hitArea system.
     */
    this.placeholder = null;
    this.on('attachtostage', () => {
      this.children.forEach(child => child.emit('attachtostage'));
    });
    this.on('detachfromstage', () => {
      this.children.forEach(child => child.emit('detachfromstage'));
    });
    // TODO: Priority Rendering
  }
  /**
   * Attaches one or several children to the top of the display list of this {@link DisplayObjectContainer} instance.
   * @param children - A single child or an array of children to be attached.
   * @returns This {@link DisplayObjectContainer} instance. Useful for chaining methods.
   */
  attach(...children) {
    if (Array.isArray(children) && children.length > 1) {
      children.forEach(child => this.attach(child));
      return this;
    }
    const [child] = children;
    if (this.children.includes(child)) {
      return this;
    }
    if (child.parent) {
      child.detach();
    }
    child.parent = this;
    this.children.push(child);
    this.sortChildren();
    if (this.stage) {
      this.updateTransform();
    }
    this.updateBounds();
    child.bubble('attach');
    if (this.stage) child.emit('attachtostage');
    return this;
  }
  /**
   * Detaches one child from the display list of this {@link DisplayObjectContainer} instance.
   * @param child - A child to be attached.
   * @returns This {@link DisplayObjectContainer} instance. Useful for chaining methods.
   */
  detach(child) {
    if (arguments.length === 0) return super.detach();
    if (!child) return this;
    const index = this.children.indexOf(child);
    if (index === -1) return this;
    this.children = [...this.children.slice(0, index), ...this.children.slice(index + 1)];
    child.bubble('detach');
    if (this.stage) child.emit('detachfromstage');
    this.updateBounds();
    child.parent = undefined;
    child = undefined;
    return this;
  }
  /**
   * Sorts all the children in the {@link children} array based on their {@link zIndex} property (the higher nearer to end of the array and the more on top they will be rendered).
   */
  sortChildren() {
    this.children = this.children.sort((a, b) => {
      if (a.zIndex < b.zIndex) {
        return -1;
      }
      if (a.zIndex > b.zIndex) {
        return 1;
      }
      return 0;
    });
  }
  /**
   * Updates the isometry of self and of all the children down the display list sub-tree in order to trigger an {@link DisplayObject.updateIsometry} in all the affected elements.
   * @param value - value of the {@link isometry} property. Check {@link Surface.isometry} and {@link IsometricMatrix.strength} for more info.
   */
  updateIsometry(value) {
    super.updateIsometry(value);
    if (this.children) {
      this.children.forEach(child => {
        child.updateIsometry(value);
      });
    }
  }
  /**
   * Updates the transforms of self and of all the children down the display list sub-tree.
   */
  updateTransform() {
    super.updateTransform();
    if (this.children) {
      this.children.forEach(child => {
        child.updateTransform();
      });
    }
  }
  /**
   * Updates the bounds of self and of all the children down the display list sub-tree.
   */
  updateBounds() {
    let rect = new Rectangle();
    let count = 1;
    if (this.children.length > 0) {
      this.children.forEach(child => {
        let bounds = new Rectangle();
        bounds = child.updateBounds();
        if (count < 1) {
          rect.extend(bounds.x, bounds.y, bounds.width, bounds.height);
        } else {
          count -= 1;
          rect = bounds.clone();
        }
      });
    }
    this.bounds.localBounds = rect.clone();
    this.bounds.transformedBounds = this.transformBounds(rect);
    const mtx = this.getConcatenatedMatrix(new Matrix());
    this.bounds.globalBounds = this.transformBounds(this.bounds.localBounds, mtx);
    return this.bounds.transformedBounds;
  }
  /**
   * Find children by class type.
   * @param clazz - Class type to match.
   * @param nested - Wether the search must go inside nested {@link DisplayObjectContainer}s.
   */
  find(clazz, nested = false) {
    return this.children.reduce((result, child) => {
      if (child instanceof clazz) result.push(child);
      if (nested && child instanceof DisplayObjectContainer) result.push(...child.find(clazz, nested));
      return result;
    }, []);
  }
  /**
   * Detaches all children from the {@link DisplayObjectContainer} instance.
   */
  clear() {
    while (this.children.length) {
      this.detach(this.children[0]);
    }
    this.bubble('detach');
    return this;
  }
  /**
   * Checks if an object is a child of another one in the display list.
   * @param child - child to be checked against the {@link DisplayObjectContainer} instance.
   */
  hasChild(child) {
    let parent = child;
    while (parent) {
      if (parent === this) return true;
      if (parent.parent instanceof DisplayObject) {
        parent = parent.parent;
      } else {
        return false;
      }
    }
    return false;
  }
  /**
   * Serializes this {@link DisplayObjectContainer} instance
   */
  serialize() {
    const data = super.serialize();
    data.surface.children = this.children.map(c => c.serialize());
    return data;
  }
  /**
   * Returns the child at a global {@link Point} ({@link Stage} coordinate space), if there is any (returns `undefined` if none matches).
   * @param position - a {@link Point} in {@link Stage} coordinate space
   */
  childAt(position) {
    // Iterate children in reverse order so we respect the isometric order of objects
    for (let i = this.children.length - 1; i >= 0; i--) {
      const child = this.children[i];
      const positionLocal = child.globalToLocal(position.x, position.y);
      if (child instanceof DisplayObjectContainer && child.visible) {
        const found = child.childAt(position);
        if (found) {
          return found;
        }
        if (child.interactive && child.visible) {
          if (child.contains(positionLocal)) {
            return child;
          }
        }
      } else if (child.interactive && child.visible) {
        if (child.contains(positionLocal)) {
          return child;
        }
      }
    }
    return undefined;
  }
  /**
   * Returns an array of children at a global area {@link Rectangle} ({@link Stage} coordinate space), if there are any (returns empty array if none matches).
   * A child is considered to be at an area if {@link DisplayObject.bounds.localBounds.center} is contained in the provided {@link Rectangle}.
   * @param rectangle - the area where we seach for children
   * @param array - this array will be populated with the found matches
   */
  childrenAt(rectangle, array) {
    if (!array) {
      array = [];
    }
    // Iterate children in reverse order so we respect the isometric order of objects
    const l = this.children.length;
    for (let i = l - 1; i >= 0; i--) {
      const child = this.children[i];
      if (child instanceof DisplayObjectContainer) {
        if (child.interactive && child.visible && child.selectable) {
          if (rectangle.contains(child.localToGlobal(child.bounds.localBounds.center.x, child.bounds.localBounds.center.y))) {
            array.push(child);
            child.childrenAt(rectangle, array);
          }
        } else {
          child.childrenAt(rectangle, array);
        }
      } else if (child.interactive && child.visible && child.selectable) {
        if (rectangle.contains(child.localToGlobal(child.bounds.localBounds.center.x, child.bounds.localBounds.center.y))) {
          array.push(child);
        }
      }
    }
    return array;
  }
  /**
   * Renders the {@link DisplayObjectContainer} on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    super.render(ctx, options);
    if (this.children) {
      if (options === null || options === void 0 ? void 0 : options.debug) {
        this.debugGraphics.clear();
      }
      this.children.forEach(child => {
        if (child.visible && !child.culled && child.scale > 0 && child.opacity > 0) {
          // draw the child:
          ctx.save();
          child.updateContext(ctx);
          child.render(ctx, options);
          ctx.restore();
          if (options === null || options === void 0 ? void 0 : options.debug) {
            // Draws the local transformed bounds of the child
            this.debugGraphics.beginFill('rgba(255,0,0,0.05)').drawRect(child.bounds.transformedBounds.x, child.bounds.transformedBounds.y, child.bounds.transformedBounds.width, child.bounds.transformedBounds.height);
          }
        }
      });
      if (options === null || options === void 0 ? void 0 : options.debug) {
        this.debugGraphics.draw(ctx);
      }
    }
  }
  /**
   * Destroys all children of this {@link DisplayObjectContainer} instance and itself.
   */
  destroy() {
    super.destroy();
    this.children.forEach(child => {
      child.destroy();
    });
  }
}

/**
 * The Stage is the root level {@link DisplayObjectContainer} for the {@link Surface} display list.
 */
class Stage extends DisplayObjectContainer {
  /**
   * @param surface - A reference to the Surface instance.
   */
  constructor(surface) {
    super();
    this.surface = surface;
    this.animations = new Animations(this);
    this.stageAnimations = new StageAnimations(this);
    const invalidateSurface = this.invalidateSurface.bind(this);
    this.onBubbling('update', () => {
      this.invalidateSurface();
    });
    this.onBubbling('resize', invalidateSurface);
    this.onBubbling('move', invalidateSurface);
    this.onBubbling('attach', invalidateSurface);
    this.onBubbling('detach', invalidateSurface);
  }
  /**
   * The Stage is has the role to set {@link Surface.dirty} if any of its children has notified of a property change.
   * If the {@link Surface} is dirty then it will re-render everything.
   */
  invalidateSurface() {
    this.surface.dirty = true;
  }
  /**
   * The `stage` of the {@link Stage} instance is itself.
   */
  get stage() {
    return this;
  }
  /**
   * Gets the visible property of the {@link Stage} instance.
   * It will always be `true`.
   */
  get visible() {
    return true;
  }
  /**
   * Restricts setting the visible property of the {@link Stage} instance.
   * The {@link Stage} must be always visible.
   */
  set visible(value) {
    // Setting visibility is prohibited on Stage
  }
  /**
   * Returns if this object is the Stage.
   * It will always return `true`.
   */
  isStage() {
    return true;
  }
  /**
   * Gets the x property of the {@link Stage} instance.
   * The stage never really moves, it will always be `0`.
   */
  get x() {
    return 0;
  }
  /**
   * Instead of setting the x property the x setter will change the {@link CameraManager.x} value.
   */
  set x(value) {
    if (value === this.surface.camera.x) return;
    this.surface.camera.x = value;
  }
  /**
   * Gets the y property of the {@link Stage} instance.
   * The stage never really moves, it will always be `0`.
   */
  get y() {
    return 0;
  }
  /**
   * Instead of setting the y property the y setter will change the {@link CameraManager.y} value.
   */
  set y(value) {
    if (value === this.surface.camera.y) return;
    this.surface.camera.y = value;
  }
  /**
   * Gets the scale property of the {@link Stage} instance.
   * The stage never scales, it will always be `1`.
   */
  get scale() {
    return 1;
  }
  /**
   * Restricts setting the scale property of the {@link Stage} instance.
   * The {@link Stage} scale never changes.
   */
  set scale(value) {
    // Setting scale is prohibited on Stage
  }
  /**
   * Gets the rotation property of the {@link Stage} instance.
   * The stage never rotates, it will always be `0`.
   */
  get rotation() {
    return 0;
  }
  /**
   * Restricts setting the rotation property of the {@link Stage} instance.
   * The {@link Stage} rotation never changes.
   */
  set rotation(value) {
    // Setting rotation is prohibited on Stage
  }
  /**
   * Gets the zIndex property of the {@link Stage} instance.
   * The stage is the top most element in the display list and has only children and no siblings, so it will always be `0`.
   */
  get zIndex() {
    return 0;
  }
  /**
   * Restricts setting the zIndex property of the {@link Stage} instance.
   * The {@link Stage} zIndex never changes.
   */
  set zIndex(value) {
    // Setting zIndex is prohibited on Stage
  }
  /**
   * Renders the {@link Stage} on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    if (this.stageAnimations.length > 0) {
      this.stageAnimations.tick();
    }
    if (this.isStage()) {
      // TODO now it runs on every redraw only once as it checks if it's the stage, that should be optimized so no all display objects are recalculated but only the branch affected from top to bottom
      this.updateBounds();
    }
    if (options === null || options === void 0 ? void 0 : options.debug) {
      const {
        bounds
      } = this;
      const c = bounds.localBounds.center;
      this.debugGraphics.clear();
      // Draws the local bounds of the stage
      this.debugGraphics.beginFill('rgba(0, 0, 255, 0.03)').setStrokeDash([3]).setStrokeStyle(3).beginStroke('rgba(34, 34, 34, 0.3)').drawRect(bounds.localBounds.x, bounds.localBounds.y, bounds.localBounds.width, bounds.localBounds.height).endStroke().setStrokeDash().endFill();
      // Draws the center of the stage
      this.debugGraphics.beginFill('ff0000').drawCircle(c.x, c.y, 5).endFill();
      // Draws the position of the stage
      this.debugGraphics.beginFill('#000000').setTextStyle('12px Inter').drawText(`[${~~c.x},${~~c.y}]`, c.x + 8, c.y - 12).endFill();
      // Draws the camera bounds
      const cameraBounds = this.surface.camera.bounds;
      this.debugGraphics.setStrokeDash().setStrokeStyle(5).beginStroke('#111').drawRect(cameraBounds.x, cameraBounds.y, cameraBounds.width, cameraBounds.height).setStrokeDash().endStroke();
      // Draws the camera center
      this.debugGraphics.beginFill('#00ff00').drawCircle(cameraBounds.center.x, cameraBounds.center.y, 5).endFill();
      this.debugGraphics.draw(ctx);
    }
    // Shrinks the camera bounds by half and draws a green rectangle in the canvas in order to check
    // that the elements outside are culled and the ones inside are not.
    if (this.surface.debugCulling) {
      this.debugGraphics.clear();
      this.debugGraphics.setStrokeStyle(5).beginStroke('#00ff00').drawRect(this.surface.camera.bounds.x + this.surface.camera.bounds.width / 4, this.surface.camera.bounds.y + this.surface.camera.bounds.height / 4, this.surface.camera.bounds.width / 2, this.surface.camera.bounds.height / 2).endStroke().setStrokeDash();
      this.debugGraphics.draw(ctx);
    }
    super.render(ctx, options);
    // Draws the selection bounds of the pointer
    if (this.surface.input.selectionBounds) {
      this.debugGraphics.clear();
      this.debugGraphics.beginFill('rgba(47, 140, 187, 0.1)').drawRect(this.surface.input.selectionBounds.x, this.surface.input.selectionBounds.y, this.surface.input.selectionBounds.width, this.surface.input.selectionBounds.height).endFill();
      this.debugGraphics.draw(ctx);
    }
    if (this.stageAnimations.length > 0) {
      // Loop animations
      this.surface.dirty = true;
    }
  }
  /**
   * Removes all stage children and animations.
   */
  destroy() {
    super.destroy();
    this.stageAnimations.destroy();
  }
}

/**
 * This class draws a Shape
 * @example
 * ```
 * const arrow = new Shape(50, 100);
 * // draws a Shape at Point(50, 100)
 * ```
 */
class Shape extends DisplayObject {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param width - width of the {@link DisplayObject}.
   * @param height - height of the {@link DisplayObject}.
   * @param alignToCenter - offsets the registration point so the resulting Shape is drawn with its center at {@link Point}(0,0).
   */
  constructor(x = 0, y = 0, width = 10, height = 10, alignToCenter = false) {
    super(x, y, width, height);
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    if (alignToCenter) {
      this.regX = width / 2;
      this.regY = height / 2;
    }
    this.graphics = new Graphics();
  }
  /**
   * Renders the Shape on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    if (this.graphics.instructions.length >= 100) {
      if (options === null || options === void 0 ? void 0 : options.debug) {
        this.debugGraphics.beginFill('rgba(0,255,0,0.7)').drawRect(0, 0, this.width, this.height).endFill();
      }
      // eslint-disable-next-line no-console
      console.warn(
      // eslint-disable-next-line max-len
      `The graphics property of this Shape instance id:${this.id} has more than 100 draw instructions (total: ${this.graphics.instructions.length}) which could mean they are not being cleared correctly.`, "Make sure this is intended behavior and you are calling 'graphics.clear()' when necessary.", '-- If you activate the debug view the affected object will be marked in green. --');
    }
    this.graphics.draw(ctx);
    this.graphics.clear();
    super.render(ctx, options);
  }
  /**
   * Serializes this {@link Shape} instance
   */
  serialize() {
    const data = super.serialize();
    return data;
  }
}

/**
 * This class draws a 3 axis (x, y, and pseudo z) and center dot Shape.
 * Mainly used for debuging
 * @example
 * ```
 * const axor = new AxorShape(50, 100);
 * // draws a 3 axis (x, y, and pseudo z) and center dot at Point(50, 100)
 * ```
 */
class AxorShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   */
  constructor(x, y) {
    super(x, y, 0, 0);
  }
  /**
   * Renders the 3 axis and center dot Axor Shape on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    if (!this.stage) {
      return;
    }
    this.graphics.clear();
    if (options === null || options === void 0 ? void 0 : options.debug) {
      const pointAxisX = this.stage.surface.camera.projectionMatrix.transformPoint(100, 0);
      const pointAxisY = this.stage.surface.camera.projectionMatrix.transformPoint(0, 100);
      // Axis X
      this.graphics.setStrokeStyle(3).beginStroke('#00ffff').moveTo(0, 0).lineTo(pointAxisX.x, pointAxisX.y).endStroke();
      // Axis Y
      this.graphics.setStrokeStyle(3).beginStroke('#ff00ff').moveTo(0, 0).lineTo(pointAxisY.x, pointAxisY.y).endStroke();
      // Center circle
      this.graphics.beginFill('#000000').drawCircle(0, 0, 5);
      // Axis Z
      this.graphics.setStrokeStyle(3).beginStroke('#ffff00').moveTo(0, 0).lineTo(0, -80 * this.stage.surface.camera.projectionMatrix.strength).endStroke();
    } else {
      this.graphics.setStrokeStyle(3).beginStroke('#fff').beginFill('#000').drawCircle(0, 0, 8);
    }
    super.render(ctx, options);
  }
}

/**
 * This class creates a cursor indicator that will follow the pointer position.
 * It extends and draws in the canvas an {@link AxorShape} with a zIndex = 9999 so it's always over other objects.
 * Mainly used for debuging when attached to the {@link Stage}
 * @example
 * ```
 * const cursor = new Cursor();
 * // creates a cursor at Point(0, 0) hat will follow the pointer position
 * ```
 */
class Cursor extends AxorShape {
  constructor() {
    super(0, 0);
    this.zIndex = 9999;
    this.isometric = false;
  }
}

/**
 * Represents screen camera and contains controls for the camera (zoom, panning).
 */
class CameraManager extends EventEmitter {
  constructor(surface) {
    super();
    this.surface = surface;
    this.locked = false;
    this._x = 0;
    this._y = 0;
    this._zoom = 1;
    this.projectionMatrix = new IsometricMatrix(surface.isometry);
  }
  /**
   * Container for animations, required for internal animation system. Points to animations container on `Stage`.
   */
  get animations() {
    return this.surface.stage.animations;
  }
  /**
   * Gets bounds of user's viewport.
   */
  get bounds() {
    const width = this.surface.canvas.width / (this.zoom * window.devicePixelRatio);
    const height = this.surface.canvas.height / (this.zoom * window.devicePixelRatio);
    let viewOrigin = this.surface.grid.viewOrigin.clone();
    viewOrigin = this.surface.camera.projectionMatrix.transformPoint(viewOrigin.x, viewOrigin.y);
    return new Rectangle(viewOrigin.x - width / 2, viewOrigin.y - height / 2, width, height);
  }
  /**
   * Center point of the viewport.
   */
  get center() {
    return new Point(Math.round(this.surface.canvas.width / 2), Math.round(this.surface.canvas.height / 2));
  }
  /**
   * Gets current camera zoom value.
   */
  get zoom() {
    // The zoom of the CameraManager controls the scale of the stage.
    return this._zoom;
  }
  /**
   * Sets current camera zoom value. Value cannot exceed `CameraManager.MAX_ZOOM` or go below `CameraManager.MIN_COOM`.
   * Emits zoom event.
   */
  set zoom(value) {
    value = Math.min(CameraManager.MAX_ZOOM, Math.max(CameraManager.MIN_ZOOM, value));
    if (value === this._zoom) return;
    this._zoom = value;
    this.surface.dirty = true;
    this.surface.grid.dirty = true;
    this.emit('zoom');
  }
  /**
   * Get x coordinate of the camera.
   */
  get x() {
    return this._x;
  }
  /**
   * Set x coordinate of the camera. Emits move event and forces rerender.
   */
  set x(value) {
    if (value === this._x) return;
    this._x = value;
    this.surface.dirty = true;
    this.emit('move');
  }
  /**
   * Get y coordinate of the camera.
   */
  get y() {
    return this._y;
  }
  /**
   * Set y coordinate of the camera. Emits move event and forces rerender.
   */
  set y(value) {
    if (value === this._y) return;
    this._y = value;
    this.surface.dirty = true;
    this.emit('move');
  }
  /**
   * Change camera position.
   * @param x - value on the x axis
   * @param y - value on the y axis
   */
  move(x, y) {
    this.x = x;
    this.y = y;
  }
  /**
   * Ensures that the area represented by the bounds area is visible.
   * @param bounds - Area that should camera pan and move to.
   * @param duration - Duration of the transition in miliseconds, defaults to 500ms.
   */
  ensureVisible(bounds, duration = 500) {
    // Compute the current Field of View
    const fov = this.bounds;
    fov.x += (fov.width - fov.width * CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_X) / 2;
    fov.y += (fov.height - fov.height * CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_Y) / 2;
    fov.width *= CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_X;
    fov.height *= CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_Y;
    // If FOV too zoomed, use MakeVisible to normalize.
    if (fov.width < bounds.width || fov.height < bounds.height) {
      this.makeVisible(bounds, duration);
      return;
    }
    // If FOV directly contains bounds, then return false, everything is fine
    if (fov.contains(bounds)) {
      return;
    }
    let x = this.x / this.zoom;
    let y = this.y / this.zoom;
    if (bounds.x < fov.x) {
      x += fov.x - bounds.x;
    }
    if (bounds.y < fov.y) {
      y += fov.y - bounds.y;
    }
    if (bounds.x + bounds.width > fov.x + fov.width) {
      x -= bounds.x + bounds.width - (fov.x + fov.width);
    }
    if (bounds.y + bounds.height > fov.y + fov.height) {
      y -= bounds.y + bounds.height - (fov.y + fov.height);
    }
    if (x !== this.x / this.zoom || y !== this.y / this.zoom) {
      this.panTo(x * this.zoom, y * this.zoom, duration);
    }
  }
  /**
   * Pans and zooms the camera so that the area represented by the bounds Rectangle is visible.
   * @param bounds - Area that should camera pan and move to.
   * @param duration - Duration of the transition in miliseconds, defaults to 500ms.
   */
  makeVisible(bounds, duration = 500) {
    const {
      center
    } = bounds;
    const fov = this.bounds;
    fov.width *= CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_X;
    fov.height *= CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_Y;
    const scale = Math.min(fov.width / bounds.width, fov.height / bounds.height);
    const zoom = Math.min(CameraManager.MAX_ZOOM, Math.max(CameraManager.MIN_ZOOM, this.zoom * scale));
    this.zoomTo(zoom, duration);
    this.panTo(-center.x * zoom, -center.y * zoom, duration);
  }
  /**
   * Smoothly zooms camera.
   * @param level - Zoom level, value cannot exceed `CameraManager.MAX_ZOOM` or go below `CameraManager.MIN_ZOOM`.
   * @param duration - Duration of the zoom transition.
   */
  zoomTo(level, duration = 500) {
    const zoom = Math.min(CameraManager.MAX_ZOOM, Math.max(CameraManager.MIN_ZOOM, level));
    if (duration > 0) {
      new Animation(this, {
        zoom
      }, {
        interrupt: true,
        easing: 'easeInOutQuad',
        duration
      });
    } else {
      this.zoom = zoom;
    }
  }
  /**
   * Smoothly pans the camera to specified coordinates.
   * @param x - New x position of the camera.
   * @param y - New y position of the camera.
   * @param duration - Duration of the transition.
   */
  panTo(x, y, duration = 500) {
    if (duration > 0) {
      new Animation(this, {
        x,
        y
      }, {
        interrupt: true,
        easing: 'easeInOutQuad',
        duration
      });
    } else {
      this.move(x, y);
    }
  }
  /**
   * Restores last camera position and zoom level that was stored using the `save` method.
   * @param animate - If true, transition to stored position and zoom will be animated.
   */
  restore(animate = true) {
    if (!this.saved) return;
    this.zoomTo(this.saved.zoom, animate ? 500 : 0);
    this.panTo(this.saved.x, this.saved.y, animate ? 500 : 0);
    this.saved = undefined;
  }
  /**
   * Saves current camera zoom and position. It can be restored using the `restore` method.
   */
  save() {
    this.saved = {
      zoom: this.zoom,
      x: this.x,
      y: this.y
    };
  }
}
/**
 * Maximum zoom treshold.
 */
CameraManager.MAX_ZOOM = 1.5;
/**
 * Minimum zoom treshold.
 */
CameraManager.MIN_ZOOM = 0.2;
/**
 * X axis scale for fov calculation
 */
CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_X = 0.9;
/**
 * Y axis scale for fov calculation
 */
CameraManager.DEFAULT_ZOOM_TO_FIT_SCALE_Y = 0.8;

/**
 * This class draws an Image with the help of an offscreen canvas {@link BackBuffer}
 * @example
 * ```
 * const image = new Image('https://domain.com/image.png', 50, 100);
 * // retrieves an asset from the provided URL and draws it as an image at Point(50, 100)
 * ```
 */
class Image extends DisplayObject {
  /**
   * @param url - URL of the image asset
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param width - width of the {@link Image} instance.
   * @param height - height of the {@link Image} instance.
   * @param alignToCenter - offsets the registration point so the resulting Image is drawn with its center at {@link Point}(0,0).
   */
  constructor(url, x, y, width, height, alignToCenter = false) {
    super(x, y, width, height);
    if (alignToCenter) {
      this.regX = width / 2;
      this.regY = height / 2;
    }
    this._filters = [];
    this.visible = false;
    this.url = url;
  }
  /**
   * Gets the url property of this {@link Image} instance.
   */
  get url() {
    return this._url;
  }
  /**
   * Sets the url property of this {@link Image} instance.
   */
  set url(value) {
    if (this._url === value) {
      return;
    }
    this._url = value;
    this._bb = undefined;
    this.visible = false;
    if (value) {
      const image = new window.Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        this._data = image;
        this._bb = new BackBuffer(this._data.width || this.width, this._data.height || this.height);
        this._bb.drawImage(this._data, 0, 0, this._data.width, this._data.height);
        if (this._filters !== undefined ? this._filters.length : undefined) {
          this._filters.forEach(filter => {
            var _a;
            return (_a = this._bb) === null || _a === void 0 ? void 0 : _a.filter(filter);
          });
        }
        this.visible = true;
      };
      image.onerror = err => {
        // Ignore error
        console.error(`Failed to load image ${value}.`, err);
      };
      image.src = value;
    }
  }
  /**
   * Gets the filters property of this {@link Image} instance.
   */
  get filters() {
    return this._filters;
  }
  /**
   * Sets the filters property of this {@link Image} instance.
   */
  set filters(value) {
    if (this._filters === value) return;
    this._filters = value;
    if (this._bb !== undefined && this._data !== undefined) {
      this._bb.clear();
      this._bb.drawImage(this._data, 0, 0, this.width, this.height);
      this._filters.forEach(filter => {
        var _a;
        return (_a = this._bb) === null || _a === void 0 ? void 0 : _a.filter(filter);
      });
    }
  }
  /**
   * Renders the {@link Image} on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    super.render(ctx, options);
    if (this._bb !== undefined) {
      if (this._bb.bufferCanvas) {
        ctx.drawImage(this._bb.bufferCanvas, 0, 0, this.width, this.height);
      }
    }
  }
  /**
   * Serializes this {@link Image} instance
   */
  serialize() {
    const data = super.serialize();
    data.url = this.url;
    data.origin = {
      x: this.regX,
      y: this.regY
    };
    return data;
  }
}

var uncurryThis$1 = functionUncurryThis;
var fails$1 = fails$q;
var isCallable = isCallable$j;
var classof = classof$4;
var getBuiltIn = getBuiltIn$6;
var inspectSource = inspectSource$2;

var noop = function () { /* empty */ };
var empty = [];
var construct = getBuiltIn('Reflect', 'construct');
var constructorRegExp = /^\s*(?:class|function)\b/;
var exec$1 = uncurryThis$1(constructorRegExp.exec);
var INCORRECT_TO_STRING = !constructorRegExp.exec(noop);

var isConstructorModern = function isConstructor(argument) {
  if (!isCallable(argument)) return false;
  try {
    construct(noop, empty, argument);
    return true;
  } catch (error) {
    return false;
  }
};

var isConstructorLegacy = function isConstructor(argument) {
  if (!isCallable(argument)) return false;
  switch (classof(argument)) {
    case 'AsyncFunction':
    case 'GeneratorFunction':
    case 'AsyncGeneratorFunction': return false;
  }
  try {
    // we can't check .prototype since constructors produced by .bind haven't it
    // `Function#toString` throws on some built-it function in some legacy engines
    // (for example, `DOMQuad` and similar in FF41-)
    return INCORRECT_TO_STRING || !!exec$1(constructorRegExp, inspectSource(argument));
  } catch (error) {
    return true;
  }
};

isConstructorLegacy.sham = true;

// `IsConstructor` abstract operation
// https://tc39.es/ecma262/#sec-isconstructor
var isConstructor$1 = !construct || fails$1(function () {
  var called;
  return isConstructorModern(isConstructorModern.call)
    || !isConstructorModern(Object)
    || !isConstructorModern(function () { called = true; })
    || called;
}) ? isConstructorLegacy : isConstructorModern;

var isConstructor = isConstructor$1;
var tryToString = tryToString$3;

var $TypeError = TypeError;

// `Assert: IsConstructor(argument) is true`
var aConstructor$1 = function (argument) {
  if (isConstructor(argument)) return argument;
  throw $TypeError(tryToString(argument) + ' is not a constructor');
};

var anObject$1 = anObject$b;
var aConstructor = aConstructor$1;
var isNullOrUndefined$1 = isNullOrUndefined$5;
var wellKnownSymbol = wellKnownSymbol$f;

var SPECIES = wellKnownSymbol('species');

// `SpeciesConstructor` abstract operation
// https://tc39.es/ecma262/#sec-speciesconstructor
var speciesConstructor$1 = function (O, defaultConstructor) {
  var C = anObject$1(O).constructor;
  var S;
  return C === undefined || isNullOrUndefined$1(S = anObject$1(C)[SPECIES]) ? defaultConstructor : aConstructor(S);
};

var apply = functionApply;
var call = functionCall;
var uncurryThis = functionUncurryThis;
var fixRegExpWellKnownSymbolLogic = fixRegexpWellKnownSymbolLogic;
var anObject = anObject$b;
var isNullOrUndefined = isNullOrUndefined$5;
var isRegExp = isRegexp;
var requireObjectCoercible = requireObjectCoercible$8;
var speciesConstructor = speciesConstructor$1;
var advanceStringIndex = advanceStringIndex$2;
var toLength = toLength$3;
var toString = toString$b;
var getMethod = getMethod$3;
var arraySlice = arraySliceSimple;
var callRegExpExec = regexpExecAbstract;
var regexpExec = regexpExec$3;
var stickyHelpers = regexpStickyHelpers;
var fails = fails$q;

var UNSUPPORTED_Y = stickyHelpers.UNSUPPORTED_Y;
var MAX_UINT32 = 0xFFFFFFFF;
var min = Math.min;
var $push = [].push;
var exec = uncurryThis(/./.exec);
var push = uncurryThis($push);
var stringSlice = uncurryThis(''.slice);

// Chrome 51 has a buggy "split" implementation when RegExp#exec !== nativeExec
// Weex JS has frozen built-in prototypes, so use try / catch wrapper
var SPLIT_WORKS_WITH_OVERWRITTEN_EXEC = !fails(function () {
  // eslint-disable-next-line regexp/no-empty-group -- required for testing
  var re = /(?:)/;
  var originalExec = re.exec;
  re.exec = function () { return originalExec.apply(this, arguments); };
  var result = 'ab'.split(re);
  return result.length !== 2 || result[0] !== 'a' || result[1] !== 'b';
});

// @@split logic
fixRegExpWellKnownSymbolLogic('split', function (SPLIT, nativeSplit, maybeCallNative) {
  var internalSplit;
  if (
    'abbc'.split(/(b)*/)[1] == 'c' ||
    // eslint-disable-next-line regexp/no-empty-group -- required for testing
    'test'.split(/(?:)/, -1).length != 4 ||
    'ab'.split(/(?:ab)*/).length != 2 ||
    '.'.split(/(.?)(.?)/).length != 4 ||
    // eslint-disable-next-line regexp/no-empty-capturing-group, regexp/no-empty-group -- required for testing
    '.'.split(/()()/).length > 1 ||
    ''.split(/.?/).length
  ) {
    // based on es5-shim implementation, need to rework it
    internalSplit = function (separator, limit) {
      var string = toString(requireObjectCoercible(this));
      var lim = limit === undefined ? MAX_UINT32 : limit >>> 0;
      if (lim === 0) return [];
      if (separator === undefined) return [string];
      // If `separator` is not a regex, use native split
      if (!isRegExp(separator)) {
        return call(nativeSplit, string, separator, lim);
      }
      var output = [];
      var flags = (separator.ignoreCase ? 'i' : '') +
                  (separator.multiline ? 'm' : '') +
                  (separator.unicode ? 'u' : '') +
                  (separator.sticky ? 'y' : '');
      var lastLastIndex = 0;
      // Make `global` and avoid `lastIndex` issues by working with a copy
      var separatorCopy = new RegExp(separator.source, flags + 'g');
      var match, lastIndex, lastLength;
      while (match = call(regexpExec, separatorCopy, string)) {
        lastIndex = separatorCopy.lastIndex;
        if (lastIndex > lastLastIndex) {
          push(output, stringSlice(string, lastLastIndex, match.index));
          if (match.length > 1 && match.index < string.length) apply($push, output, arraySlice(match, 1));
          lastLength = match[0].length;
          lastLastIndex = lastIndex;
          if (output.length >= lim) break;
        }
        if (separatorCopy.lastIndex === match.index) separatorCopy.lastIndex++; // Avoid an infinite loop
      }
      if (lastLastIndex === string.length) {
        if (lastLength || !exec(separatorCopy, '')) push(output, '');
      } else push(output, stringSlice(string, lastLastIndex));
      return output.length > lim ? arraySlice(output, 0, lim) : output;
    };
  // Chakra, V8
  } else if ('0'.split(undefined, 0).length) {
    internalSplit = function (separator, limit) {
      return separator === undefined && limit === 0 ? [] : call(nativeSplit, this, separator, limit);
    };
  } else internalSplit = nativeSplit;

  return [
    // `String.prototype.split` method
    // https://tc39.es/ecma262/#sec-string.prototype.split
    function split(separator, limit) {
      var O = requireObjectCoercible(this);
      var splitter = isNullOrUndefined(separator) ? undefined : getMethod(separator, SPLIT);
      return splitter
        ? call(splitter, separator, O, limit)
        : call(internalSplit, toString(O), separator, limit);
    },
    // `RegExp.prototype[@@split]` method
    // https://tc39.es/ecma262/#sec-regexp.prototype-@@split
    //
    // NOTE: This cannot be properly polyfilled in engines that don't support
    // the 'y' flag.
    function (string, limit) {
      var rx = anObject(this);
      var S = toString(string);
      var res = maybeCallNative(internalSplit, rx, S, limit, internalSplit !== nativeSplit);

      if (res.done) return res.value;

      var C = speciesConstructor(rx, RegExp);

      var unicodeMatching = rx.unicode;
      var flags = (rx.ignoreCase ? 'i' : '') +
                  (rx.multiline ? 'm' : '') +
                  (rx.unicode ? 'u' : '') +
                  (UNSUPPORTED_Y ? 'g' : 'y');

      // ^(? + rx + ) is needed, in combination with some S slicing, to
      // simulate the 'y' flag.
      var splitter = new C(UNSUPPORTED_Y ? '^(?:' + rx.source + ')' : rx, flags);
      var lim = limit === undefined ? MAX_UINT32 : limit >>> 0;
      if (lim === 0) return [];
      if (S.length === 0) return callRegExpExec(splitter, S) === null ? [S] : [];
      var p = 0;
      var q = 0;
      var A = [];
      while (q < S.length) {
        splitter.lastIndex = UNSUPPORTED_Y ? 0 : q;
        var z = callRegExpExec(splitter, UNSUPPORTED_Y ? stringSlice(S, q) : S);
        var e;
        if (
          z === null ||
          (e = min(toLength(splitter.lastIndex + (UNSUPPORTED_Y ? q : 0)), S.length)) === p
        ) {
          q = advanceStringIndex(S, q, unicodeMatching);
        } else {
          push(A, stringSlice(S, p, q));
          if (A.length === lim) return A;
          for (var i = 1; i <= z.length - 1; i++) {
            push(A, z[i]);
            if (A.length === lim) return A;
          }
          q = p = e;
        }
      }
      push(A, stringSlice(S, p));
      return A;
    }
  ];
}, !SPLIT_WORKS_WITH_OVERWRITTEN_EXEC, UNSUPPORTED_Y);

/**
 * This class creates a Text and measures it with the help of an offscreen canvas {@link BackBuffer}
 * If {@link Text.lineWidth} is set, then an auto word wrap will take place, converting the single line text into multiline.
 * @example
 * ```
 * const text = new Text("Hi world!");
 * // creates a text object with the text 'Hi world!'
 *
 * const text = new Text("Hi world! Ready to draw awesome graphs?\nI bet you are! :)", 100, 100, false, 50);
 * // creates a text object with the text 'Hi world!' at 100, 100 coordinates, with a max lineWidth of 50px
 * ```
 */
class Text extends Shape {
  /**
   * @param text - The text of this {@link Text} instance.
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param alignToCenter - offsets the registration point so the resulting Text is drawn with its center at {@link Point}(0,0).
   * @param lineWidth - Determines the maximum width for a line of text before it is wrapped to multiple lines. If null, the text will not be wrapped.
   * @param lineHeight - Determines the line height (vertical distance between baselines) for multi-line text. If null or 0, the value of {@link Text.getMeasuredLineHeight} is used.
   */
  constructor(text = '', x = 0, y = 0, alignToCenter = false, lineWidth = null, lineHeight = 0) {
    super(x, y);
    /**
     * The text of this {@link Text} instance.
     */
    this._text = '';
    /**
     * Offsets the registration point so the resulting Text is drawn with its center at {@link Point}(0,0).
     */
    this._alignToCenter = false;
    /**
     * Determines the maximum width for a line of text before it is wrapped to multiple lines.
     * If null, the text will not be wrapped.
     */
    this._lineWidth = null;
    /**
     * Determines the line height (vertical distance between baselines) for multi-line text.
     * If null or 0, the value of {@link Text.getMeasuredLineHeight} is used.
     */
    this._lineHeight = 0;
    /**
     * A string parsed as CSS font value.
     */
    this.font = null;
    /**
     * Determines the weight (or boldness) of the font
     */
    this.fontWeight = null;
    /**
     * Determines the size of the font
     */
    this.fontSize = null;
    /**
     * Prioritized list of one or more font family names and/or generic family names.
     */
    this.fontFamily = null;
    /**
     * A BackBuffer that uses OffscreenCanvas to perform text metrics calculations.
     */
    this.buffer = BackBuffer.global;
    this._text = text;
    this._alignToCenter = alignToCenter;
    this._lineWidth = lineWidth;
    this._lineHeight = lineHeight;
    this.update();
  }
  /**
   * Gets the text of this {@link Text} instance.
   */
  get text() {
    return this._text;
  }
  /**
   * Sets the text of this {@link Text} instance.
   */
  set text(value) {
    if (value === this._text) return;
    this._text = value;
    this.update();
  }
  /**
   * Gets the alignToCenter property of this {@link Text} instance.
   * If true it will offset the registration point so the resulting {@link Text} is drawn with its center at {@link Point}(0,0).
   */
  get alignToCenter() {
    return this._alignToCenter;
  }
  /**
   * Sets the alignToCenter property of this {@link Text} instance.
   * If true it will offset the registration point so the resulting {@link Text} is drawn with its center at {@link Point}(0,0).
   */
  set alignToCenter(value) {
    if (value === this._alignToCenter) return;
    this._alignToCenter = value;
    this.update();
  }
  /**
   * Gets the lineWidth property of this {@link Text} instance.
   * Determines the maximum width for a line of text before it is wrapped to multiple lines. If null, the text will not be wrapped.
   */
  get lineWidth() {
    return this._lineWidth;
  }
  /**
   * Sets the lineWidth property of this {@link Text} instance.
   * Determines the maximum width for a line of text before it is wrapped to multiple lines. If null, the text will not be wrapped.
   */
  set lineWidth(value) {
    if (value === this._lineWidth) return;
    this._lineWidth = value;
    this.update();
  }
  /**
   * Gets the lineHeight property of this {@link Text} instance.
   * Determines the line height (vertical distance between baselines) for multi-line text. If null or 0, the value of {@link Text.getMeasuredLineHeight} is used.
   */
  get lineHeight() {
    return this._lineHeight;
  }
  /**
   * Sets the lineHeight property of this {@link Text} instance.
   * Determines the line height (vertical distance between baselines) for multi-line text. If null or 0, the value of {@link Text.getMeasuredLineHeight} is used.
   */
  set lineHeight(value) {
    if (value === this._lineHeight) return;
    this._lineHeight = value;
    this.update();
  }
  /**
   * Calculates the approximate line height of the text, ignoring the {@link Text.lineHeight} property.
   * It uses the measured width of a "M" character multiplied by 1.2, which provides an approximate line height for most fonts.
   * @returns The approximate measured line height.
   */
  getMeasuredLineHeight() {
    return this.getMeasuredWidth('M') * 1.2;
  }
  /**
   * Calculates the width of the text passed as parameter drawn in a single line.
   * @param text - The text to measure it's width in a single line.
   * @returns - The width of the text passed as parameter drawn in a single line.
   */
  getMeasuredWidth(text) {
    return this.buffer.measureText(text).width;
  }
  /**
   * Parses a Css valid font string and sets {@link fontWeight}, {@link fontSize} and {@link fontFamily} properties.
   */
  parseFont(font) {
    const parts =
    // eslint-disable-next-line max-len
    /^\s*(?=(?:(?:[-a-z]+\s*){0,2}(italic|oblique))?)(?=(?:(?:[-a-z]+\s*){0,2}(small-caps))?)(?=(?:(?:[-a-z]+\s*){0,2}(bold(?:er)?|lighter|[1-9]00))?)(?:(?:normal|\1|\2|\3)\s*){0,3}((?:xx?-)?(?:small|large)|medium|smaller|larger|[.\d]+(?:%|in|[cem]m|ex|p[ctx]))(?:\s*\/\s*(normal|[.\d]+(?:%|in|[cem]m|ex|p[ctx])))?\s*([-,"\sa-z0-9]+?)\s*$/i.exec(font);
    if (parts) {
      [,,, this.fontWeight, this.fontSize,, this.fontFamily] = parts;
    }
  }
  /**
   * Performs all the measure calculations and draws the text in a single or multiple lines.
   */
  drawText() {
    var _a, _b;
    if (!this.buffer.bufferCtx) {
      return;
    }
    this.buffer.clear();
    this.buffer.reset();
    this.graphics.clear();
    const style = this.style.actual;
    // Prepare graphics
    if (style.outline) {
      this.graphics.setStrokeStyle(style.outline).beginStroke(((_a = style.color) === null || _a === void 0 ? void 0 : _a.toHex()) || '#000');
    } else {
      this.graphics.beginFill(((_b = style.color) === null || _b === void 0 ? void 0 : _b.toHex()) || '#000');
    }
    if (style.font && style.font !== this.font) {
      this.font = style.font;
      this.parseFont(this.font);
    }
    if (style.fontWeight && style.fontWeight !== this.fontWeight) {
      this.fontWeight = style.fontWeight;
    }
    if (style.fontSize && style.fontSize !== this.fontSize) {
      this.fontSize = style.fontSize;
    }
    if (style.fontFamily && style.fontFamily !== this.fontFamily) {
      this.fontFamily = style.fontFamily;
    }
    this.buffer.bufferCtx.font = `${this.fontWeight || 'normal'} ${this.fontSize || '14px'} ${this.fontFamily || 'Inter'}`;
    this.graphics.setTextStyle(`${this.fontWeight || 'normal'} ${this.fontSize || '14px'} ${this.fontFamily || 'Inter'}`, 'left', 'top', 'ltr');
    const lineHeight = this._lineHeight || this.getMeasuredLineHeight();
    let maxWidth = 0;
    let lineCount = 0;
    const hardLines = String(this._text).split(/(?:\r\n|\r|\n)/);
    let width = null;
    hardLines.forEach(line => {
      // Check if word-wrap is needed
      if (this._lineWidth != null) {
        width = this.getMeasuredWidth(line);
        if (width > this._lineWidth) {
          const words = line.split(/(\s)/);
          [line] = words;
          width = this.getMeasuredWidth(line);
          for (let i = 1; i < words.length; i += 2) {
            const wordWidth = this.getMeasuredWidth(words[i] + words[i + 1]);
            // Check if wrapping is needed
            if (width + wordWidth > this._lineWidth) {
              this.graphics.drawText(line, 0, lineCount * lineHeight);
              if (width > maxWidth) {
                maxWidth = width;
              }
              line = words[i + 1];
              width = this.getMeasuredWidth(line);
              lineCount += 1;
            } else {
              line += words[i] + words[i + 1];
              width += wordWidth;
            }
          }
        }
      }
      this.graphics.drawText(line, 0, lineCount * lineHeight);
      width = this.getMeasuredWidth(line);
      if (width > maxWidth) {
        maxWidth = width;
      }
      lineCount += 1;
    });
    // We update the width and height of the display object with the new measurements
    this.width = maxWidth;
    this.height = lineCount * lineHeight;
    if (this._alignToCenter) {
      this.regX = this.width / 2;
      this.regY = this.height / 2;
    } else {
      this.regX = 0;
      this.regY = 0;
    }
  }
  /**
   * Renders the Text on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    this.drawText();
    super.render(ctx, options);
  }
  update() {
    // we redraw on update to recalculate measures
    this.drawText();
    super.update();
  }
  /**
   * Returns a clone of this {@link Text} instance.
   * @returns a clone of this {@link Text} instance.
   */
  clone() {
    const elem = new Text();
    elem.text = this._text;
    elem.alignToCenter = this._alignToCenter;
    elem.lineWidth = this._lineWidth;
    elem.lineHeight = this._lineHeight;
    return elem;
  }
}

/**
 *
 */
const PIXEL_SIZE = 4;
/**
 * This class draws an Arrow Shape
 * @example
 * ```
 * const arrow = new ArrowShape(50, 100);
 * // draws an arrow at Point(50, 100)
 * ```
 */
class ArrowShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   */
  constructor(x, y) {
    super(x, y, 12 * PIXEL_SIZE, 19 * PIXEL_SIZE, false);
  }
  /**
   * Renders the Arrow Shape on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    const style = this.style.actual;
    this.graphics.clear();
    // Add a fill
    if (style.backgroundColor) {
      this.graphics.beginFill(style.backgroundColor.toRGBA());
    }
    // Background
    this.graphics.drawRect(PIXEL_SIZE * 1, PIXEL_SIZE * 2, PIXEL_SIZE * 2, PIXEL_SIZE * 14).drawRect(PIXEL_SIZE * 3, PIXEL_SIZE * 4, PIXEL_SIZE * 2, PIXEL_SIZE * 10).drawRect(PIXEL_SIZE * 5, PIXEL_SIZE * 6, PIXEL_SIZE * 2, PIXEL_SIZE * 10).drawRect(PIXEL_SIZE * 7, PIXEL_SIZE * 8, PIXEL_SIZE * 2, PIXEL_SIZE * 4).drawRect(PIXEL_SIZE * 9, PIXEL_SIZE * 10, PIXEL_SIZE * 2, PIXEL_SIZE * 2).drawRect(PIXEL_SIZE * 7, PIXEL_SIZE * 14, PIXEL_SIZE * 2, PIXEL_SIZE * 4);
    // Border
    this.graphics.drawRect(0, 0, PIXEL_SIZE, PIXEL_SIZE * 17).drawRect(PIXEL_SIZE * 1, PIXEL_SIZE * 1, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 2, PIXEL_SIZE * 2, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 3, PIXEL_SIZE * 3, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 4, PIXEL_SIZE * 4, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 5, PIXEL_SIZE * 5, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 6, PIXEL_SIZE * 6, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 7, PIXEL_SIZE * 7, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 8, PIXEL_SIZE * 8, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 9, PIXEL_SIZE * 9, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 10, PIXEL_SIZE * 10, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 11, PIXEL_SIZE * 11, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 7, PIXEL_SIZE * 12, PIXEL_SIZE * 5, PIXEL_SIZE).drawRect(PIXEL_SIZE * 7, PIXEL_SIZE * 13, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 8, PIXEL_SIZE * 14, PIXEL_SIZE, PIXEL_SIZE * 2).drawRect(PIXEL_SIZE * 9, PIXEL_SIZE * 16, PIXEL_SIZE, PIXEL_SIZE * 2).drawRect(PIXEL_SIZE * 7, PIXEL_SIZE * 18, PIXEL_SIZE * 2, PIXEL_SIZE).drawRect(PIXEL_SIZE * 6, PIXEL_SIZE * 16, PIXEL_SIZE, PIXEL_SIZE * 2).drawRect(PIXEL_SIZE * 5, PIXEL_SIZE * 14, PIXEL_SIZE, PIXEL_SIZE * 2).drawRect(PIXEL_SIZE * 4, PIXEL_SIZE * 13, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 3, PIXEL_SIZE * 14, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 2, PIXEL_SIZE * 15, PIXEL_SIZE, PIXEL_SIZE).drawRect(PIXEL_SIZE * 1, PIXEL_SIZE * 16, PIXEL_SIZE, PIXEL_SIZE);
    super.render(ctx, options);
  }
}

/**
 * This class draws an Circle Shape
 * @example
 * ```
 * const circle = new CircleShape(50, 100, 20);
 * // draws a circle of radius 20 at Point(50, 100)
 * ```
 */
class CircleShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param radius - radius of the circle
   */
  constructor(x, y, radius) {
    super(x, y, radius * 2, radius * 2, true);
    this.radius = radius;
  }
  /**
   * Renders the circle on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    const style = this.style.actual;
    this.graphics.clear();
    // Add a fill
    if (style.backgroundColor) {
      this.graphics.beginFill(style.backgroundColor.toRGBA());
    }
    // Add a stroke
    if (style.borderColor && style.borderWidth) {
      this.graphics.setStrokeStyle(style.borderWidth, style.borderCap);
      this.graphics.beginStroke(style.borderColor.toRGBA());
    }
    // Draws the circle
    this.graphics.drawCircle(this.width / 2, this.height / 2, this.radius);
    super.render(ctx, options);
  }
}

/**
 * This class draws a Cross Shape
 * @example
 * ```
 * const cross = new CrossShape(50, 100, 40, 40);
 * // draws a cross at Point(50, 100) of width = 40 and height = 40
 * ```
 */
class CrossShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param width - width of the cross
   * @param height - height of the cross
   */
  constructor(x, y, width, height) {
    super(x, y, width, height, true);
  }
  /**
   * Renders the cross on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(ctx, options) {
    this.graphics.clear();
    this.graphics.setStrokeStyle(1).beginStroke('#000').moveTo(0, this.height / 2).lineTo(this.width, this.height / 2).moveTo(this.width / 2, 0).lineTo(this.width / 2, this.height).endStroke();
    this.graphics.beginFill('#fff').drawCircle(this.width / 2, this.height / 2, this.width / 8);
    super.render(ctx, options);
  }
}

/**
 * This class draws a Line Shape
 * @example
 * ```
 * const line = new LineShape(50, 100, 150, 200);
 * // draws a line from Point(50, 100) to Point(150,200)
 * ```
 */
class LineShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) of the first point in a cartesian plane.
   * @param y - y coordinate (vertical) of the first point in a cartesian plane.
   * @param x2 - x coordinate (horizontal) of the second point in a cartesian plane.
   * @param y2 - y coordinate (vertical) of the second point in a cartesian plane.
   */
  constructor(x, y, x2, y2) {
    super(x, y, x2, y2, false);
  }
  /**
   * Renders the line on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(context, options) {
    const style = this.style.actual;
    this.graphics.clear();
    if (style.borderColor && style.borderWidth) {
      this.graphics.setStrokeStyle(style.borderWidth, style.borderCap);
      this.graphics.beginStroke(style.borderColor.toRGBA());
    }
    this.graphics.moveTo(0, 0).lineTo(this.width, this.height);
    super.render(context, options);
  }
}

/**
 * This class draws a Pie Shape
 * It's very similar to the {@link CircleShape} class but also allows for a {@link pieThickness} property
 * @example
 * ```
 * const pie = new PieShape(50, 100, 20);
 * pie.startAngle = 30;
 * pie.endAngle = 180;
 * pie.pieThickness = 16;
 * // draws a pie shape of raidus 20 at Point(50, 100)
 * ```
 */
class PieShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param radius - radius of the pie.
   */
  constructor(x, y, radius) {
    super(x, y, radius * 2, radius * 2, true);
    /**
     * start angle in degrees, used for the rendering, if it's different from 0 it will draw a partial pie instead of a full pie.
     */
    this.startAngle = 0;
    /**
     * end angle in degrees, used for the rendering, if it's different from 360 it will draw a partial pie instead of a full pie.
     */
    this.endAngle = 360;
    /**
     * thickness of the pie
     */
    this.pieThickness = 10;
  }
  /**
   * Renders the pie on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(context, options) {
    const style = this.style.actual;
    this.graphics.clear();
    // Add a fill
    if (style.backgroundColor) {
      this.graphics.beginFill(style.backgroundColor.toRGBA());
    }
    // Add a stroke
    if (style.borderColor && style.borderWidth) {
      this.graphics.setStrokeStyle(style.borderWidth, style.borderCap);
      this.graphics.beginStroke(style.borderColor.toRGBA());
    }
    this.graphics.arc(this.width / 2, this.width / 2, this.width / 2, toRad(this.startAngle), toRad(this.endAngle));
    if (this.startAngle > 0 || this.endAngle < 360) {
      this.graphics.lineTo(this.width / 2, this.width / 2);
    }
    this.graphics.arc(this.width / 2, this.width / 2, this.width / 2 - this.pieThickness, toRad(this.endAngle), toRad(this.startAngle), true);
    super.render(context, options);
  }
}

/**
 * This class draws a Rectangle Shape
 * @example
 * ```
 * const rect = new Rectangle(50, 100, 40, 40);
 * // draws a rectangle at Point(50, 100) of width = 40 and height = 40
 * ```
 */
class RectangleShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param width - width of the rectangle.
   * @param height - height of the rectangle.
   * @param alignToCenter - offsets the registration point so the resulting Rectangle is drawn with its center at {@link Point}(0,0).
   */
  constructor(x, y, width, height, alignToCenter = false) {
    super(x, y, width, height, alignToCenter);
  }
  /**
   * Renders the rectangle on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(context, options) {
    const style = this.style.actual;
    this.graphics.clear();
    // Add a fill
    if (style.backgroundColor) {
      this.graphics.beginFill(style.backgroundColor.toRGBA());
    }
    // Add a stroke
    if (style.borderColor && style.borderWidth) {
      this.graphics.setStrokeStyle(style.borderWidth, style.borderCap);
      this.graphics.beginStroke(style.borderColor.toRGBA());
    }
    if (this.style.actual.borderRadius) {
      this.graphics.drawRoundRect(0, 0, this.width, this.height, this.style.actual.borderRadius);
    } else {
      this.graphics.drawRect(0, 0, this.width, this.height);
    }
    super.render(context, options);
  }
}

/**
 * This class draws a circular Shadow Shape
 * @example
 * ```
 * const shadow = new ShadowShape(50, 100, 20);
 * // draws a circular Shadow Shape of radius 20 at Point(50, 100)
 * ```
 */
class ShadowShape extends Shape {
  /**
   * @param x - x coordinate (horizontal) in a cartesian plane.
   * @param y - y coordinate (vertical) in a cartesian plane.
   * @param radius - radius of the circle
   */
  constructor(x, y, radius) {
    super(x, y, radius * 2, radius * 2, true);
  }
  /**
   * Renders the rectangle on the screen canvas.
   * @param ctx - canvas context.
   * @param options - rendering options.
   */
  render(context, options) {
    this.graphics.clear();
    this.graphics.beginRadialGradientFill(['rgba(0, 0, 0, .3)', 'rgba(0, 0, 0, 0)'], [0, 1], this.width / 2, this.width / 2, 10, this.width / 2, this.width / 2, this.width / 2);
    this.graphics.drawCircle(this.width / 2, this.width / 2, this.width / 2);
    super.render(context, options);
  }
}

const GRID_LINE_WIDTH = 2;
const GRID_DOT_SIZE_MAX = 4;
const GRID_LINE_SIZE_MAX = 9;
const GRID_SIZE = 150;
/**
 * Handles rendering of the grid pattern on canvas.
 */
class GridManager {
  constructor(surface) {
    this.surface = surface;
    this.resetting = false;
    this.pattern = null;
    this._style = 'lines';
    this._size = GRID_SIZE;
    this._weight = GRID_LINE_WIDTH;
    this._color = new Color('#ddd');
    this._opacity = 1;
    this.graphics = new Graphics();
    this.debugGraphics = new Graphics();
    this.dirty = true;
    this.visible = true;
    this.bufferCanvas = document.createElement('canvas');
    this.bufferCanvas.width = this._size;
    this.bufferCanvas.height = this._size;
    if (CAPABILITIES.offscreenCanvas) {
      this.bufferCanvas = this.bufferCanvas.transferControlToOffscreen();
    }
    this.bufferCtx = this.bufferCanvas.getContext('2d');
    this.resetGrid = this.reset.bind(this);
  }
  /**
   * Calculates scale of the grid pattern based on the zoom level of camera.
   */
  getScale() {
    return 1 / this.surface.camera.zoom;
  }
  /**
   * Redraws the pattern to new offscreen canvas and stores it internally.
   */
  reset() {
    if (!this.bufferCtx) return;
    this.bufferCtx.clearRect(0, 0, this._size, this._size);
    this.graphics.clear();
    this._color.alpha = this.opacity;
    if (this._style === 'dots') {
      const weight = Math.max(Math.min(this._weight / this.surface.camera.zoom, GRID_DOT_SIZE_MAX), this._weight);
      this.graphics.beginFill(this._color.toRGBA()).drawCircle(this._size / 2, this._size / 2, weight).endFill();
    } else if (this._style === 'lines') {
      const weight = ~~Math.max(Math.min(this._weight / this.surface.camera.zoom, GRID_LINE_SIZE_MAX), this._weight);
      this.graphics.setStrokeStyle(weight, 'square').beginStroke(this._color.toRGBA()).moveTo(this._size / 2, 0).lineTo(this._size / 2, this._size).moveTo(0, this._size / 2).lineTo(this._size, this._size / 2).endStroke();
    }
    this.graphics.draw(this.bufferCtx);
    this.pattern = this.bufferCtx.createPattern(this.bufferCanvas, 'repeat');
    this.resetting = false;
    this.dirty = false;
  }
  /**
   * Schedules pattern redraw to next frame.
   */
  scheduleReset() {
    if (this.resetting) return;
    this.resetting = true;
    requestAnimationFrame(this.resetGrid);
    this.surface.dirty = true;
  }
  /**
   * Grid pattern opacity
   */
  get opacity() {
    return this._opacity;
  }
  /**
   * Sets the pattern opacity and forces a redraw.
   */
  set opacity(value) {
    if (value === this._opacity) return;
    this._opacity = value;
    this.scheduleReset();
  }
  /**
   * Style of the grid pattern. `'lines'` and `'dots'` are curerntly supported.
   */
  get style() {
    return this._style;
  }
  /**
   * Sets the style and schedules redraw of the pattern.
   */
  set style(value) {
    if (value === this._style) return;
    this._style = value;
    this.scheduleReset();
  }
  /**
   * Size of the pattern.
   */
  get size() {
    return this._size;
  }
  /**
   * Sets size of and schedules redraw of the pattern.
   */
  set size(value) {
    if (value === this._size) return;
    this._size = value;
    this.bufferCanvas.width = this._size;
    this.bufferCanvas.height = this._size;
    this.scheduleReset();
  }
  /**
   * Represent's thickness of the lines or size of the dots, depending on the `GridStyle`.
   */
  get weight() {
    return this._weight;
  }
  /**
   * Sets the weight, higher number makes the lines thicker or dots bigger, depening on the `GridStyle`. Schedules pattern redraw.
   */
  set weight(value) {
    if (value === this._weight) return;
    this._weight = value;
    this.scheduleReset();
  }
  /**
   * Color of the pattern.
   */
  get color() {
    return this._color;
  }
  /**
   * Sets color and schedules redraw of the pattern.
   */
  set color(value) {
    if (value.toString() === this._color.toString()) return;
    this._color = value;
    this.scheduleReset();
  }
  /**
   * Gets point representing the origin of the grid pattern.
   */
  get viewOrigin() {
    let viewOrigin = new Point(-this.surface.camera.x, -this.surface.camera.y);
    viewOrigin.scale(this.getScale());
    viewOrigin = this.surface.camera.projectionMatrix.inverted.transformPoint(viewOrigin.x, viewOrigin.y);
    return viewOrigin;
  }
  /**
   * Renders pattern on the on screen canvas
   * @param ctx - Canvas context
   * @param options - Rendering options
   */
  render(ctx, options) {
    const mtx = this.surface.camera.projectionMatrix.m;
    const imtx = this.surface.camera.projectionMatrix.inverted.m;
    ctx.transform(mtx[0], mtx[1], mtx[2], mtx[3], mtx[4], mtx[5]);
    const halfSize = Math.round(this._size / 2);
    if (this.dirty) this.resetGrid();
    // Draw Grid
    if (this.visible && this.opacity > 0 && this.pattern) {
      const scale = this.getScale();
      const origin = this.viewOrigin;
      let {
        width,
        height
      } = ctx.canvas;
      if (this.surface.camera.projectionMatrix.strength > 0) {
        const c = Math.sqrt(Math.pow(ctx.canvas.width + this._size * 2, 2) + Math.pow(height + this._size * 2, 2));
        width *= c / ctx.canvas.width;
        height *= c / ctx.canvas.height;
      }
      width *= scale;
      height *= scale;
      ctx.translate(-halfSize, -halfSize);
      // We draw the grid with the pattern
      this.graphics.clear();
      this.graphics.beginBitmapFill(this.bufferCanvas, 'repeat').drawRect(-width / 2 + origin.x, -height / 2 + origin.y, width + halfSize, height + halfSize).endFill();
      this.graphics.draw(ctx);
    } else {
      ctx.translate(-halfSize, -halfSize);
    }
    if (options === null || options === void 0 ? void 0 : options.debug) {
      ctx.translate(halfSize, halfSize);
      const origin = this.viewOrigin;
      this.debugGraphics.clear();
      // Axis X of the grid
      this.debugGraphics.setStrokeStyle(3).beginStroke('#ff0000').moveTo(0, 0).lineTo(100, 0).endStroke();
      // Axis Y of the grid
      this.debugGraphics.setStrokeStyle(3).beginStroke('#00ff00').moveTo(0, 0).lineTo(0, 100).endStroke();
      // Center circle of the grid
      this.debugGraphics.beginFill('#fecd5f').drawCircle(0, 0, 5).endFill();
      // Transformed origin Drawing
      // Axis X of the view origin
      this.debugGraphics.setStrokeStyle(3).beginStroke('#ff9999').moveTo(origin.x, origin.y).lineTo(origin.x + 100, origin.y).endStroke();
      // Axis Y of the view origin
      this.debugGraphics.setStrokeStyle(3).beginStroke('#99ff99').moveTo(origin.x, origin.y).lineTo(origin.x, origin.y + 100).endStroke();
      // Center circle of the view origin
      this.debugGraphics.beginFill('#feddaa').drawCircle(origin.x, origin.y, 5).endFill();
      // We apply the isometric transform to the view origin
      const vot = this.surface.camera.projectionMatrix.transformPoint(origin.x, origin.y);
      this.debugGraphics.beginFill('#333').setTextStyle('12px Inter', 'left', 'top').drawText(`[${~~origin.x},${~~origin.y}]`, origin.x, origin.y).drawText(`[${~~vot.x},${~~vot.y}]`, origin.x, origin.y + 12).drawText(`[${~~this.surface.camera.x},${~~this.surface.camera.y}, ${this.surface.camera.zoom.toFixed(4)}]`, origin.x, origin.y + 24).endFill();
      // We draw the current debugGraphics instructions and apply the inverted matrix projectionMatrix to the ctx so the isometry is no longer applied,
      // that way we can draw the Z axes normaly with no complex calculations.
      this.debugGraphics.draw(ctx);
      ctx.transform(imtx[0], imtx[1], imtx[2], imtx[3], imtx[4], imtx[5]);
      this.debugGraphics.clear();
      // Axis Z of the view origin
      this.debugGraphics.setStrokeStyle(3).beginStroke('#9999ff').moveTo(vot.x, vot.y).lineTo(vot.x, vot.y + -81 * this.surface.camera.projectionMatrix.strength).endStroke();
      // Axis Z of the grid
      this.debugGraphics.setStrokeStyle(3).beginStroke('#0000ff').moveTo(0, 0).lineTo(0, -80 * this.surface.camera.projectionMatrix.strength).endStroke();
      this.debugGraphics.draw(ctx);
    } else {
      // We apply the inverted matrix projectionMatrix to the ctx so the isometry is no longer applied
      ctx.translate(halfSize, halfSize);
      ctx.transform(imtx[0], imtx[1], imtx[2], imtx[3], imtx[4], imtx[5]);
    }
  }
}

const POINTER_CLICK_TOLERANCE = 5;
/**
 * Handles all user input inside Surface.
 */
class InputManager {
  /**
   * Creates new instance of {@link InputManager} for specified surface. Event listeners are automatically registered.
   * @param surface - {@link Surface} instance.
   */
  constructor(surface) {
    this.mouseCursor = 'default';
    /**
     * Indicates whether pointer moved.
     */
    this.POINTER_MOVED = false;
    /**
     * Indicates whether shift is pressed.
     */
    this.KEY_SHIFT_DOWN = false;
    /**
     * Indicates whether something is beeing dragged.
     */
    this.POINTER_DRAGGING = false;
    /**
     * Indicates whether pointer is in selection mode.
     */
    this.POINTER_SELECTION = false;
    /**
     * Indicates the type of pointer event (mouse, touch or pen).
     */
    this.POINTER_TYPE = '';
    this.PINCH_DISTANCE = 0;
    this.prevDiff = -1;
    this.eventsCache = [];
    this.points = [];
    this.isTouch = false;
    this.mouseWheelEnabled = true;
    this.doubleClickEnabled = true;
    this.contextMenuEnabled = true;
    this._enabled = true;
    this._focusEnabled = true;
    this._selectionEnabled = false;
    this._selected = [];
    this.surface = surface;
    this.surface.canvas.addEventListener('pointerdown', this.pointerDownHandler.bind(this));
    this.surface.canvas.addEventListener('pointermove', this.pointerMoveHandler.bind(this));
    this.surface.canvas.addEventListener('pointerup', this.pointerUpHandler.bind(this));
    window.addEventListener('contextmenu', event => {
      if (event.target === this.surface.canvas) {
        event.preventDefault();
        this.POINTER_DOWN = undefined;
        this.eventsCache = [];
        if (!this.contextMenuEnabled) return;
        this.check();
        (this.hover || this.surface.stage).bubble('contextmenu', this.surface.stage.documentToGlobal(event.offsetX, event.offsetY));
      }
    });
    this.surface.canvas.addEventListener('wheel', event => {
      if (!this.mouseWheelEnabled) return;
      event.preventDefault();
      if (!this.enabled || this.surface.camera.locked) return;
      if (!(event.metaKey || event.altKey)) {
        this.zoom(event.deltaY * -0.001, new Point(event.offsetX, event.offsetY));
      } else {
        this.pan(event.deltaX, event.deltaY);
        this.surface.camera.move(-event.deltaX, -event.deltaY);
      }
    });
    window.addEventListener('keyup', event => {
      if (!this.enabled) return;
      const target = event.target;
      if (target && target.matches('input, textarea, select, [contenteditable="true"]')) {
        return;
      }
      this.keyUpHandler(event.key);
    });
    window.addEventListener('keydown', event => {
      if (!this.enabled) return;
      const target = event.target;
      if (target && target.matches('input, textarea, select, [contenteditable="true"]')) {
        return;
      }
      this.keyDownHandler(event.key, event.ctrlKey || event.metaKey);
    });
  }
  /**
   * Gets reference to object that is currently beeing dragged by cursor or undefined user is not dragging anything.
   */
  get drag() {
    if (this.POINTER_DRAGGING) {
      return this.POINTER_TARGET;
    }
    return undefined;
  }
  /**
   * Indicates whether the {@link InputManager} is enabled.
   */
  get enabled() {
    return this._enabled;
  }
  /**
   * Enables/disables the {@link InputManager}. Disabled InputManager doesn't react to user events.
   */
  set enabled(value) {
    if (value === this._enabled) return;
    this._enabled = value;
    if (!value) {
      this.hover = undefined;
    }
  }
  /**
   * Indicates if objects can be focused.
   */
  get focusEnabled() {
    return this._focusEnabled;
  }
  /**
   * Indicates if objects can be focused.
   */
  set focusEnabled(value) {
    if (value === this._focusEnabled) return;
    this._focusEnabled = value;
    if (!value) {
      this.focus = undefined;
    }
  }
  /**
   * Enables or disables the selection functionality.
   */
  get selectionEnabled() {
    return this._selectionEnabled;
  }
  /**
   * Enables or disables the selection functionality.
   */
  set selectionEnabled(value) {
    if (value === this._selectionEnabled) return;
    this._selectionEnabled = value;
    this.selected = [];
  }
  /**
   * Object that currently has focus.
   */
  get focus() {
    return this._focus;
  }
  /**
   * Object that currently has focus.
   */
  set focus(value) {
    if (!this._focusEnabled) return;
    if (value === this._focus) return;
    if (value && !value.interactive) return;
    if (value && !value.focusable) return;
    const old = this._focus;
    this._focus = value;
    if (old) {
      old.bubble('blur');
    }
    if (value) {
      value.bubble('focus');
    }
    this.surface.dirty = true;
  }
  /**
   * Object that the cursor is hovering over.
   */
  get hover() {
    return this._hover;
  }
  /**
   * Object that the cursor is hovering over.
   */
  set hover(value) {
    if (this._hover === value) return;
    const old = this._hover;
    this._hover = value;
    let previous;
    if (old) {
      if (value && old instanceof DisplayObjectContainer && old.hasChild(value)) {
        previous = old;
      } else {
        let parent = old;
        while (parent && parent !== value && parent !== this.surface.stage) {
          parent.bubble('pointerleave', null);
          // console.log(`Left: ${parent.__proto__.constructor.name}`);
          parent = parent.parent;
        }
        if (this.mouseCursor !== 'default') {
          this.surface.canvas.style.cursor = '';
          this.mouseCursor = 'default';
        }
        previous = parent;
      }
    }
    if (value) {
      let parent = value;
      while (parent && parent !== previous && parent !== this.surface.stage) {
        parent.bubble('pointerenter', null);
        parent = parent.parent;
      }
      if (value.style.cursor && value.style.cursor !== this.mouseCursor) {
        this.surface.canvas.style.cursor = value.style.cursor;
        this.mouseCursor = value.style.cursor;
      }
    }
    this.surface.dirty = true;
  }
  /**
   * Array of selected objects.
   */
  get selected() {
    return this._selected;
  }
  /**
   * Array of selected objects.
   */
  set selected(value) {
    if (!Array.isArray(value)) value = value ? [value] : [];
    if (!this.selectionEnabled) return;
    if (value === this._selected) return;
    value = value.filter((node, position) => node.interactive && node.selectable && value.indexOf(node) === position);
    if (value.length === 0 && this._selected.length === 0) return;
    const old = this._selected;
    this._selected = value;
    if (old) {
      old.forEach(node => {
        if (!value.includes(node)) {
          node.bubble('unselect');
        }
      });
    }
    if (value) {
      value.forEach(node => {
        if (!old.includes(node)) {
          node.bubble('select');
        }
      });
    }
    this.surface.dirty = true;
  }
  /**
   * HELPERS
   */
  check() {
    if (this.POINTER_DRAGGING) return;
    let found = this.POINTER_LAST && this.surface.stage.childAt(this.POINTER_LAST);
    if (this.exclusiveInteractiveObject && found !== this.exclusiveInteractiveObject) {
      found = undefined;
    }
    this.hover = found;
  }
  /**
   * Zooms the viewport.
   * @param delta - Number representing change of the zoom level.
   * @param pointerEvent - Coordinates of the pointer.
   */
  zoom(delta, pointerEvent) {
    let deltaPoint;
    if (pointerEvent) {
      const beforePoint = this.surface.stage.documentToGlobal(pointerEvent.x, pointerEvent.y);
      const centrePoint = this.surface.camera.projectionMatrix.inverted.transformPoint(beforePoint.x, beforePoint.y);
      this.surface.camera.zoom += delta;
      let movedCentrePoint = this.surface.stage.documentToGlobal(pointerEvent.x, pointerEvent.y);
      movedCentrePoint = this.surface.camera.projectionMatrix.inverted.transformPoint(movedCentrePoint.x, movedCentrePoint.y);
      deltaPoint = new Point(centrePoint.x - movedCentrePoint.x, centrePoint.y - movedCentrePoint.y);
    } else {
      const centrePoint = this.surface.grid.viewOrigin;
      this.surface.camera.zoom += delta;
      const movedCentrePoint = this.surface.grid.viewOrigin;
      deltaPoint = new Point(centrePoint.x - movedCentrePoint.x, centrePoint.y - movedCentrePoint.y);
    }
    deltaPoint.scale(this.surface.camera.zoom);
    deltaPoint = this.surface.camera.projectionMatrix.transformPoint(deltaPoint.x, deltaPoint.y);
    this.surface.camera.move(this.surface.camera.x - deltaPoint.x, this.surface.camera.y - deltaPoint.y);
  }
  /**
   * Pans camera to specified coordinates.
   * @param x - X coordinate.
   * @param y - Y coordinate.
   */
  pan(x, y) {
    this.surface.camera.x -= x;
    this.surface.camera.y -= y;
  }
  /**
   * Zooms the camera to the canvasPoint (canvas coordinates) by the specified amout.
   * @param amount - How much you want to zoom the camera.
   * @param canvasPoint - Point to zoom towards, defaults to center of the canvas.
   */
  zoomTo(amount, canvasPoint) {
    if (canvasPoint) {
      this.zoom(amount, new Point(canvasPoint.x, canvasPoint.y));
    } else {
      this.zoom(amount);
    }
  }
  /**
   * Handles keyboard input when user presses a key.
   * @param key - Pressed key
   * @param ctrl - If ctrl (or cmd) key is pressed.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  keyDownHandler(key, ctrl) {
    switch (key) {
      case '+':
        // +
        return this.zoom(0.1);
      case '-':
        return this.zoom(-0.1);
      case 'Shift':
        this.KEY_SHIFT_DOWN = true;
        return undefined;
      default:
        return undefined;
    }
  }
  /**
   * Handles keyboard input when user releases a key.
   * @param key - Released key.
   */
  keyUpHandler(key) {
    switch (key) {
      case 'Shift':
        this.KEY_SHIFT_DOWN = false;
        break;
    }
  }
  /**
   * Removes a specific item from the {@link eventsCache} using {@link PointerEvent.pointerId}.
   * @param event - The event to be removed from the events cache
   */
  removeEvent(event) {
    this.eventsCache = this.eventsCache.filter(ev => ev.pointerId !== event.pointerId);
  }
  pointerDownHandler(event) {
    var _a, _b;
    if (!this.enabled || event.button !== 0) return;
    event.preventDefault();
    event.stopPropagation();
    this.surface.canvas.focus();
    // We add this event to the event cache
    this.eventsCache.push(event);
    // Compute the pinch center
    if (this.eventsCache.length === 2) {
      this.PINCH_CENTER = new Point((this.eventsCache[0].offsetX + this.eventsCache[1].offsetX) / 2, (this.eventsCache[0].offsetY + this.eventsCache[1].offsetY) / 2);
      this.PINCH_DISTANCE = new Point(this.eventsCache[0].offsetX, this.eventsCache[0].offsetY).distance(new Point(this.eventsCache[1].offsetX, this.eventsCache[1].offsetY));
      return;
    }
    // We check if it's a touch event or not and trasnform points to stage coordinate space
    if (event.pointerType === 'touch') {
      this.isTouch = true;
      this.points = this.eventsCache.map(evt => this.surface.stage.documentToGlobal(evt.offsetX, evt.offsetY));
    } else {
      this.points = [this.surface.stage.documentToGlobal(event.offsetX, event.offsetY)];
    }
    this.POINTER_TYPE = event.pointerType;
    this.POINTER_LAST = this.points[0].clone();
    this.POINTER_DOWN = this.points[0].clone();
    this.POINTER_TARGET = undefined;
    // Check if there is an element under the pointer
    const found = this.surface.stage.childAt(this.points[0]);
    if (found) {
      // There is an object under the pointer so we
      if (this.exclusiveInteractiveObject && found !== this.exclusiveInteractiveObject) return;
      this.POINTER_TARGET = found;
      this.POINTER_TARGET_ORIGIN = (_a = this.POINTER_TARGET.parent) === null || _a === void 0 ? void 0 : _a.localToGlobal(this.POINTER_TARGET.x, this.POINTER_TARGET.y);
      if ((_b = this.POINTER_TARGET.parent) === null || _b === void 0 ? void 0 : _b.isStage()) {
        this.POINTER_DOWN = this.surface.camera.projectionMatrix.inverted.transformPoint(this.POINTER_DOWN.x, this.POINTER_DOWN.y);
      }
      this.POINTER_TARGET.bubble('pointerdown', {
        point: this.POINTER_DOWN,
        pointerType: this.POINTER_TYPE
      });
    } else {
      // No objects under the pointer, so we prepare to drag the camera or do a multiple selection
      if (this.KEY_SHIFT_DOWN && this.selectionEnabled) this.POINTER_SELECTION = true;
      this.POINTER_TARGET_ORIGIN = new Point(event.offsetX - this.surface.camera.x, event.offsetY - this.surface.camera.y);
    }
  }
  pointerMoveHandler(event) {
    var _a, _b;
    if (!this.enabled || ![0, -1].includes(event.button)) return;
    event.preventDefault();
    event.stopPropagation();
    // Logic to move the debug cursor
    const cursorPoint = this.surface.stage.documentToGlobal(event.offsetX, event.offsetY);
    const pointCursor = this.surface.camera.projectionMatrix.inverted.transformPoint(cursorPoint.x, cursorPoint.y);
    this.surface.cursor.move(pointCursor);
    // Find this event in the cache and update its record with this event
    this.eventsCache.forEach((evt, index) => {
      if (this.eventsCache[index].pointerId === event.pointerId) {
        this.eventsCache[index] = event;
      }
    });
    // If two pointers are down, check for pinch gestures
    if (this.eventsCache.length === 2) {
      // Calculate the distance between the two pointers
      const curDiff = new Point(this.eventsCache[0].offsetX, this.eventsCache[0].offsetY).distance(new Point(this.eventsCache[1].offsetX, this.eventsCache[1].offsetY));
      // We zoom 'around' the center of the pinch gesture
      if (this.prevDiff > 0) {
        this.zoom((curDiff * this.surface.camera.zoom - this.PINCH_DISTANCE * this.surface.camera.zoom) * 0.002, this.PINCH_CENTER);
        this.PINCH_DISTANCE = curDiff;
      }
      // Cache the distance for the next move event
      this.prevDiff = curDiff;
      return;
    }
    // We check if it's a touch event or not and trasnform points to stage coordinate space
    if (this.isTouch) {
      this.points = this.eventsCache.map(evt => this.surface.stage.documentToGlobal(evt.offsetX, evt.offsetY));
    } else {
      this.points = [this.surface.stage.documentToGlobal(event.offsetX, event.offsetY)];
    }
    this.POINTER_LAST = this.points[0].clone();
    if (this.POINTER_DOWN) {
      // Check if
      if (!this.POINTER_MOVED && this.POINTER_DOWN.distance(this.points[0]) > POINTER_CLICK_TOLERANCE) {
        this.POINTER_MOVED = true;
      }
      if (this.POINTER_MOVED) {
        if (this.POINTER_TARGET && this.POINTER_TARGET_ORIGIN) {
          if (this.POINTER_TARGET.draggable) {
            if (!this.POINTER_DRAGGING) {
              this.POINTER_DRAGGING = true;
              this.POINTER_TARGET.bubble('dragstart');
              this.surface.canvas.style.cursor = 'grabbing';
            } else if (this.POINTER_TARGET.parent) {
              // Logic to perform the drag movement
              if ((_a = this.POINTER_TARGET.parent) === null || _a === void 0 ? void 0 : _a.isStage()) {
                this.points[0] = this.surface.camera.projectionMatrix.inverted.transformPoint(this.points[0].x, this.points[0].y);
              }
              const globalPosition = this.POINTER_TARGET.parent.localToGlobal(this.POINTER_TARGET.x, this.POINTER_TARGET.y);
              const delta = new Point(globalPosition.x - (this.POINTER_TARGET_ORIGIN.x + (this.points[0].x - this.POINTER_DOWN.x)), globalPosition.y - (this.POINTER_TARGET_ORIGIN.y + (this.points[0].y - this.POINTER_DOWN.y)));
              const newGlobalPosition = new Point(globalPosition.x - delta.x, globalPosition.y - delta.y);
              const newLocalPosition = (_b = this.POINTER_TARGET.parent) === null || _b === void 0 ? void 0 : _b.globalToLocal(newGlobalPosition.x, newGlobalPosition.y);
              this.POINTER_TARGET.move(newLocalPosition.x, newLocalPosition.y);
              this.POINTER_TARGET.bubble('dragmove', this.isTouch);
              // if multiple nodes selected, move them all as a group
              if (this.selected.length && this.selected.includes(this.POINTER_TARGET)) {
                this.selected.forEach(node => {
                  if (node !== this.POINTER_TARGET && node.parent) {
                    const nodeGlobalPosition = node.parent.localToGlobal(node.x, node.y);
                    const newNodeGlobalPosition = new Point(nodeGlobalPosition.x - delta.x, nodeGlobalPosition.y - delta.y);
                    const newNodeLocalPosition = node.parent.globalToLocal(newNodeGlobalPosition.x, newNodeGlobalPosition.y);
                    node.move(newNodeLocalPosition.x, newNodeLocalPosition.y);
                  }
                });
              }
              // We disable the interactivity of the target object so it's not catched in the childAt check
              this.POINTER_TARGET.interactive = false;
              const found = this.surface.stage.childAt(this.points[0]);
              if (found !== this.DRAG_TARGET) {
                if (this.DRAG_TARGET) {
                  this.POINTER_TARGET.bubble('dragleave', this.DRAG_TARGET);
                }
                this.DRAG_TARGET = found;
                if (this.DRAG_TARGET) {
                  this.POINTER_TARGET.bubble('dragenter', this.DRAG_TARGET);
                }
              }
              // We restore the interactivity of the target object just after the check
              this.POINTER_TARGET.interactive = true;
            }
          }
        } else if (this.POINTER_SELECTION) {
          // We do the following calculation to always ensure that the rectangle has positive width and height
          this.selectionBounds = new Rectangle();
          this.selectionBounds.x = Math.min(this.POINTER_DOWN.x, this.points[0].x);
          this.selectionBounds.y = Math.min(this.POINTER_DOWN.y, this.points[0].y);
          this.selectionBounds.width = Math.max(this.POINTER_DOWN.x, this.points[0].x) - this.selectionBounds.x;
          this.selectionBounds.height = Math.max(this.POINTER_DOWN.y, this.points[0].y) - this.selectionBounds.y;
          this.selected = this.surface.stage.childrenAt(this.selectionBounds);
          this.surface.dirty = true;
        } else if (this.POINTER_TARGET_ORIGIN && !this.surface.camera.locked) {
          // We are dragging outside of any element, so we drag the camera
          this.surface.camera.move(event.offsetX - this.POINTER_TARGET_ORIGIN.x, event.offsetY - this.POINTER_TARGET_ORIGIN.y);
        }
      }
      this.surface.stage.bubble('pointermove', {
        point: this.points[0],
        pointerType: this.POINTER_TYPE
      });
    } else {
      let found = this.surface.stage.childAt(this.points[0]);
      if (this.exclusiveInteractiveObject && found !== this.exclusiveInteractiveObject) {
        found = undefined;
      }
      this.hover = found;
      found === null || found === void 0 ? void 0 : found.bubble('pointermove', {
        point: this.points[0],
        pointerType: this.POINTER_TYPE
      });
    }
  }
  pointerUpHandler(event) {
    if (!this.enabled) return;
    event.preventDefault();
    event.stopPropagation();
    this.removeEvent(event);
    // If the number of pointers down is less than two then reset diff tracker
    if (this.eventsCache.length < 2) {
      this.prevDiff = -1;
    }
    if (this.POINTER_DOWN) {
      if (this.POINTER_TARGET) {
        this.POINTER_TARGET.bubble('pointerup', {
          point: this.POINTER_DOWN,
          pointerType: this.POINTER_TYPE
        });
        if (!this.POINTER_MOVED) {
          if (this.POINTER_CLICK_TIME && this.POINTER_TARGET === this.POINTER_CLICK_LAST && Date.now() - this.POINTER_CLICK_TIME < 400) {
            this.POINTER_CLICK_TIME = undefined;
            this.POINTER_CLICK_LAST = undefined;
            if (this.doubleClickEnabled) {
              this.POINTER_TARGET.bubble('dblclick', this.POINTER_DOWN);
            }
          } else {
            this.POINTER_CLICK_TIME = Date.now();
            this.POINTER_CLICK_LAST = this.POINTER_TARGET;
            this.POINTER_TARGET.bubble('click', this.POINTER_DOWN);
          }
          if (this.POINTER_TARGET.focusable) {
            this.selected = [];
            this.focus = this.POINTER_TARGET;
          }
        } else if (this.POINTER_TARGET.draggable) {
          this.POINTER_TARGET.bubble('dragend');
          if (this.POINTER_LAST) {
            const source = this.surface.stage.childAt(this.POINTER_DOWN);
            const target = this.surface.stage.childAt(this.POINTER_LAST);
            if (source) {
              source.bubble('drop', target);
            }
          }
          this.surface.canvas.style.cursor = this.mouseCursor;
        }
      } else if (!this.POINTER_MOVED) {
        if (this.POINTER_CLICK_TIME && this.POINTER_TARGET === this.POINTER_CLICK_LAST && Date.now() - this.POINTER_CLICK_TIME < 400) {
          this.POINTER_CLICK_TIME = undefined;
          this.POINTER_CLICK_LAST = undefined;
          if (this.doubleClickEnabled) {
            this.surface.stage.bubble('dblclick', this.POINTER_DOWN);
          }
        } else {
          this.POINTER_CLICK_TIME = Date.now();
          this.POINTER_CLICK_LAST = this.POINTER_TARGET;
          this.surface.stage.bubble('click', this.POINTER_DOWN);
        }
        if (!this.KEY_SHIFT_DOWN) {
          this.selected = [];
          this.focus = undefined;
        }
      }
    }
    if (this.selectionBounds) {
      this.selectionBounds = undefined;
      this.surface.dirty = true;
    }
    this.POINTER_DOWN = undefined;
    this.POINTER_MOVED = false;
    this.POINTER_TARGET = undefined;
    this.POINTER_TARGET_ORIGIN = undefined;
    this.POINTER_DRAGGING = false;
    this.POINTER_SELECTION = false;
    this.DRAG_TARGET = undefined;
  }
}

const PIXELRATIO = Math.round(window.devicePixelRatio || 1);
const WIDTH = 80 * PIXELRATIO;
const HEIGHT = 48 * PIXELRATIO;
const TEXT_X = 3 * PIXELRATIO;
const TEXT_Y = 2 * PIXELRATIO;
const GRAPH_X = 3 * PIXELRATIO;
const GRAPH_Y = 15 * PIXELRATIO;
const GRAPH_WIDTH = 74 * PIXELRATIO;
const GRAPH_HEIGHT = 30 * PIXELRATIO;
/** Renders graph for runtime metric. */
class StatsPanel {
  /**
   * Creates new instance of {@link StatsPanel}.
   * @param name - Name of the metric.
   * @param foreground - Foreground color of the metric.
   * @param background - Background color of the metric.
   */
  constructor(name, foreground, background) {
    this.min = Infinity;
    this.max = 0;
    this.name = name;
    this.foreground = foreground;
    this.background = background;
    this.canvas = document.createElement('canvas');
    this.canvas.width = WIDTH;
    this.canvas.height = HEIGHT;
    this.canvas.style.cssText = 'width:80px;height:48px';
    const ctx = this.canvas.getContext('2d');
    if (ctx === null) {
      throw new Error('Failed to get Canvas Context');
    }
    this.context = ctx;
    this.context.font = `bold ${9 * PIXELRATIO}px Helvetica,Arial,sans-serif`;
    this.context.textBaseline = 'top';
    this.context.fillStyle = this.background;
    this.context.fillRect(0, 0, WIDTH, HEIGHT);
    this.context.fillStyle = this.foreground;
    this.context.fillText(name, TEXT_X, TEXT_Y);
    this.context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);
    this.context.fillStyle = this.background;
    this.context.globalAlpha = 0.9;
    this.context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);
  }
  /**
   * Updates the metric.
   * @param value - New value of the metric.
   * @param maxValue - Maximum possible value of the metric.
   */
  update(value, maxValue) {
    this.min = Math.min(this.min, value);
    this.max = Math.max(this.max, value);
    this.context.fillStyle = this.background;
    this.context.globalAlpha = 1;
    this.context.fillRect(0, 0, WIDTH, GRAPH_Y);
    this.context.fillStyle = this.foreground;
    this.context.fillText(`${Math.round(value)} ${this.name} (${Math.round(this.min)}-${Math.round(this.max)})`, TEXT_X, TEXT_Y);
    this.context.drawImage(this.canvas, GRAPH_X + PIXELRATIO, GRAPH_Y, GRAPH_WIDTH - PIXELRATIO, GRAPH_HEIGHT, GRAPH_X, GRAPH_Y, GRAPH_WIDTH - PIXELRATIO, GRAPH_HEIGHT);
    this.context.fillRect(GRAPH_X + GRAPH_WIDTH - PIXELRATIO, GRAPH_Y, PIXELRATIO, GRAPH_HEIGHT);
    this.context.fillStyle = this.background;
    this.context.globalAlpha = 0.9;
    this.context.fillRect(GRAPH_X + GRAPH_WIDTH - PIXELRATIO, GRAPH_Y, PIXELRATIO, Math.round((1 - value / maxValue) * GRAPH_HEIGHT));
  }
}

/** Keeps track of runtime statistics and renders them. */
class Stats {
  /** Creates new instance of {@link Stats}. */
  constructor() {
    this.mode = 0;
    this._fps = 0;
    this._ms = 0;
    this._mem = 0;
    this.mode = 0;
    this.container = document.createElement('div');
    this.container.style.cssText = 'position:fixed;top:0;left:0;cursor:pointer;opacity:0.9;z-index:10000';
    this.container.addEventListener('click', event => {
      event.preventDefault();
      this.mode += 1;
      this.showPanel(this.mode % this.container.children.length);
    }, false);
    this.dom = this.container;
    this.beginTime = (performance || Date).now();
    this.prevTime = this.beginTime;
    this.frames = 0;
    this.fpsPanel = this.addPanel(new StatsPanel('FPS', '#0ff', '#002'));
    this.msPanel = this.addPanel(new StatsPanel('MS', '#0f0', '#020'));
    this.window = window;
    if (window.performance && this.window.performance.memory) {
      this.memPanel = this.addPanel(new StatsPanel('MB', '#f08', '#201'));
    }
    this.dom.id = 'surface-performance-stats';
    this.showPanel(0);
  }
  /**
   * Frames per second
   */
  get fps() {
    return this._fps;
  }
  /**
   * Execution time in milliseconds
   */
  get ms() {
    return this._ms;
  }
  /**
   * RAM usage in Mb
   */
  get mem() {
    return this._mem;
  }
  /**
   * Adds new {@link StatsPanel}.
   * @param panel - Instance of {@link StatsPanel}.
   * @returns Newly added instance.
   */
  addPanel(panel) {
    this.container.appendChild(panel.canvas);
    return panel;
  }
  /**
   * Makes panel with specified id visible.
   * @param id - Id of {@link StatsPanel}.
   */
  showPanel(id) {
    for (let i = 0; i < this.container.children.length; i++) {
      this.container.children[i].style.display = i === id ? 'block' : 'none';
    }
    this.mode = id;
  }
  /** Starts measurement of current frame. */
  begin() {
    this.beginTime = (this.window.performance || Date).now();
  }
  /** Ends measurement of current frame. */
  end() {
    this.frames += 1;
    const time = (performance || Date).now();
    this._ms = time - this.beginTime;
    this.msPanel.update(this._ms, 200);
    if (time >= this.prevTime + 1000) {
      this._fps = this.frames * 1000 / (time - this.prevTime);
      this.fpsPanel.update(this._fps, 100);
      this.prevTime = time;
      this.frames = 0;
      if (this.memPanel) {
        const {
          usedJSHeapSize,
          jsHeapSizeLimit
        } = this.window.performance.memory;
        this._mem = usedJSHeapSize / 1048576;
        this.memPanel.update(this._mem, jsHeapSizeLimit / 1048576);
      }
    }
    return time;
  }
  /** Ends current frame measurement and starts new one immediately. */
  update() {
    this.beginTime = this.end();
  }
}

/**
 * Ensures that only objects visible in viewport are rendered.
 */
class CullingManager {
  /**
   * Creates {@link CullingManager} instance for specified {@link Surface} instance.
   * @param surface - Reference to Surface instance.
   */
  constructor(surface) {
    this.surface = surface;
    // We initiate a helper box object to prevent memory allocations
    this._box = new Rectangle();
  }
  /**
   * Culls out objects that are not in viewport so that they are not renered.
   * @param viewportBounds - Rectangle representing the viewport.
   * @param container - Culling will be done on children of this container.
   */
  // We traverse all the display list and check which objects are elegible for culling recursively
  cull(ctx, viewportBounds, container) {
    // Shrinks the camera bounds by half to be able to visual debug it
    if (container.children) {
      container.children.forEach(child => {
        if (child.visible) {
          this._box = child.bounds.globalBounds;
          if (this.surface.debugCulling) {
            // debug global bounds
            // Draws the local transformed bounds of the child
            this.surface.stage.debugGraphics.clear();
            this.surface.stage.debugGraphics.beginFill('rgba(0, 255, 0, 0.1)').drawRect(child.bounds.globalBounds.x, child.bounds.globalBounds.y, child.bounds.globalBounds.width, child.bounds.globalBounds.height).endFill();
            this.surface.stage.debugGraphics.draw(ctx);
            this.surface.stage.debugGraphics.clear();
          }
          if (!this.surface.cull) {
            child.culled = false;
          } else {
            let cullingBounds;
            if (this.surface.debugCulling) {
              cullingBounds = new Rectangle(viewportBounds.x + viewportBounds.width / 4, viewportBounds.y + viewportBounds.height / 4, viewportBounds.width / 2, viewportBounds.height / 2);
            } else {
              cullingBounds = viewportBounds;
            }
            child.culled = !(this._box.x + this._box.width > cullingBounds.x && this._box.x < cullingBounds.x + cullingBounds.width && this._box.y + this._box.height > cullingBounds.y && this._box.y < cullingBounds.y + cullingBounds.height);
          }
          // if the child is a DisplayObjectContainer, has children, and was not culled then we recursively traverse its childen
          if (!child.culled && child instanceof DisplayObjectContainer && child.children.length >= 0) {
            this.cull(ctx, viewportBounds, child);
          }
        }
      });
    }
  }
}

const defaultSurfaceConfig = {
  width: 0,
  height: 0
};
class Surface extends EventEmitter {
  constructor(config) {
    super();
    this._isometry = 0;
    this._debug = false;
    this._width = 0;
    this._height = 0;
    this.showStats = false;
    this._dirty = true;
    this._cull = true;
    this._debugCulling = false;
    const {
      width,
      height
    } = Object.assign(Object.assign({}, defaultSurfaceConfig), config);
    this._width = width;
    this._height = height;
    this.canvas = this.createCanvasElement();
    this.canvas.setAttribute('tabindex', '0');
    const ctx = this.canvas.getContext('2d');
    if (ctx === null) {
      throw new Error('Failed to get Canvas Context');
    }
    this.stage = new Stage(this);
    this.camera = new CameraManager(this);
    this.grid = new GridManager(this);
    this.input = new InputManager(this);
    this.culling = new CullingManager(this);
    this.cursor = new Cursor();
    this.animations = new Animations(this.stage);
    // JavaScript Performance Monitor - Fps, RAM, and frame time panels
    this.stats = new Stats();
    // We initiate the rendering loop
    this.renderLoop(ctx);
  }
  get width() {
    return this._width;
  }
  set width(value) {
    if (this._width === value) return;
    this._width = value;
    this.canvas.width = Math.round(this._width * window.devicePixelRatio);
    this.canvas.style.width = `${this._width}px`;
    this.dirty = true;
  }
  get height() {
    return this._height;
  }
  set height(value) {
    if (this._height === value) return;
    this._height = value;
    this.canvas.height = Math.round(this._height * window.devicePixelRatio);
    this.canvas.style.height = `${this._height}px`;
    this.dirty = true;
  }
  get isometry() {
    return this._isometry;
  }
  set isometry(value) {
    if (this._isometry === value) return;
    const centrePoint = this.grid.viewOrigin;
    this._isometry = value;
    this.camera.projectionMatrix.strength = value;
    this.stage.updateIsometry(this._isometry);
    const movedCentrePoint = this.grid.viewOrigin;
    let delta = new Point(centrePoint.x - movedCentrePoint.x, centrePoint.y - movedCentrePoint.y);
    delta.scale(this.camera.zoom);
    delta = this.camera.projectionMatrix.transformPoint(delta.x, delta.y);
    this.camera.move(this.camera.x - delta.x, this.camera.y - delta.y);
    this.dirty = true;
  }
  /**
   * Gets the value of `surface.dirty`
   * The `dirty` property determines if the Surface object has to be redrawn in the new available frame of the renderLoop
   */
  get dirty() {
    return this._dirty;
  }
  /**
   * Sets the value of `surface.dirty`
   * The `dirty` property determines if the Surface object has to be redrawn in the new available frame of the renderLoop
   */
  set dirty(value) {
    this._dirty = value;
  }
  get debug() {
    return this._debug;
  }
  set debug(value) {
    this._debug = value;
    if (value) {
      this.stage.attach(this.cursor);
    } else {
      this.stage.detach(this.cursor);
    }
    this.dirty = true;
  }
  /**
   * Gets the value of `surface.cull`
   * The `cull` property determines if the objects outside of the viewport are rendered
   */
  get cull() {
    return this._cull;
  }
  /**
   * Sets the value of `surface.cull`
   * The `cull` property determines if the objects outside of the viewport are rendered
   */
  set cull(value) {
    this._cull = value;
    this._dirty = true;
  }
  /**
   * Gets the value of `surface.debugCulling`
   * The `debugCulling` property shrinks the culling ara to half of the viewport in order to see the objects that are being culled
   */
  get debugCulling() {
    return this._debugCulling;
  }
  /**
   * Sets the value of `surface.debugCulling`
   * The `debugCulling` property shrinks the culling ara to half of the viewport in order to see the objects that are being culled
   */
  set debugCulling(value) {
    this._debugCulling = value;
    this._dirty = true;
  }
  /**
   * Toogles the value of `showStats` if no value is provided or sets its value if it is.
   * The `showStats` property determines if the Stats panel must be shown and its values computed.
   * The renderLoop cheks the value of this property to determine if the Stats panel DOM object has to be attached/detached from the parentNode of the canvas.
   */
  toggleStats(value = !this.showStats) {
    this.showStats = value;
  }
  attachInstanceToElement(canvas) {
    return Object.defineProperty(canvas, 'surface', {
      get: () => this,
      set: () => null
    });
  }
  serialize() {
    return this.stage.serialize();
  }
  createCanvasElement() {
    const canvas = document.createElement('canvas');
    const element = this.attachInstanceToElement(canvas);
    element.width = Math.round(this._width * window.devicePixelRatio);
    element.height = Math.round(this._height * window.devicePixelRatio);
    element.style.width = `${this._width}px`;
    element.style.height = `${this._height}px`;
    element.style.touchAction = 'none';
    return element;
  }
  toggleStatsHtmlElement() {
    // check if canvas is already attached to the DOM
    if (this.canvas.parentNode) {
      if (this.showStats) {
        // check if Stats panel is already attached to the DOM, if not attach it
        if (document.getElementById('surface-performance-stats') === null) {
          this.canvas.parentNode.appendChild(this.stats.dom);
        }
      } else if (document.getElementById('surface-performance-stats') !== null) {
        // check if Stats panel is already attached to the DOM, then detach it
        document.getElementById('surface-performance-stats').remove();
      }
    }
  }
  renderFrame(ctx) {
    if (this.dirty) {
      this.dirty = false;
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      if (this.stage.style.backgroundColor) {
        ctx.fillStyle = this.stage.style.backgroundColor.toRGBA();
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      } else {
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      }
      ctx.translate(this.camera.center.x, this.camera.center.y);
      ctx.translate(this.camera.x * window.devicePixelRatio, this.camera.y * window.devicePixelRatio);
      ctx.scale(this.camera.zoom * window.devicePixelRatio, this.camera.zoom * window.devicePixelRatio);
      this.culling.cull(ctx, this.camera.bounds, this.stage);
      this.grid.render(ctx, {
        debug: this._debug
      });
      this.stage.render(ctx, {
        debug: this._debug
      });
    }
  }
  // New render loop
  renderLoop(ctx) {
    // attaches or detaches the stats panel from the Html DOM as needed
    this.toggleStatsHtmlElement();
    if (this.showStats) this.stats.begin();
    // Pre-rendering ------------------------------------------
    // Do stuff before everything is drawn
    // End Pre-rendering --------------------------------------
    // Rendering
    this.renderFrame(ctx);
    // End Rendering -----------------------------------------
    // Post-rendering -----------------------------------------
    // Do stuff after everything is drawn
    // End Post-rendering -------------------------------------
    if (this.showStats) this.stats.end();
    requestAnimationFrame(ts => {
      this.emit('frame', ts);
      // console.log(ts);
      this.renderLoop(ctx);
    });
  }
  /**
   * Destroy whole surface by detaching all attached objects (`detach` event is not emitted), removing all the
   * animations and removing canvas.
   */
  destroy() {
    this.stage.destroy();
    this.canvas.remove();
    this.emit('destroy');
  }
}

export { Animation, Animations, ArcCmd, ArcToCmd, ArrowShape, AxorShape, BackBuffer, BeginPathCmd, BezierCurveToCmd, CAPABILITIES, CameraManager, Circle, CircleCmd, CircleShape, ClosePathCmd, Color, CrossShape, Cursor, DEFAULT_TEXT_FONT, DisplayObject, DisplayObjectContainer, Easing, EllipseCmd, EventEmitter, FillCmd, Graphics, GridManager, ISOMETRIC_LENGTH, Image, InputManager, IsometricMatrix, Line, LineShape, LineToCmd, Matrix, MoveToCmd, PieShape, Point, PolygonCmd, PolystarCmd, QuadraticCurveToCmd, RectCmd, Rectangle, RectangleShape, RoundRectCmd, Shadow, ShadowShape, Shape, Stage, StageAnimations, StrokeCmd, StrokeDashCmd, StrokeStyleCmd, Style, Surface, Text, TextFillCmd, TextStrokeCmd, TextStyleCmd, defaultSurfaceConfig, delay, generateID, memoize, toDeg, toRad, undelay };
