export const start = [
`
xxxxxxxx
 uyuhxx
  yxxxy
`,
`
ttxxxx
yu ?xx
   uxx
 txxxy
 xhxx
 yyy
`
]

export const middle = [
`
wwwwwwwww
wwwwwwwww
txttx?xxt
rrrrrrrrr
`,
`
ttxxttyxu
t?xx?tuyx
xxxx?ttuy
xxxtuxxx?
`,
`
txxx?xxtt
ttxtx?tut
rrrrrrrrr
ttyxxtxtt
`,
`
utyy?xxtt
rrrrrrrrr
rrrrrrrrr
rrrrrrrrr
ttxxtx?xt
`,
`
xxyxxxxyx
wwwwwwwww
ttt?xxxxt

`
]

export const end = [
`
  yxxxy
  xtfxx
xxxtx?xx
`,
`
yyxxxxttt
uuxtfxxtu
yyxtx?xx
`
]