import { Animation, Color } from '@celonis/surface-core';
import { TILE_SIZE, LAYER_DEPTH } from './constants';
import { Tile } from './Tile';

export interface QuestDefinition {
	start: Function;
	end: Function;
}

export class Quest extends Tile {
	protected reversedFade = true;

	constructor(cx, cy, cz = 0) {
		super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, TILE_SIZE, TILE_SIZE, 0);

		this.opacity = 0;
		this.cube.style.color = new Color('##ffcb00').transparent(0);
		this.cube.style.radius = this.width / 2;
		this.cube.style.borderWidth = 4;
		this.cube.style.borderColor = new Color('#ffcb00');
		this.cube.style.borderDash = 10;
		this.cube.style.borderDashGap = 10;
		this.cube.style.borderDashOffset = 0;

		const animate = () => {
			if (!this.stage) return;

			new Animation(this.cube.style, {
				borderDashOffset: {
					plus: 20
				}
			}, {
				duration: 1000,
				complete: animate
			});
		}

		this.on('attachtostage', animate);
	}

	get walkable() {
		return true;
	}

	collect() {
		this.detach();
	}
}