import { DisplayObject, ISOMETRIC_LENGTH } from "@celonis/surface-core";
import { Point3D } from "./Point3D";

export class DisplayObject3D extends DisplayObject {
	protected _z = 0;
	protected _regZ = 0;

	constructor(x?: number, y?: number, z?: number, width?: number, height?: number) {
		super(x, y, width, height);
		this._z = z || 0;
	}

	get regZ() {
		return this._regZ;
	}
	set regZ(value) {
		if (value === this._regZ) return;
		this._regZ = value;
		this.invalidate();
	}

	get z() {
		return this._z;
	}
	set z(value) {
		if (value === this._z) return;
		this._z = value;
		this.invalidate();
		this.bubble('move');
	}

	toPoint() {
		return new Point3D(this.x, this.y, this.z);
	}

	override updateTransform() {
		super.updateTransform();
	
		if (this.stage && (this.z || this.regZ)) {
			const depth = (this.z + this.regZ) * ISOMETRIC_LENGTH * this.stage.surface.camera.projectionMatrix.strength;
			const point = this.stage.surface.camera.projectionMatrix.inverted.transformPoint(0, -depth);
			this.transform.translate(point.x, point.y);
		}
	}

	protected translateZ(ctx: CanvasRenderingContext2D, z: number) {
		const depth = z * ISOMETRIC_LENGTH * this.stage!.surface.camera.projectionMatrix.strength;
		const pt = this.stage!.surface.camera.projectionMatrix.inverted.transformPoint(0, -depth);
		ctx.translate(pt.x, pt.y);
		return ctx;
	}
}