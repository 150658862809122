import { Point } from '@celonis/surface-core';

export class Point3D extends Point {
	public z:number;

	constructor(x, y, z = 0) {
		super(x, y);
		this.z = z;
	}

	override equal(point: Point | Point3D): boolean {
		if (point instanceof Point3D) {
			return this.x === point.x && this.y === point.y && this.z === point.z;
		} else {
			return this.x === point.x && this.y === point.y;
		}
	}
}