export const chase = true;
export const clouds = true;
export const map = `
xxfxxx
xtxt
tcxxtx
xtxxx
xxxxy  xxtwx
xtxxxxxxxcwtxx
 xxxx   xxwx
xxxxt  t
xxxxx
xx  xxxxxt
xx  wwwwww
xt  cxxtxx
xxxxtxx
xxhxxx u
xxyy
xx
`