import { Color } from '@celonis/surface-core';
import { Cube } from '../Cube';
import { Car } from '../Car';

export class Van extends Car {
  private _wheel1: Cube;
  private _wheel2: Cube;
  private _front: Cube;
  private _body: Cube;
  private _leftLight: Cube;
  private _rightLight: Cube;
  private _shade: Cube;

  constructor(x, y, z) {
    const width = 30;
    const height = 30;
  
    super(x, y, z, width, height);
    
    this._shade = new Cube(0, 0, 0, width, height, 0);
    this._shade.style.color = new Color('#000').transparent(.1);
    this.attach(this._shade);

    this._front = new Cube(width - 10, -10, 5, 10, 10, 20);
    this._front.style.color = new Color('#fff');
    this.attach(this._front);
  
    this._body = new Cube(0, 0, 3, width, height, 30);
    this._body.style.color = new Color('#fff');
    this.attach(this._body);
  
    this._leftLight = new Cube(2, height - 1, 7, 2, 2, 2);
    this._leftLight.style.color = new Color('#f00');
    this.attach(this._leftLight);
  
    this._rightLight = new Cube(width - 4, height - 1, 7, 2, 2, 2);
    this._rightLight.style.color = new Color('#f00');
    this.attach(this._rightLight);
  
	  this._wheel1 = new Cube(width, -2, 0, 2, 6, 6);
    this._wheel1.style.color = new Color('#000');
    this.attach(this._wheel1);

	  this._wheel2 = new Cube(width, height - 12, 0, 2, 6, 6);
    this._wheel2.style.color = new Color('#000');
    this.attach(this._wheel2);
  }

  protected override update(): void {

  }
}