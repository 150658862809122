export const chase = false;
export const clouds = false;
export const hint = 'Collect coins and gas to progress in the game.';
export const map = `
  xtt
   fx
  xxx
u xcy
  txx u
  xhx
  xyy
`