export const chase = false;
export const clouds = true;
export const hint = 'Drive carefully backwards.';
export const zoomed = false;
export const cars = true;
export const night = {
	color: '#ccc',
	radius: 800,
	offset: 400,
	opacity: 0.8
};
export const map = `
ttt
tht
txt
txt
txt
www
www
www
www
www
xxx
rrr
rrr
rrr
rrr
rrr
xfx
`

// x - snow ground
// y - gravel ground
// t - show tree
// u - gravel tree
// c - coin
// w - water
// r - road
// f - finish
// h - hygge