import { Color } from '@celonis/surface-core';
import { Cube } from './Cube';
import { TILE_SIZE } from './constants';
import { DisplayObjectContainer3D } from './DisplayObjectContainer3D';

export class Car extends DisplayObjectContainer3D {
  private _direction = 'up';

  constructor(x, y, z, width, height) {
    super(x, y, z);

    this.isometric = true;
    this.snapToPixel = false;

    const offset = (TILE_SIZE - width) / 2;
    this.regX = -offset
    this.regY = -offset;
  }

  get direction() {
    return this._direction;
  }

  set direction(value) {
    this._direction = value;
    this.update();
  }
}