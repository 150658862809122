import { Color, ISOMETRIC_LENGTH, RenderOptions } from '@celonis/surface-core';
import { Ground } from './Ground';
import { Point3D } from './Point3D';


export class Road extends Ground {
  private _direction: 'vertical' | 'horizontal' = 'vertical';

  constructor(cx, cy, cz = 0) {
    super(cx, cy, cz);
    this.cube.style.color = new Color('#666666');
  }

  prepare() {
    super.prepare();

    const neighbors = this.neighbors;

    if (neighbors.left instanceof Road || neighbors.right instanceof Road) {
      this._direction = 'horizontal';
    } else {
      this._direction = 'vertical';
    }
  }

  get roadNeighbors() {
    const out: Road[] = [this];
    let tile;
    if (this._direction === 'vertical') {
      tile = this;
      while ((tile = tile.neighbors.top) instanceof Road) out.push(tile);
      tile = this;
      while ((tile = tile.neighbors.down) instanceof Road) out.push(tile);
    } else if (this._direction === 'horizontal') {
      tile = this;
      while ((tile = tile.neighbors.left) instanceof Road) out.push(tile);
      tile = this;
      while ((tile = tile.neighbors.right) instanceof Road) out.push(tile);
    }
    return out.sort((a, b) => {
      if (this._direction === 'horizontal') return a.x === b.x ? 0 : (a.x < b.x ? -1 : 1);
      else return a.y === b.y ? 0 : (a.y < b.y ? -1 : 1);
    });
  }

  override render(ctx: CanvasRenderingContext2D, options) {
    super.render(ctx, options);

    this.translateZ(ctx, this.depth);
    ctx.translate(this.width / 2, this.height / 2);
    
    ctx.fillStyle = '#ffffff';
    if (this._direction === 'vertical') {
      ctx.fillRect(0, -14, 3, 7);
      ctx.fillRect(0, 11, 3, 7);
    } else {
      ctx.fillRect(-14, 0, 7, 3);
      ctx.fillRect(11, 0, 7, 3);
    }
  }
}