import { Color } from '@celonis/surface-core';
import { TILE_SIZE, LAYER_DEPTH } from './constants';
import { Tile } from './Tile';

export class Water extends Tile {
  constructor(cx, cy, cz = 0) {
    super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, TILE_SIZE, TILE_SIZE, LAYER_DEPTH - 10);
    this.cube.style.color = new Color('#7fa9dc');
  }

  get waterNeighbors() {
    const out: Water[] = [this];
    let tile;
    tile = this;
    while ((tile = tile.neighbors.left) instanceof Water) out.push(tile);
    tile = this;
    while ((tile = tile.neighbors.right) instanceof Water) out.push(tile);

    return out.sort((a, b) => {
      return a.x === b.x ? 0 : (a.x < b.x ? -1 : 1);
    });
  }

  get walkable() {
    return true;
  }
}