import { Animation, Color, DEFAULT_TEXT_FONT, Point, Surface } from "@celonis/surface-core";
import { COIN_COLOR, GAS_COLOR, GAS_CRITICAL_COLOR, HEART_COLOR, LAYER_DEPTH } from "./constants";
import { Hygge } from "./Hygge";
import { roundRect } from './utils';

const HEART_PATH = new Path2D('M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z');
export class UI {
	private _surface: Surface;
	public coins: number = 0;
	public gas: number = 100;
	public hearts: number = 3;
	public hint: string;
	public level: number = 1;
	public fail: string;
	public nightIntensity = 0;
	public nightColor = '#000';
	public nightRadius = 600;
	public nightOffset = 0;
	public nightOpacity = 1;

	constructor(private _hygge: Hygge) {
		this._surface = _hygge.surface;

		const originalRenderFrame = this._surface.renderFrame;
		this._surface.renderFrame = (ctx) => {
			ctx.save();
			originalRenderFrame.call(this._surface, ctx);
			ctx.restore();
			this.render(ctx);
		}
	}

	get animations() {
	  return this._surface.stage.animations;
	}

	get night() {
		return this.nightIntensity > 0;
	}

	set night(value: any) {
		if (value){
			if(value.color) this.nightColor = value.color;
			if (value.radius) this.nightRadius = value.radius;
			if (value.offset) this.nightOffset = value.offset;
			if (value.opacity) this.nightOpacity = value.opacity;
		}

		new Animation(this as UI, {
			nightIntensity: value ? 1 : 0
		}, {
			easing: 'easeInOutQuad',
			duration: 1000
		})
	}

	render(context: CanvasRenderingContext2D) {
		context.save();
		context.scale(window.devicePixelRatio / 2, window.devicePixelRatio / 2);

		const canvasWidth = this._surface.canvas.width * (2 / window.devicePixelRatio);
		const canvasHeight = this._surface.canvas.height * (2 / window.devicePixelRatio);
		const playerBounds = this._hygge.player?.bounds.globalBounds;

		// Night


		context.save();
		context.fillStyle = this.nightColor;
		context.globalAlpha = this.nightIntensity * this.nightOpacity;
		context.beginPath();
		context.rect(0, 0, canvasWidth, canvasHeight);
		if (playerBounds && this._hygge.player!.visible && this._hygge.player!.opacity > 0) {
			// TODO: Replace with stage.globalToDocument when fixed
			const point = new Point(playerBounds.x + this._hygge.player!.width / 2, playerBounds.y + this._hygge.player!.height / 2)
			.scale(this._surface.camera.zoom)
			.translate(this._surface.camera.center.x + this._surface.camera.x, this._surface.camera.center.y + this._surface.camera.y)
			// .scale(1 / window.devicePixelRatio);
			
			context.translate(point.x, point.y - LAYER_DEPTH * 2);
			context.transform(...this._surface.camera.projectionMatrix.m);
			context.arc(40, -150 + this.nightOffset, this.nightRadius, 0, Math.PI * 2, true);
		}
		context.fill();
		context.restore();

		context.save();
		context.translate(canvasWidth - 140, 80);
	
		context.beginPath();
		context.arc(0, 0, 20, 0, Math.PI * 2);
		context.closePath();
		context.fillStyle = COIN_COLOR;
		context.strokeStyle = new Color(COIN_COLOR).darker(30).toHex();
		context.lineWidth = 6;
		context.fill();
		context.stroke();

		context.font = `bold 30px ${DEFAULT_TEXT_FONT}`;
		context.fillStyle = '#fff';
		context.strokeStyle = 'rgba(0, 0, 0, .4)';
		context.strokeText(this.coins.toString(), 40, 10);
		context.fillText(this.coins.toString(), 40, 10);

		context.translate(-280, 0);
	
		context.beginPath();
		context.strokeStyle = '#fff';
		context.lineWidth = 5;
		roundRect(context, 0, -20, 90, 40, 10);
		context.stroke();

		context.beginPath();
		context.fillStyle = this.gas < 20 ? GAS_CRITICAL_COLOR : GAS_COLOR;
		roundRect(context, 5, -15, 80 * this.gas / 100, 30, 8);
		context.fill();

		context.font = `bold 30px ${DEFAULT_TEXT_FONT}`;
		context.fillStyle = '#fff';
		context.strokeStyle = 'rgba(0, 0, 0, .4)';
		context.lineWidth = 5;
		context.strokeText(`${Math.round(this.gas)}%`, 110, 10);
		context.fillText(`${Math.round(this.gas)}%`, 110, 10);

		const scale = 40 / 512;
		context.translate(-120, -20);
		context.scale(scale, scale);

		for (let i = 0; i < this.hearts; i++) {
			if (i > 0) context.translate(-60 / scale, 0);
			context.fillStyle = HEART_COLOR;
			context.strokeStyle = new Color(HEART_COLOR).darker(30).toHex();
			context.lineWidth = 6 / scale;
			context.fill(HEART_PATH);
			context.stroke(HEART_PATH);
		}
	
		context.restore();
		context.save();
		context.translate(canvasWidth / 2 - 550, canvasHeight - 150);

		const offsetX = 50;
		const offsetY = 30;

		for (let i = 0; i < 24; i++) {
			context.globalAlpha = i < this.level ? 1 : (.2);
			context.beginPath();
			context.arc(offsetX * i, i % 2 ? offsetY : 0, i + 1 === this.level ? 30 : 20, 0, Math.PI * 2);
			context.closePath();
			context.fillStyle = '#fff';
			context.strokeStyle = new Color('#fff').darker(30).toHex();
			context.lineWidth = 6;
			context.fill();

			context.globalAlpha = i < this.level ? .5 : .1;
			context.font = `bold 23px ${DEFAULT_TEXT_FONT}`;
			context.textAlign = 'center';
			context.fillStyle = '#000';
			context.fillText(String(i + 1), offsetX * i, (i % 2 ? offsetY : 0) + 8);
		}

		context.restore();

		if (this.fail) {
			context.save();
			context.translate(canvasWidth / 2 - 100, 300);

			context.font = `bold 100px ${DEFAULT_TEXT_FONT}`;
			context.textAlign = 'center';
			context.fillStyle = '#fb4e4e';
			context.strokeStyle = 'rgba(255, 255, 255, .6)';
			context.lineWidth = 8;
			context.strokeText(`${this.fail}`, 110, 10);
			context.fillText(`${this.fail}`, 110, 10);

			context.restore();
		} else if (this.hint) {
			context.save();
			context.translate(canvasWidth / 2 - 100, 300);

			context.font = `bold 40px ${DEFAULT_TEXT_FONT}`;
			context.textAlign = 'center';
			context.fillStyle = '#fff';
			context.strokeStyle = 'rgba(0, 0, 0, .4)';
			context.lineWidth = 5;
			context.strokeText(`${this.hint}`, 110, 10);
			context.fillText(`${this.hint}`, 110, 10);

			context.restore();
		}

		context.restore();
	}
}