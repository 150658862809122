import { Animation, TChild } from '@celonis/surface-core';
import { Point3D } from './Point3D';
import { Cube } from './Cube';
import { TILE_SIZE, LAYER_DEPTH } from "./constants";
import { rand } from './utils';
import { DisplayObjectContainer3D } from './DisplayObjectContainer3D';

export interface Neighbors {
  top: TChild | undefined,
  bottom: TChild | undefined,
  left: TChild | undefined,
  right: TChild | undefined,
  above: TChild | undefined,
  under: TChild | undefined
}

export class Tile extends DisplayObjectContainer3D {
  public cube: Cube;

  protected reversedFade = false;

  constructor(x, y, z, width, height, depth) {
		super(x, y, z, width, height);

		this.snapToPixel = false;
		this.isometric = true;

    this.cube = new Cube(0, 0, 0, width, height, depth);
    this.attach(this.cube);
	}

  get coords() {
    return new Point3D(
      Math.round(this.x / TILE_SIZE),
      Math.round(this.y / TILE_SIZE),
      Math.round(this.z / LAYER_DEPTH)
    )
  }

  get depth() {
    return this.cube.depth;
  }

  get walkable() {
    return false;
  }

  get trap() {
    return false;
  }

  get neighbors(): Neighbors {
    const coords = this.coords;
    const out: Neighbors = {
      top: undefined,
      bottom: undefined,
      left: undefined,
      right: undefined,
      above: undefined,
      under: undefined,
    }

    this.parent!.children.forEach(child => {
      if (!(child instanceof Tile)) return;

      if (!out.top && child.coords.equal(new Point3D(coords.x, coords.y - 1, coords.z))) {
        out.top = child;
      } else if (!out.bottom && child.coords.equal(new Point3D(coords.x, coords.y + 1, coords.z))) {
        out.bottom = child;
      } else if (!out.left && child.coords.equal(new Point3D(coords.x - 1, coords.y, coords.z))) {
        out.left = child;
      } else if (!out.right && child.coords.equal(new Point3D(coords.x + 1, coords.y, coords.z))) {
        out.right = child;
      } else if (!out.right && child.coords.equal(new Point3D(coords.x, coords.y, coords.z + 1))) {
        out.above = child;
      } else if (!out.right && child.coords.equal(new Point3D(coords.x, coords.y, coords.z - 1))) {
        out.under = child;
      }
    })

    return out;
  }

  fadeIn(done?) {
    const regZ = this.regZ;
    this.regZ = regZ + (this.reversedFade ? 100 : -300);
    this.opacity = 0;
    new Animation(this as Tile, {
      opacity: 1,
      regZ: regZ
    }, {
      duration: 500,
      delay: rand(500),
      easing: 'easeOutCirc',
      complete: done
    });
  }

  fadeOut(done?) {
    const regZ = this.regZ;
    new Animation(this as Tile, {
      opacity: 0,
      regZ: this.regZ - (this.reversedFade ? -100 : 300)
    }, {
      duration: 500,
      delay: rand(500),
      easing: 'easeInCirc',
      complete: () => {
        this.regZ = regZ;
        if (done) done();
      }
    });
  }

  prepare() {
    //
  }

  zoom(animate = false, done?) {
    this.updateBounds();

    const bounds = this.bounds.globalBounds.center;
    const zoom = this.stage!.surface.camera.zoom;

    if (!animate) {
      this.stage!.surface.camera.move(-bounds.x * zoom, -bounds.y * zoom);
      if (done) done();
    } else {
      this.stage!.surface.camera.panTo(-bounds.x * zoom, -bounds.y * zoom, 1000);
      if (done) setTimeout(done, 1000);
    }
  }
}