import { Color } from '@celonis/surface-core';
import { TILE_SIZE, LAYER_DEPTH } from './constants';
import { Tile } from './Tile';
export class Log extends Tile {
  constructor(x, y, z = 0) {
    super(x, y, z, TILE_SIZE, TILE_SIZE, 10);
    this.regZ = 10;

    this.cube.y = 15;
    this.cube.height -= this.cube.y * 2;
    this.cube.style.color = new Color('#896a57');
  }

  get zIndex() {
    return 1;
  }

  get walkable() {
    return true;
  }
}