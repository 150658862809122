import { Color, Point, ISOMETRIC_LENGTH, Style, Rectangle } from '@celonis/surface-core';
import { DisplayObject3D } from "./DisplayObject3D";

interface CubeStyle extends Style {
	radiusTopLeft?: number;
	radiusTopRight?: number;
	radiusBottomRight?: number;
	radiusBottomLeft?: number;
	radius?: number;
	sideColor?: Color
	sideColorLight?: Color
	sideColorDark?: Color
	shadowColor?: Color
	shadowBlur?: number
	shadowOffsetX?: number
	shadowOffsetY?: number
	borderDash?: number
	borderDashGap?: number
	borderDashOffset?: number
}

export class Cube extends DisplayObject3D {
	override style!: CubeStyle;

	private _depth: number;

	constructor(x, y, z, width, height, depth) {
		super(x, y, z, width, height);
		this._depth = depth;
		this.isometric = true;
		this.snapToPixel = false;
	}

	get depth() {
		return this._depth;
	}

	set depth(value) {
		if (value === this._depth) return;
		this._depth = value;
		this.invalidate();
		this.bubble('resize');
	}

	render(ctx: CanvasRenderingContext2D, options) {
		super.render(ctx, options);
		if (!this.stage) return;

		ctx.save();

		const strength = this.stage.surface.camera.projectionMatrix.strength;
		const radius = [
			this.style.actual.radiusTopLeft || this.style.actual.radius || 0,
			this.style.actual.radiusTopRight || this.style.actual.radius || 0,
			this.style.actual.radiusBottomRight || this.style.actual.radius || 0,
			this.style.actual.radiusBottomLeft || this.style.actual.radius || 0
		];
		let p7 = new Point(0, 0);

		if (this.stage.surface.input.focus === this) {
			ctx.fillStyle = '#0000ff';
			ctx.fillRect(-10, -10, this.width + 20, this.height + 20);
		}

		let paintStroke = false;
		if (this.style.actual.borderWidth && this.style.actual.borderWidth > 0 && this.style.actual.borderColor) {
			paintStroke = true;
			ctx.lineWidth = this.style.actual.borderWidth;
			ctx.strokeStyle = Color.from(this.style.actual.borderColor).toRGBA();
			if (this.style.actual.borderCap) {
				ctx.lineCap = this.style.actual.borderCap;
			}
			if (this.style.actual.borderDash || this.style.actual.borderDashGap) {
				ctx.setLineDash([this.style.actual.borderDash || 15, this.style.actual.borderDashGap || 10]);
				ctx.lineDashOffset = this.style.actual.borderDashOffset || 0;
			}
		}
		
		if (this.style.actual.shadowColor && this.style.actual.shadowBlur) {
			ctx.shadowColor = Color.from(this.style.actual.shadowColor).toRGBA();
			ctx.shadowBlur = this.style.actual.shadowBlur || 0;
			ctx.shadowOffsetX = this.style.actual.shadowOffsetX || 0;
			ctx.shadowOffsetY = this.style.actual.shadowOffsetY || 0;
		}
		
		if (this.depth > 0 && strength > 0) {
			const angle = 45 * strength * Math.PI / 180;
			const depth = -this.depth * ISOMETRIC_LENGTH * strength;

			const p1 = new Point(radius[3] - Math.cos(angle) * radius[3], this.height - radius[3] + Math.sin(angle) * radius[3]);
			const p2 = new Point(this.width - radius[1] + Math.cos(-angle) * radius[1], radius[1] + Math.sin(-angle) * radius[1]);
			const p3 = this.stage.surface.camera.projectionMatrix.transformPoint(p2.x, p2.y).translate(0, depth);
			const p4 = this.stage.surface.camera.projectionMatrix.transformPoint(p1.x, p1.y).translate(0, depth);
			const p5 = this.stage.surface.camera.projectionMatrix.inverted.transformPoint(p3.x, p3.y);
			const p6 = this.stage.surface.camera.projectionMatrix.inverted.transformPoint(p4.x, p4.y);
			p7 = this.stage.surface.camera.projectionMatrix.inverted.transformPoint(0, depth);

			const sideColorLight = this.style.actual.sideColorLight || this.style.actual.sideColor || Color.from(this.style.actual.color).darker(10);
			const sideColorDark = this.style.actual.sideColorDark || this.style.actual.sideColor || Color.from(this.style.actual.color).darker(30);

			// Calculate gradient
			const p8 = this.stage.surface.camera.projectionMatrix.inverted.transformPoint(this.width + (-this.width * 2 * Math.sin(Math.PI / 2) * strength), 0);
			const p9 = this.stage.surface.camera.projectionMatrix.inverted.transformPoint(this.width, 0);
			const grdScale = radius[2] / this.width / 2;
			const grd = ctx.createLinearGradient(p8.x, p8.y, p9.x, p9.y);
			const ratio = this.width / (this.width + this.height);
			grd.addColorStop(ratio - grdScale, sideColorLight.toRGBA());
			grd.addColorStop(ratio + grdScale, sideColorDark.toRGBA());

			if (options.debug) {
				ctx.beginPath();
				ctx.fillStyle = '#ff0000';
				ctx.arc(p8.x, p8.y, 5, 0, Math.PI * 2);
				ctx.arc(p9.x, p9.y, 5, 0, Math.PI * 2);
				ctx.fill();
			}

			ctx.beginPath();
			// Bottom part
			ctx.moveTo(0 + radius[0], 0);
			ctx.arcTo(this.width, 0, this.width, this.height, radius[1]);
			ctx.arcTo(this.width, this.height, 0, this.height, radius[2]);
			ctx.arcTo(0, this.height, 0, 0, radius[3]);
			ctx.arcTo(0, 0, this.width, 0, radius[0]);

			// Middle part
			ctx.moveTo(p6.x, p6.y);
			ctx.lineTo(p5.x, p5.y);
			ctx.lineTo(p2.x, p2.y);
			ctx.lineTo(p1.x, p1.y);

			// Top part
			ctx.moveTo(p7.x + radius[0], p7.y);
			ctx.arcTo(p7.x + this.width, p7.y, p7.x + this.width, p7.y + this.height, radius[1]);
			ctx.arcTo(p7.x + this.width, p7.y + this.height, p7.x, p7.y + this.height, radius[2]);
			ctx.arcTo(p7.x, p7.y + this.height, p7.x, p7.y, radius[3]);
			ctx.arcTo(p7.x, p7.y, p7.x + this.width, p7.y, radius[0]);

			// Clear content behind cube
			/*const originalAlpha = ctx.globalAlpha;
			ctx.globalCompositeOperation = 'destination-out';
			ctx.globalAlpha = 1 - originalAlpha;
			ctx.fill();
			ctx.globalCompositeOperation = 'source-over';
			ctx.globalAlpha = originalAlpha;*/

			// Draw cube
			ctx.fillStyle = grd;
			ctx.fill();
			ctx.shadowBlur = 0;
			if (paintStroke) ctx.stroke();
		}

		// Top part
		ctx.beginPath();
		ctx.fillStyle = ctx.fillStyle = Color.from(this.style.actual.color).toRGBA();
		ctx.moveTo(p7.x + radius[0], p7.y);
		ctx.arcTo(p7.x + this.width, p7.y, p7.x + this.width, p7.y + this.height, radius[1]);
		ctx.arcTo(p7.x + this.width, p7.y + this.height, p7.x, p7.y + this.height, radius[2]);
		ctx.arcTo(p7.x, p7.y + this.height, p7.x, p7.y, radius[3]);
		ctx.arcTo(p7.x, p7.y, p7.x + this.width, p7.y, radius[0]);
		ctx.fill();
		if (paintStroke) ctx.stroke();

		ctx.restore();
	}
}