import { Animation, Surface } from '@celonis/surface-core';
import { Point3D } from './Point3D';
import { TILE_SIZE, LAYER_DEPTH, PLAYER_MOVE_DURATION } from './constants';
import { Tile } from './Tile';
import { Van } from './cars/Van';
import { Car } from './Car';

export class Player extends Tile {
  public readonly car: Car;

  private _ground?: Tile;
  private _initialCoords: Point3D;
  private _locked = false;

  protected reversedFade = true;
  protected movementAnimation;

  constructor(cx, cy, cz = 0) {
    super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, TILE_SIZE, TILE_SIZE, 0);

    this.opacity = 0;
    this._initialCoords = new Point3D(cx, cy, cz);
    this.follow = this.follow.bind(this);
    this.unfollow = this.unfollow.bind(this);
    this.cube.visible = false;
    this.car = new Van(0, 0, 0);

    this.attach(this.car);
  }

  get locked() {
    return this._locked;
  }

  set locked(value) {
    this._locked = value;
  }

  init(cx, cy, cz) {
    this.move(cx * TILE_SIZE, cy * TILE_SIZE);
    this.z = cz * LAYER_DEPTH;
    this._initialCoords = new Point3D(cx, cy, cz);
  }

  fall(done?) {
    this.lock();

    // TODO: Play fall sound.
    const z = this.z;
    new Animation(this, {
      opacity: 0,
      z: -300
    }, {
      duration: 500,
      easing: 'easeInCirc',
      completeOnInterrupt: true,
      complete() {
        this.z = z;
        this.emit('dead');
        if (done) done();
      }
    });
  }

  crash(done?) {
    this.lock();
    
    this.emit('dead');
  }

  drown(done?) {
    this.lock();
    
    // TODO: Play drown sound
    const z = this.z;
    new Animation(this, {
      z: -100
    }, {
      duration: 300,
      easing: 'easeInCirc',
      completeOnInterrupt: true,
      complete() {
        this.z = z;
        this.emit('dead');
        if (done) done();
      }
    });
  }

  lock() {
    this._locked = true;
  }

  unlock() {
    this._locked = false;
  }

  moveTo(cx: Tile | number, cy?: number, cz?: number, done?) {
    this._ground?.off('move', this.follow);
    this._ground?.off('detach', this.unfollow);

    if (cx instanceof Tile) {
      this._ground = cx;
      cz = cx.coords.z + (cx.depth / LAYER_DEPTH);
      cy = cx.coords.y;
      cx = cx.coords.x;
    } else {
      this._ground = undefined;
    }
  
    this.movementAnimation = new Animation(this as Player, {
      x: cx * TILE_SIZE,
      y: cy! * TILE_SIZE,
      z: (cz || 0) * LAYER_DEPTH
    }, {
      duration: PLAYER_MOVE_DURATION,
      completeOnInterrupt: true,
      complete: done
    });

    this._ground?.on('move', this.follow);
    this._ground?.on('detach', this.unfollow);
  }
  
  reset() {
    this.movementAnimation?.stop();
    this.move(this._initialCoords.x * TILE_SIZE, this._initialCoords.y * TILE_SIZE);
    this.opacity = 1;
    this.z = this._initialCoords.z * LAYER_DEPTH;
  }

  private follow(event) {
    this.move(event.target.x, event.target.y);
  }

  private unfollow() {
    this.lock();
    this.fall(() => {
      this.unlock();
      this.emit('dead');
    })
  }
}