export const chase = true;
export const clouds = true;
export const hint = 'Keep Hygge on the screen.';
export const map = `
  xxx
  ufx
  xtx
  xuc
u uux
t xxx 
 xxtt
 x xx
 xxtt
u xcy
  ttx u
  xhx
  xyy
`