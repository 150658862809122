import { Surface, EventEmitter, Line, CameraManager, Animation, Point } from '@celonis/surface-core';
import { Point3D } from './Point3D';
import { Log } from './Log';
import { GAS_CONSUMPTION, PLAYER_MOVE_DURATION, TILE_SIZE } from './constants';
import { Tile } from './Tile';
import { Ground } from './Ground';
import { Player } from './Player';
import { Tree } from './Tree';
import { Water } from './Water';
import { Cloud } from './Cloud';
import { rand, randColor } from './utils';
import { Shadow } from './Shadow';
import { Snow } from './snow';
import { Coin } from './Coin';
import { UI } from './ui';
import { Finish } from './Finish';
import { Porsche } from './cars/Porsche';
import { Quest, QuestDefinition } from './Quest';
import { Road } from './Road';
import { Car } from './Car';
import { Van } from './cars/Van';
import { generator } from './Generator';

CameraManager.MAX_ZOOM = 8;

const finishSound = new URL("../../assets/sounds/finish.mp3", import.meta.url).toString();
const snowSound = new URL("../../assets/sounds/snow.mp3", import.meta.url).toString();

export class Hygge extends EventEmitter {
  public surface: Surface;
  public player: Player;
  public finish?: Finish;
  public coins: Coin[] = [];
  public ui: UI;

  private _chase = false;
  private _lastFrameDate: number;
  private _quest: QuestDefinition;
  private _carTimers: number[] = [];
  private _logTimers: number[] = [];
  private _carAnimations: Animation<Car>[] = [];
  private _logAnimations: Animation<Log>[] = [];

  constructor() {
    super();

    this.onFrame = this.onFrame.bind(this);
  
    // Surface content

    const surface = this.surface = new Surface();
    surface.camera.locked = true;
    surface.camera.zoom = 2;
    surface.isometry = 1;
    surface.grid.size = TILE_SIZE;
    surface.grid.visible = false;
    this.surface.on('frame', this.onFrame);

    this.surface.stage.on('click', event => {
      if (!this.player) return;
      const bounds = this.player.bounds.globalBounds;
      const angle = new Line(bounds.x, bounds.y, event.payload.x, event.payload.y).angle;
      
      if (angle >= -75 && angle < 15) {
        this.movePlayer('up');
      } else if (angle >= -165 && angle < -75) {
        this.movePlayer('left');
      } else if (angle >= 15 && angle < 105) {
        this.movePlayer('right');
      } else {
        this.movePlayer('down');
      }
    });
  
    new Snow(surface);
    this.ui = new UI(this);
    
    // TODO: Report to team, this should not be necessary
    surface.canvas.style.width = '';
    surface.canvas.style.height = '';
    function resize() {
      surface.width = window.innerWidth;
      surface.height = window.innerHeight;
    }
    window.addEventListener('resize', resize);
		setTimeout(() => {
      resize()
    }, 1);

    surface.stage.sortChildren = function() {
      this.children = this.children.sort((a, b) => {
        if (a.z < b.z) return -1;
        if (a.z > b.z) return 1;
        if (a.y < b.y) return -1;
        if (a.y > b.y) return 1;
        if (a.regZ < b.regZ) return -1;
        if (a.regZ > b.regZ) return 1;
        if (a.x < b.x) return -1;
        if (a.x > b.x) return 1;
        return 0;
      });
    }

    surface.stage.onBubbling('move', (event) => {
      if (surface.stage.children.indexOf(event.target)) {
        // If the sender is direct child, reorder
        surface.stage.sortChildren();
      }
    });

    window.addEventListener('keydown', (event) => {
      switch (event.key) {
        case 'ArrowUp': this.movePlayer('up'); break;
        case 'ArrowDown': this.movePlayer('down'); break;
        case 'ArrowLeft': this.movePlayer('left'); break;
        case 'ArrowRight': this.movePlayer('right'); break;
        default: return;
      }
    });

    this.player = new Player(0, 0, 0);
    this.player.on('dead', this.restart.bind(this));
  }

  movePlayer(direction) {
    if (this.player.locked) return;
  
    let target;
    switch (direction) {
      case 'up': target = new Point3D(this.player.coords.x, this.player.coords.y - 1); break;
      case 'down': target = new Point3D(this.player.coords.x, this.player.coords.y + 1); break;
      case 'left': target = new Point3D(this.player.coords.x - 1, this.player.coords.y); break;
      case 'right': target = new Point3D(this.player.coords.x + 1, this.player.coords.y); break;
      default: return;
    }

    this.player.car.direction = direction;
  
    const tilesOnTarget: Tile[] = this.surface.stage.find(Tile).filter(tile => {
      if (tile.trap) return false;
      return tile.coords.x === target.x && tile.coords.y === target.y
    }) as Tile[];
    if (!tilesOnTarget.length) {
      // No tile, player is falling down.
      this.player.lock();
      this.player.moveTo(target.x, target.y, this.player.coords.z, () => {
        this.player.fall();
      })
  
      return;
    }
  
    if (tilesOnTarget.some((tile) => tile instanceof Tree)) {
      return;
    }
  
    const topMostCube = tilesOnTarget.sort((a, b) => {
      if (b.z === a.z) return b.zIndex - a.zIndex;
      return b.z - a.z
    })[0] as Tile;

    if (!topMostCube.walkable) {
      return;
    }

    if (topMostCube instanceof Water) {
      // Water, player is drowning.
      this.player.lock();
      this.player.moveTo(target.x, target.y, this.player.coords.z, () => {
        this.player.drown();
      })
  
      return;
    }
    
    if (topMostCube instanceof Coin) {
      topMostCube.collect();
      this.ui.coins++;
    }

    if (topMostCube instanceof Quest) {
      topMostCube.collect();
      this._quest?.start(this);
    }

    if (topMostCube instanceof Finish) {
      topMostCube.detach();
      this.player.lock();
      setTimeout(() => {
        this.onFinish();
      }, 200);
    }

    this.ui.gas -= GAS_CONSUMPTION;
    if (this.ui.gas <= 0) {
      this.player.lock();
      setTimeout(this.gameover.bind(this, 'Out of gas :('), PLAYER_MOVE_DURATION);
    }

    new Audio(snowSound).play();
    this.player.moveTo(topMostCube);
  }
  
  private initMap(map) {
    if (!(typeof map === 'string')) map = generator(map);
  
    // Split source and change spaces to nulls
    const rows = map.split(/\n/g).map(row => {
      return row.split('').map(char => {
        return char === ' ' ? null : char
      });
    });

    const shadows = new Set();
    const hasShadow = ['x', 'w', 't', 'u', 'y'];
    const addShadow = (x, y) => {
      if (!shadows.has(`${x}.${y}`)) {
        shadows.add(`${x}.${y}`);
        this.surface.stage.attach(new Shadow(x, y, -1));
      }
    }
  
    const z = 1;
    for (let y = 0; y < rows.length; y++) {
      for (let x = 0; x < rows[y].length; x++) {
        if (hasShadow.includes(rows[y][x])) {
          addShadow(x, y);
          addShadow(x + 1, y);
          addShadow(x, y + 1);
          addShadow(x + 1, y + 1);
          addShadow(x + 1, y - 1);
          addShadow(x - 1, y);
          addShadow(x, y - 1);
          addShadow(x - 1, y - 1);
          addShadow(x - 1, y + 1);
        }
        switch (rows[y][x]) {
          case 'y': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            break;
          }
          case 'x': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Ground(x, y, z));
            break;
          }
          case 'r': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Road(x, y, z));
            break;
          }
          case 'h': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Ground(x, y, z));
            this.surface.stage.attach(this.player);
            this.player.init(x, y, z + 1);
            break;
          }
          case 'p': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Ground(x, y, z));
            this.surface.stage.attach(new Porsche(x, y, z + 1));
            break;
          }
          case 'q': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Ground(x, y, z));
            this.surface.stage.attach(new Quest(x, y, z + 1));
            break;
          }
          case 'w': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Water(x, y, z));
            break;
          }
          case 't': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Ground(x, y, z));
            this.surface.stage.attach(new Tree(x, y, z + 1));
            break;
          }
          case 'u': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Tree(x, y, z));
            break;
          }
          case 'c': {
            const coin = new Coin(x, y, z + 1);
            this.coins.push(coin)
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Ground(x, y, z));
            this.surface.stage.attach(coin);
            break;
          }
          case 'f': {
            this.surface.stage.attach(new Ground(x, y, z - 1));
            this.surface.stage.attach(new Ground(x, y, z));
            this.finish = new Finish(x, y, z + 1);
            this.surface.stage.attach(this.finish);
            break;
          }
          default: {
            if (rows[y][x]) throw new Error(`Invalid map token '${rows[y][x]}' at ${x}.${y}.`);
          }
        };
      }
    }

    this.surface.stage.children.forEach(child => {
      if (!(child instanceof Tile)) return;
      child.prepare();
    });
  }

  private initClouds() {
    const ground = this.surface.stage.find(Ground);
    
    for (let i = 0; i < 8; i++) {
      const tile = ground[rand(ground.length)];
      if (tile) this.surface.stage.attach(new Cloud(tile.coords.x, tile.coords.y, 10));
    }
  }

  private initCars() {
    const roads: Road[] = this.surface.stage.find(Road);
    const groups: Array<Road[]> = [];

    while (roads.length) {
      const road = roads.pop();
      const group = road!.roadNeighbors;
      group.forEach(r => {
        const i = roads.indexOf(r);
        if (i !== -1) roads.splice(i, 1);
      });
      groups.push(group);
    }

    for (const group of groups) {
      const direction = ['start', 'end'][Math.round(Math.random())];
      const offset = direction === 'start' ? TILE_SIZE : -TILE_SIZE;
      const speed = group.length * (500 + rand(1000));
    
      const startCar = () => {
        const start = direction === 'start' ? group[0] : group[group.length - 1];
        const end = direction === 'start' ? group[group.length - 1] : group[0];
        const car = new Porsche(start.x - offset, start.y, start.z + start.depth, randColor());
        car.direction = direction === 'start' ? 'right' : 'left';
        car.opacity = 0;
        this.surface.stage.attach(car);

        new Animation(car, {
          opacity: 1
        }, {
          easing: 'easeInCirc',
          duration: 300
        })

        const anim = new Animation(car, {
          x: end.x + offset,
          y: end.y
        }, {
          easing: 'linear',
          duration: speed,
          completeOnInterrupt: true,
          complete(interrupted) {
            if (interrupted) car.detach();
          },
          tick: () => {
            if (this.player.locked) return;

            const distance = new Point(car.x, car.y).distance(new Point(this.player.x, this.player.y));
            if (distance < TILE_SIZE / 2) {
              // TODO: Play Crash Sound
              this.player.crash();
              anim.stop();
              car.detach();
            }
          }
        });
        this._carAnimations.push(anim);

        setTimeout(() => {
          if (!car.parent) return;
  
          new Animation(car, {
            opacity: 0
          }, {
            easing: 'easeInCirc',
            duration: 300,
            complete() {
              car.detach();
            }
          })
        }, speed - 300);

        this._carTimers.push(setTimeout(startCar, 1000 + Math.random() * 6000));
      }
      startCar();
    }
  }

  private terminateCars() {
    this._carTimers.forEach(timer => clearTimeout(timer));
    this._carTimers = [];
    this._carAnimations.forEach(anim => anim.stop(true));
    this._carAnimations = [];
  }

  private initLogs() {
    const waters: Water[] = this.surface.stage.find(Water);
    const groups: Array<Water[]> = [];

    while (waters.length) {
      const water = waters.pop();
      const group = water!.waterNeighbors;
      group.forEach(r => {
        const i = waters.indexOf(r);
        if (i !== -1) waters.splice(i, 1);
      });
      groups.push(group);
    }

    for (const group of groups) {
      const direction = ['start', 'end'][Math.round(Math.random())];
      const offset = direction === 'start' ? TILE_SIZE : -TILE_SIZE;
      const speed = group.length * (1000 + rand(2000));

      const startLog = () => {
        const start = direction === 'start' ? group[0] : group[group.length - 1];
        const end = direction === 'start' ? group[group.length - 1] : group[0];
        const log = new Log(start.x - offset, start.y, start.z);
        log.opacity = 0;
        this.surface.stage.attach(log);

        new Animation(log, {
          opacity: 1
        }, {
          easing: 'easeInCirc',
          duration: 300
        })

        const anim = new Animation(log, {
          x: end.x + offset,
          y: end.y
        }, {
          easing: 'linear',
          duration: speed,
          completeOnInterrupt: true,
          complete(interrupted) {
            if (interrupted) log.detach();
          }
        });
        this._logAnimations.push(anim);

        setTimeout(() => {
          if (!log.parent) return;
  
          new Animation(log, {
            opacity: 0
          }, {
            easing: 'easeInCirc',
            duration: 300,
            complete() {
              log.detach();
            }
          })
        }, speed - 300);

        this._logTimers.push(setTimeout(startLog, 1000 + Math.random() * 3000));
      }
      startLog();
    }
  }

  private terminateLogs() {
    this._logTimers.forEach(timer => clearTimeout(timer));
    this._logTimers = [];
    this._logAnimations.forEach(anim => anim.stop(true));
    this._logAnimations = [];
  }

  private onFrame() {
    if (this._chase) {
      if (!this.player.locked) {
        const delta = (Date.now() - this._lastFrameDate) / 5;
        this.surface.camera.move(this.surface.camera.x - delta, this.surface.camera.y + (delta / 2));
      }
      this._lastFrameDate = Date.now();
  
      if (this.player.culled && !this.player.locked) {
        this.restart();
      }
    } else if (!this.player.locked) {
      // If not chasing, follow player by default
      const camera = new Point3D(this.surface.camera.x, this.surface.camera.y);
      const playerGlobal = this.player.bounds.globalBounds.center;
      const player = new Point3D(
        -playerGlobal.x * this.surface.stage.surface.camera.zoom,
        -playerGlobal.y * this.surface.stage.surface.camera.zoom
      )
      const delta = new Point3D(camera.x - player.x, camera.y - player.y);
      delta.x = delta.x * .05;
      delta.y = delta.y * .05;
      this.surface.camera.move(this.surface.camera.x - delta.x, this.surface.camera.y - delta.y);
    }
  }

  private onFinish() {
    new Audio(finishSound).play();

    this.stop(true);
  }
  
  start(level) {
    this.initMap(level.map);

    if (!this.player) throw new Error('Player not defined on map.');
    if (!this.finish) throw new Error('Finish not defined on map.');

    if (level.clouds) this.initClouds();
    if (level.cars) this.initCars();
    this.initLogs();
    this._chase = level.chase;
    this._quest = level.quest;
    this.ui.night = level.night;
    this.ui.hint = level.hint;
    this.ui.fail = '';
    this.surface.camera.zoom = level.zoomed ? 4 : 3;

    // Fade in stuff to playground

    this.surface.stage.children.forEach(child => {
      if (!(child instanceof Tile)) return;
      if (child instanceof Player || child instanceof Finish) return;
      child.fadeIn();
    });

    // Init intro sequence

    this.player.lock();
    this.finish.zoom();
    setTimeout(() => {
      this.finish?.fadeIn();
      setTimeout(() => {
        this.player.zoom(true, () => {
          this.player.fadeIn(() => {
            this.player.unlock();
          });
        })
      }, 1000)
    }, 1000);
  }

  restart() {
    this.ui.hearts--;
    if (this.ui.hearts <= 0) {
      this.gameover('No more hearts :(');
      return;
    }

    this.player.locked = this.player.culled;
    this.player.reset();
    this.player.zoom(true, () => {
      this.player.unlock();
    })
  }

  stop(finished: boolean) {
    this.player.lock();

    this.terminateCars();
    this.terminateLogs();
  
    // Destroy map
    this.surface.stage.children.forEach(child => {
      if (!(child instanceof Tile)) return;
      child.fadeOut();
    });

    setTimeout(() => {
      this.surface.stage.children.forEach(child => {
        child.detach();
      });
  
      this.coins = [];

      this.emit('stop', finished);
    }, 1000);
  }
  
  gameover(reason: string) {
    this.player.lock();
    this.ui.fail = reason;

    setTimeout(() => {
      this.ui.coins = 0;
      this.ui.gas = 100;
      this.ui.hearts = 3;
  
      this.stop(false);
    }, 3000);
  }

  showcase() {
    this.surface.camera.y = -200;
    const ground = new Ground(0, 0, 0);
    this.surface.stage.attach(ground);

    const ground2 = new Ground(2, 0, 0);
    this.surface.stage.attach(ground2);

    const ground3 = new Ground(2, 0, 1);
    this.surface.stage.attach(ground3);

    const van = new Van(0, 2 * TILE_SIZE, 0);
    this.surface.stage.attach(van);

    const van2 = new Van(2 * TILE_SIZE, 2 * TILE_SIZE, 0);
    van2.direction = 'left';
    this.surface.stage.attach(van2);

    const van3 = new Van(4 * TILE_SIZE, 2 * TILE_SIZE, 0);
    van2.direction = 'right';
    this.surface.stage.attach(van3);

    const porsche = new Porsche(0, 4 * TILE_SIZE, 0);
    this.surface.stage.attach(porsche);

    const porsche2 = new Porsche(2 * TILE_SIZE, 4 * TILE_SIZE, 0);
    porsche2.direction = 'left';
    this.surface.stage.attach(porsche2);

    const porsche3 = new Porsche(4 * TILE_SIZE, 4 * TILE_SIZE, 0);
    porsche3.direction = 'right';
    this.surface.stage.attach(porsche3);
  }
}
