import { Animation, Color } from '@celonis/surface-core';
import { TILE_SIZE, LAYER_DEPTH } from './constants';
import { Cube } from './Cube';
import { rand } from './utils';

const CLOUD_ANIMATION_DELTA = 500
const CLOUD_ANIMATION_DURATION = 10000

export class Cloud extends Cube {
  constructor(cx, cy, cz = 0) {
    const width = rand(2) * TILE_SIZE;
    const height = rand(2) * TILE_SIZE;
    super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, width, height, 10);
    this.style.color = new Color('#fff');
    this.style.radius = Math.round(Math.random() * (TILE_SIZE / 2));
    this.opacity = .3;

    const animate = () => {
      if (!this.stage) return;
  
      const delta = rand(CLOUD_ANIMATION_DELTA);
      new Animation(this, {
        y: { plus: delta }
      }, {
        duration: CLOUD_ANIMATION_DURATION + rand(CLOUD_ANIMATION_DURATION),
        easing: 'easeInOutQuad',
        complete: () => {
          new Animation(this, {
            y: { minus: delta }
          }, {
            duration: CLOUD_ANIMATION_DURATION + rand(CLOUD_ANIMATION_DURATION),
            easing: 'easeInOutQuad',
            complete: animate
          });
        }
      });
    }

    this.on('attachtostage', animate);
  }
}