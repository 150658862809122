export const chase = false;
export const clouds = false;
export const hint = 'Watch out for passing cars.';
export const zoomed = false;
export const cars = true;
export const night = false;
export const map = `
xutxx
xxfxx
xtxxt
rrrrr
yxxtx
yuhxx
yxxxy
`