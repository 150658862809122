import { Color } from "@celonis/surface-core";

export const rand = (num) => Math.floor(Math.random() * num) + 1;
export const randColor = () => new Color(Math.round(Math.random() * 255), Math.round(Math.random() * 255), Math.round(Math.random() * 255));
export const roundRect = function (ctx, x, y, width, height, radius, cc = false) {
	const values = Array.isArray(radius) ? radius : Array(4).fill(radius);
	const [topLeft, topRight, bottomRight, bottomLeft] = values;

	if (cc) {
		ctx.moveTo(x + topLeft, y);
		if (topLeft) ctx.quadraticCurveTo(x, y, x, y + topLeft);
		ctx.lineTo(x, y + height - bottomLeft);
		if (bottomLeft) ctx.quadraticCurveTo(x, y + height, x + bottomLeft, y + height);
		ctx.lineTo(x + width - bottomRight, y + height);
		if (bottomRight) ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - bottomRight);
		ctx.lineTo(x + width, y + topRight);
		if (topRight) ctx.quadraticCurveTo(x + width, y, x + width - topRight, y);
		ctx.lineTo(x + width - topRight, y);
		return;
	}

	ctx.moveTo(x + topLeft, y);
	ctx.lineTo(x + width - topRight, y);
	if (topRight) ctx.quadraticCurveTo(x + width, y, x + width, y + topRight);
	ctx.lineTo(x + width, y + height - bottomRight);
	if (bottomRight) ctx.quadraticCurveTo(x + width, y + height, x + width - bottomRight, y + height);
	ctx.lineTo(x + bottomLeft, y + height);
	if (bottomLeft) ctx.quadraticCurveTo(x, y + height, x, y + height - bottomLeft);
	ctx.lineTo(x, y + topLeft);
	if (topLeft) ctx.quadraticCurveTo(x, y, x + topLeft, y);
};
export const replaceAt = (text, index, replacement) => {
    return text.substring(0, index) + replacement + text.substring(index + replacement.length);
}