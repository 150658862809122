import { Color } from '@celonis/surface-core';
import { TILE_SIZE, LAYER_DEPTH } from './constants';
import { Tile } from './Tile';

export class Shadow extends Tile {
  constructor(cx, cy, cz = 0) {
    super(cx * TILE_SIZE, cy * TILE_SIZE, cz * LAYER_DEPTH, TILE_SIZE, TILE_SIZE, 0);
    this.regZ = LAYER_DEPTH;
    this.cube.style.color = new Color('#1d4297').transparent(.1);
  }

  get trap() {
    return true;
  }

  prepare() {
    const neighbors = this.neighbors;

    if (!neighbors.bottom && !neighbors.right) this.cube.style.radiusBottomRight = 20;
    if (!neighbors.top && !neighbors.left) this.cube.style.radiusTopLeft = 20;
    if (!neighbors.top && !neighbors.right) this.cube.style.radiusTopRight = 20;
    if (!neighbors.bottom && !neighbors.left) this.cube.style.radiusBottomLeft = 20;
  }
}