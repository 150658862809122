import { start, middle, end } from './levels/blocks';
import { rand, replaceAt } from './utils';

export function generator(config): string {
	let middleBlocks = '';
	while (middleBlocks.split('\n').length < config.rows) {
		if (middleBlocks.length) middleBlocks += '\n';
		middleBlocks += middle[rand(middle.length) - 1].trim();
	}

	let map = [
		end[rand(end.length) - 1].trim()
	,
		middleBlocks
	,
		start[rand(start.length) - 1].trim()
	].join('\n');

	var indices: number[] = [];
	for (let i = 0; i < map.length; i++) {
		if (map[i] === "?") indices.push(i);
	}

	let coins = config.coins || 0;
	while (coins--) {
		const i = rand(indices.length) - 1;
		map = replaceAt(map, indices[i], 'c');
	}

	// Replace the rest of questionmarks with ground.
	return map.replace(/\?/g, 'x');
}